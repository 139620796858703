import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ScaleContext } from '../../Context/ScaleContext'
import { ProfileContext } from '../../Context/ProfileContext'

import ProfileInfo from './ProfileInfo'
import ProfileStatus from './ProfileStatus'
import ProfilePassword from './ProfilePassword'

export default function ProfileSettings ({ tab, supplierId }) {
  const [attbContext] = useContext(ScaleContext)
  const [attContextProfile] = useContext(ProfileContext)
  const { isMobileView } = attbContext
  // const [to, setTo] = useState()
  // const [from, setFrom] = useState()
  const [clickedInstantCourier, setClickedInstantCourier] = useState(false)

  return (
    <div className='col-12 p-5'>
      <div className='row'>
        <div className='col-12 border'>
          <div className='menu-scroll-x border-bottom'>
            <ul className='nav nav-tab text-center'>
              <li className={`navigation ${tab === 'information' && 'active'}`} id='information'>
                <Link to={`/profile/${supplierId}?type=settings&tab=information`}>
                  <span className={`nav-item nav-link store-dashboard-primary ${isMobileView ? 'fs--s' : 'fs--m'}`}>
                    Informasi&nbsp;Toko
                  </span>
                </Link>
              </li>
              <li className={`navigation ${tab === 'status' && 'active'}`} id='status'>
                <Link to={`/profile/${supplierId}?type=settings&tab=status`}>
                  <span className={`nav-item nav-link store-dashboard-primary ${isMobileView ? 'fs--s' : 'fs--m'}`}>
                    Status
                  </span>
                </Link>
              </li>
              <li className={`navigation ${tab === 'password' && 'active'}`} id='password'>
                <Link to={`/profile/${supplierId}?type=settings&tab=password`}>
                  <span className={`nav-item nav-link store-dashboard-primary ${isMobileView ? 'fs--s' : 'fs--m'}`}>Password</span>
                </Link>
              </li>

            </ul>
          </div>

          {/* <div className='fs--s font-weight-bold py-2'>{
              (tab === 'information') ? 'Informasi Toko'
                : (tab === 'status') ? 'Status Toko'
                  : (tab === 'password') ? 'Password'
                    : null
            }
            </div> */}
          <div className='row p-4'>
            {attContextProfile.supplier_data
              ? (
                <div className={`col-12 ${!isMobileView ? 'border p-5 fs--m' : 'fs--s'}`}>
                  {tab === 'information' &&
                    <ProfileInfo
                      clickedInstantCourier={clickedInstantCourier}
                      setClickedInstantCourier={setClickedInstantCourier}
                    />}
                  {tab === 'status' &&
                    <ProfileStatus />}
                  {tab === 'password' && <ProfilePassword />}
                </div>
                )
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
