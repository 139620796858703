import React, { useState, useEffect, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import config from '../../../config'
import Loading from '../../../Components/Behaviors/Loading'
import { validationMpFlag } from '../../../Services/ValidationMpFlag'
import { fetchGetCarrierList, fetchShipmentBooking, fetchUpdateInvoice, fetchUpdateShipmentCarrier } from '../../../Services/FetchingGroup'

function PrintPackingSlipModal ({ invoice, onClickedModal, type, fetchUpdateInvoiceStatus, handleToogleModal }) {
  const carrierAutoList = config.carrierAuto?.concat(config?.carrierGosend)
  const [successShipmentCarrier, setSuccessShipmentCarrier] = useState({ success: false, data: {} })
  const [successShipmentBooking, setSuccessShipmentBooking] = useState({ success: false, data: {} })
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [carriers, setCarriers] = useState()
  const [carrierAuto, setCarrierAuto] = useState()
  const [choosenCarrier, setChoosenCarrier] = useState()
  const [refetchBooking, setRefetchBooking] = useState({ state: false, message: '' })

  const init = () => {
    setCarrierAuto()
    setChoosenCarrier()
    setRefetchBooking({ state: false, message: '' })
  }

  const handleOnChangeCarrier = (e, status) => {
    const { value } = e.target
    if (status === 'lvl1') {
      setCarrierAuto(parseInt(value))
      if (value !== 0) {
        setChoosenCarrier(parseInt(value))
      }
    } else if (status === 'lvl2') {
      setChoosenCarrier(parseInt(value))
    }
  }

  // =======================================//
  // ==  TEMBAKAN API  ==
  // =======================================//

  function handleChooseDelivery () {
    const body = {
      invoices: [
        {
          invoice_id: invoice.invoice_id,
          carrier_id: choosenCarrier
        }
      ]
    }
    fetchUpdateShipmentCarrier(body, setIsLoading, setErr, setSuccessShipmentCarrier)
  }

  const handleShipmentBooking = useCallback(() => {
    setRefetchBooking({ state: false, message: '' })
    const body = {
      invoices: [invoice.invoice_id]
    }
    fetchShipmentBooking(body, setIsLoading, setErr, setSuccessShipmentBooking)
  },
  [invoice]
  )

  useEffect(() => {
    fetchGetCarrierList({}, setCarriers, setIsLoading, setErr)
    if (invoice?.invoice_status === 'stand_by' && (invoice.shipment?.carrier?.carrier_id === 7 || invoice.shipment?.carrier?.carrier_id === 8)) {
      handleShipmentBooking()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (successShipmentCarrier.success) {
      // this for update invoice status, cause it's only need invoice id
      if (carrierAutoList.includes(choosenCarrier)) {
        handleShipmentBooking()
      } else {
        if (type === 'stand_by') {
          const bodyUpdate = {
            invoices: [invoice.invoice_id],
            status: 'ready_to_ship'
          }
          fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, null)
        } else if (type === 'ready_to_ship') {
          window.location.replace(config.baseURL + '/print?type=packing_slip&status=ready_to_ship&invoice_id=' + invoice.invoice_id)
        }
      }
      setSuccessShipmentCarrier({ success: false, data: {} })
    }

    if (successShipmentBooking.success) {
      const data = successShipmentBooking?.data
      if (data.invoices[0]?.is_awb_generated) {
        if (type === 'stand_by') {
          const bodyUpdate = {
            invoices: [invoice.invoice_id],
            status: 'ready_to_ship'
          }
          fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, null)
        } else if (type === 'ready_to_ship') {
          window.location.replace(config.baseURL + '/print?type=packing_slip&status=ready_to_ship&invoice_id=' + invoice.invoice_id)
        }
      } else {
        setRefetchBooking({ state: true, message: 'Try to refetch the booking code again to continue' })
      }
      setSuccessShipmentBooking({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successShipmentCarrier, successShipmentBooking])

  const renderSetDelivery = () => {
    if (type === 'stand_by') {
      return (
        <>
          <div className='row pl-5 mr-5 d-flex'>
            <div className='col-6 col-lg-5 fs--s font-weight-bold px-5 my-auto'>Pilih metode delivery</div>
            {refetchBooking.state
              ? (
                <div>
                  <button className='btn btn-s' onClick={() => handleShipmentBooking()}>
                    {(invoice.shipment?.carrier?.carrier_id === 7 || invoice.shipment?.carrier?.carrier_id === 8) ? 'MENCARI DRIVER KEMBALI' : 'REFETCH BOOKING CODE'}
                  </button>
                  {/* it's not really an error,just for awb generate value is false which means the booking codes isn't ready
                  to continue the booking code need to be ready */}
                </div>
                )
              : isLoading
                ? <center> <Loading type='bounce' /></center>
                : (
                  <div className='col-6 col-lg-7'>
                    <div className='input-text-bar'>
                      <select disabled={isLoading} className='input fs--s ' defaultValue={-1} onChange={(e) => handleOnChangeCarrier(e, 'lvl1')}>
                        <option disabled value={-1} hidden>Pilih metode delivery</option>
                        {carriers?.carriers?.map((carrier, index) => {
                        // 6 JNE JOB          7 gosend instant
                        // 8 gosend sameday   9 NCS
                          if (config?.carrierAuto?.includes(carrier.carrier_id)) {
                            const show = validationMpFlag(carrier.carrier_id, invoice?.supplier?.mp_flag_shipment)
                            if (show) return <option key={index} value={carrier.carrier_id}>{carrier.carrier_name}</option>
                            else return null
                          } else return null
                        })}
                        <option value={0}>Lainnya</option>
                      </select>
                    </div>
                  </div>
                  )}
          </div>
          {carrierAuto === 0
            ? (
              <div className='row pl-5 mr-5 pt-5 d-flex'>
                <div className='col-6 col-lg-5 fs--s font-weight-bold px-5 my-auto'>Masukkan nama 3PL</div>
                <div className='col-6 col-lg-7'>
                  <div className='input-text-bar'>
                    <select className='input fs--s ' defaultValue={-1} onChange={(e) => handleOnChangeCarrier(e, 'lvl2')}>
                      <option disabled value={-1} hidden>Nama 3PL</option>
                      {carriers?.carriers?.map((carrier, index) => {
                        // 6 JNE JOB          7 gosend instant
                        // 8 gosend sameday   9 NCS
                        if (!carrierAutoList.includes(carrier.carrier_id)) {
                          const show = validationMpFlag(carrier.carrier_id, invoice?.supplier?.mp_flag_shipment)
                          if (show) return <option key={index} value={carrier.carrier_id}>{carrier.carrier_name}</option>
                          else return null
                        } else return null
                      })}
                    </select>
                  </div>
                </div>
              </div>
              )
            : null}
        </>
      )
    } else if (type === 'ready_to_ship') {
      return (
        <div className='row pl-5 mr-5 d-flex'>
          <div className='col-12 pb-4'>
            <div className='info-box'>
              <i className='anticon icon-infocirlceo' />
              <span className='text'>Silahkan print ulang packing slip setelah mengganti delivery method baru</span>
            </div>
          </div>
          <div className='col-5  pb-4 fs--s font-weight-bold px-5 my-auto'> Current Delivery</div>
          <div className='col-7  pb-4 fs--s '><b>{invoice.shipment.carrier.carrier_name}</b></div>
          <div className='col-5 fs--s font-weight-bold px-5 my-auto'>Pilih metode delivery</div>
          {refetchBooking.state
            ? (
              <div>
                <button className='btn btn-s' onClick={() => handleShipmentBooking()}>
                  {(invoice.shipment?.carrier?.carrier_id === 7 || invoice.shipment?.carrier?.carrier_id === 8) ? 'MENCARI DRIVER KEMBALI' : 'REQUEST BOOKING CODE'}
                </button>
                {/* it's not really an error,just for awb generate value is false which means the booking codes isn't ready
                  to continue the booking code need to be ready */}
              </div>
              )
            : isLoading
              ? <center><Loading type='bounce' /></center>
              : (
                <select className='col-7 input-text-bar input fs--s ' defaultValue={-1} onChange={(e) => handleOnChangeCarrier(e, 'lvl1')}>
                  <option disabled value={-1} hidden>Pilih metode delivery</option>
                  {carriers?.carriers?.map((carrier, index) => {
                    const show = validationMpFlag(carrier.carrier_id, invoice?.supplier?.mp_flag_shipment)
                    if (show) {
                      if (carrier.carrier_type === invoice.shipment.carrier.carrier_type && carrier.carrier_id !== invoice.shipment.carrier.carrier_id) {
                        return <option key={index} value={carrier.carrier_id}>{carrier.carrier_name}</option>
                      } else return null
                    } else return null
                  })}
                </select>
                )}
        </div>
      )
    }
  }

  if (onClickedModal.printPackingSlipModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => { handleToogleModal('printPackingslipModal', 'close'); init() }}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <h2 className='mt-4'><b>Pilih Delivery {invoice.invoice_no}</b></h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            (isLoading)
              ? <center><Loading type='bounce' /></center>
              : (err.err)
                  ? (
                    <div className='row pt-4 d-flex text-center'>
                      <div className='col-12 error fs--s '>
                        <span>{err.message}</span>
                      </div>
                    </div>
                    )
                  : (
                    <>
                      {renderSetDelivery()}
                      {refetchBooking.state && (
                        <div className='row pt-4 d-flex'>
                          <div className='offset-5 col pl-0'>
                            <div className='fs--s error'>{refetchBooking.message}</div>
                          </div>
                        </div>
                      )}
                    </>
                    )
          }
        </Modal.Body>
        <Modal.Footer className=' justify-content-center text-center m-2'>
          <div className='col-12'>
            {/* <Link to={`/print?type=ready_to_ship&method=${choosenCarrier}`}> */}
            <button disabled={!choosenCarrier || refetchBooking.state || isLoading} onClick={() => handleChooseDelivery()} type='button' className='btn-primary btn-m btn-full'>Pilih Delivery</button>
            {/* </Link> */}
          </div>

        </Modal.Footer>
      </Modal>
    )
  } else {
    return null
  }
}

export default PrintPackingSlipModal
