import { fork, all } from 'redux-saga/effects'
import API from '../Services/Api'

import { login, logout, getUserRole, getSupplierData } from './UserSagas'
import { getCount, getCountProduct } from './CountSagas'
import { getSupplierList, getCarrierList, getCommentTopicList } from './ListSagas'
import { getComment } from './CommentSagas'

import GET_TOKEN from '../Services/GetToken'

const api = API.create()
const token = GET_TOKEN.getToken

function * userSagas () {
  yield all([
    fork(login, api),
    fork(logout, api),
    fork(getSupplierData, api, token),
    fork(getUserRole, api, token)
  ])
}

function * countSagas () {
  yield all([
    fork(getCount, api, token),
    fork(getCountProduct, api, token)
  ])
}

function * listSagas () {
  yield all([
    fork(getSupplierList, api, token),
    fork(getCarrierList, api, token),
    fork(getCommentTopicList, api, token)
  ])
}

function * commentSagas () {
  yield all([
    fork(getComment, api, token)
  ])
}

export default function * rootSaga () {
  yield all([
    fork(userSagas),
    fork(listSagas),
    fork(countSagas),
    fork(commentSagas)
  ])
}
