import React from 'react'

import BaseQuery from '../Base/BaseQuery'

import DefaultLayout from '../Containers/DefaultLayout'
import ProfileHomeGroup from '../Containers/Profiles/Home/ProfileHomeGroup'
import NoAccessRules from '../Containers/NoAccessRules'

import { useSelector } from 'react-redux'

import { checkRole } from '../Services/CheckRole'

function Profile (props) {
  const { supplierId } = props.match.params
  const type = BaseQuery().get('type')
  const tab = BaseQuery().get('tab')

  const user = useSelector(state => state.user)
  return (
    <DefaultLayout pages='seller-profile'>
      {checkRole({
        menu: 'supplier_profile',
        method: 'view',
        attribute: '',
        yes: (access) => {
          return <ProfileHomeGroup supplierId={supplierId} type={type} tab={tab} />
        },
        no: (access) => { return <NoAccessRules /> },
        userRules: user?.role
      })}

    </DefaultLayout>
  )
}

export default Profile
