export const validationMpFlag = (carrierId, mpFlagShipment) => {
  // Added any carrier_id that were mp_flag_shipment have, to exclude or included from that validation
  switch (carrierId) {
    case 33:
      if (mpFlagShipment.includes('jtr')) return true
      else return false

      // case 3:
      //   if (mpFlagShipment.includes('ownfleet')) return true
      //   else return false
    default:
      return true
  }
}
