import React from 'react'
import BaseQuery from '../Base/BaseQuery'
import DefaultLayout from '../Containers/DefaultLayout'
import Pendings from '../Containers/Pendings/Pendings'

export default function Pending () {
  const supplier = BaseQuery().get('supplier')
  const supplierName = BaseQuery().get('supplier_name')
  const supplierId = BaseQuery().get('supplier_id')

  return (
    <DefaultLayout pages='pending'>
      <Pendings supplier={supplier} supplierName={supplierName} supplierId={supplierId} />
    </DefaultLayout>
  )
}
