import { put, fork, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import CommentActions, { CommentTypes } from '../Redux/CommentRedux'
import UserActions from '../Redux/UserRedux'
import isEmpty from 'lodash/isEmpty'

export function * getComment (api, getToken) {
  let action = yield take(CommentTypes.COMMENT_REQUEST)
  while (action !== END) {
    yield fork(fetchGetComment, api, getToken, action)
    action = yield take(CommentTypes.COMMENT_REQUEST)
  }
}

export function * fetchGetComment (api, getToken, { data }) {
  const token = yield call(getToken)
  if (token) {
    try {
      const res = yield call(api.getCommentList, data, token)
      if (!res.ok) {
        yield put(CommentActions.commentFailure())
      } else {
        if (isEmpty(res.data.errors)) {
          const data = res.data.data
          if (data) {
            yield put(CommentActions.commentSuccess(data))
          }
        } else {
          if (!isEmpty(res.data.errors.message)) {
            yield put(CommentActions.userFailure(res.data.errors.message))
          } else {
            yield put(CommentActions.commentFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
          }
        }
      }
    } catch (error) {
      yield put(CommentActions.commentFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
    }
  } else yield put(UserActions.userFailure('Token tidak ditemukan, mohon untuk melakukan login ulang'))
}
