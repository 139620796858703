import React, { useContext, useEffect, useState } from 'react'
import { ScaleContext } from '../../../Context/ScaleContext'
import JoditEditor from 'jodit-react'
import config from '../../../config'

export default function ProductDescriptionForm ({ setProduct, method, product }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [isOnBlur, setIsOnBlur] = useState(false)
  const [productDescription, setProductDescription] = useState({})

  useEffect(() => {
    // for setProduct when jodit onblur
    // there is a bug when using onblur for set the value
    // using onchange to directly set the product object will make it look lagging
    // using onblur directly just going pass us the focus event not the value of it, only onChange that pass the value like what expected
    if (isOnBlur) {
      setProduct(product => ({ ...product, ...productDescription }))
      setIsOnBlur(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnBlur])

  const handleSetProduct = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (name === 'videos') {
      const split = value.split(',')
      value = split
    }
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderWYSWYG (name, value) {
    if (method === 'add' || method === 'update') {
      return (
        <div className='form-group jodit-ul'>
          <JoditEditor
            name={name}
            config={config.textEditorConfig}
            onChange={(e) => setProductDescription({ ...productDescription, [name]: e })}
            onBlur={() => setIsOnBlur(true)}
            value={product[name] || ''}
          />
        </div>
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <div className='input-text-bar jodit-ul'>
            <div className='inner-html' dangerouslySetInnerHTML={{ __html: value || '-' }} />
          </div>
        </div>
      )
    } else return null
  }

  function renderVideo () {
    return (
      <input disabled={method === 'view'} className={isMobileView ? 'fs--s' : 'fs--m'} defaultValue={method === 'update' || method === 'view' ? (product.videos?.toString() || '') : ''} placeholder={method !== 'view' ? 'Ex: https://www.youtube.com/watch?v=fJ9rUzIMcZQ,https://www.youtube.com/watch?v=kx5TWKPE5sU' : ''} name='videos' type='text' onBlur={(e) => handleSetProduct(e)} />
    )
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Deskripsi Produk</div>
        <div className='row spacing-add-product no-align'>
          <div className='col-2'>Deskripsi produk<span className='required'>*</span></div>
          <div className='col'>

            <div className='input-text-bar input-text-area'>
              {renderWYSWYG('description', product?.description)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product no-align'>
          <div className='col-2'>
                  Spesifikasi produk<span className='required'>*</span>
          </div>
          <div className='col'>
            <div className='input-text-bar input-text-area'>
              {renderWYSWYG('specification', product?.specification)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product no-align'>
          <div className='col-2'>
                  How to Use
          </div>
          <div className='col'>
            <div className='input-text-bar input-text-area'>
              {renderWYSWYG('how_to_use', product?.how_to_use)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product no-align'>
          <div className='col-2'>
                 Tips Trick
          </div>
          <div className='col'>
            <div className='input-text-bar input-text-area'>
              {renderWYSWYG('tips_trick', product?.tips_trick)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product '>
          <div className='col-2'>Video produk</div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderVideo()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // DESAIN YANG MASIH UNTUK ADMIN
  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4 fs--s '>
        <div className='col-12 fs--m font-weight-bold my-4 text-center'>Deskripsi Produk</div>
        <div className='row spacing-add-product no-align'>
          <div className='col-12 mb-2 fs--s '>Deskripsi Produk<span className='required'>*</span></div>
          <div className='col'>
            <div className='fs--s'>
              {renderWYSWYG('description', product?.description)}
            </div>

          </div>
        </div>

        <div className='row spacing-add-product no-align'>
          <div className='col-12 mb-2 fs--s '>Spesifikasi Produk<span className='required'>*</span></div>
          <div className='col'>
            <div className='fs--s'>
              {renderWYSWYG('specification', product?.specification)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product no-align'>
          <div className='col-12 mb-2 fs--s '>How To Use<span className='required'>*</span></div>
          <div className='col'>
            <div className='fs--s'>
              {renderWYSWYG('how_to_use', product?.how_to_use)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product no-align'>
          <div className='col-12 mb-2 fs--s '>Tips Trick<span className='required'>*</span></div>
          <div className='col'>
            <div className='fs--s'>
              {renderWYSWYG('tips_trick', product?.tips_trick)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product no-align'>
          <div className='col-12 mb-2 fs--s '>Video Produk<span className='required'>*</span></div>
          <div className='col-12'>
            <div className='input-text-bar fs--s '>
              {renderVideo()}
            </div>
          </div>
        </div>
      </div>

    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
