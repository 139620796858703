import React, { useEffect, useState } from 'react'

import { fetchUpdatePrice } from '../../../Services/FetchingGroup'

import dayjs from 'dayjs'
import UpdatePrice from '../../../Components/Products/Modals/UpdatePrice'
import { updatePriceSchema } from '../../../Validations/Product'
import { notifyError } from '../../../Services/Notify'
import { isEmpty } from 'lodash'

export default function UpdatePriceModal ({ params, onClickedUpdatePriceModal, handleToggleUpdatePriceModal, product, handleChangeParams }) {
  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)

  const [price, setPrice] = useState({
    store_code: params.store_code,
    price: product?.price || '',
    special_price: product?.special_price || '',
    special_to_date: product?.special_to_date ? (product?.special_to_date !== '1970-01-01 00:00:00' ? new Date(product?.special_to_date) : '') : '', // if the special date were set empty BE will give feedback as 1970-01-01 00:00:00 default
    special_from_date: product?.special_from_date ? (product?.special_from_date !== '1970-01-01 00:00:00' ? new Date(product?.special_from_date) : '') : '' // if the special date were set empty BE will give feedback as 1970-01-01 00:00:00 default
  })

  async function handleUpdatePrice () {
    const priceModified = { ...price }
    if (priceModified.special_from_date) {
      priceModified.special_from_date = dayjs(priceModified.special_from_date).format('YYYY-MM-DD HH:mm')
    }
    if (priceModified.special_to_date) {
      priceModified.special_to_date = dayjs(priceModified.special_to_date).format('YYYY-MM-DD HH:mm')
    }
    const body = {
      sku: product.sku,
      old_price: product.price,
      old_special_price: product.special_price,
      ...priceModified
    }
    if (body.special_price === '') body.special_price = 0
    if (body.price === '') body.price = product.price

    setErr({ err: false, message: '' })
    let isValid = await updatePriceSchema.isValid(body)

    if (body.special_price !== 0) {
      let errMsg = ''
      if (isEmpty(body.special_to_date) || isEmpty(body.special_from_date)) errMsg = 'Harap untuk melengkapi tanggal pengadaan harga spesial'
      if (dayjs(body.special_to_date).isBefore(body.special_from_date)) errMsg = 'Tanggal dimulainya harga spesial tidak boleh lebih besar dari tanggal berakhirnya'
      if (errMsg !== '') {
        isValid = false
        setErr({ err: true, message: errMsg })
        return notifyError(errMsg)
      }
    }
    if (isValid) {
      return fetchUpdatePrice(body, setIsLoading, setErr, setSuccess)
    } else {
      const errorMessage = await updatePriceSchema.validate(body).catch(function (err) {
        if (err.errors.length > 0) return err.errors[0]
      })
      setErr({ err: true, message: errorMessage })
      return notifyError(errorMessage)
    }
  }

  const handleOnChange = (type, values, e) => {
    let value = e.target?.value || values
    const name = e.target?.name || type
    if (name === 'special_to_date' || name === 'special_from_date') {
      if (value !== '') value = new Date(value)
    } else {
      // for price
      if (value !== '') value = parseInt(value)
    }
    setPrice({ ...price, [name]: value })
  }

  useEffect(() => {
    if (success.success) {
      handleToggleUpdatePriceModal('close', product)
      handleChangeParams(params)
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <UpdatePrice
      product={product}
      price={price}
      fetching={isLoading}
      error={err}
      onClickedUpdatePriceModal={onClickedUpdatePriceModal}
      handleOnChange={handleOnChange}
      handleUpdatePrice={handleUpdatePrice}
      handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
    />
  )
}
