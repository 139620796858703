import React, { useState, useContext, useEffect } from 'react'
import { ProfileProvider } from '../../../Context/ProfileContext'
import ProfileSettings from '../ProfileSettings'
import { ScaleContext } from '../../../Context/ScaleContext'

import { fetchCountInvoice, fetchGetProfileInfo } from '../../../Services/FetchingGroup'
import ProfileBrief from './ProfileBrief'
import ProfileHomeMobile from '../../../Components/Profiles/Home/ProfileHomeMobile'
import ProfileHomeDesktop from '../../../Components/Profiles/Home/ProfileHomeDesktop'
import { useSelector } from 'react-redux'

import { isEmpty } from 'lodash'

export default function ProfileHomeGroup ({ type, supplierId, tab }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const user = useSelector(state => state.user)

  const [profile, setProfile] = useState({})
  const [countOrder, setCountOrder] = useState({})

  const params = { supplier_id: parseInt(supplierId) !== 0 ? parseInt(supplierId) : '' }

  useEffect(() => {
    if (supplierId) {
      if (user?.user?.role_id !== 2) {
        fetchCountInvoice({ supplier: 'mp' + supplierId }, setCountOrder, setIsLoading, setErr)
      }
      getProfile()
    }
    return () => {
      setProfile({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getProfile () {
    fetchGetProfileInfo(params, setProfile, setIsLoading, setErr)
  }

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)

  function renderContent () {
    switch (type) {
      case 'default':
        return <ProfileBrief fetching={isLoading} error={err} supplierId={supplierId} type={type} countOrder={countOrder} />

      case 'settings':
        return <ProfileSettings fetching={isLoading} error={err} supplierId={supplierId} type={type} tab={tab} />
      default:
        break
    }
  }
  if (!isEmpty(profile)) {
    return (
      <ProfileProvider supplier_data={profile} getProfile={getProfile}>
        {isMobileView
          ? (
            <ProfileHomeMobile
              supplierId={supplierId}
              type={type}
              tab={tab}
              countOrder={countOrder}
              renderContent={renderContent}
            />
            )
          : (
            <ProfileHomeDesktop
              supplierId={supplierId}
              type={type}
              tab={tab}
              countOrder={countOrder}
              renderContent={renderContent}
            />
            )}
      </ProfileProvider>
    )
  } else return null
}
