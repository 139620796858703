import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../Components/Behaviors/Loading'

import ListActions from '../../Redux/ListRedux'

export default function CommentSellerHeader ({ params, handleParamsChange, total, isLoading, err }) {
  // const [paramsModified, setParamsModified] = useState({ })
  const user = useSelector(state => state.user)
  const list = useSelector(state => state.list)

  const dispatch = useDispatch()

  useEffect(() => {
    if (list.comment_topic === null) {
      dispatch(ListActions.commentTopicRequest())
    }
    if (list.supplier === null && user.user?.role_id !== 2) {
      dispatch(ListActions.supplierListRequest())
    }
    if (list.carrier === null) {
      dispatch(ListActions.carrierRequest())
    }
    // eslint-disable-next-line
  }, [])

  const handleOnChange = (event) => {
    event.preventDefault()
    const { value, name } = event.target
    handleParamsChange({ [name]: value })
  }

  return (
    <div className='row align-items-center'>
      <div className='col-12'>
        <h2 className='font-weight-bold pb-3'>Comment Seller</h2>
        <h4 className='pb-5'>{total} Comment From Seller</h4>
      </div>
      {user?.user?.role_id !== 2 &&
        <div className='col-12'>
          <div className='row'>
            <div className='col'>
              <div className='font-weight-bold fs--s pb-3'>
                Filter berdasarkan Seller
              </div>
              {isLoading
                ? (
                  <div className='col-6 pl-0'>
                    <center><Loading type='bounce' /></center>
                  </div>
                  )
                : err?.err
                  ? (
                    <div className='col-6 pl-0'>
                      <span className='fs--s error'>{err.message}</span>
                    </div>
                    )
                  : (
                    <div className='input-text-bar'>
                      <select className='input fs--s ' name='supplier' value={params.supplier || ''} onChange={(e) => handleOnChange(e)}>
                        <option value=''>Semua Seller {list.supplier?.total && `(${list.supplier.total})`}</option>
                        {list.supplier?.suppliers?.map((supplier, index) => {
                          return (
                            <option value={supplier.supplier_alias} key={index}>{supplier.name} ({supplier.supplier_alias})</option>
                          )
                        })}
                      </select>
                    </div>
                    )}
            </div>

            <div className='col'>
              <div className='font-weight-bold fs--s pb-3'>
                Filter berdasarkan Topik
              </div>
              {isLoading
                ? (
                  <div className='col-6 pl-0'>
                    <center><Loading type='bounce' /></center>
                  </div>
                  )
                : err?.err
                  ? (
                    <div className='col-6 pl-0'>
                      <span className='fs--s error'>{err.message}</span>
                    </div>
                    )
                  : (
                    <div className='input-text-bar'>
                      <select className='input fs--s ' name='topic' value={params.topic || ''} onChange={(e) => handleOnChange(e)}>
                        <option value=''>Semua Topik </option>
                        {list.comment_topic?.comments?.map((comment, index) => {
                          return (
                            <option value={comment.topic_id} key={index}>{comment.name}</option>
                          )
                        })}
                      </select>
                    </div>
                    )}
            </div>
          </div>
        </div>}
    </div>
  )
}
