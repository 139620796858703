// untuk mobile view bagian order group
import React from 'react'
import { Modal } from 'react-bootstrap'
import OrderComment from '../Orders/OrderComment'

function CommentModal ({ onClickedModal, handleToogleModal, invoice }) {
  if (onClickedModal.commentModal) {
    return (
      <Modal
        show
        size='l'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('commentModal', 'close')}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold p-3'>Komentar {invoice.invoice_no}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderComment invoice={invoice} />
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  } else {
    return null
  }
}

export default CommentModal
