import React, { useContext } from 'react'
import config from '../../config'
import { ScaleContext } from '../../Context/ScaleContext'

import imageDefault from '../../static/images/WaitingForApproval.jpg'
import noImagePlacehoder from '../../static/images/noImage.png'

export default function ThumbnailProduct ({ product }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext
  function renderDesktop () {
    return (
      <div className='row fs--s '>
        <div className='col-12 pb-2 font-weight-bold'>{product.name}</div>
        <div className='col-11 pb-2'>
          <div className='row'>
            <div className='col-4'>SKU Ruparupa:</div>
            <div className='col-3'>{product.sku}</div>
          </div>
        </div>
        <div className='col-11 pb-2'>
          <div className='row'>
            <div className='col-4'>SKU Toko:</div>
            <div className='col-3'>{product.sku_seller}</div>
          </div>
        </div>
      </div>
    )
  }

  function renderMobile () {
    return (
      <div className='row'>
        <ul>
          <li className='fs--m'><b>{product.name}</b></li>
          <li>SKU Ruparupa:</li>
          <li>{product.sku}</li>
          <li>SKU Toko:</li>
          <li>{product.sku_seller}</li>
        </ul>
      </div>
    )
  }
  return (
    // tambahin class set-product-status kalo dari status card
    <div className='info-box fs--s '>
      <div className='row align-items-center'>
        <div className='col-4 text-center'>
          <img src={product.image_url ? config.imageURL + product.image_url : imageDefault} width='80px' alt='thumbnail' onError={(e) => { e.target.src = noImagePlacehoder }} />
        </div>
        <div className='col'>
          {isMobileView ? renderMobile() : renderDesktop()}
        </div>

      </div>
    </div>
  )
}
