import React, { useState, useEffect, useContext } from 'react'
import { ScaleContext } from '../../../Context/ScaleContext'

export default function ProductInformationForm ({ product, setProduct, categorySelected, categoryListLvl1, categoryListLvl2, categoryListLvl3, categoryListLvl4, brandList, handleCategoryLevel, method }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext
  const [categorySelect, setCategorySelect] = useState(categorySelected)

  useEffect(() => {
    if (categorySelected !== categorySelect) {
      setCategorySelect(categorySelected)
    }
  }, [categorySelected, categorySelect, method])

  const handleSetProduct = (e) => {
    const { name, value } = e.target
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderBrand (brandList) {
    if (method === 'add' || method === 'update') {
      return (
        <select
          className='input' name='brand' defaultValue={method === 'update' ? (product.brand || '') : ''}
          onChange={(e) => { handleSetProduct(e) }}
        >
          <option value='' hidden>Pilih Brand</option>
          {
            brandList && brandList.map((brand, index) => {
              return (
                <option value={brand.brand_id} key={index}>{brand.name}</option>
              )
            })
          }
        </select>
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name='brand' type='text' value={product.brand || '-'} disabled />
        </div>
      )
    } else return null
  }

  function renderCategory (categoryList, categoryValue, lvl) {
    let filter = ''
    if (categoryValue) {
      if (method === 'view' || method === 'update') {
        filter = categoryValue.filter(category => category.breadcrumb_position === lvl)
      }
    }
    if (method === 'add') {
      return (
        <select className='input' name={'lvl' + lvl} value={categoryValue[lvl - 1]} disabled={lvl > 1 && !(categoryList?.data && categorySelect[lvl - 2] !== '')} onChange={(e) => handleCategoryLevel(e, lvl + 1)}>
          <option disabled value='' hidden>Pilih Category</option>
          {
            (lvl > 1) ? (
              categoryList?.data && categorySelect[lvl - 2] !== '' && categoryList.data?.map((category, index) => {
                return (
                  <option value={category.category_id} key={index}>{category.name}</option>
                )
              })
            )
              : categoryList.data && categoryList.data.map((category, index) => {
                return (
                  <option value={category.category_id} key={index}>{category.name}</option>
                )
              })
          }
        </select>
      )
    } else if (method === 'view' || method === 'update') {
      return (
        <div className='view-only'>
          <input name='name' className={isMobileView ? 'fs--s' : 'fs--m'} type='text' value={filter[0]?.name || '-'} disabled />
        </div>
      )
    } else return null
  }

  function renderDimension (name) {
    if (method === 'add' || method === 'update') {
      if (name === 'packaging_uom') {
        return (
          <select
            className='input' name='packaging_uom' defaultValue={product?.packaging_uom || 'CM'}
            onChange={(e) => handleSetProduct(e)}
          >
            <option value='M'>Meter</option>
            <option value='CM'>Centimeter</option>
            <option value='MM'>Milimeter</option>
          </select>
        )
      } else {
        return (
          <input
            placeholder='0.00' type='text' name={name} defaultValue={product[name]}
            onBlur={(e) => handleSetProduct(e)}
          />)
      }
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={product[name] || '0'} disabled />
        </div>
      )
    } else return null
  }

  function renderInputText (name, placeholder) {
    if (method === 'add' || method === 'update') {
      return (
        <input
          className={isMobileView ? 'fs--s' : 'fs--m'} placeholder={placeholder} name={name} type='text' defaultValue={product[name] || ''}
          onBlur={(e) => handleSetProduct(e)}
        />)
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={product[name] || '-'} disabled />
        </div>
      )
    } else return null
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Informasi produk</div>
        {/* ============================== */}
        <div className='row spacing-add-product '>
          <div className='col-2'>Nama Produk<span className='required'>*</span></div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('name', 'Ex: Set Stoples Vintage Small')}
            </div>
          </div>
        </div>
        {method === 'view' &&
          <div className='row spacing-add-product '>
            <div className='col-2'>URL Key</div>
            <div className='col'>
              <div className='input-text-bar'>
                {renderInputText('url_key', 'Ex: Set Stoples Vintage Small')}
              </div>
            </div>
          </div>}
        {/* ============================== */}
        {/* ============================== */}
        <div className='row spacing-add-product'>
          <div className='col-2'>Kategori<span className='required'>*</span></div>
          <div className='col-10'>
            <div className='row'>
              <div className='col-3'>
                <div className='fs--s'>Level 1</div>
                <div className='input-text-bar'>
                  {renderCategory(categoryListLvl1, categorySelect, 1)}
                </div>
              </div>
              <div className='col-3'>
                <div className='fs--s'>Level 2</div>
                <div className='input-text-bar'>
                  {renderCategory(categoryListLvl2, categorySelect, 2)}
                </div>
              </div>
              <div className='col-3'>
                <div className='fs--s'>Level 3</div>
                <div className='input-text-bar'>
                  {renderCategory(categoryListLvl3, categorySelect, 3)}
                </div>
              </div>
              <div className='col-3'>
                <div className='fs--s'>Level 4</div>
                <div className='input-text-bar'>
                  {renderCategory(categoryListLvl4, categorySelect, 4)}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>Brand<span className='required'>*</span></div>
          <div className='col-7'>
            <div className='input-text-bar'>
              {renderBrand(brandList)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>Dimensi Produk<span className='required'>*</span></div>
          <div className='col-10'>
            <div className='row align-items-center'>
              <div className='col-3'>
                <div className='fs--s'>Satuan ukuran</div>
                <div className='input-text-bar'>
                  {renderDimension('packaging_uom')}
                </div>
              </div>
              <div className='col-3'>
                <div className='fs--s'>Panjang</div>
                <div className='input-text-bar'>
                  {renderDimension('product_length')}
                </div>
                <div className='operator-position'>X</div>
              </div>

              <div className='col-3'>
                <div className='fs--s'>Tinggi</div>
                <div className='input-text-bar'>
                  {renderDimension('product_height')}
                </div>
                <div className='operator-position'>X</div>
              </div>

              <div className='col-3'>
                <div className='fs--s'>Lebar</div>
                <div className='input-text-bar'>
                  {renderDimension('product_width')}

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ============================== */}
        {/* ============================== */}
        <div className='row spacing-add-product'>
          <div className='col-2'>Package Content<span className='required'>*</span></div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('package_content', 'Masukkan Package Content')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // its only for add
  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4 fs--s '>
        <div className='col-12 fs--m font-weight-bold my-4 text-center'>Informasi Produk</div>

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2'>Nama Produk<span className='required'>*</span></div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('name', 'Ex: Set Stoples Vintage Small')}
            </div>
          </div>
        </div>
        {method === 'view' &&
          <div className='row spacing-add-product'>
            <div className='col-12 mb-2'>URL Key<span className='required'>*</span></div>
            <div className='col'>
              <div className='input-text-bar'>
                {renderInputText('url_key', 'Ex: Set Stoples Vintage Small')}
              </div>
            </div>
          </div>}

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2'>Kategori<span className='required'>*</span></div>
          <div className='col-12 mb-2'>Level 1</div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderCategory(categoryListLvl1, categorySelect, 1)}
            </div>
          </div>
          <div className='col-12 mb-2'>Level 2</div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderCategory(categoryListLvl2, categorySelect, 2)}
            </div>
          </div>
          <div className='col-12 mb-2'>Level 3</div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderCategory(categoryListLvl3, categorySelect, 3)}
            </div>

          </div>
          <div className='col-12 mb-2'>Level 4</div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderCategory(categoryListLvl4, categorySelect, 4)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Brand<span className='required'>*</span></div>
          <div className='col fs--s '>
            <div className='input-text-bar'>
              {renderBrand(brandList)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Dimensi produk<span className='required'>*</span></div>
          <div className='col-12'>
            <div className='row centralized-content'>
              <div className='col-4 pl-0 fs--s '>Satuan Ukuran</div>
              <div className='col-7 none-horizontal-padding fs--s '>
                <div className='input-text-bar'>
                  {renderDimension('packaging_uom')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-4'>
            <div className='input-text-bar'>
              {renderDimension('product_length')}
            </div>
            <div className='fs--xs text-center'>Panjang</div>
            <div className='mobile-operator-position'>X</div>
          </div>

          <div className='col-4'>
            <div className='input-text-bar'>
              {renderDimension('product_height')}
            </div>
            <div className='fs--xs text-center'>Tinggi</div>
            <div className='mobile-operator-position'>X</div>
          </div>
          <div className='col-4'>
            <div className='input-text-bar'>
              {renderDimension('product_width')}
            </div>
            <div className='fs--xs text-center'>Lebar</div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Package Content<span className='required'>*</span></div>
          <div className='col-12'>
            <div className='input-text-bar fs--s '>
              {renderInputText('package_content', 'Masukkan Package Content')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
