import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProductAdditionalInformationForm from '../../../Components/Products/Forms/ProductAdditionalInformationForm'
import ProductDescriptionForm from '../../../Components/Products/Forms/ProductDescriptionForm'
import ProductInformationForm from '../../../Components/Products/Forms/ProductInformationForm'
import ProductMetaInformationForm from '../../../Components/Products/Forms/ProductMetaInformationForm'
import ProductStockAndPriceForm from '../../../Components/Products/Forms/ProductStockAndPriceForm'
import ProductVariantInformationForm from '../../../Components/Products/Forms/ProductVariantInformationForm'
import ProductWeightAndDeliveryForm from '../../../Components/Products/Forms/ProductWeightAndDeliveryForm'
import config from '../../../config'
import { ScaleContext } from '../../../Context/ScaleContext'

import { fetchGetProductDetail, fetchGetExpressCarriers, fetchGetOwnfleetTemplate } from '../../../Services/FetchingGroup'
import ViewProductDetailDesktop from './ViewProductDetailDesktop'
import ViewProductDetailMobile from './ViewProductDetailMobile'

export default function ViewProductDetailGroup ({ type, sku, storeCode, supplierAlias, supplierId }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [product, setProduct] = useState()
  const [productModified, setProductModified] = useState({})

  const user = useSelector(state => state.user)

  const warrantyLabel = [
    'warranty_unit', 'warranty_part', 'warranty_service', 'warranty_contributor'
  ]

  const [expressCarrierSelected] = useState([])

  const [categorySelected, setCategorySelected] = useState([])
  const [attributeList, setAttributeList] = useState([])
  const [expressCarrierList, setExpressCarrierList] = useState()
  const [ownfleetList, setOwnfleetList] = useState([])

  const [isLoading, setIsLoading] = useState()
  const [err, setErr] = useState({ err: false, message: '' })

  useEffect(() => {
    if (sku) {
      const params = { sku: sku }
      fetchGetProductDetail(params, setProduct, setIsLoading, setErr)
    }
  }, [sku])

  useEffect(() => {
    // for reset purpose
    if (!isEmpty(product) && isEmpty(productModified)) {
      // need to construct the data first
      handleInit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  function handleInit () {
    // need to construct the data first
    const body = {
      attribute_set_id: product.attribute_set_id,
      attribute_variant_set_id: product.attribute_variant_set_id,
      attribute_variants: product.variants[0].attributes,
      attributes: product.attributes,
      brand: product.brand.name,
      express_courier: product.variants[0].express_courier,
      description: product.description,
      gtin: product.variants[0].gtins,
      how_to_use: product.how_to_use,
      images: product.variants[0].images,
      is_accessories: product.is_accessories,
      is_sparepart: product.is_sparepart,
      manufacture_no: product.variants[0].manufacture_no,
      meta_description: product.seo.meta_description,
      meta_title: product.seo.meta_title,
      name: product.name,
      ownfleet_template_name: product.variants[0].ownfleet_template_name,
      package_content: product.package_content,
      packaging_height: product.packaging_height,
      packaging_length: product.packaging_length,
      packaging_uom: product.packaging_uom,
      packaging_width: product.packaging_width,
      product_height: product.product_height,
      product_length: product.product_length,
      product_width: product.product_width,
      sku_seller: product.variants[0].sku_seller,
      specification: product.specification,
      tag_search: product.tag_search,
      tips_trick: product.tips_trick,
      url_key: product.url_key,
      warranty_contributor: product.warranty.warranty_contributor,
      warranty_part: product.warranty.warranty_part,
      warranty_service: product.warranty.warranty_services,
      warranty_unit: product.warranty.warranty_unit,
      weight: product.weight
    }
    setAttributeList({ additional_attributes: product.attributes, attribute_variants: product.variants[0].attributes })
    if (product.variants[0]?.gtins) {
      const gtinArr = []
      const gtins = product.variants[0]?.gtins
      gtins.forEach(element => {
        gtinArr.push(element.gtin)
      })
      body.gtin = gtinArr.toString()
    }
    if (product.variants[0].videos) {
      const videoArr = []
      const video = product.variants[0].videos
      video.forEach(element => {
        videoArr.push(element.video_url)
      })
      body.videos = videoArr.toString()
    }
    // initial express courier
    if (!isEmpty(product?.variants[0]?.express_courier) && isEmpty(expressCarrierSelected)) {
      const courier = product?.variants[0]?.express_courier || []
      courier.forEach(element => {
        expressCarrierSelected.push(element.carrier_id)
      })
    }
    setProductModified(body)
  }

  useEffect(() => {
    if (product?.categories && isEmpty(categorySelected)) {
      const sort = product?.categories.sort((a, b) => {
        return a.breadcrumb_position - b.breadcrumb_position
      })
      setCategorySelected(sort)
    }

    if (!isEmpty(product)) {
      handleInit()
    }

    // if (product?.attributes && isEmpty(attributeList)) {
    //   setAttributeList(product?.attributes)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])
  useEffect(() => {
    const supplierID = user?.user?.role_id !== 2 ? supplierId : user?.supplier?.supplier_id
    fetchGetOwnfleetTemplate({ supplier_id: supplierID }, setOwnfleetList, setIsLoading, null)
    fetchGetExpressCarriers({ carrier_type: 'instant,sameday' }, setExpressCarrierList, setIsLoading, setErr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderImages () {
    const images = product.variants[0]?.images || []
    if (images.length > 0) {
      let title = ''
      return (
        images.map((image, index) => {
          switch (image.angle) {
            case 1: title = 'Utama'
              break
            case 2: title = 'Depan'
              break
            case 3: title = 'Samping'
              break
            case 4: title = 'Detail 1'
              break
            case 5: title = 'Detail 2'
              break
            case 6: title = 'Detail 3'
              break
            default:
              break
          }
          if (isMobileView) {
            return (
              <div>
                <img
                  src={config.imageURL + (image.image_url || image.image_beta_url)}
                  alt={title}
                  className='px-5'
                />
                <h4 className='text-center'>{title}</h4>
              </div>
            )
          } else {
            return (
              <ul key={index}>
                <li>
                  <img className='border' src={config.imageURL + (image.image_url || image.image_beta_url)} alt={title} width='130' />
                  <div className='mt-4'>{title}</div>
                </li>
              </ul>
            )
          }
        })
      )
    } else return null
  }

  function renderComponent () {
    return (
      <>
        <ProductInformationForm
          product={productModified}
          method={type}
          categorySelected={categorySelected}
        />
        <ProductVariantInformationForm
          product={productModified}
          method={type}
        />
        <ProductMetaInformationForm
          product={productModified}
          method={type}
        />
        <ProductDescriptionForm
          product={productModified}
          method={type}
        />
        <ProductStockAndPriceForm
          product={product}
          method={type}
        />
        <ProductWeightAndDeliveryForm
          product={productModified}
          ownfleetList={ownfleetList}
          expressCarrierSelected={expressCarrierSelected}
          method={type}
          expressCarrierList={expressCarrierList}
        />
        <ProductAdditionalInformationForm
          product={productModified}
          method={type}
          warrantyLabel={warrantyLabel}
          attributeList={attributeList}
        />
      </>
    )
  }

  return (
    <>
      {product && (
        isMobileView
          ? (
            <ViewProductDetailMobile
              storeCode={storeCode}
              supplierAlias={supplierAlias}
              renderComponent={renderComponent}
              product={product}
              renderImages={renderImages}
              fetching={isLoading}
              err={err}
            />
            )
          : (
            <ViewProductDetailDesktop
              storeCode={storeCode}
              supplierAlias={supplierAlias}
              renderComponent={renderComponent}
              product={product}
              renderImages={renderImages}
              fetching={isLoading}
              err={err}
            />
            )
      )}
    </>
  )
}
