import React, { useContext } from 'react'
import { ScaleContext } from '../../../Context/ScaleContext'

// import { ScaleContext } from '../../Context/ScaleContext'

export default function ProductMetaInformationForm ({ setProduct, method, product }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const handleSetProduct = (e) => {
    const { name, value } = e.target
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderInputText (name, placeholder, value) {
    if (method === 'add' || method === 'update') {
      const defaultValue = product[name] || ''
      return (
        <input
          className={isMobileView ? 'fs--s' : 'fs--m'}
          defaultValue={defaultValue} placeholder={placeholder} name={name} type='text' onBlur={(e) => handleSetProduct(e)}
        />
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={value || '-'} disabled />
        </div>
      )
    } else return null
  }

  function renderTextArea (name, placeholder, value) {
    if (method === 'add' || method === 'update') {
      return (
        <div className='form-group'>
          <textarea
            className='form-control fs--m'
            id={name}
            name={name}
            rows='4'
            onBlur={(e) => handleSetProduct(e)}
            defaultValue={product[name]}
          />
        </div>
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <div className='form-group'>
            <textarea
              className={`form-control ${isMobileView ? 'fs--s' : 'fs--m'}`}
              id={name}
              name={name}
              rows='4'
              value={value || '-'}
              disabled
            />
          </div>
        </div>
      )
    } else return null
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Informasi Meta</div>

        <div className='row spacing-add-product'>
          <div className='col-2'>
                  Meta Title<span className='required'>*</span>
          </div>
          <div className='col'>
            <div className='input-text-bar '>
              {renderInputText('meta_title', 'Ex: Meta Title', product?.meta_title)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>
                  Tag Search<span className='required'>*</span>
          </div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('tag_search', 'Ex: ???', product?.tag_search)}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>Meta Description</div>
          <div className='col'>

            <div className='input-text-bar input-text-area '>
              {renderTextArea('meta_description', 'Ex: ???', product?.meta_description)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // DESAIN YANG MASIH UNTUK ADMIN
  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4 fs--s '>
        <div className='col-12 fs--m font-weight-bold my-4 text-center'>Informasi Meta</div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Meta Title</div>
          <div className='col-12'>
            <div className='input-text-bar fs--s '>
              {renderInputText('meta_title', 'Ex: Meta Title', product?.meta_title)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Tag Search<span className='required'>*</span></div>
          <div className='col-12'>
            <div className='input-text-bar fs--s '>
              {renderInputText('tag_search', 'Ex: ???', product?.tag_search)}

            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Meta Description</div>
          <div className='col'>
            <div className='input-text-bar input-text-area '>
              {renderTextArea('meta_description', 'Ex: ???', product?.meta_description)}
            </div>
          </div>
        </div>

      </div>
    )
  }
    if (isMobileView) return renderMobile()
  else return renderDesktop()
}
