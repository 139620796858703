import React, { useContext } from 'react'
import { ScaleContext } from '../../Context/ScaleContext'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'

import { useSelector } from 'react-redux'
import NumberWithCommas from '../../Utils/NumberWithCommas'

// import OrderCommentGroup from './OrderCommentGroup'

export default function ContentOrderGroup ({ grandTotal, shipmentFeeTotal, invoice, handleToogleModal }) {
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext

  const user = useSelector(state => state.user)

  function renderCancelledContent () {
    return (
      <div className='col-12'>
        <div className='info-box'>
          <div className='fs--m pb-4'><b>Produk tidak tersedia</b></div>
          <div className='pb-4'>
            {invoice.items.map((item, index) => {
              return <div key={index}>{item.sku} - {item.name} </div>
            })}
          </div>
          <div>Order <b>{invoice.order_no}</b>, <b>{invoice.invoice_no}</b> tidak tersedia di store Anda.</div>
          <div>Order ini <b>sudah dibatalkan</b> dan akan di <b>follow up oleh CS Ruparupa</b></div>
        </div>
      </div>
    )
  }
  function renderContent () {
    return (
      <>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Order No</div>
          <div className='text font-weight-bold'>{invoice.order_no}</div>
        </div>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Invoice No</div>
          <div className='text font-weight-bold'>{invoice.invoice_no}</div>
        </div>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Customer Email</div>
          <div className='text font-weight-bold'>{invoice.customer.customer_email}</div>
        </div>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Delivery Type</div>
          <div className='text font-weight-bold'>{invoice.shipment.carrier.carrier_name}</div>
        </div>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Invoice Date</div>
          <div className='text font-weight-bold'>{dayjs(invoice.created_at).locale('id').format('DD MMM YYYY HH:mm')}</div>
        </div>
        <div className='col-12 col-lg-4 pb-4'>
          <div>Customer Phone</div>
          <div className='text font-weight-bold'>{invoice.customer.customer_phone}</div>
        </div>
        {(invoice.shipment.shipment_status === 'ready_to_ship' ||
            invoice.shipment.shipment_status === 'shipped' ||
            invoice.shipment.shipment_status === 'pending_delivered' ||
            invoice.shipment.shipment_status === 'pending_received' ||
            invoice.shipment.shipment_status === 'received') &&
              <div className='col-12 col-lg-4 pb-4' onClick={(e) => { invoice.shipment?.pod_image && handleToogleModal('seeTrackingNumberModal', 'open', invoice) }}>
                {invoice?.shipment?.pod_image ? <div className='link'><u><b>Track Number</b></u></div> : <div>Track Number </div>}
                <div className='text font-weight-bold'>{invoice.shipment.track_number || '-'}</div>
              </div>}
        {!isEmpty(invoice.shipment?.shipment_tracking) &&
          <div className='col-12 col-lg-4 pb-4' onClick={(e) => handleToogleModal('shipmentStatusModal', 'open', invoice)}>
            <div className='link'><u><b>Status Pengiriman</b></u></div>
            {(invoice.shipment.shipment_status === 'pending_delivered' || invoice.shipment.shipment_status === 'pending_received') && <div>{startCase(invoice.shipment.shipment_status)}</div>}
          </div>}
        {user?.user?.role_id !== 2 && invoice?.supplier?.name && (
          <div className='col-12 col-lg-4 pb-4'>
            <div>Seller</div>
            <div className='text font-weight-bold'>{startCase(invoice?.supplier?.name)}</div>
          </div>
        )}
        {grandTotal !== 0 && (
          <div className='col-12 col-lg-4 pb-4'>
            <div>Grand Total</div>
            <div className='text font-weight-bold'>Rp {NumberWithCommas(grandTotal)}</div>
          </div>
        )}
        <div className='col-12 col-lg-4 pb-4' onClick={(e) => { shipmentFeeTotal === 0 && handleToogleModal('shipmentFeeModal', 'open', invoice) }}>
          <div className={shipmentFeeTotal === 0 ? 'link' : ''}><u><b>Total Shipment Fee</b></u></div>
          <div className='text font-weight-bold'>{shipmentFeeTotal === 0 ? 'GRATIS ONGKIR' : 'Rp ' + NumberWithCommas(shipmentFeeTotal)}</div>
        </div>
      </>
    )
  }
  if (isMobileView) {
    return (
      <div className='row fs--s pt-5 pb-4'>
        {invoice.status_fulfillment === 'batal' ? renderCancelledContent() : renderContent()}
      </div>
    )
  } else {
    if (invoice.status_fulfillment === 'batal') {
      return (
        <div className='row fs--s pt-5 pb-4'>
          {renderCancelledContent()}
        </div>
      )
    } else {
      return (
        <div className='row fs--s pt-5 pb-4'>
          <div className='col-9'>
            <div className='row pt-4 align-items-center'>
              {renderContent()}
            </div>
          </div>
        </div>
      )
    }
  }
}
