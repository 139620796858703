import React, { useContext } from 'react'
import { ScaleContext } from '../Context/ScaleContext'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { checkRole } from '../Services/CheckRole'

export default function SummaryReport ({ id, paramsAdmin, paramsSeller, startPeriode, endPeriode, fileName, handleSettlementDownload, handleSendReport, loading, supplierId }) { // const urlPeriode = encodeURIComponent(periode)
  // Uncomment this if you want to use is mobile trigger
  const user = useSelector(state => state.user)
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const handlePost = (type, startPeriode, supplier) => {
    const body = {
      start_periode: startPeriode,
      supplier_id: supplier,
      report_type: type
    }
    handleSendReport(body)
  }

  const handleDownload = () => {
    const params = {
      start_periode: startPeriode
    }
    if (user.user?.role_id === 1) {
      params.report_type = paramsAdmin.report_type
    } else {
      params.report_type = 'settlement'
      params.supplier_id = user.user?.supplier?.supplier_id
    }
    handleSettlementDownload(params)
  }

  const renderMobile = () => {
    return (
      <div className='row fs--s  border p-5'>
        <div className='col-12 mb-5 fs--m'><b className='mr-3'>Period: </b>{dayjs(startPeriode).locale('id').format('DD MMM YYYY')} - {dayjs(endPeriode).locale('id').format('DD MMMM YYYY')}</div>
        {/* <div className='col-12 py-3'><b>Settlement Amount: </b>{props.amount}</div> */}
        <div className='col-12 mb-3'>
          <button onClick={() => handleDownload()} className='btn btn-full btn-secondary fs--s '>
            <div className='my-1'>Download File<i className='pl-2 anticon icon-download' /></div>
          </button>
        </div>
        {!supplierId &&
        checkRole({
          menu: 'settlement_report',
          method: 'edit',
          attribute: 'all',
          yes: (access) => {
            return (
              <>
                <div className='col-12 mb-3'>
                  <button disabled={loading} onClick={() => handlePost(paramsAdmin.report_type, startPeriode, 0)} className='btn btn-full btn-ghost-secondary  fs--s '>
                    <div className='my-1'>Post<i className='pl-2 anticon icon-download' /></div>
                  </button>
                </div>
                <div className='col-12 mb-3'>
                  <Link to={`/settlement-report/details?start_periode=${startPeriode}&end_periode=${endPeriode}`}>
                    <button className='btn btn-full btn-primary fs--s '>
                      <div className='my-1'>Details<i className='pl-2 anticon icon-right' /></div>
                    </button>
                  </Link>
                </div>
              </>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <tr key={id} className='table-content'>
        <td className='align-middle pl-5 py-3'>{dayjs(startPeriode).locale('id').format('DD MMMM YYYY')} - {dayjs(endPeriode).locale('id').format('DD MMMM YYYY')}</td>
        <td colspan={supplierId && 4} className='align-middle pl-5 py-3'>
          <span className='link' onClick={() => handleDownload()}>
            {fileName}
          </span>
        </td>

        {!supplierId &&
        checkRole({
          menu: 'settlement_report',
          method: 'edit',
          attribute: 'all',
          yes: (access) => {
            return (
              <>
                <td className='py-5'>
                  <button disabled={loading} onClick={() => handlePost(paramsAdmin.report_type, startPeriode, 0)} className='btn btn-ghost-secondary btn-full btn-s '>
                    <i className='anticon icon-mail pr-4' />
                    Post
                  </button>
                </td>
                <td className='py-5'>
                  <Link to={`/settlement-report/details?start_periode=${startPeriode}&end_periode=${endPeriode}`}>
                    <button className='setllement-details'>
                      <i className='anticon icon-right' />
                    </button>
                  </Link>
                </td>
              </>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}
      </tr>
    )
  }

  return (
    <>
      {/* [STARTS HERE] ====
      //todo akan berubah jika width layar kurang dari 992px
      */}
      {isMobileView ? renderMobile() : renderDesktop()}
      {/* [ENDS HERE] --- akan berubah jika width layar kurang dari 992px */}
    </>
  )
}
