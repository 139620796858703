import React, { useEffect, useState } from 'react'

import PrintPackingSlip from '../Components/Prints/PrintPackingSlip'
import PrintListProducts from '../Components/Prints/PrintListProducts'
import PrintInvoice from '../Components/Prints/PrintInvoice'
import Loading from '../Components/Behaviors/Loading'
import { fetchGetInvoiceItems } from '../Services/FetchingGroup'

export default function Prints (props) {
  const { type, method, invoiceId, status } = props
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [invoices, setInvoices] = useState()

  const renderLoading = () => {
    return (
      <div className='container-fluid'>
        <Loading type='alt' />
      </div>
    )
  }

  const renderError = () => {
    return (
      <div className='container-fluid'>
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12'>
            <span className='fs--xm error'>{err.message}</span>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const params = {
      invoice_id_list: invoiceId
    }
    fetchGetInvoiceItems(params, setInvoices, setIsLoading, setErr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return renderLoading()
  else if (err.err) return renderError()
  else {
    switch (status) {
      case 'new':
        return <PrintListProducts invoices={invoices} invoiceId={invoiceId} />
      case 'processing':
      case 'stand_by':
        return <PrintInvoice invoices={invoices} />
      case 'ready_to_ship':
      case 'shipped':
      case 'received':
        if (type === 'invoice') {
          return <PrintInvoice invoices={invoices} />
        } else if (type === 'packing_slip') {
          return <PrintPackingSlip method={method} invoices={invoices} />
        } else return null
      default:
        return <PrintListProducts invoices={invoices} />
    }
  }
}
