import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CheckInvoicesItemDetail from '../../Orders/CheckInvoicesItemDetail'

import { fetchUpdateInvoiceItemAvailability, fetchUpdateInvoice, fetchGetRejectReasonList } from '../../../Services/FetchingGroup'

function CheckItemsBulkModal ({ invoices, onClickedModal, handleToogleModal }) {
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)
  const [listReason, setListReason] = useState([])
  const [invoiceId, setInvoiceId] = useState()
  const [showIncompleteReasons, setShowIncompleteReasons] = useState(false)

  const handleInvoiceId = (invoiceId) => {
    setInvoiceId(invoiceId)
  }

  function handleUpdateItemAvailability (data) {
    // the payload need to be wrap inside object that has array of object
    const body = {
      invoices: data
    }
    fetchUpdateInvoiceItemAvailability(body, setIsLoading, setErr, setSuccess)
  }

  useEffect(() => {
    if (success.success) {
      const collections = []
      // this for update invoice status, cause it's only need invoice id
      const data = success?.data?.invoices || []
      data.forEach(element => {
        // only status_fullfillment lengkap that cand move to next status
        if (element.status_fulfillment === 'lengkap') {
          collections.push(element.invoice_id)
        }
      })
      const body = {
        invoices: collections,
        status: 'stand_by'
      }
      fetchUpdateInvoice(body, setIsLoading, setErr, null)
      setSuccess({ success: false, data: {} })
    }
  }, [success, selectedInvoices])

  const handleConditional = (invoice, states, choose, reason, note) => {
    setInvoiceId(invoice.invoice_id)
    setShowIncompleteReasons(states)
    const invoices = selectedInvoices
    // initial for conditioning
    // 0 means new
    // > 0 means there already selected
    let i = 0
    // this is for the checking if the invoices already there
    if (invoices.length > 0) {
      // check one by one using for each
      invoices.forEach(element => {
        if (element.invoice_id === invoice.invoice_id) {
          // there are chance that the items more than 1
          element.items.forEach(element => {
            // changes the value
            // only the 'items' object that need to be changes
            element.note = note
            element.reason = reason
            element.status_fulfillment = choose
          })
          // if i more than 0 that means there were a similar invoice id
          i++
        }
      })
    }
    // for inserting new data i must be 0
    if (i === 0) {
      // initialize
      const collection = {
        invoice_id: invoice.invoice_id,
        items: []
      }
      const items = []
      // we need to create the items array of object especially for invoice that have multiple items
      invoice.items.forEach(element => {
        items.push({
          invoice_item_id: element.invoice_item_id,
          status_fulfillment: choose,
          reason: 0,
          note: ''
        })
      })
      collection.items = items
      invoices.push(collection)
    }
    setSelectedInvoices(invoices)
  }

  useEffect(() => {
    // get reject list first
    fetchGetRejectReasonList({}, setListReason, setIsLoading, setErr)
  }, [])

  if (onClickedModal.checkItemsBulkModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => { handleToogleModal('checkItemsBulkModal', 'close') }}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--m font-weight-bold p-3'>Pilih order yang akan di print bulking</div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='container pt-3 pb-3'>
            <table className='table table-layout table-layout__multiple-invoices'>
              <thead>
                <tr className='fs--s my-auto'>
                  <th className='' width='12%'>Order ID</th>
                  <th className='' width='12%'>Invoice ID</th>
                  <th className='' width='25%'>Invoice Date</th>
                  <th className='' width='5%'>Items</th>
                  <th className='' width='40%' />
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice, idx) => {
                  return (
                    <CheckInvoicesItemDetail
                      key={idx}
                      invoice={invoice}
                      reasons={listReason}
                      invoiceId={invoiceId}
                      handleInvoiceId={handleInvoiceId}
                      handleConditional={handleConditional}
                      showIncompleteReasons={showIncompleteReasons}
                    />
                  )
                })}
              </tbody>
            </table>
            {(err.err) &&
                        (
                          <div className='row fs--m'>
                            <div className='col-12 pl-5 py-4 error'>
                              {err.message}
                            </div>
                          </div>
                        )}
          </div>
        </Modal.Body>
        <Modal.Footer className=' justify-content-center text-center m-2'>
          <div className='col-12'>
            {/* <Link to='/order/stand_by'> */}
            <button
              disabled={isLoading}
              onClick={() => handleUpdateItemAvailability(selectedInvoices)}
              type='button'
              className='btn-primary-border btn-full btn-m margin-top-m center'
            >{isLoading ? 'Mohon menunggu' : 'Pilih Order yang akan di cek'}
            </button>
            {/* </Link> */}
          </div>
        </Modal.Footer>
      </Modal>
    )
  } else return null
}

export default CheckItemsBulkModal
