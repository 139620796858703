import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Loading from '../../../Components/Behaviors/Loading'
import { fetchGetMiscFile } from '../../../Services/FetchingGroup'

export default function TrackingNumberModal ({ onClickedModal, handleToogleModal, invoice }) {
  const [image, setImage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const params = { key: invoice?.shipment?.pod_image }
    if (params) fetchGetMiscFile(params, setImage, setIsLoading, null)
    // eslint-disable-next-line
  }, [])
  if (onClickedModal.seeTrackingNumberModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('seeTrackingNumberModal', 'close')}
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold pt-4 px-5'>Bukti Input Resi {invoice.invoice_no}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container pt-3 pb-3 text-center'>
            {
              isLoading
                ? (
                  <div className='row pl-5 mr-5 d-flex py-4'>
                    <div className='col-12'>
                      <center>
                        <Loading type='bounce' />
                      </center>
                    </div>
                  </div>
                  )
                : (
                    image?.url
                      ? (<img src={image?.url} width={500} alt='tracking-number' />)
                      : (
                        <div className='row pl-5 mr-5 d-flex py-4'>
                          <div className='col-12'>
                            <center>
                              Bukti Foto Resi Belum Di Upload
                            </center>
                          </div>
                        </div>
                        )
                  )
                  }
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  } else return null
}
