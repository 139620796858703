import React, { useState } from 'react'
import ChangePassword from './ChangePassword'
import OtpVerification from './OtpVerification'
import VerifyEmail from './VerifyEmail'

import { ForgotPasswordProvider } from '../../Context/ForgotPasswordContext'
import OtpChoices from './OtpChoices'
import { useHistory } from 'react-router'

export default function ForgotPassword ({ type }) {
//! NEED TO THINK ABOUT PUTTING ALL THE STATE HERE OR  WHAT
//! IF PROVIDER CAN BE UPDATED INSIDE THE CHILD JUST PUT THE FUNCTION INSIDE THE CHILD
  const history = useHistory()

  const [value, setValue] = useState()

  function handleOnChange (body) {
    setValue({ ...value, ...body })
    // setValue({ ...value, [name]: value })
  }

  function handleBackToPreviousOtpPage (type) {
    switch (type) {
      case 'otp-choices':
        setValue()
        history.push('/login?page=forgot-password&type=forgot-password')
        break
      case 'forgot-password':
        history.push('/login')
        break
      default:
        break
    }
  }

  function renderContent () {
    switch (type) {
      case 'forgot-password':
        return <VerifyEmail />
      case 'otp-verification':
        return <OtpVerification />
      case 'otp-choices':
        return <OtpChoices />
      case 'reset-password':
        return <ChangePassword />
      default:
        return <VerifyEmail />
    }
  }

  const headerTitle = type === 'forgot-password' ? 'Lupa kata sandi'
    : type === 'reset-password'
      ? 'Masukkan kata sandi baru anda'
      : type === 'otp-verification'
        ? 'Verifikasi OTP'
        : 'Verifikasi'
  return (
    <ForgotPasswordProvider value={value} handleOnChange={handleOnChange}>
      <div className=' container container-outer-padding container-outer-padding__forgot-password'>
        <div className='container-card-default'>
          <div className='col-xs-12'>
            <h1 className='text-center mb-4'>
              {type === 'otp-choices' || type === 'update-phone' || type === 'forgot-password' ? <i onClick={() => handleBackToPreviousOtpPage(type)} className='fs--l anticon icon-left pull-left cursor-pointer' /> : null}
              <span><b>{headerTitle}</b></span>
            </h1>
          </div>
          <div className='col-xs-12'>
            <hr className='margin-top-xs btn-primary-border' />
          </div>
          <div className='col-xs-12 my-4'>
            {renderContent()}
          </div>
        </div>
      </div>
    </ForgotPasswordProvider>

  )
}
