import React, { useState, useEffect } from 'react'

import { fetchChangePassword } from '../../Services/FetchingGroup'

export default function ProfilePassword () {
  const [password, setPassword] = useState({ old_password: '', password: '' })
  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePassword = (e) => {
    const { value, name } = e.target
    setPassword({ ...password, [name]: value })
  }

  useEffect(() => {
    if (success.success) {
      setPassword({ old_password: '', password: '' })
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  function onKeyDown (e) {
    if (e.key === 'Enter') {
      changePassword()
    }
  }

  function changePassword () {
    fetchChangePassword(password, setIsLoading, setErr, setSuccess)
  }

  return (
    <div className='row align-items-center'>
      <div className='col-12 mb-5'>
        <b className='fs--xm'>Ubah Password</b>
      </div>
      <div className='col-12 mb-3'>
        <div className='input-text-bar'>
          <input type='password' value={password?.old_password} name='old_password' placeholder='Old Password' onChange={(e) => handleChangePassword(e)} onKeyDown={(e) => onKeyDown(e)} />
        </div>
      </div>
      <div className='col-12 mb-4'>
        <div className='input-text-bar'>
          <input type='password' value={password?.password} name='password' placeholder='New Password' onChange={(e) => handleChangePassword(e)} onKeyDown={(e) => onKeyDown(e)} />
        </div>
      </div>
      {err.err && <div className='col-12 my-4 error'>{err.message}</div>}
      <div className='col col-lg-12 mb-4'>
        <button onClick={() => changePassword()} disabled={isLoading} className='btn btn-full btn-m btn-primary'>{isLoading ? 'Mohon Menunggu' : 'Change Password'} </button>
      </div>
    </div>
  )
}
