import React, { useEffect, useState } from 'react'
import { fetchSendReport, fetchSettlementDownload } from '../Services/FetchingGroup'

function SettlementReport ({
  checkedValues,
  startPeriode,
  endPeriode,
  settlement,
  reportType,
  handleSettlementDownload,
  handleToogleUploadModal,
  handleCheckboxClick,
  init
}) {
  const [successDownload, setSuccessDownload] = useState({ success: false, data: {} })
  const [successPost, setSuccessPost] = useState({ success: false, data: {} })

  const [isLoading, setIsLoading] = useState(false)
  const paramsPost = {
    reports: [
      {
        start_periode: startPeriode,
        supplier_id: settlement.supplier_id,
        report_type: reportType
      }
    ]
  }

  const handleDownloadFile = () => {
    const body = {
      start_periode: startPeriode,
      report_type: reportType,
      supplier_id: settlement.supplier_id
    }
    fetchSettlementDownload(body, null, null, setSuccessDownload)
  }

  useEffect(() => {
    if (successDownload.success) {
      window.open(successDownload.data.url)
      setSuccessDownload({ success: false, data: {} })
    }
    if (successPost.success) {
      init()
      setSuccessPost({ success: false, data: {} })
    }
  }, [successDownload, successPost, init])

  const handleUpload = () => {
    const data = {
      start_periode: startPeriode,
      end_periode: endPeriode,
      supplier_id: settlement.supplier_id,
      report_type: reportType,
      xls_file: ''
    }

    handleToogleUploadModal('open', data)
  }

  return (
    <>
      <tr className='fs--m'>
        <td className='py-5'>
          <label className='container-checkbox'>
            <input
              type='checkbox'
              value={settlement.supplier_id}
              checked={checkedValues.includes(settlement.supplier_id)}
              onChange={(e) => handleCheckboxClick(e)}
            />
            <span className='checkbox' />
          </label>
        </td>
        <td className='py-5'>{settlement.supplier_name}</td>
        <td className='py-5'>
          <span className='link' onClick={() => handleDownloadFile()}>
            {settlement.file_name}
          </span>
        </td>
        <td className='py-5 pl-5'>
          <button
            disabled={isLoading} onClick={() => fetchSendReport(paramsPost, setIsLoading, null, setSuccessPost)} className='btn btn-ghost-secondary btn-full btn-s'
          >
            <i className='anticon icon-mail pr-4' />
            Post
          </button>

        </td>
        <td className='py-5'>
          <button onClick={() => handleUpload()} className='btn btn-ghost-secondary btn-full btn-s' data-toggle='modal' data-target='#exampleModalCenter'>
            <i className='anticon icon-upload pr-4' />
            Upload
          </button>
        </td>
      </tr>
    </>
  )
}

export default SettlementReport
