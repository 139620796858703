import { isEmpty } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import InfoBox from '../../../Components/InfoBox'
import ProductImageUploadForm from '../../../Components/Products/Forms/ProductImageUploadForm'

export default function InsertProductFromDesktop ({ handleImage, handleValidationErr, validationErr, renderComponent, handleSubmit, fetching, angles, storeCode, supplierAlias, supplierId }) {
  return (
    <div className='container container-add-product'>
      <div className='mt-5 mb-5'>
        <div className='fs--xxm font-weight-bold'>Tambah Produk</div>
        <div className='container-card-default fs--m'>
          <div className='fs--xxm font-weight-bold mb-5'>Foto Produk</div>
          <InfoBox
            text='
            - Dimensi Foto, minimum 500 x 500 dan maksimum 1000 x 1000 Pixel <br>
            - Dimensi Foto, panjang dan lebar harus sama <br>
            - Ukuran Foto, tidak lebih dari 1 MB <br>
            - File Foto, berupa JPG / JPEG / PNG <br>
            - Background Foto, berwarna putih
            - Primary Foto wajib diisi <br>'
            icon='info'
            status=''
          />
          <div className='row dropzone-container'>
            {angles.map((angle, key) => {
              return (
                <ProductImageUploadForm
                  key={key}
                  angle={angle}
                  handleImage={handleImage}
                  handleValidationErr={handleValidationErr}
                  method='add'
                />
              )
            })}

          </div>
          {validationErr && validationErr.map((err, index) => {
            if (!isEmpty(err.message)) {
              return (
                <InfoBox
                  text={err.message}
                  icon='danger'
                  status='danger'
                />
              )
            } else {
              return null
            }
          })}
        </div>
        {
          // fetching
          //   ? (
          //     <div className='row spacing-add-product justify-content-center'>
          //       <Loading type='alt' />
          //     </div>
          //   ) :
          renderComponent()
        }

        <div className='row spacing-add-product'>
          <div className='col'>
            <Link to={`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}&supplier_id=${supplierId}`}>
              <button className='btn btn-full btn-negative-border btn-m font-weight-bold'>Batal</button>
            </Link>
          </div>
          <div className='col'>
            {/* //todo not implemented */}
            <button
              onClick={() => handleSubmit('another')}
              disabled={fetching}
              className='btn btn-full btn-primary-border btn-m font-weight-bold'
            >
              {fetching ? 'Mohon Menunggu' : 'Simpan dan Tambah Baru'}
            </button>
          </div>

          <div className='col'>
            <button
              onClick={() => handleSubmit('single')}
              disabled={fetching}
              className='btn btn-full btn-primary btn-m font-weight-bold'
            >
              {fetching ? 'Mohon Menunggu' : 'Simpan'}
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}
