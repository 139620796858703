import { isEmpty, startCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from '../../../Components/Behaviors/Loading'
import config from '../../../config'

import imageDefault from '../../../static/images/WaitingForApproval.jpg'
import noImagePlaceholder from '../../../static/images/noImage.png'

import { fetchGetDownloadCsvProduct, fetchGetListProductStatus } from '../../../Services/FetchingGroup'

export default function ProductStatusModal ({ productStatus, onClickProductStatusModal, handleToogleProductStatusModal }) {
  const user = useSelector(state => state.user)
  const [urlCsv, setUrlCsv] = useState({})

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [listProduct, setListProduct] = useState([])

  function renderTitle () {
    switch (productStatus.status) {
      case 'approved':
        return 'Authorized Product'
      case 'rejected':
        return 'Produk Bermasalah'
      case 'pending':
        return 'Produk Menunggu Otorisasi'
      default:
        return ''
    }
  }

  function handleDownload () {
    // const supplier = user.user?.role_id === 1 ? 'ALL' : user.user?.supplier.supplier_alias
    const params = {
      status: productStatus.status,
      limit: 30,
      total: productStatus.total,
      page: 1
    }
    fetchGetDownloadCsvProduct(params, setUrlCsv, setIsLoading, setErr)
  }

  function renderButton () {
    if (!isLoading && !err.err) {
      if (user.user?.role_id === 1) {
        return (
          <div className='col'>
            <button type='button' onClick={() => handleDownload()} className='btn-primary btn-m btn-full'><b>Export CSV</b></button>
          </div>
        )
      } else if (user.user?.role_id === 2) {
        const params = {
          store_code: user.supplier?.supplier_addresses[0]?.store?.store_code,
          supplier_alias: user.user?.supplier?.supplier_alias,
          status: productStatus.status
        }
        let link = ''
        if (productStatus.status === 'rejected') {
          link = `/product/rejected-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`
        } else {
          link = `/product/list-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&status=${params.status}`
        }
        return (
          <div className='col'>
            <Link to={link}>
              <button type='button' className='btn-primary btn-m btn-full'><b>Lihat Selengkapnya</b></button>
            </Link>
          </div>
        )
      } else return null
    }
  }
  useEffect(() => {
    const params = {
      status: productStatus.status,
      limit: 30,
      total: productStatus.total,
      page: 1
    }
    fetchGetListProductStatus(params, setListProduct, setIsLoading, setErr)
    return () => {
      setListProduct({})
    }
  }, [productStatus])

  useEffect(() => {
    if (!isEmpty(urlCsv)) {
      window.open(urlCsv?.url)
      setUrlCsv({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCsv])

  return (
    <Modal
      show={onClickProductStatusModal}
      size='update'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => handleToogleProductStatusModal('close', '', '')}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--xm font-weight-bold p-3'>{renderTitle()}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='custom-modal product-status'>
          {isLoading
            ? (
              <div className='row justify-content-center align-content-center' style={{ height: '200px' }}>
                <Loading type='bounce' />
              </div>
              )
            : err?.err
              ? (
                <div className='row text-center justify-content-center align-content-center' style={{ height: '200px' }}>
                  <div className='col-12'>
                    <span className='fs--m error'>{err.message}</span>
                  </div>
                </div>
                )
              : listProduct?.map((product, index) => {
                return (
                  <div className='container-card-default mx-4' key={index}>
                    <div className='content'>
                      <div className='row align-items-center'>
                        <div className='col-3 text-center'>
                          <img src={product.image_url ? config.imageURL + product.image_url : imageDefault} alt='product' width='80' onError={(e) => { e.target.src = noImagePlaceholder }} />
                        </div>
                        <div className='col'>
                          <div className='px-4'><b>{startCase(product.name)}</b></div>
                          <div className='col-12'>
                            <div className='row'>
                              <div className='col-12 col-lg-4 '>SKU Ruparupa:</div>
                              <div className='col-12 col-lg'>{product.sku}</div>
                            </div>
                            <div className='row'>
                              <div className='col-12 col-lg-4'>SKU Ruparupa:</div>
                              <div className='col-12 col-lg'>{product.sku_seller}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
        </div>
      </Modal.Body>
      <div className='modal-container pb-3'>
        <Modal.Footer className=' justify-content-center text-center'>
          {renderButton()}
        </Modal.Footer>
      </div>
    </Modal>
  )
}
