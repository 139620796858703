import React, { useContext, useState } from 'react'
import { ScaleContext } from '../../Context/ScaleContext'

export default function SearchProduct ({ handleChangeParams, type }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext

  const [keyword, setKeyword] = useState('')
  const handleChange = (e) => {
    e.preventDefault()
    setKeyword(e.target.value)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    handleChangeParams({ keyword: keyword, status: type !== 'rejected' ? '' : 'rejected' })
  }

  return (
    <>
      {isMobileView
        ? (
          <div className='input-text-bar' style={{ width: '100%' }}>
            <form onSubmit={handleSearch}>
              <input className='fs--s' placeholder='Cari nama produk atau SKU' type='text' onChange={handleChange} />
              <button className='search-btn-product' type='submit'><i className='anticon icon-search1' /></button>
            </form>
            {/* <div className='pt-1'>
            <div className='text-left font-weight-bold fs--s '>Sort by</div>
          </div>
          <div className=''>
            <select className='input input-product fs--s ' style={{ width: '100%' }}>
              <option value='1'>Huruf A - Z</option>
            </select>
          </div> */}
          </div>
          )
        : (
          <div className='input-text-bar input-bar-product'>
            <form onSubmit={handleSearch}>
              <input className='fs--s' placeholder='Cari nama produk atau SKU' type='text' onChange={handleChange} />
              <button className='search-btn-product' type='submit'><i className='anticon icon-search1' /></button>
            </form>
          </div>)}

    </>
  )
}
