import React from 'react'
import { Modal } from 'react-bootstrap'

function BookingCodeModal ({ invoice, onClickedModal, handleToogleModal }) {
  if (onClickedModal.bookingCodeModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('bookingCodeModal', 'close')}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xxm font-weight-bold p-3'>Kode Booking</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row align-items-center justify-content-center text-center'>
            <div className='col-12'>
              <div className='fs--xm'>Kode Booking {invoice.shipment?.carrier?.carrier_name || ''} untuk invoice <b>{invoice.invoice_no}</b></div>
            </div>
            <div className='col-12'>
              <div className='fs--xxl pt-4 booking'><b>{invoice.shipment.track_number}</b></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className=' px-5'>
          <div className='col-12'>
            <button type='button' className='btn btn-primary btn-m btn-full'>OK</button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  } else {
    return null
  }
}

export default BookingCodeModal
