import React, { Component } from 'react'
import _ from 'lodash'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'
import config from '../../config'
// import { ScaleContext } from '../../Context/ScaleContext'
// const google = window.google

/*
  - Google Map Component (Stable API v3.31) -
  This Google Map Component has to be pure component, wihout any store connected.
  This component ONLY resposible for getting geolocation/place not dispatching some functions
  - Cheers.
*/

export class MapComponent extends Component {
  constructor (props) {
    super(props)
    const defaultGeolocation = !_.isEmpty(config.defaultGeolocation) ? config.defaultGeolocation.split(',') : ['-6.188441699999998', '106.73396839999998']
    this.state = {
      map: undefined,
      searchBox: undefined,
      center: props.geolocation && !_.isEmpty(props.geolocation) ? props.geolocation : { lat: parseFloat(defaultGeolocation[0]), lng: parseFloat(defaultGeolocation[1]) }, // this will be device's current position ?
      draggedCenter: {},
      markers: [],
      place: [],
      currentFormattedAddress: ''
    }
  }

  // this is different from desktop
  UNSAFE_componentWillReceiveProps (newProps) {// eslint-disable-line
    const { geolocation, hasGeoChange } = newProps
    if (geolocation && !_.isEqual(this.state.center, geolocation)) {
      if (hasGeoChange) {
        const geocoder = new window.google.maps.Geocoder()
        this.sendMapsDataToParent(geolocation, 'OBJECT')
        this.geocodeLatLng(geocoder, geolocation, 'RAW_LAT_LNG')
        this.props.handleGeoChange(false)
      }
      this.setState({ center: geolocation, draggedCenter: {} })
    }
  }

  componentDidMount () { // eslint-disable-line
    const { center } = this.state
    // init current location (it could be current device's position)
    const geocoder = new window.google.maps.Geocoder()
    this.sendMapsDataToParent(center, 'OBJECT')
    this.geocodeLatLng(geocoder, center, 'RAW_LAT_LNG')
  }

  mapLoaded = (map) => {
    if (!this.state.map) {
      this.setState({ map })
    }
  }

  searchBoxLoaded = (searchBox) => {
    if (!this.state.searchBox) {
      this.setState({ searchBox })
    }
  }

  mapMoved = () => {
    const { map } = this.state
    const geocoder = new window.google.maps.Geocoder()

    this.setState({
      center: map.getCenter()
    }, () => {
      this.sendMapsDataToParent(map.getCenter(), 'FN')
      this.geocodeLatLng(geocoder, map, 'MAP_OBJECT')
    })
  }

  // reverse geocode - to get place based on lat lng
  geocodeLatLng = (geocoder, map, type: String) => {
    const latlng = (type === 'RAW_LAT_LNG') ? map : map.getCenter()
    geocoder.geocode({
      location: latlng
    }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.setState({ currentFormattedAddress: results[0].formatted_address })
        } else {
          window.alert('No results found')
        }
      } else {
        if (status === 'OVER_QUERY_LIMIT') {
          window.alert('Terjadi kesalahan dalam alokasi posisi. Silahkan coba kembali')
        } else {
          window.alert('Geocoder failed due to: ' + status)
        }
      }
    })
  }

  mapBeingDragged = () => {
    const { map, draggedCenter } = this.state
    if (!_.isEqual(map.getCenter(), draggedCenter)) {
      this.setState({
        draggedCenter: map.getCenter()
      })
    }
  }

  // when user clicked/choose dropdown from search box
  onPlacesChanged = () => {
    const { searchBox } = this.state
    const places = searchBox.getPlaces()
    const bounds = new window.google.maps.LatLngBounds()

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    })

    const nextMarkers = places.map(place => ({
      position: place.geometry.location
    }))

    const nextCenter = _.get(nextMarkers, '0.position', this.state.center)

    this.setState({
      center: nextCenter,
      markers: nextMarkers,
      place: places[0],
      currentFormattedAddress: places[0].formatted_address,
      draggedCenter: {}
    }, () => {
      // this.props.handleGoogleMapsAddress(places[0].formatted_address)
      this.sendMapsDataToParent(nextCenter, 'FN')
    })
  }

  sendMapsDataToParent = (center, type: String) => {
    if (this.props.getMapsData) {
      this.props.getMapsData(center, type)
    }
  }

  render () {
    const { center, draggedCenter, currentFormattedAddress } = this.state
    const { isMarkerShown, isMobileView } = this.props || false
    const pinIcon = 'https://res.cloudinary.com/ruparupa-com/image/upload/v1531730030/2.1/svg/ruparupa-marker.png'

    return (
      <>
        <GoogleMap
          ref={(map) => this.mapLoaded(map)}
          defaultZoom={16}
          center={center}
          onDrag={() => this.mapBeingDragged()}
          onDragEnd={() => this.mapMoved()}
          defaultOptions={{ streetViewControl: false, mapTypeControl: false }}
        >
          <SearchBox
            ref={(searchBox) => this.searchBoxLoaded(searchBox)}
            controlPosition={window.google.maps.ControlPosition.TOP_CENTER}
            onPlacesChanged={() => this.onPlacesChanged()}
          >
            <input
              type='text'
              placeholder='Cari alamat Anda..'
              className={`searchbox-maps${config.environment === 'mobile' ? ' google-search-mobile' : ''}`}
            />
          </SearchBox>
          {
            isMarkerShown &&
              <Marker
                position={!_.isEmpty(draggedCenter) ? draggedCenter : center}
                defaultAnimation={window.google.maps.Animation.DROP}
                defaultIcon={pinIcon}
              />
          }
        </GoogleMap>
        <div className={`chosen-formatted-address ${isMobileView ? 'col-12 pl-0' : ''}`}>
          <div className={`margin-top-s ${isMobileView ? 'fs--s' : ''}`} id='formatted-address'>
            {
              (!_.isEmpty(currentFormattedAddress))
                ? <><i className='icon anticon icon-enviroment' /> {currentFormattedAddress}</>
                : 'Alamat yang Anda pilih..'
            }
          </div>
        </div>
      </>
    )
  }
}

export default withScriptjs(withGoogleMap(MapComponent))
