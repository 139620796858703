import { isEmpty, startCase } from 'lodash'
import React, { useContext } from 'react'
import { ScaleContext } from '../../../Context/ScaleContext'

export default function ProductAdditionalInformationForm ({
  product, method, setProduct, attributeList, handleSetAttribute, handleCheckboxClick, checkedAttributes, attributesSelected
}) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const warrantyLabel = [
    'warranty_unit', 'warranty_part', 'warranty_service'
  ]
  const warrantyUnitsValue = [
    'No period', '1 Months', '3 Months', '6 Months', '12 Months', '6 Months', '18 Months', '24 Months', '36 Months', '60 Months', '10 Years', 'Lifetime'
  ]

  const warrantyContributorValue = ['Please Choose', 'Seller', 'Distribution']
  // const warrantyContributorValue = [{ value: '0', label: 'Pilih Warranty Contributer' }, { value: '1', label: 'Seller' }, { value: '2', label: 'Distribution' }]

  const handleSetProduct = (e) => {
    const { name } = e.target
    let { value } = e.target
    if (name === 'is_sparepart' || name === 'is_accessories') {
      value = parseInt(value)
    }
    setProduct(product => ({ ...product, [name]: value }))
  }

  const renderDrop = (name, label, options, type, attribute, group) => {
    let filter = []
    if (name === 'attribute_variants') {
      filter = attributesSelected?.attribute_variants?.filter(element => element.attribute_id === parseInt(attribute?.attribute_id))
    } else filter = attributesSelected?.additional_attributes?.filter(element => element.attribute_id === parseInt(attribute?.attribute_id))

    let defaultValue = ''
    if (type === 'warranty') {
      defaultValue = product[name]
    }
    if (filter && filter.length > 0) {
      if (filter[0]?.attribute_type === 'option') {
        defaultValue = filter[0]?.attribute_value
      } else if (filter[0]?.attribute_type === 'option_unit') {
        defaultValue = filter[0]?.unit_option_value
      } else if (filter[0]?.attribute_type === 'short_text') {
        defaultValue = filter[0]?.attribute_unit_value
      } else if (filter[0]?.attribute_name === 'color') {
        defaultValue = filter[0]?.attribute_value
      } else defaultValue = filter[0]
    }
    return (
      <div className='input-text-bar'>
        {method === 'add' || method === 'update'
          ? (
            <select
              className='input' name={name} defaultValue={method === 'update' ? defaultValue : ''} onChange={(e) => {
                if (type === 'warranty') {
                  handleSetProduct(e)
                } else {
                  handleSetAttribute(e, type, attribute, '', group)
                }
              }}
            >
              <option value='' hidden>Pilih {startCase(label)}</option>
              {options.map((option, index) => {
                if (type === 'option') {
                  if (label === 'Color') {
                    return <option value={option.option_id} key={index}>{option.option_value}</option>
                  } else {
                    return <option value={option.option_value} key={index}>{option.option_value}</option>
                  }
                } else if (type === 'option_unit') {
                  return (
                    <option value={option.unit_option_value} key={index}>{option.unit_option_value}</option>
                  )
                } else {
                  // need to search alternatif using selected in option because the selected defaultValue doesn't work
                  return (
                    <option value={option} selected={option === defaultValue} key={index}>{option}</option>
                  )
                }
              })}
            </select>
            )
          : (method === 'view')
              ? (
              <div className='view-only'>
                <input className={isMobileView ? 'fs--s' : 'fs--m'} name={type} type='text' value={attribute.attribute_value + ' ' + (attribute.attribute_unit_value || '') || '-'} disabled />
              </div>
                )
              : null}
      </div>
    )
  }

  const renderCheckbox = (attb, type) => {
    // todo check
    const filter = attributesSelected?.additional_attributes?.filter(element => element.attribute_id === parseInt(attb?.attribute_id))
    let defaultValue = ''
    if (filter && filter.length > 0) {
      defaultValue = filter[0].attribute_value
    }
    return (
      <label className={`container-checkbox mb-0 ${isMobileView ? 'fs--s' : 'fs--m'}`}>
        {method === 'add' || method === 'update'
          ? (
            <input
              type='checkbox'
              name={type}
              value={attb.attribute_id}
              checked={defaultValue === 'true' || checkedAttributes.includes(attb.attribute_id)}
              onChange={(e) => handleCheckboxClick(e, attb)}
            />
            )
          : method === 'view'
            ? (
              <input
                type='checkbox'
                name={type}
                value={attb.attribute_id}
                checked={attb.attribute_value === 'true'}
                disabled
              />
              )
            : null}

        <span className='checkbox' />
        <span>{attb.attribute_label}</span>
      </label>
    )
  }

  const rendertInputText = (attb, name, index, group) => {
    const filter = attributesSelected?.additional_attributes?.filter(element => element.attribute_id === parseInt(attb?.attribute_id))
    let defaultValue = ''
    if (filter && filter.length > 0) {
      defaultValue = filter[0].attribute_value
    }
    return (
      <div className={`row spacing-add-product ${isMobileView ? 'fs--s' : 'fs--m'}`} key={index}>
        <div className='col-4 col-lg-2'>
          {attb.attribute_label}
        </div>
        <div className='col col-lg-4'>
          <div className='input-text-bar'>
            {method === 'add' || method === 'update'
              ? (
                <input name={name} placeholder='' defaultValue={defaultValue} type='text' onBlur={(e) => handleSetAttribute(e, 'short_text', attb, '', group)} />
                )
              : (method === 'view')
                  ? (
                  <div className='view-only'>
                    <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={attb.attribute_value + ' ' + attb.attribute_unit_value} disabled />
                  </div>
                    )
                  : null}
          </div>
        </div>
      </div>
    )
  }

  const renderDropDownOnly = (attb, type, index, group) => {
    return (
      <div className={`row spacing-add-product ${isMobileView ? 'fs--s' : 'fs--m'}`} key={index}>
        <div className='col-4 col-lg-2'> {attb.attribute_label}</div>
        <div className='col col-lg-4'>
          {renderDrop(type, attb.attribute_label, attb.option_list, 'option', attb, group)}
        </div>
      </div>
    )
  }

  const renderInputDropdown = (attb, name, index, group) => {
    const filter = attributesSelected?.additional_attributes?.filter(element => element.attribute_id === parseInt(attb?.attribute_id))
    let defaultValue = ''
    if (filter && filter.length > 0) {
      defaultValue = filter[0].attribute_value
    }
    return (
      <div className={`row spacing-add-product ${isMobileView ? 'fs--s' : 'fs--m'}`} key={index}>
        <div className='col-4 col-lg-2'>
          {attb.attribute_label}
        </div>
        <div className='col-4 col-lg-1'>
          <div className='input-text-bar'>
            {method === 'add' || method === 'update'
              ? (
                <input name={name} placeholder='' defaultValue={defaultValue} type='text' onBlur={(e) => handleSetAttribute(e, 'short_text', attb, group)} />
                )
              : (method === 'view')
                  ? (
                  <div className='view-only'>
                    <input name={name} type='text' value={attb.attribute_value + ' ' + attb.attribute_unit_value} disabled />
                  </div>
                    )
                  : null}
          </div>
        </div>
        <div className='col-4 col-lg-2'>
          {renderDrop(name, attb.attribute_label, attb.attribute_unit_list, 'option_unit', attb, group)}
        </div>
      </div>
    )
  }

  function renderAttribute () {
    // todo: refactor needed!!
    if (method === 'add' || method === 'update') {
      return (
        <div className={`row spacing-add-product ${isMobileView ? 'fs--s' : 'fs--m'}`}>
          <div className='col-12 col-lg-2 d-inline-block mb-2'>Barang ini adalah</div>
          <div className='col-12 col-lg-8'>
            <div className='row align-items-center'>
              {
                attributeList.attributes.map((attribute, index) => {
                  return (
                    <div className='col-4 col-lg py-2 pr-0' key={index}>
                      {renderCheckbox(attribute, 'additional_attributes')}
                    </div>
)
                })
              }
            </div>
          </div>
        </div>

      )
    } else if (method === 'view') {
      let show = false
      const additionalAttribute = attributeList?.additional_attribute
      if (additionalAttribute) {
        additionalAttribute.forEach(element => {
          if (element.attribute_type === 'boolean') {
            show = true
          } else return null
        })
      }
      return (
        <div className={`row spacing-add-product ${isMobileView ? 'fs--s' : 'fs--m'}`}>
          {show && <div className='col-4 col-lg-2 d-inline-block mb-2'>Barang ini adalah</div>}
          <div className='col'>
            <div className='row align-items-center'>
              {
                additionalAttribute?.map((attribute, index) => {
                  if (attribute.attribute_type === 'boolean') {
                    show = true
                    return (
                      <div className='col-6 col-lg-2' key={index}>
                        {renderCheckbox(attribute, 'additional_attribute')}
                      </div>
                    )
                  } else return null
                })
              }
            </div>
          </div>
        </div>
      )
    } else return null
  }

  function renderAdditionalAttribute (attribute, index, group) {
    let name = ''
    if (group === 'attribute_variants') name = 'attribute_variants'
    else name = 'additional_attributes'
    if (attribute.attribute_type === 'option' || attribute.attribute_label === 'Color') {
      return renderDropDownOnly(attribute, name, index, group)
    } else if (attribute.attribute_type === 'short_text') {
      if (isEmpty(attribute.attribute_unit_list)) {
        return rendertInputText(attribute, name, index, group)
      } else {
        return renderInputDropdown(attribute, name, index, group)
      }
    } else return null
  }

  function renderRadio () {
    return (
      <>
        <div className='row spacing-add-product align-items-center'>
          <div className='col-4 col-lg-2 d-inline-block mb-2'>Is Sparepart</div>
          <div className='col col-lg-3'>
            {method === 'add' || method === 'update'
              ? (
                <div className='row'>
                  <div className='col text-left '>
                    <div className='container-radio'>
                      <label>Yes
                        <input type='radio' name='is_sparepart' value={1} id='radioBtn1' defaultChecked={product?.is_sparepart === 1} onChange={e => handleSetProduct(e)} />
                        <span className='checked product-form' />
                      </label>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='container-radio'>
                      <label>No
                        <input type='radio' name='is_sparepart' value={0} id='radioBtn2' defaultChecked={product?.is_sparepart === 0} onChange={e => handleSetProduct(e)} />
                        <span className='checked product-form' />
                      </label>
                    </div>
                  </div>
                </div>
                )
              : (method === 'view')
                  ? (
                  <div className='input-text-bar'>
                    <div className='view-only'>
                      <input name='is_sparepart' type='text' value={product?.is_sparepart === 1 ? 'Yes' : 'No'} disabled />
                    </div>
                  </div>
                    )
                  : null}

          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-4 col-lg-2 d-inline-block mb-2'>Is Accesories</div>
          <div className='col col-lg-3'>
            {method === 'add' || method === 'update'
              ? (
                <div className='row'>
                  <div className='col text-left '>
                    <div className='container-radio'>
                      <label>Yes
                        <input type='radio' name='is_accessories' value={1} id='radioBtn3' defaultChecked={product?.is_accessories === 1} onChange={e => handleSetProduct(e)} />
                        <span className='checked product-form' />
                      </label>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='container-radio'>
                      <label>No
                        <input type='radio' name='is_accessories' value={0} id='radioBtn4' defaultChecked={product?.is_accessories === 0} onChange={e => handleSetProduct(e)} />
                        <span className='checked product-form' />
                      </label>
                    </div>
                  </div>
                </div>
                )
              : (method === 'view')
                  ? (
                  <div className='input-text-bar'>
                    <div className='view-only'>
                      <input name='is_accessories' type='text' value={product?.is_accessories === 1 ? 'Yes' : 'No'} disabled />
                    </div>
                  </div>
                    )
                  : null}
          </div>
        </div>
      </>
    )
  }
  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Informasi Tambahan</div>
        {(product.is_sparepart && product.is_accessories) ? renderRadio() : null}
        {/* {(product.hasOwnProperty('is_sparepart') && product.hasOwnProperty('is_accessories')) ? renderRadio() : null} */}
        {/* Note:
        When you try to get detail, Backend only passing one object that containt 1 attribute
        meanwhile when you trying to add, it's divided into 3 (attributes, addtional_attribute, attribute_variant)
        */}
        {attributeList && renderAttribute()}

        {/* {attributeList && method === 'view' && attributeList.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attributes')
        })} */}

        {/* Note:
        there are three types of attribute in here when you want try to add something,
        attributes (mostly boolean)
        additional_attribute (this have the most attribute with short_text, option)
        attribute_variants (only for color, as far as I know)
        */}
        {attributeList?.additional_attributes?.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attributes')
        })}
        {attributeList?.attribute_variants?.map((attribute, index) => {
          // {attributeList && (method === 'add' || method === 'update') && attributeList.attribute_variant?.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attribute_variants')
        })}

        <div className='row spacing-add-product'>
          {
            warrantyLabel.map((warranty, index) => {
              return (
                <div className='col-6' key={index}>
                  <div className='row  pb-4'>
                    <div className='col-4'>{startCase(warranty)}</div>
                    <div className='col-7'>
                      {method === 'add' || method === 'update'
                        ? (renderDrop(warranty, startCase(warranty), warrantyUnitsValue, 'warranty'))
                        : method === 'view'
                          ? (
                            <div className='input-text-bar'>
                              <input name={warranty} type='text' value={product?.[warranty] || '-'} disabled />
                            </div>
                            )
                          : null}
                    </div>
                  </div>
                </div>
              )
            })
          }
          <div className='col-6'>
            <div className='row  pb-4'>
              <div className='col-4'>Warranty Contributor</div>
              <div className='col-7'>
                {method === 'add' || method === 'update'
                  ? (renderDrop('warranty_contributor', startCase('warranty_contributor'), warrantyContributorValue, 'warranty'))
                  : method === 'view'
                    ? (
                      <div className='input-text-bar'>
                        <input name='warranty_contributor' type='text' value={product?.warranty_contributor || '-'} disabled />
                      </div>
                      )
                    : null}
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className='border p-3 fs--s '>
        <div className='row spacing-add-product '>
          <div className='col-12 mb-2 font-weight-bold fs--m mb-3 text-center'>Informasi Tambahan</div>
        </div>
        {(product.is_sparepart && product.is_accessories) ? renderRadio() : null}
        {/* Note:
        When you try to get detail, Backend only passing one object that containt 1 attribute
        meanwhile when you trying to add, it's divided into 3 (attributes, addtional_attribute, attribute_variant)
        */}
        {attributeList && renderAttribute()}

        {/* {attributeList && method === 'view' && attributeList.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attributes')
        })} */}

        {/* Note:
        there are three types of attribute in here when you want try to add something,
        attributes (mostly boolean)
        additional_attribute (this have the most attribute with short_text, option)
        attribute_variant (only for color)
        */}
        {attributeList?.additional_attributes?.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attributes')
        })}
        {attributeList?.attribute_variants?.map((attribute, index) => {
          // {attributeList && (method === 'add' || method === 'update') && attributeList.attribute_variant?.map((attribute, index) => {
          return renderAdditionalAttribute(attribute, index, 'attribute_variants')
        })}
        {/* // todo: CHECK WARRANTY */}
        {
          warrantyLabel.map((warranty, index) => {
            return (
              <div className='row spacing-add-product' key={index}>
                <div className='col-12'>
                  <div className='row centralized-content fs--s '>
                    <div className='col-4 pl-0 '>{startCase(warranty)}</div>
                    <div className='col-7 none-horizontal-padding'>
                      <div className='input-text-bar '>
                        {method === 'add' || method === 'update'
                          ? (renderDrop('warranty_contributor', startCase('warranty_contributor'), warrantyContributorValue, 'warranty'))
                          : method === 'view'
                            ? (
                              <input name='warranty_contributor' type='text' value={product?.warranty_contributor || '-'} disabled />
                              )
                            : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  if (isMobileView) {
    return renderMobile()
  } else {
    return renderDesktop()
  }
}
