import React, { createContext, useState, useEffect } from 'react'

const ScaleContext = createContext([{}, () => {}])

const ScaleProvider = (props) => {
  const [attbContext, setattbContext] = useState({})

  useEffect(() => {
    setattbContext(props)
  }, [props])

  return (
    <ScaleContext.Provider value={[attbContext, setattbContext]}>
      {props.children}
    </ScaleContext.Provider>
  )
}

export { ScaleProvider, ScaleContext }
