import { createStore, applyMiddleware, combineReducers } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'

import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import Immutable from 'seamless-immutable'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}

export default (rootReducer, rootSaga, initialState, history) => {
  // create the saga middleware
  const middlewares = [sagaMiddleware, routerMiddleware(history)]
  // const store = createStore(persistedReducer)
  const store = createStore(
    persistReducer(persistConfig, combineReducers(rootReducer)),
    Immutable(initialState),
    composeWithDevTools(applyMiddleware(...middlewares)
    )
  )
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
