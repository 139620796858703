import React, { useContext, Fragment } from 'react'
import PendingStatusGroup from './PendingStatusGroup'
import { ScaleContext } from '../../Context/ScaleContext'
import dayjs from 'dayjs'

export default function PendingOrderGroup ({ invoice, handleToogleModal }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext

  // const handle

  const renderDesktop = () => {
    return (
      <div className='row fs--s p-4 '>
        <div className='col-12 border border-bottom-0'>
          <div className='row pending-box pending-header'>
            <div className='col-5'>
              <span>Order&nbsp;No <b className='pl-4'>{invoice.order_no}</b></span>
            </div>
            <div className='col'>
              <span>Invoice&nbsp;No <b className='pl-4'>{invoice.invoice_no}</b></span>
            </div>
            <div className='col text-right'>
              <span>Invoice&nbsp;Date <b className='pl-4'>{dayjs(invoice.created_at).format('DD MMM YYYY HH:mm')}</b></span>
            </div>
          </div>
        </div>

        <div className='col-12 border border-top-0 '>
          <div className='row align-items-center align-content-center'>
            <div className='col-9 border-right'>
              <div className='row pending-box pending-header border-bottom-0 '>
                {invoice.items.map((item, key) => {
                  return (
                    <Fragment key={key}>
                      <div className='col-6 py-3'>
                        {item.name}
                      </div>
                      <div className='col py-3'>
                        <span className='pr-4'>SKU</span><span className='font-weight-bold'>{item.sku}</span>
                      </div>
                      <div className='col py-3'>
                        <span className='pr-4'>Quantity</span><span className='font-weight-bold'>{item.qty_ordered}</span>
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            </div>
            <div className='col-3 text-center'>
              <PendingStatusGroup status={invoice.is_refund_processed} invoice={invoice} />
            </div>
          </div>
        </div>

        <div className='col-12 border border-top-0 '>
          <div className='row pending-box pending-footer'>
            <div className='col-2 color__secondary--primary link' onClick={(e) => handleToogleModal('commentModal', 'open', invoice)}>
              <i className='anticon icon-message1 pr-4' /> Chat Ke Store
            </div>
            <div className='col color__secondary--primary link' onClick={(e) => handleToogleModal('seeProductListModal', 'open', invoice)}>
              <i className='anticon icon-exception1 pr-4' />Cek Kelengkapan
            </div>

          </div>
        </div>
      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className='row fs--s p-4 '>
        <div className='col-12 border border-bottom-0'>
          <div className='row pending-box pending-header' style={{ backgroundColor: '#D7E9F0' }}>
            <div className='col-6'>
              <div className='row'>
                <div className='col-12'>
                  <div className='font-weight-bold'>Order No</div>
                </div>
                <div className='col-12'>
                  <div className='font-weight-bold'>{invoice.order_no}</div>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <div className='row'>
                <div className='col-12'>
                  <div className='font-weight-bold'>Invoice No</div>
                </div>
                <div className='col-12'>
                  <div className='font-weight-bold'>{invoice.invoice_no}</div>
                </div>
              </div>
            </div>
            <div className='col-6 mt-4'>
              <div className='row'>
                <div className='col-12'>
                  <div className='font-weight-bold'>Invoice Date</div>
                </div>
                <div className='col-12'>
                  <div className='font-weight-bold'>{dayjs(invoice.created_at).format('DD MMM YYYY HH:mm')}</div>
                </div>
              </div>
            </div>
            {/* <div className='col color__secondary--primary pl-5 pr-0'>
              <i className='anticon icon-message1 pr-2' /> Chat Ke Store
            </div> */}
          </div>
        </div>
        <div className='col-12 border border-top-0 py-4 '>
          {invoice.items.map((item, key) => {
            return (
              <div className='row p-3 pending-box pending-header border-bottom align-items-center' key={key}>
                <div className='col-12'>
                  <div className='row' style={{ alignItems: 'center' }}>
                    <div className='col-9'>
                      <span>{item.name}</span>
                    </div>
                    <div className='col-3'>
                      <span>Qty: <b>{item.qty_ordered}</b></span>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-3'>
                  <div className='row' style={{ alignItems: 'center' }}>
                    <div className='col-7'>
                      <span>SKU: <b>{item.sku}</b></span>
                    </div>
                    <div className='col-5'>
                      {/* <PendingStatusGroup status={order.status} isMobileView={isMobileView} /> */}
                    </div>
                  </div>
                </div>
                <div className='col-12 text-center border-bottom py-5 pb-4'>
                  <PendingStatusGroup status={invoice.is_refund_processed} invoice={invoice} />
                </div>
                <div className='col-12 mt-4'>
                  <div className='btn btn-secondary-border btn-s btn-full' onClick={(e) => handleToogleModal('seeProductListModal', 'open', invoice)}>
                    <i className='anticon icon-exception1 pr-1' /> Cek Kelengkapan
                  </div>
                </div>

                <div className='col-12 mt-4'>
                  <div className='btn btn-secondary-border btn-s btn-full' onClick={(e) => handleToogleModal('commentModal', 'open', invoice)}>
                    <i className='anticon icon-message1 pr-2' />Chat Ke Store
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
