import React, { useContext } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { ScaleContext } from '../../../Context/ScaleContext'

export default function ProductStockAndPriceForm ({ price, handleSetPrice, setProduct, method, product }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const from = price?.special_price_from ? new Date(price?.special_price_from) : ''
  const to = price?.special_price_to ? new Date(price?.special_price_to) : ''

  const handleSetProduct = (e) => {
    const { name, value } = e.target
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderInputText (name, placeholder, value) {
    if (method === 'add') {
      return <input className={isMobileView ? 'fs--s' : 'fs--m'} placeholder={placeholder} name={name} type='text' onBlur={(e) => handleSetProduct(e)} />
    } else return null
    // else if (method === 'view') {
    //   return (
    //     <div className='view-only'>
    //       <input name={name} type='text' value={value || 0} disabled />
    //     </div>
    //   )
    // }
  }

  function renderPrice (name, placeholder, value) {
    if (method === 'add') {
      return <input placeholder={placeholder} type='text' name={name} onChange={(e) => handleSetPrice(name, e.target.value, e)} value={price[name] || ''} />
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={value || '-'} disabled />
        </div>
      )
    } else return null
  }

  function renderDateTime (name, initialValue, value) {
    if (method === 'add') {
      return (
        <DateTimePicker
          disableClock
          calendarIcon={false}
          yearPlaceholder='YYYY'
          dayPlaceholder='DD'
          monthPlaceholder='MM'
          hourPlaceholder='HH'
          minutePlaceholder='mm'
          format='dd-MM-y HH:mm'
          name={name}
          value={initialValue || ''}
          onChange={value => handleSetPrice(name, value, value)}
        />
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={value || '-'} disabled />
        </div>
      )
    } else return null
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Stok dan Harga</div>
        <div className='row spacing-add-product '>
          <div className='col-2'>Harga<span className='required'>*</span></div>
          <div className='col-1 width-rate'>
            Rp.
          </div>
          <div className='col-6'>
            <div className='input-text-bar'>
              {renderPrice('price', 'Ex: Masukkan Harga', product?.variants[0]?.prices[0]?.price)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product '>
          <div className='col-2'>Special Price</div>
          <div className='col-1 width-rate'>
            Rp.
          </div>
          <div className='col-6'>
            <div className='input-text-bar'>
              {renderPrice('special_price', 'Ex: Masukkan Harga Spesial', product?.variants[0]?.prices[0]?.special_price)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          {/* <div className='col-2'>Discount</div> */}
          {method === 'add' &&
            <div className='offset-2 col-2'>
              <div className='input-text-bar'>
                {renderPrice('discount', 'Ex: 0', ((product?.variants[0]?.prices[0]?.price - product?.variants[0]?.prices[0]?.special_price) / product?.variants[0]?.prices[0]?.price * 100))}
                <span className='percentage-position'>%</span>
              </div>
            </div>}
          <div className='col-2 width-date'>Start Date</div>
          <div className='col-3 width-date-time__form'>
            <div className='input-text-bar'>
              {renderDateTime('special_price_from', from, product?.variants[0]?.prices[0]?.special_price_from)}
            </div>
          </div>
          <div className='col-2 width-date '>End Date</div>
          <div className='col-3 width-date-time__form'>
            <div className='input-text-bar'>
              {renderDateTime('special_price_to', to, product?.variants[0]?.prices[0]?.special_price_to)}
            </div>
          </div>
        </div>
        {/* todo: stock */}
        {method === 'add' &&
          <div className='row spacing-add-product'>
            <div className='col-2'>Stock<span className='required'>*</span></div>
            <div className='col'>
              <div className='input-text-bar'>
                {renderInputText('stock', 'Masukkan Stok', 0)}
              </div>
            </div>
          </div>}
      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4 fs--s '>
        <div className='col-12 fs--m font-weight-bold my-4 text-center'>Stok dan Harga</div>
        <div className='row spacing-add-product'>
          <div className='col-4 mb-2 '>Harga<span className='required'>*</span></div>
          <div className='col-8'>
            <div className='row'>
              <div className='col-2 mt-3 '>Rp</div>
              <div className='col'>
                <div className='input-text-bar'>
                  {renderPrice('price', 'Ex: Masukkan Harga', product?.variants[0]?.prices[0]?.price)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-4 mb-2 '>Special Price<span className='required'>*</span></div>
          <div className='col-8'>
            <div className='row'>
              <div className='col-2 mt-3 '>Rp</div>
              <div className='col'>
                <div className='input-text-bar'>
                  {renderPrice('special_price', 'Ex: Masukkan Harga Spesial', product?.variants[0]?.prices[0]?.special_price)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {method === 'add' &&
          <div className='row spacing-add-product'>
            {/* <div className='col-4 mb-2 '>Discount</div> */}
            <div className='offset-4 col-4'>
              <div className='input-text-bar'>
                {renderPrice('discount', 'Ex: 0', ((product?.variants[0]?.prices[0]?.price - product?.variants[0]?.prices[0]?.special_price) / product?.variants[0]?.prices[0]?.price * 100))}
                <span className='percentage-position'>%</span>
              </div>
            </div>
          </div>}
        <div className='row spacing-add-product'>
          <div className='col-4 mb-2'>Start Date</div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderDateTime('special_price_from', from, product?.variants[0]?.prices[0]?.special_price_from)}
            </div>
          </div>
        </div>
        <div className='row spacing-add-product'>
          <div className='col-4 mb-2'>Start Date</div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderDateTime('special_price_to', to, product?.variants[0]?.prices[0]?.special_price_to)}
            </div>
          </div>
        </div>
        {method === 'add' &&
          <div className='row spacing-add-product'>
            <div className='col-4 mb-2 '>Stok<span className='required'>*</span></div>
            <div className='col'>
              <div className='input-text-bar'>
                {renderInputText('stock', 'Masukkan Stok', 0)}
              </div>
            </div>
          </div>}
      </div>
    )
  }
  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
