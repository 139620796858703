import React from 'react'
import { Modal } from 'react-bootstrap'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import InfoBox from '../../InfoBox'
import ThumbnailProduct from '../ThumbnailProduct'

export default function UpdatePrice ({
  product,
  price,
  fetching,
  error,
  onClickedUpdatePriceModal,
  handleOnChange,
  handleUpdatePrice,
  handleToggleUpdatePriceModal
}) {
  function renderDateTime (name, initialValue) {
    return (
      <div className='row'>
        <div className='col-10 p-0'>
          <div className='input-text-bar'>
            <DateTimePicker
              disableClock
              calendarIcon={false}
              yearPlaceholder='YYYY'
              dayPlaceholder='DD'
              monthPlaceholder='MM'
              hourPlaceholder='HH'
              minutePlaceholder='mm'
              format='dd-MM-y HH:mm'
              name={name}
              value={initialValue || ''}
              onChange={value => handleOnChange(name, value, value)}
            />
          </div>
        </div>
        {/* clearing purpose */}
        <div className='col-1 p-0'>
          <button onClick={() => handleOnChange(name, '', '')} className='btn btn-secondary-ghost clear-date'><i className='anticon icon-close' /></button>
        </div>
      </div>
    )
  }

  return (
    <Modal
      show={onClickedUpdatePriceModal}
      size='update'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => handleToggleUpdatePriceModal('close', product)}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--xm font-weight-bold p-3'>Ubah Harga</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-container py-3'>
          <ThumbnailProduct product={product} />
          <div className='row align-items-center my-4'>
            <div className='col-12 col-lg-3'>Harga Normal</div>
            <div className='col-1 width-rate'>Rp.</div>
            <div className='col-11 col-lg-5 '>
              <div className='input-text-bar'>
                <input name='price' placeholder='Masukkan harga' defaultValue={price.price} type='text' onBlur={(e) => handleOnChange('price', e.target.value, e)} />
              </div>
            </div>
          </div>
          <div className='my-4 font-weight-bold fs--m'>Special Price</div>
          <div className='info-box input-box'>
            <div className='row spacing-add-product'>
              <div className='col-12 col-lg-2'>Special Price</div>
              <div className='col-1 width-rate'>
                Rp.
              </div>
              <div className='col-10 col-lg-6'>
                <div className='input-text-bar'>
                  <input name='special_price' defaultValue={price?.special_price} placeholder='Masukkan harga' type='text' onBlur={(e) => handleOnChange('special_price', e.target.value, e)} />
                </div>
              </div>
            </div>

            <div className='row spacing-add-product'>
              <div className='col-12 col-lg-2'>Start Date</div>
              <div className='col-12 col-lg-4'>
                {renderDateTime('special_from_date', price?.special_from_date)}

              </div>
              <div className='col-12 col-lg-2'>End Date</div>
              <div className='col-12 col-lg-4'>
                {renderDateTime('special_to_date', price?.special_to_date)}
              </div>
            </div>
          </div>
          {error.err && (
            <div className='row spacing-add-product mt-4'>
              <div className='col-12'>
                <InfoBox
                  text={error.message}
                  icon='danger'
                  status='danger'
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <div className='modal-container pb-3'>
        <Modal.Footer className=' justify-content-center text-center'>
          <div className='col'>
            <button type='button' disabled={fetching} onClick={() => handleToggleUpdatePriceModal('close', product)} className='btn-ghost-primary btn-m btn-full'>Cancel</button>
          </div>
          <div className='col'>
            <button type='button' disabled={fetching} onClick={(e) => handleUpdatePrice()} className='btn-primary btn-m btn-full'>{fetching ? 'Mohon menunggu' : 'Update'}</button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
