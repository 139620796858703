import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

export default function CheckInvoicesItemDetail ({ invoice, idx, reasons, invoiceId, showIncompleteReasons, handleConditional }) {
  const [choose, setChoose] = useState('')
  const [reason, setReason] = useState(0)
  const [note, setNote] = useState('')

  const handleOnChange = (e, invoice) => {
    const choose = e.target.value
    setChoose(choose)
    // handleConditional
    // (the invoice, show or not the reject reasong, complete or incomplete, rejectReasonID( set to 0), notes rejection (set to ' '))
    if (choose === 'incomplete') {
      handleConditional(invoice, true, choose, 0, '')
    } else if (choose === 'complete') {
      handleConditional(invoice, false, choose, 0, '')
    }
  }

  const handleOnchageReason = (e) => {
    const { value, name } = e.target
    if (name === 'reason') {
      setReason(parseInt(value))
      handleConditional(invoice, true, choose, parseInt(value), note)
    } else if (name === 'note') {
      setNote(value)
      handleConditional(invoice, true, choose, reason, value)
    }
  }

  // useEffect(() => {
  //   // initialize the reason id with the first item form the list reasons
  //   if (reason === 0 && reasons) {
  //     setReason(reasons[0]?.reject_reason_id)
  //   }
  // }, [reason, reasons])

  return (
    <>
      <tr key={idx} className='fs--s'>
        <td>{invoice.order_no}</td>
        <td>{invoice.invoice_no}</td>
        <td>{dayjs(invoice.created_at).locale('id').format('DD MMMM YYYY')}</td>
        <td>{invoice.items.length}</td>
        <td>
          <div className='d-flex'>
            <div className='container-radio'>
              <label className='fs--s'>Ada&nbsp;
                <input type='radio' name={`radioBtn${invoice.invoice_id}`} id={`radioBtn-yes${invoice.invoice_id}`} onChange={(e) => handleOnChange(e, invoice)} value='complete' />
                <label htmlFor={`radioBtn-yes${invoice.invoice_id}`} className='checked' />
              </label>
            </div>
            <div className='container-radio'>
              <label className='fs--s'>Tidak Ada/ Kurang
                <input type='radio' name={`radioBtn${invoice.invoice_id}`} id={`radioBtn-no${invoice.invoice_id}`} onChange={(e) => handleOnChange(e, invoice)} value='incomplete' />
                <label htmlFor={`radioBtn-no${invoice.invoice_id}`} className='checked' />
              </label>
            </div>
          </div>
          {choose !== 'complete' &&
            <div className={!(showIncompleteReasons && invoiceId === invoice.invoice_id)
              ? choose === 'complete' || choose === '' ? `d-none ${invoice.invoice_id}` : ''
              : ' '}
            >
              <div className='pt-3'>
                <div className='input-text-bar'>
                  <select className='input' name='reason' defaultValue='' onChange={(e) => handleOnchageReason(e)}>
                    <option value='' hidden>Pilih Alasan</option>
                    {!isEmpty(reasons) && reasons.map((reason, idx) => {
                      return (
                        <option key={idx} value={reason.reject_reason_id}>{reason.name}</option>
                      )
                    })}

                  </select>
                </div>
              </div>
              <div className='pt-3'>
                <div className='input-text-bar input-text-area '>
                  <textarea className='form-control fs--s ' name='note' onChange={(e) => handleOnchageReason(e)} style={{ height: '8rem' }} />
                </div>
              </div>
            </div>}

        </td>
      </tr>
    </>
  )
}
