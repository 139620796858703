import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ProductAdditionalInformationForm from '../../../Components/Products/Forms/ProductAdditionalInformationForm'
import ProductDescriptionForm from '../../../Components/Products/Forms/ProductDescriptionForm'
import ProductInformationForm from '../../../Components/Products/Forms/ProductInformationForm'
import ProductMetaInformationForm from '../../../Components/Products/Forms/ProductMetaInformationForm'
import ProductVariantInformationForm from '../../../Components/Products/Forms/ProductVariantInformationForm'
import ProductWeightAndDeliveryForm from '../../../Components/Products/Forms/ProductWeightAndDeliveryForm'
import { ScaleContext } from '../../../Context/ScaleContext'
import { fetchGetExpressCarriers, fetchGetAttributes, fetchGetBrands, fetchGetCategories, fetchGetOwnfleetTemplate, fetchGetProductDetail, fetchUpdateProduct } from '../../../Services/FetchingGroup'
import { notifyError } from '../../../Services/Notify'
import { updateProductSchema } from '../../../Validations/Product'
import UpdateProductDetailDesktop from './UpdateProductDetailDesktop'
import UpdateProductDetailMobile from './UpdateProductDetailMobile'

export default function UpdateProductFormGroup ({ sku, storeCode, supplierAlias, supplierId }) {
  const history = useHistory()

  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const user = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState()
  // const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })

  const [imageCollections, setImageCollections] = useState([])

  const [validationErr, setValidationErr] = useState([])

  const angle = [{ angle: 1, label: 'Utama' }, { angle: 2, label: 'Depan' }, { angle: 3, label: 'Samping' }, { angle: 4, label: 'Detail 1' }, { angle: 5, label: 'Detail 2' }, { angle: 6, label: 'Detail 3' }]

  const [categoryListLvl1, setCategoryListLvl1] = useState()
  const [categoryListLvl2, setCategoryListLvl2] = useState()
  const [categoryListLvl3, setCategoryListLvl3] = useState()
  const [categoryListLvl4, setCategoryListLvl4] = useState()
  const [expressCarrierList, setExpressCarrierList] = useState()
  const [attributeList, setAttributeList] = useState()
  const [brandList, setBrandList] = useState()

  const [product, setProduct] = useState({})
  const [productModified, setProductModified] = useState({})
  const [categorySelected, setCategorySelected] = useState([])
  const [attributesSelected, setAttributesSelected] = useState({ additional_attributes: [], attribute_variants: [] })
  const [expressCarrierSelected, setExpressCarrierSelected] = useState([])
  const [checkedAttributes, setCheckedAttributes] = useState([])
  const [ownfleetList, setOwnfleetList] = useState([])

  useEffect(() => {
    if (sku) {
      const params = { sku: sku }
      fetchGetProductDetail(params, setProduct, setIsLoading, null)
    }
  }, [sku])

  useEffect(() => {
    // for reset purpose
    if (!isEmpty(product) && isEmpty(productModified)) {
      // need to construct the data first
      handleInit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  async function handleInit () {
    // need to construct the data first
    // because get product detail struct data is difference with the one that we want to update
    const body = {
      attribute_set_id: product.attribute_set_id.toString(),
      attribute_variant_set_id: product.attribute_variant_set_id.toString(),
      attribute_variants: product.variants[0].attributes,
      brand: product.brand.brand_id.toString(),
      express_courier: product.variants[0].express_courier,
      description: product.description,
      gtin: product.variants[0].gtins,
      how_to_use: product.how_to_use,
      images: product.variants[0].images,
      is_accessories: product.is_accessories,
      is_sparepart: product.is_sparepart,
      manufacture_no: product.variants[0].manufacture_no,
      meta_description: product.seo.meta_description,
      meta_title: product.seo.meta_title,
      name: product.name,
      package_content: product.package_content,
      packaging_height: product.packaging_height.toString(),
      packaging_length: product.packaging_length.toString(),
      packaging_uom: product.packaging_uom || 'CM',
      packaging_width: product.packaging_width.toString(),
      product_height: product.product_height.toString(),
      product_length: product.product_length.toString(),
      product_width: product.product_width.toString(),
      sku: sku,
      sku_seller: product.variants[0].sku_seller,
      specification: product.specification,
      tag_search: product.tag_search,
      tips_trick: product.tips_trick,
      warranty_contributor: product.warranty.warranty_contributor,
      warranty_part: product.warranty.warranty_part,
      warranty_service: product.warranty.warranty_services,
      warranty_unit: product.warranty.warranty_unit,
      weight: product.weight.toString()
    }
    if (product.variants[0]?.gtins) {
      const gtinArr = []
      const gtins = product.variants[0]?.gtins
      gtins.forEach(element => {
        gtinArr.push(element.gtin)
      })
      body.gtin = gtinArr.toString()
    }

    if (product.variants[0].videos) {
      const videoArr = []
      const video = product.variants[0].videos
      video.forEach(element => {
        videoArr.push(element.video_url)
      })
      body.videos = videoArr
    }

    if (!isEmpty(ownfleetList) && product?.variants[0]?.ownfleet_template_id) {
      body.ownfleet_template_id = product.variants[0].ownfleet_template_id.toString()
    }
    setProductModified(body)

    if (product.attributes) {
      const templateAttb = product.attributes
      const collectionCheckedAttb = []
      templateAttb.forEach(element => {
        if (element.attribute_type === 'boolean') {
          collectionCheckedAttb.push(element)
        }
      })
      setCheckedAttributes(collectionCheckedAttb)
    }
    setAttributesSelected({ additional_attributes: product.attributes, attribute_variants: product.variants[0].attributes })

    // initial express courier
    if (!isEmpty(product?.variants[0]?.express_courier) && isEmpty(expressCarrierSelected)) {
      const courier = product?.variants[0]?.express_courier || []
      courier.forEach(element => {
        expressCarrierSelected.push(element.carrier_id)
      })
    }
    if (!isEmpty(product?.variants[0]?.images)) {
      const images = product?.variants[0].images || []
      // HF: Ini buat Fix validasi image angle 0 yang seharusnya anglenya 1 untuk foto utama
      let isAngleZero = false
      const filter = images?.filter(element => element.angle === 0).length
      if (filter > 0) isAngleZero = true
      else isAngleZero = false
      //= ================
      images.forEach(element => {
        const packet = {
          angle: isAngleZero ? element.angle + 1 : element.angle,
          image: element.image_url || element.image_beta_url,
          status: element.status
        }
        imageCollections.push(packet)
      })
    }

    if (product?.categories && isEmpty(categorySelected)) {
      const sort = product?.categories.sort((a, b) => {
        return a.breadcrumb_position - b.breadcrumb_position
      })
      setCategorySelected(sort)
    }

    // initial the attibutes
    if (product?.attributes) {
      const attributeParams = {
        attribute_set_id: product.attribute_set_id.toString(),
        attribute_variant_set_id: product.attribute_variant_set_id.toString()
        // `${product.attribute_variant_set_id}`
      }
      fetchGetAttributes(attributeParams, setAttributeList, setIsLoading, null)
    }
  }

  const handleImage = (type, file, angle, status) => {
    const collection = imageCollections

    const find = collection.findIndex(e => e.angle === angle)

    if (type === 'insert') {
      const packet = {
        angle: angle,
        image: file,
        status: status
      }
      if (find !== -1) {
        collection[find] = packet
      } else {
        collection.push(packet)
      }
    } else if (type === 'delete') {
      if (find !== -1) {
        collection[find].status = -1
        // collection.splice(find, 1)
        handleValidationErr(type, '', angle.label)
      } else {
        handleValidationErr(type, 'Foto tidak ditemukan', angle.label)
      }
    } else if (type === 'status') {
      if (find !== -1) {
        collection[find].status = status
      } else {
        handleValidationErr(type, 'Foto belum dimasukan', angle.label)
      }
    }
    setImageCollections([...collection])
  }

  const handleCategoryLevel = (e, level) => {
    const { value } = e.target
    const params = {
      level: level + 1,
      parent_id: value
    }
    let attributeParams = {}
    const index = parseInt(level) - 2
    const collection = categorySelected
    if (level === 2) {
      fetchGetCategories(params, setCategoryListLvl2, setIsLoading, null)
      setCategoryListLvl3()
      setCategoryListLvl4()
    } else if (level === 3) {
      fetchGetCategories(params, setCategoryListLvl3, setIsLoading, null)
      setCategoryListLvl4()
    } else if (level === 4) {
      fetchGetCategories(params, setCategoryListLvl4, setIsLoading, null)
    } else if (level === 5) {
      const filter = categoryListLvl4.data.filter(category => category.category_id === value)
      if (!isEmpty(filter)) {
        attributeParams = {
          attribute_set_id: filter[0].attribute_set_id,
          attribute_variant_set_id: filter[0].attribute_variant_set_id
        }
        fetchGetAttributes(attributeParams, setAttributeList, setIsLoading, null)
      }
    }

    collection[index] = value
    for (let i = index + 1; i < collection.length; i++) {
      collection[i] = ''
    }
    setAttributesSelected([]) // clean the attributes everytimes the category changes
    setCategorySelected(collection)
    setProductModified({ ...productModified, ...attributeParams })
  }

  const handleSetAttribute = (e, type, attribute, value, group) => {
    let collection = []
    const valueTarget = e.target.value || ''
    const { name } = e.target

    if (name === 'additional_attributes') {
      collection = attributesSelected.additional_attributes || []
    } else if (name === 'attribute_variants') {
      collection = attributesSelected.attribute_variants || []
    }
    const choosen = {
      attribute_id: parseInt(attribute.attribute_id),
      attribute_angle: '',
      attribute_unit_angle: ''
    }
    if (group === 'attribute_variants') {
      const filter = attribute?.option_list?.filter(element => element.option_id === valueTarget)
      if (!isEmpty(filter)) {
        choosen.option_value = filter[0]?.option_value
        choosen.attribute_value = filter[0]?.option_id
      }
    } else {
      if (type === 'checkbox') {
        choosen.attribute_value = value
      } else if (type === 'option' || type === 'short_text') {
        choosen.attribute_value = valueTarget
      } else if (type === 'option_unit') {
        choosen.attribute_unit_value = valueTarget
      }
    }
    if (collection.length > 0) {
      const find = collection.findIndex(e => parseInt(e.attribute_id) === parseInt(attribute.attribute_id))
      if (find !== -1) {
        const findCollection = collection[find]
        if (type === 'checkbox') {
          collection.splice(find, 1)
        } else {
          if (group === 'attribute_variants') {
            const filter = attribute?.option_list?.filter(element => element.option_id === valueTarget)
            if (!isEmpty(filter)) {
              findCollection.attribute_value = filter[0].option_id
              findCollection.option_value = filter[0].option_value
            }
          } else {
            if (type === 'option_unit') {
              findCollection.attribute_unit_value = valueTarget
            } else if (type === 'option' || type === 'short_text') {
              findCollection.attribute_value = valueTarget
            }
          }
          collection[find] = findCollection
        }
      } else {
        collection.push(choosen)
      }
    } else {
      collection.push(choosen)
    }
    setAttributesSelected({ ...attributesSelected, [name]: collection })
  }

  const handleCheckboxClick = (e, packet) => {
    const { value, checked, name } = e.target
    if (checked) {
      if (name === 'additional_attributes') {
        setCheckedAttributes([...checkedAttributes, value])
        handleSetAttribute(e, 'checkbox', packet, 'true')
      } else if (name === 'express_carrier') {
        setExpressCarrierSelected([...expressCarrierSelected, parseInt(value)])
      }
    } else {
      if (name === 'additional_attributes') {
        setCheckedAttributes(checkedAttributes.filter(item => item !== value))
        handleSetAttribute(e, 'checkbox', packet, 'false')
      } else if (name === 'express_carrier') {
        setExpressCarrierSelected(expressCarrierSelected.filter(item => item !== parseInt(value)))
      }
    }
  }

  function handleValidationErr (type, errMsg, name) {
    const collection = validationErr
    const packet = {
      name: name,
      message: errMsg
    }
    const find = collection.findIndex(e => e.name === name)
    if (find !== -1) {
      if (errMsg === '') {
        collection.splice(find, 1)
      } else {
        collection[find].message = errMsg
      }
    } else {
      collection.push(packet)
    }
    setValidationErr([...collection])
  }

  async function handleSubmit (type) {
    const categories = []
    categorySelected.forEach(element => {
      if (element.breadcrumb_position === 1 || element.breadcrumb_position === 2 || element.breadcrumb_position === 3 || element.breadcrumb_position === 4) {
        categories.push(element.category_id.toString())
      }
    })
    const body = {
      ...productModified,
      attributes: attributesSelected.additional_attributes || [],
      attribute_variants: attributesSelected.attribute_variants || [],
      category: categories,
      express_courier: expressCarrierSelected,
      images: imageCollections,
      supplier_alias: supplierAlias,
      supplier_id: supplierId
    }

    let isValid = await updateProductSchema.isValid(body)

    if (!isEmpty(imageCollections)) {
      const filter = imageCollections.filter(element => element.angle === 1).length
      if (filter !== 1) {
        isValid = false
        notifyError('Foto Utama tidak boleh kosong')
      }
    }
    if (isValid) {
      fetchUpdateProduct(body, setIsLoading, null, setSuccess)
    } else {
      const errorMessage = await updateProductSchema.validate(body).catch(function (err) {
        if (err.errors.length > 0) {
          return err.errors[0]
        }
      })
      notifyError(errorMessage)
    }
  }

  function renderComponent () {
    return (
      <>
        {categoryListLvl1 && brandList && categorySelected &&
          <ProductInformationForm
            categoryListLvl1={categoryListLvl1}
            categoryListLvl2={categoryListLvl2}
            categoryListLvl3={categoryListLvl3}
            categoryListLvl4={categoryListLvl4}
            categorySelected={categorySelected}
            setCategoryListLvl2={setCategoryListLvl2}
            setCategoryListLvl3={setCategoryListLvl3}
            setCategoryListLvl4={setCategoryListLvl4}
            handleCategoryLevel={handleCategoryLevel}
            product={productModified}
            brandList={brandList}
            isLoading={isLoading}
            setProduct={setProductModified}
            method='update'
          />}
        <ProductVariantInformationForm
          setProduct={setProductModified}
          product={productModified}
          method='update'
        />
        <ProductMetaInformationForm
          setProduct={setProductModified}
          product={productModified}
          method='update'
        />
        <ProductDescriptionForm
          setProduct={setProductModified}
          product={productModified}
          method='update'
        />
        <ProductWeightAndDeliveryForm
          supplierId={supplierId}
          ownfleetList={ownfleetList}
          expressCarrierList={expressCarrierList}
          expressCarrierSelected={expressCarrierSelected}
          handleCheckboxClick={handleCheckboxClick}
          product={productModified}
          setProduct={setProductModified}
          method='update'
        />
        <ProductAdditionalInformationForm
          attributeList={attributeList}
          attributesSelected={attributesSelected}
          checkedAttributes={checkedAttributes}
          handleSetAttribute={handleSetAttribute}
          handleCheckboxClick={handleCheckboxClick}
          setProduct={setProductModified}
          product={productModified}
          method='update'
        />
      </>
    )
  }

  useEffect(() => {
    if (success.success) {
      history.push(`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}&supplier_id=${supplierId}&status=`)
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, history])

  useEffect(() => {
    fetchGetCategories({ level: '2' }, setCategoryListLvl1, setIsLoading, null) // start from category level 2-5
    fetchGetExpressCarriers({ carrier_type: 'instant,sameday' }, setExpressCarrierList, setIsLoading, null)
    fetchGetBrands({}, setBrandList, setIsLoading, null, null)
    // todo: change to the user redux
    const supplierID = user?.user?.role_id !== 2 ? supplierId : user?.supplier?.supplier_id
    fetchGetOwnfleetTemplate({ supplier_id: supplierID }, setOwnfleetList, setIsLoading, null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (product) {
    if (isMobileView) {
      return (
        <UpdateProductDetailMobile
          storeCode={storeCode}
          supplierAlias={supplierAlias}
          supplierId={supplierId}
          handleValidationErr={handleValidationErr}
          handleCategoryLevel={handleCategoryLevel}
          handleCheckboxClick={handleCheckboxClick}
          setProduct={setProductModified}
          handleImage={handleImage}
          handleSubmit={handleSubmit}
          renderComponent={renderComponent}
          fetching={isLoading}
          angles={angle}
          validationErr={validationErr}
          product={productModified}
          imageCollections={imageCollections}
        />
      )
    } else {
      return (
        <UpdateProductDetailDesktop
          storeCode={storeCode}
          supplierAlias={supplierAlias}
          supplierId={supplierId}
          handleValidationErr={handleValidationErr}
          handleCategoryLevel={handleCategoryLevel}
          handleCheckboxClick={handleCheckboxClick}
          setProduct={setProductModified}
          handleImage={handleImage}
          handleSubmit={handleSubmit}
          renderComponent={renderComponent}
          fetching={isLoading}
          angles={angle}
          validationErr={validationErr}
          product={productModified}
          imageCollections={imageCollections}
        />
      )
    }
  }
}
