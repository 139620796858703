import React from 'react'
import { Link } from 'react-router-dom'
import ProductImageUploadForm from '../../../Components/Products/Forms/ProductImageUploadForm'

export default function InsertProductFormMobile ({ handleImage, handleValidationErr, validationErr, renderComponent, handleSubmit, fetching, angles, storeCode, supplierAlias, supplierId }) {
  return (
    <div className='container container-add-product'>
      <div className='mt-3 mb-5'>
        <div className='row border-bottom pb-3'>
          <div className='col-2 pl-0 ml-4'>
            <Link to={`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}`}>
              <span><i className='anticon icon-arrowleft' /></span>
            </Link>
          </div>
          <div className='col-9 pl-0'>
            <span className='fs--m font-weight-bold text-center'><p className='mb-0 mr-5'>Tambah Produk</p></span>
          </div>
        </div>
        {/* ----------------------- */}
        <div className='col-12 mt-4 mb-4 border px-5 py-4'>
          <div className='text-center fs--s font-weight-bold mb-3'>Foto Produk</div>
          <div className='row'>
            {angles.map((name, key) => {
              return (
                <ProductImageUploadForm
                  key={key}
                  index={key}
                  angle={name}
                  handleImage={handleImage}
                  handleValidationErr={handleValidationErr}
                  method='add'
                />
              )
            })}
          </div>
        </div>
        {
          // fetching
          //   ? (
          //     <div className='row spacing-add-product justify-content-center'>
          //       <Loading type='alt' />
          //     </div>
          //   ) :
          renderComponent()
        }
        <div className='row spacing-add-product'>
          <div className='col-12'>
            <Link to={`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}&supplier_id=${supplierId}`}>
              <button className='btn btn-full btn-negative-border btn-m font-weight-bold'>Batal</button>
            </Link>
          </div>
          <div className='col-12 mt-3 mb-3'>
            <button
              onClick={() => handleSubmit('another')}
              disabled={fetching}
              className='btn btn-full btn-primary-border btn-m font-weight-bold'
            >
              {fetching ? 'Mohon Menunggu' : 'Simpan dan Tambah Baru'}
            </button>
          </div>
          <div className='col-12'>
            <button
              onClick={() => handleSubmit('single')}
              disabled={fetching}
              className='btn btn-full btn-primary btn-m font-weight-bold'
            >
              {fetching ? 'Mohon Menunggu' : 'Simpan '}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
