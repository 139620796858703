import React, { useContext, useEffect, useState } from 'react'
import CommentSellerHeader from './CommentSellerHeader'
import { ScaleContext } from '../../Context/ScaleContext'

import OrderComment from '../Orders/OrderComment'
import Pagination from '../Pagination'

import ListActions from '../../Redux/ListRedux'
import CommentActions from '../../Redux/CommentRedux'

import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../Components/Behaviors/Loading'
import { isEmpty } from 'lodash'
export default function CommentSellers () {
  const list = useSelector(state => state.list)
  const user = useSelector(state => state.user)
  const comment = useSelector(state => state.comment)

  const dispatch = useDispatch()

  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [params, setParams] = useState({ supplier: (user?.user?.role_id === 2 ? user?.user?.supplier?.supplier_alias : ''), limit: '6', page: '', topic: '' })

  useEffect(() => {
    if (list.comment_topic === null) {
      dispatch(ListActions.commentTopicRequest())
    }
    // eslint-disable-next-line
  }, [])

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    if (paramsChange !== params) {
      setParams(paramsChange)
    }
    dispatch(CommentActions.commentRequest(paramsChange))
    // fetchGetCommentList(paramsChange, setCommentList, setIsLoading, setErr)
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }
  return (
    <div className='col-12 col-lg offset-lg-2 p-0'>
      <div className='order-header'>
        <div className='p-5'>
          <CommentSellerHeader
            params={params}
            setParams={setParams}
            total={comment?.comment?.total_comment}
            isLoading={comment?.fetching}
            err={comment?.err}
            handleParamsChange={handleParamsChange}
          />
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 mt-5'>
            {comment?.comment?.total_comment > 6 &&
              <Pagination
                total={comment?.comment?.total_comment}
                show={6}
                classNames='pagination pull-right'
                handlePageClick={handlePageClick}
              />}
          </div>

        </div>

        {
            comment?.fetching
              ? <Loading type='alt' />
              : (isEmpty(comment?.comment?.comment_list)) ?(
                <div className='row text-center align-content-center' style={{ height: '400px' }}>
                <div className='col-12'>
                  <span className='fs--m'><b>Saat ini Anda tidak memiliki comment.</b></span>
                </div>
              </div>
              ):
              (
                <div className={`row ${isMobileView ? 'pt-3' : 'p-3'}`}>
                {comment?.comment?.comment_list?.map((invoice, index) =>{
                  return(
                  <div className={`outer-comment-box ${isMobileView ? 'col-12 p-0' : 'col-4 p-3'}`} key={ index } >
                    <div className='border-top border-left border-right p-4 m-0'>
                      <div className='row'>
                        <div className='col-12 mb-5'>
                        <i className='anticon icon-isv'></i> <b>{invoice.supplier?.name}</b>
                         </div>
                         <div className='col-6 text-left'> <b>{invoice.invoice_no}</b> </div>
                         <div className='col-6 text-right'><b>{invoice.invoice_status}</b></div>
                         </div>
                      </div>
                    <OrderComment invoice={invoice} handleParamsChange={handleParamsChange}  params={params} from='comment-seller'/>
                  </div>
                  )
                })}
              </div>
              )
            }
      </div>
    </div>
  )
}
