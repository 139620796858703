import { put, fork, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import ListActions, { ListTypes } from '../Redux/ListRedux'
import isEmpty from 'lodash/isEmpty'

export function * getSupplierList (api) {
  let action = yield take(ListTypes.SUPPLIER_LIST_REQUEST)
  while (action !== END) {
    yield fork(fetchGetSupplierList, api, action)
    action = yield take(ListTypes.SUPPLIER_LIST_REQUEST)
  }
}

export function * getCarrierList (api) {
  let action = yield take(ListTypes.CARRIER_REQUEST)
  while (action !== END) {
    yield fork(fetchGetCarrierList, api, action)
    action = yield take(ListTypes.CARRIER_REQUEST)
  }
}

export function * getCommentTopicList (api) {
  let action = yield take(ListTypes.COMMENT_TOPIC_REQUEST)
  while (action !== END) {
    yield fork(fetchCommentTopicList, api, action)
    action = yield take(ListTypes.COMMENT_TOPIC_REQUEST)
  }
}

export function * fetchGetSupplierList (api, { data }) {
  try {
    const res = yield call(api.getSupplierList, data)
    if (!res.ok) {
      yield put(ListActions.supplierListFailure())
    } else {
      if (isEmpty(res.data.errors)) {
        const data = res.data.data
        if (data) {
          yield put(ListActions.supplierListSuccess(data))
        } else {
          // ON DEVELOPMENT
        }
      } else {
        if (!isEmpty(res.data.errors.message)) {
          yield put(ListActions.supplierListFailure(res.data.errors.message))
        } else {
          yield put(ListActions.supplierListFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
        }
      }
    }
  } catch (error) {
    yield put(ListActions.supplierListFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
  }
}

export function * fetchGetCarrierList (api, { data }) {
  try {
    const res = yield call(api.getCarrierList, data)
    if (!res.ok) {
      yield put(ListActions.carrierFailure())
    } else {
      if (isEmpty(res.data.errors)) {
        const data = res.data.data
        if (data) {
          yield put(ListActions.carrierSuccess(data))
        } else {
          // ON DEVELOPMENT
        }
      } else {
        if (!isEmpty(res.data.errors.message)) {
          yield put(ListActions.carrierFailure(res.data.errors.message))
        } else {
          yield put(ListActions.carrierFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
        }
      }
    }
  } catch (error) {
    yield put(ListActions.carrierFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
  }
}

export function * fetchCommentTopicList (api, { data }) {
  try {
    const res = yield call(api.getCommentTopicList, data)
    if (!res.ok) {
      yield put(ListActions.commentTopicFailure())
    } else {
      if (isEmpty(res.data.errors)) {
        const data = res.data.data
        if (data) {
          yield put(ListActions.commentTopicSuccess(data))
        } else {
          // ON DEVELOPMENT
        }
      } else {
        if (!isEmpty(res.data.errors.message)) {
          yield put(ListActions.commentTopicFailure(res.data.errors.message))
        } else {
          yield put(ListActions.commentTopicFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
        }
      }
    }
  } catch (error) {
    yield put(ListActions.commentTopicListFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
  }
}
