import React, { useContext } from 'react'

import { ScaleContext } from '../../../Context/ScaleContext'

export default function ProductVariantInformationForm ({ setProduct, method, product }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const handleSetProduct = (e) => {
    const { name, value } = e.target
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderInputText (name, placeholder, value) {
    return <input disabled={method === 'view'} className={isMobileView ? 'fs--s' : 'fs--m'} defaultValue={method === 'update' || method === 'view' ? (product[name] || '') : ''} placeholder={placeholder} name={name} type='text' onBlur={(e) => handleSetProduct(e)} />
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Informasi Variant</div>

        <div className='row spacing-add-product'>
          <div className='col-2'>
                  SKU Seller<span className='required'>*</span>
          </div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('sku_seller', 'Ex: 123456789', (product.sku_seller || ''))}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>
                  Manufacture Number
          </div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('manufacture_no', 'Ex: 123456789', (product.manufacture_no || ''))}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-2'>
                 GTIN (Bracode)
          </div>
          <div className='col'>
            <div className='input-text-bar'>
              {renderInputText('gtin', 'Ex: barcode1, barcode2, barcode3', (product.gtin?.toString() || ''))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4 fs--s '>
        <div className='col-12 mb-2 fs--m font-weight-bold mb-3 text-center'>Informasi Variant</div>
        <div className='row spacing-add-product'>
          <div className='col-12 mb-2'>SKU Seller</div>

          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderInputText('sku_seller', 'Ex: 123456789', (product.sku_seller || ''))}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2'>Manufacture Number</div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderInputText('manufacture_no', 'Ex: 123456789', (product.manufacture_no || ''))}
            </div>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2'>GTIN (Bracode)
          </div>
          <div className='col-12 mb-2'>
            <div className='input-text-bar'>
              {renderInputText('gtin', 'Ex: barcode1, barcode2, barcode3', (product.gtin?.toString()))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
