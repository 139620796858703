import React from 'react'
import BaseQuery from '../Base/BaseQuery'
import PrintHeader from '../Containers/PrintHeader'
import Prints from '../Containers/Prints'

export default function Print () {
  const type = BaseQuery().get('type')
  const status = BaseQuery().get('status')
  const method = BaseQuery().get('method')
  const invoiceNo = BaseQuery().get('invoice_no')
  const invoiceId = BaseQuery().get('invoice_id')

  const showPrint = (e) => {
    window.print()
  }

  return (
    <div className='p-5'>
      <div className='no-print'>
        <PrintHeader type={type} status={status} print={showPrint} method={method} invoiceNo={invoiceNo} invoiceId={invoiceId} />
      </div>
      <Prints type={type} status={status} method={method} invoiceNo={invoiceNo} invoiceId={invoiceId} />
    </div>
  )
}
