import React from 'react'
import DefaultLayout from '../Containers/DefaultLayout'
import RejectedProductGroup from '../Containers/Products/RejectedProductGroup'
import ProductListGroup from '../Containers/Products/List/ProductListGroup'
import BaseQuery from '../Base/BaseQuery'

export default function Product (props) {
  const { menu } = props.match.params
  const storeCode = BaseQuery().get('store_code')
  const supplierAlias = BaseQuery().get('supplier_alias')
  const keyword = BaseQuery().get('keyword')
  const status = BaseQuery().get('status')
  const sortBy = BaseQuery().get('sort_by')
  const sortType = BaseQuery().get('sort_type')
  const supplierId = BaseQuery().get('supplier_id')

  return (
    <DefaultLayout pages='product' menu={menu}>
      {(menu === 'list-product') ? <ProductListGroup storeCode={storeCode} keyword={keyword} supplierAlias={supplierAlias} status={status} sortBy={sortBy} sortType={sortType} supplierId={supplierId} /> : null}
      {(menu === 'rejected-product') ? <RejectedProductGroup storeCode={storeCode} keyword={keyword} supplierAlias={supplierAlias} status={status} sortBy={sortBy} sortType={sortType} supplierId={supplierId} /> : null}
      {/* {checkRole({
        menu: 'product_list',
        method: 'view',
        attribute: 'all',
        yes: (access) => {
          if (menu === 'list-product') return <ProductListGroup storeCode={storeCode} keyword={keyword} supplierAlias={supplierAlias} status={status} sortBy={sortBy} sortType={sortType} supplierId={supplierId} />
        },
        no: (access) => {
          return <NoAccessRules />
        },
        userRules: user?.role
      })}
      {checkRole({
        menu: 'defect_product',
        method: 'view',
        attribute: 'all',
        yes: (access) => {
          if (menu === 'rejected-product') return <RejectedProductGroup storeCode={storeCode} keyword={keyword} supplierAlias={supplierAlias} status={status} sortBy={sortBy} sortType={sortType} supplierId={supplierId} />
        },
        no: (access) => {
          return <NoAccessRules />
        },
        userRules: user?.role
      })} */}
    </DefaultLayout>
  )
}
