import React from 'react'
import { useSelector } from 'react-redux'

export default function ProfileStatusDesktop ({
  supplierData,
  status,
  closedTime,
  fetching,
  error,
  setNotes,
  checkedValues,
  renderStatus,
  renderDatePicker,
  handleCheckboxClick,
  handleChangeStatus,
  handleInit
}) {
  const closedAt = closedTime.closed_at ? new Date(closedTime.closed_at) : ''
  const closedUntil = closedTime.closed_until ? new Date(closedTime.closed_until) : ''
  const user = useSelector(state => state.user)
  return (
    <div className='row fs--m p-4'>
      <div className='col-12 mb-4'>
        <div className='row'>
          <div className='col-2'>
            Status toko
          </div>
          <div className='col-10'>
            {renderStatus()}
          </div>
        </div>
      </div>
      <div className='col-12 mb-4'>
        <div className='row align-items-center'>
          <div className='col-2'>
            Tutup toko
          </div>

          <div className='col-10 py-1'>
            <div className='row align-items-center '>
              <div className='col-2'>
                Mulai&nbsp;Tanggal
              </div>
              <div className='col-3 ml-4 pl-0 pr-0'>
                <div className='input-text-bar'>
                  {renderDatePicker('closed_at', closedAt)}
                </div>
              </div>

              <div className='col-3 ml-1 pr-0 text-center'>
                Sampai Tanggal
              </div>
              <div className='col-3 pl-0 pr-0'>
                <div className='input-text-bar'>
                  {renderDatePicker('closed_until', closedUntil)}
                </div>
              </div>
            </div>
          </div>

          {user.user?.role_id === 1 &&
            <div className='col-10 offset-2 my-2'>
              <div className=' pb-3'>
                <div className='row pt-4 align-items-center'>
                  <div className='col-4'>
                    <label className='container-checkbox mb-0'>
                      <input
                        type='checkbox'
                        name='express_carrier'
                        value
                        checked={checkedValues || status === -1}
                        disabled={status === -1 || status === 0}
                        onChange={(e) => handleCheckboxClick(e)}
                      />
                      <span className='checkbox' />
                      <span>Tutup Selamanya</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>}

          <div className='col-10 offset-2 my-2'>
            <div className='row'>
              <div className='col-2'>
                Catatan
              </div>
              <div className='col-9 '>
                <textarea disabled={status === -1 || status === 0} placeholder='Tulis alasan tutup toko disini...' className='fs--s form-control' rows='5' onChange={(e) => setNotes(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        error.err && (
          <div className='col-12 pt-5'>
            <span className='error fs--m'>{error.message}</span>
          </div>
        )
      }
      <div className='col-12 pt-5'>
        <div className='row'>
          <div className='col-6'>
            <button disabled={fetching} onClick={() => handleInit()} className='btn btn-ghost-secondary btn-m btn-full'><p className='mb-0 fs--m font-weight-bold'>Reset</p></button>
          </div>
          {(supplierData?.closed_at && supplierData?.closed_until) || status === -1 || status === 0
            ? (
              <div className='col'>
                <button disabled={fetching} onClick={(e) => handleChangeStatus(e, 10)} className='btn btn-primary btn-m btn-full'><p className='mb-0 fs--m font-weight-bold'>{fetching ? 'Mohon Menunggu ...' : 'Buka Sekarang'}</p></button>
              </div>
              )
            : (
              <div className='col'>
                <button disabled={fetching} onClick={(e) => handleChangeStatus(e, 0)} className='btn btn-primary btn-m btn-full'><p className='mb-0 fs--m font-weight-bold'>{fetching ? 'Mohon Menunggu ...' : 'Simpan'}</p></button>
              </div>
              )}

        </div>
      </div>
    </div>
  )
}
