import config from '../../config'

import React, { useContext, useEffect, useState } from 'react'
import { startsWith, isEmpty } from 'lodash'
import { useDropzone } from 'react-dropzone'

import MapComponent from '../../Components/Profiles/MapComponent'
import ProfileInfoMobile from '../../Components/Profiles/Info/ProfileInfoMobile'
import ProfileInfoDesktop from '../../Components/Profiles/Info/ProfileInfoDesktop'

import { infoSchema } from '../../Validations/Profile'

import { ScaleContext } from '../../Context/ScaleContext'
import { ProfileContext } from '../../Context/ProfileContext'

import UserActions from '../../Redux/UserRedux'
import { useDispatch, useSelector } from 'react-redux'

import { fetchChangeSupplierInfo, fetchGetProvinceList, fetchGetCityList, fetchGetDistrictList } from '../../Services/FetchingGroup'
import { notifyError } from '../../Services/Notify'
import imageDefault from '../../static/images/no-thumbnail.png'

const defaultGeolocation = ['-6.188441699999998', '106.73396839999998']

export default function ProfileInfo () {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const [err, setErr] = useState({ err: false, message: '' })
  const [attContextProfile] = useContext(ProfileContext)
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [districtList, setDistrictList] = useState([])

  const [clickedInstantCourier, setClickedInstantCourier] = useState(false)
  const [supplierAddress, setSupplierAddress] = useState({})

  const [clickedHasGeoChange, setClickedHasGeoChange] = useState(false)
  const [center, setCenter] = useState({ lat: parseFloat(defaultGeolocation[0]), lng: parseFloat(defaultGeolocation[1]) })

  const [files, setFiles] = useState({})
  const maxSize = 1048576

  let imageUrl = imageDefault
  const image = attContextProfile?.supplier_data?.logo
  if (!isEmpty(image)) {
    if (startsWith(image, '/v')) {
      imageUrl = config.imageURL + image
    } else {
      imageUrl = image
    }
  } else {
    imageUrl = imageDefault
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if (acceptedFiles[0]?.size > maxSize) {
        setFiles({})
        setErr({ err: true, message: 'Ukuran file lebih dari 1 MB ' })
      } else {
        fileAccepted(acceptedFiles)
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })))
      }
    },
    maxFiles: 1,
    multiple: false
  })

  useEffect(() => {
    handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInit = () => {
    if (attContextProfile?.supplier_data) {
      const addressData = attContextProfile.supplier_data.supplier_addresses[0]
      setFiles(imageUrl)
      setSupplierAddress(addressData)
      fetchGetProvinceList({}, setProvinceList, setIsLoading, setErr)
      fetchGetCityList(addressData.province_id, setCityList, setIsLoading, setErr)
      fetchGetDistrictList(addressData.city_id, setDistrictList, setIsLoading, setErr)
      const convertedGeolocation = !isEmpty(addressData.geolocation) ? addressData.geolocation.split(',') : false
      if (convertedGeolocation) {
        setCenter({ lat: parseFloat(convertedGeolocation[0]), lng: parseFloat(convertedGeolocation[1]) })
      }
      if (addressData.instant_courier_status === 10) {
        setClickedInstantCourier(true)
      } else {
        setClickedInstantCourier(false)
      }
    }
  }

  // ==== START -- HANDLE CHANGES ====
  const getMapsData = (mapsData, type) => {
    if (type === 'FN') {
      setCenter({ lat: mapsData.lat(), lng: mapsData.lng() })
    } else {
      setCenter(mapsData)
    }
  }

  const handleChangeProvince = (e) => {
    const value = e.target.value
    setSupplierAddress({
      ...supplierAddress,
      province_id: parseInt(value),
      city_id: 0,
      kecamatan_id: 0
    })
    fetchGetCityList(value, setCityList, setIsLoading, setErr)
  }

  const handleChangeCity = (e) => {
    const value = e.target.value
    setSupplierAddress({
      ...supplierAddress,
      city_id: parseInt(value),
      kecamatan_id: 0
    })
    fetchGetDistrictList(value, setDistrictList, setIsLoading, setErr)
  }

  const handleChangeDistrict = (e) => {
    const value = e.target.value
    setSupplierAddress({
      ...supplierAddress,
      kecamatan_id: parseInt(value),
      kecamatan_code: value
    })
  }

  const handleChangeAddress = (e) => {
    const { value, name } = e.target
    setSupplierAddress({
      ...supplierAddress,
      [name]: value
    })
  }

  const handleGeoChange = (boolean) => {
    setClickedHasGeoChange(!boolean)
  }

  // ==== END -- HANDLE CHANGES ====

  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = e => {
      const result = e.target.result
      const image = new Image()
      image.src = result
      setFiles(result)
    }
  }

  // ==== START -- FETCH API ====
  async function handleChangeSupplierInfo () {
    const supplierData = attContextProfile.supplier_data
    const addressData = attContextProfile.supplier_data.supplier_addresses[0]
    if (supplierData) {
      let mutatedSupplierAddress = { ...supplierAddress, geolocation: center.lat + ',' + center.lng }
      // harus di cek yaaa
      // mutatedSupplierAddress.phone = mutatedSupplierAddress.phone?.replace(/^\D+/g, '')
      if (clickedInstantCourier) {
        mutatedSupplierAddress = {
          ...supplierAddress,
          instant_courier_status: 10,
          geolocation: center.lat + ',' + center.lng
        }
      } else {
        mutatedSupplierAddress = {
          ...supplierAddress,
          instant_courier_status: 0,
          geolocation: addressData.geolocation
        }
      }
      const body = {
        logo: files,
        supplier_id: supplierData.supplier_id,
        supplier_alias: supplierData.supplier_alias,
        supplier_address: mutatedSupplierAddress
      }

      const isValid = await infoSchema.isValid(body.supplier_address)

      if (isValid) {
        setErr({ err: false, message: '' })
        fetchChangeSupplierInfo(body, setIsLoading, setErr, setSuccess)
      } else {
        const errorMessage = await infoSchema.validate(body.supplier_address).catch(function (err) {
          return err.errors[0]
        })
        setErr({ err: true, message: errorMessage })
      }
      //
    } else {
      const message = 'Supplier data tidak ada'
      notifyError(message)
      setErr({ err: true, message: message })
    }
  }

  useEffect(() => {
    if (success.success) {
      const supplierData = attContextProfile.supplier_data
      attContextProfile.getProfile()
      if (user.user?.role_id === 2) {
        dispatch(UserActions.supplierRequest({ supplier_id: supplierData.supplier_id }))
      }
      setSuccess({ success: false, data: {} })
    }
    // eslint-disable-next-line
  }, [success, attContextProfile])

  useEffect(() => {
    if (clickedInstantCourier && isEmpty(supplierAddress.phone)) {
      // only seller that have phone number that can active this instant courier
      setClickedInstantCourier(false)
    }
  }, [supplierAddress.phone, clickedInstantCourier])
  // ==== END -- FETCH API ====

  const uploadProduct = () => {
    return (
      <div className='align-content-left'>
        <div {...getRootProps({ className: 'dropzone-profile' })} style={{ width: '13rem' }}>
          <input {...getInputProps()} />
          <div className='dropzone-product'>
            <div className='dropzone-area text-center m-auto'>
              <div className='dropzone-space__profile d-flex' style={!isEmpty(files) ? { opacity: 0 } : { opacity: 1 }}><i className='anticon icon-plus' /></div>
              <div className='dropzone-title__profile with-button'>
                <span>Ubah Foto Profil</span>
              </div>
            </div>
          </div>
        </div>
        <label htmlFor='image' className='preview-container-profile border'>
          {!isEmpty(files) ? <img src={files} alt='images' /> : <img src={imageUrl} alt='images' />}
        </label>
        =
        {/* {acceptedFiles.length > 0 && fileAccepted(acceptedFiles)} */}
        {/* {files && showPreview(files)} */}
      </div>
    )
  }

  function renderSelect () {
    return (
      <>
        <div className='col-12 col-lg-3 offset-lg-2 pt-4'>
          <div className='input-text-bar'>
            <select className='input' onChange={handleChangeProvince} value={supplierAddress.province_id}>
              <option value={0} hidden>--PROVINSI--</option>
              {provinceList?.province?.map((province, index) => {
                return (
                  <option key={index} value={province.province_id}>{province.province_name}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='col-12 col-lg-3 pt-4'>
          <div className='input-text-bar'>
            <select className='input' onChange={handleChangeCity} value={supplierAddress.city_id}>
              <option value={0} hidden>--KOTA--</option>
              {cityList?.city?.map((city, index) => {
                return (
                  <option key={index} value={city.city_id}>{city.city_name}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='col-12 col-lg-3 pt-4'>
          <div className='input-text-bar'>
            <select className='input' onChange={handleChangeDistrict} value={supplierAddress.kecamatan_id}>
              <option value={0} hidden>--KECAMATAN--</option>
              {districtList?.kecamatan?.map((district, index) => {
                return (
                  <option key={index} value={district.kecamatan_id}>{district.kecamatan_name}</option>
                )
              })}
            </select>
          </div>
        </div>
      </>
    )
  }

  function renderMap () {
    return (
      <MapComponent
        isMarkerShown
        googleMapURL={config.googleMapURL}
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '200px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        getMapsData={getMapsData}
        geolocation={center}
        hasGeoChange={clickedHasGeoChange}
        handleGeoChange={handleGeoChange}
      />
    )
  }

  return isMobileView
    ? (
      <ProfileInfoMobile
        uploadProduct={uploadProduct}
        renderSelect={renderSelect}
        renderMap={renderMap}
        supplierAddress={supplierAddress}
        center={center}
        fetching={isLoading}
        error={err}
        getMapsData={getMapsData}
        clickedInstantCourier={clickedInstantCourier}
        clickedHasGeoChange={clickedHasGeoChange}
        setClickedInstantCourier={setClickedInstantCourier}
        handleInit={handleInit}
        handleGeoChange={handleGeoChange}
        handleChangeSupplierInfo={handleChangeSupplierInfo}
        handleChangeAddress={handleChangeAddress}
      />
      )
    : (
      <ProfileInfoDesktop
        uploadProduct={uploadProduct}
        renderSelect={renderSelect}
        renderMap={renderMap}
        supplierAddress={supplierAddress}
        center={center}
        fetching={isLoading}
        error={err}
        getMapsData={getMapsData}
        clickedInstantCourier={clickedInstantCourier}
        clickedHasGeoChange={clickedHasGeoChange}
        setClickedInstantCourier={setClickedInstantCourier}
        handleInit={handleInit}
        handleGeoChange={handleGeoChange}
        handleChangeSupplierInfo={handleChangeSupplierInfo}
        handleChangeAddress={handleChangeAddress}
      />
      )
}
