import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  countRequest: ['data'],
  countSuccess: ['data'],
  countFailure: ['err'],
  countProductRequest: ['data'],
  countProductSuccess: ['data'],
  countProductFailure: ['err'],
  countInit: null
})

export const CountTypes = Types
export default Creators

// Initial State
export const INITIAL_STATE = Immutable({
  err: null,
  fetching: false,
  fetching_product: false,
  success: false,
  count_order: null,
  count_product: null
})

export const init = (state) => {
  return ({
    ...state,
    fetching: false,
    fetching_product: false,
    err: null,
    count_order: null,
    count_product: null
  })
}

export const request = (state) => {
  return ({
    ...state,
    success: false,
    fetching: true
  })
}

export const success = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    count_order: data,
    err: null
  })
}

export const successProduct = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching_product: false,
    count_product: data,
    err: null
  })
}

export const failure = (state, { err }) => {
  return ({
    ...state,
    success: false,
    fetching: false,
    fetching_product: false,
    err
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COUNT_REQUEST]: request,
  [Types.COUNT_SUCCESS]: success,
  [Types.COUNT_FAILURE]: failure,
  [Types.COUNT_PRODUCT_REQUEST]: request,
  [Types.COUNT_PRODUCT_SUCCESS]: successProduct,
  [Types.COUNT_PRODUCT_FAILURE]: failure,
  [Types.COUNT_INIT]: init
})
