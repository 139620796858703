import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ScaleContext } from '../../Context/ScaleContext'
import { fetchUpdateInvoice } from '../../Services/FetchingGroup'

import Loading from '../../Components/Behaviors/Loading'
import Pagination from '../Pagination'

import SelectAndSeeItemsListsModal from '../Modals/Order/SelectAndSeeItemsListsModal'
import CommentModal from '../Modals/CommentModal'
import ShipmentStatusModal from '../Modals/Order/ShipmentStatusModal'
import OrderGroupContainer from './OrderGroupContainer'
import ShipmentFeeModal from '../Modals/Order/ShipmentFeeModal'

export default function NewOrderDetail ({ params, handleParamsChange, fetchUpdateInvoiceStatus, invoiceList, fetching, error }) {
  const count = useSelector(state => state.count)

  const [paramsModified, setParamsModified] = useState({ status: 'new', page: 1, keyword: '', supplier: params?.supplier || '' })
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [newInvoiceList, setNewInvoiceList] = useState()
  const [selectedInvoice, setSelectedInvoice] = useState()

  const [onClickedModal, setOnClickedModal] = useState({
    seeMultipleProductListModal: false,
    commentModal: false,
    shipmentStatusModal: false,
    shipmentFeeModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  useEffect(() => {
    handleParamsChange(paramsModified)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (invoiceList !== newInvoiceList) setNewInvoiceList(invoiceList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceList])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      setParamsModified({ ...paramsModified, page: selected + 1 })
      handleParamsChange({ ...paramsModified, page: selected + 1 })
    }
  }

  const handleToogleSingleListItem = (invoice) => {
    const body = {
      invoices: [invoice.invoice_id],
      status: 'processing'
    }
    fetchUpdateInvoice(body, null, null, null)
  }

  function renderModal () {
    return (
      <>
        {onClickedModal.seeMultipleProductListModal &&
          <SelectAndSeeItemsListsModal
            invoices={newInvoiceList.invoices}
            fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentFeeModal &&
          <ShipmentFeeModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentStatusModal &&
          <ShipmentStatusModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }

  const renderDesktop = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 px-5 py-4'>
            <div className='row align-items-center'>
              <div className='col-4'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.new} order baru</h1>
              </div>
              <div className='col-4'>
                <button
                  onClick={() => handleToogleModal('seeMultipleProductListModal', 'open')}
                  className='btn-primary btn-s w-100 fs--s '
                >
                  Pilih & Lihat Daftar Barang
                </button>
              </div>
              <div className='col-4' style={{ display: 'flex', justifyContent: 'center' }}>
                {newInvoiceList?.total_invoice > 10 &&
                  <Pagination
                    total={newInvoiceList?.total_invoice}
                    show={10}
                    classNames='pagination pull-right'
                    handlePageClick={handlePageClick}
                  />}
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : (
              <OrderGroupContainer
                type='new'
                invoices={newInvoiceList}
                handleToogleSingleListItem={handleToogleSingleListItem}
                handleToogleModal={handleToogleModal}
              />
              )}
          {renderModal()}
        </div>
      )
    }
  }

  const renderMobile = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 p-3'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.new} order baru</h1>
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='new'
                invoices={newInvoiceList}
                handleToogleSingleListItem={handleToogleSingleListItem}
                handleToogleModal={handleToogleModal}
              />}
          {renderModal()}
          <div className='col-12'>
            {newInvoiceList?.total_invoice > 10 &&
              <Pagination
                total={newInvoiceList?.total_invoice}
                show={10}
                classNames='pagination pull-right'
                handlePageClick={handlePageClick}
              />}
          </div>
        </div>
      )
    }
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
