import React, { useEffect, useRef, useState } from 'react'
import Pagination from '../../../Containers/Pagination'
import ProductHeader from '../../../Containers/Products/ProductHeader'
import ProductList from '../../../Containers/Products/List/ProductList'
import SearchProduct from '../SearchProduct'
import SelectInput from '../SelectInput'

export default function ProductListMobile ({
  handleToggleBulkUploadModal,
  handlePageClick,
  listProduct,
  params,
  fetching,
  error,
  supplierDetail,
  supplierId,
  sortList,
  filterList,
  handleSelect,
  renderModal,
  handleChangeParams,
  handleToggleUpdatePriceModal,
  handleToggleUpdateStatusModal,
  handleToggleUpdateStockModal,
  handleToggleDeleteProductModal,
  handleToggleSetCommissionModal
}) {
  const [status, setStatus] = useState(params.status)
  const selectedSort = useRef(0)
  const selectedFilter = useRef(0)

  useEffect(() => {
    const filteredSort = sortList?.filter(element => element.type === params.sort_type && element.by === params.sort_by)
    if (filteredSort?.length > 0) {
      selectedSort.current = filteredSort[0].id
    } else selectedSort.current = 0

    const filteredList = filterList?.filter(element => element.value === params.status)
    if (filteredList?.length > 0) {
      selectedFilter.current = filteredList[0].id
    } else selectedFilter.current = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (status !== params.status) {
      setStatus(params.status)
    }
  }, [params, status])

  return (
    <div className='col-12 col-lg p-0 border-left'>
      <div className='product-header'>
        <ProductHeader supplierDetail={supplierDetail} supplierId={supplierId} handleToggleBulkUplModaload={handleToggleBulkUploadModal} params={params} type='listProduct' />
      </div>
      <div className='product-background'>
        <div className='px-4 pt-4 pb-3'>
          <div className='row '>
            <div className='col-12'>
              <SearchProduct handleChangeParams={handleChangeParams} type='' />
            </div>
          </div>
        </div>
        {/* <div className='col-12 pt-1'>
          <div className='text-left font-weight-bold fs--s '>Sort by</div>
        </div> */}
        <SelectInput
          label='Sort By'
          optionGroup={sortList}
          action={handleSelect}
          defaultValue={selectedSort.current}
          name='sort'
          labelClass='col-12 py-1 text-left font-weight-bold fs--s '
          inputClass='col-12'
        />
        <div className='col-12 px-5 pt-4'>
          <div className='row border py-4'>
            <SelectInput
              label='Filter '
              optionGroup={filterList}
              action={handleSelect}
              defaultValue={selectedFilter.current}
              name='filter'
              labelClass='col-12 py-1 text-left font-weight-bold fs--s '
              inputClass='col-12'
            />
            <div className='col-12 mt-5'>
              {listProduct?.total > 10 &&
                <Pagination
                  total={listProduct?.total}
                  show={10}
                  classNames='pagination justify-content-center'
                  handlePageClick={handlePageClick}
                />}
            </div>
          </div>
        </div>
        <div className='px-4 pb-5'>
          <ProductList
            params={params}
            listProduct={listProduct}
            fetching={fetching}
            error={error}
            handleChangeParams={handleChangeParams}
            handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
            handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
            handleToggleUpdateStockModal={handleToggleUpdateStockModal}
            handleToggleDeleteProductModal={handleToggleDeleteProductModal}
            handleToggleSetCommissionModal={handleToggleSetCommissionModal}
          />
        </div>
      </div>
      {renderModal()}
    </div>
  )
}
