import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import config from '../../config'
import { ForgotPasswordContext } from '../../Context/ForgotPasswordContext'
import { fetchResetPassword } from '../../Services/FetchingGroup'

export default function ChangePassword () {
  const history = useHistory()

  const [attbContext] = useContext(ForgotPasswordContext)
  const [password, setPassword] = useState({})

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)

  const [success, setSuccess] = useState({ success: false, data: {} })

  useEffect(() => {
    if (isEmpty(attbContext.value)) {
      window.location.replace(config.baseURL + '/login?page=forgot-password&type=forgot-password')
    }
  }, [attbContext, history])

  useEffect(() => {
    if (success.success) {
      // this is for verify OTP success
      // it will be pushed to change password while bringing the token for the header
      if (success.data) {
        history.push('/login')
      }
      setSuccess({ success: false, data: {} })
    }
  }, [success, history])

  function handleOnChange (e) {
    const { name, value } = e.target
    setPassword({ ...password, [name]: value })
  }

  function handleUpdatePassword (e) {
    e.preventDefault()
    if (password?.password && password?.password_confirmation) {
      if (password.password === password.password_confirmation) {
        const body = {
          password: password.password,
          token: attbContext.value.token
        }
        fetchResetPassword(body, setIsLoading, setErr, setSuccess)
        // fetch
      } else {
        setErr({ err: true, message: 'Password Konfirmasi tidak sama' })
      }
    } else {
      setErr({ err: true, message: 'Dimohon untuk melangkapi inputan sebelum melanjutkan' })
    }
  }

  return (
    <div className='row fs--m justify-content-center'>
      <form className='form-width' onSubmit={(e) => { handleUpdatePassword(e) }}>
        <div className='col-12 mt-4' style={{ padding: '0 5rem' }}>
          <div className='input-text-bar'>
            <input name='password' type='password' placeholder='Masukkan kata sandi' onChange={(e) => handleOnChange(e)} />
          </div>
        </div>
        <div className='col-12 mt-4' style={{ padding: '0 5rem' }}>
          <div className='input-text-bar'>
            <input name='password_confirmation' type='password' placeholder='Masukkan kata sandi ulang' onChange={(e) => handleOnChange(e)} />
          </div>
        </div>
        {err.err &&
      (
        <div className='col-12 mt-4' style={{ padding: '0 5rem' }}>
          {err.message}
        </div>

      )}
        <div className='col-12 mt-4' style={{ padding: '0 5rem' }}>
          <div className='input-text-bar'>
            <button type='submit' disabled={isLoading} onClick={(e) => handleUpdatePassword(e)} className='btn btn-primary btn-full btn-m'>{isLoading ? 'Mohon Menunggu' : 'Kirim'}</button>
          </div>
        </div>
      </form>
    </div>
  )
}
