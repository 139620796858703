import React, { useContext } from 'react'
import ContentOrderGroup from './ContentOrderGroup'
import ButtonOrderGroup from './ButtonOrderGroup'
import { ScaleContext } from '../../Context/ScaleContext'
// import { CountUp, FinalCount, clearCountUpInterval } from '../../Utils/CountUp'

export default function OrderGroup ({
  invoice,
  grandTotal,
  shipmentFeeTotal,
  handleToogleModal,
  handleToogleSingleListItem,
  handleTooglePrintPackingSlipPrint
}) {
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  let percentage = ''

  const renderShipmentStatus = (menu) => {
    // todo harus lempar persentase buat di progressnya, disabled dan non disabled nya, active atau engganya
    // Harus ngecek dari shipment status bukan menu
    switch (menu) {
      case 'new' || 'canceled':
        percentage = '8%'
        break
      case 'processing':
        percentage = '24%'
        break
      case 'stand_by':
        percentage = '40%'
        break
      case 'ready_to_ship':
        percentage = '56%'
        break
      case 'shipped':
      case 'pending_delivered':
      case 'pending_received':
        percentage = isMobileView ? '67%' : '72%'
        break
      case 'received':
        percentage = '100%'
        break
      default:
        percentage = '8%'
    }

    return (
      <div className='shipment-status-steps text-center'>
        <div className='shipment-status-progress'>
          <div className={`shipment-status-progress-line-dashboard ${menu === 'canceled' ? 'canceled' : ''}`} style={{ width: `${percentage}` }} />
        </div>
        <div className={`shipment-status-step-dashboard ${(menu === 'new' && 'active') || (menu === 'canceled' && 'canceled') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>{((menu === 'canceled' && 'Canceled') || 'New Order')}</div>
          {/* <div className='text'>{menu === 'new' ? 'New Order' : (menu === 'cancelled') ? 'Cancelled' :}</div> */}
        </div>
        <div className={`shipment-status-step-dashboard ${(menu === 'processing' && 'active') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>Processing</div>
        </div>
        <div className={`shipment-status-step-dashboard ${(menu === 'stand_by' && 'active') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>Stand by</div>
        </div>
        <div className={`shipment-status-step-dashboard ${(menu === 'ready_to_ship' && 'active') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>Ready to Ship</div>
        </div>
        <div className={`shipment-status-step-dashboard ${((menu === 'shipped' || menu === 'pending_delivered' || menu === 'pending_received') && 'active') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>Shipped</div>
        </div>
        <div className={`shipment-status-step-dashboard ${(menu === 'received' && 'active') || ''}`}>
          <div className='shipment-status-step-icon-dashboard' />
          <div className='text'>Received</div>
        </div>
      </div>
    )
  }

  return (
    <div className='container-card-default m-0' height='60vh'>
      <div className='row'>
        <div className='col-12'>
          {renderShipmentStatus(invoice.shipment.shipment_status)}
          <ContentOrderGroup grandTotal={grandTotal} shipmentFeeTotal={shipmentFeeTotal} invoice={invoice} handleToogleModal={handleToogleModal} />
          <ButtonOrderGroup
            invoice={invoice}
            handleToogleSingleListItem={handleToogleSingleListItem}
            handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlipPrint}
            handleToogleModal={handleToogleModal}
          />
        </div>
      </div>
    </div>
  )
}
