import React from 'react'
// import localforage from 'localforage'
// import UserActions from '../Redux/UserRedux'

export default function NoAccess () {
  // const dispatch = useDispatch()
  // const user = useSelector(state => state.user)
  // console.log('🚀 ~ file: NoAccess.js ~ line 9 ~ NoAccess ~ user', user)
  const handleExpired = () => {
    window.location.replace('/')
  }

  return (
    <div>
      MOHON MAAF SESI ANDA SUDAH HABIS HARAP LOGIN ULANG
      <button onClick={() => handleExpired()} className='btn btn-primary btn-l'>LOGIN</button>
    </div>
  )
}
