import React, { useState, useContext, useEffect } from 'react'
import Cookies from 'cookies-js'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { ScaleContext } from '../../Context/ScaleContext'
import { fetchCountInvoice, fetchGetInvoiceList } from '../../Services/FetchingGroup'
import ListActions from '../../Redux/ListRedux'
import CountActions from '../../Redux/CountRedux'

import PendingMobile from './PendingMobile'
import PendingDesktop from './PendingDesktop'
import { notifyError } from '../../Services/Notify'

export default function Pendings ({ supplier, supplierName, supplierId }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext

  const user = useSelector(state => state.user)
  const list = useSelector(state => state.list)
  const count = useSelector(state => state.count)

  const dispatch = useDispatch()
  const [invoiceList, setInvoiceList] = useState({})

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [countFetching, setCountFetching] = useState(false)

  const [countDisplayed, setCountDisplayed] = useState()

  const [params, setParams] = useState({
    status: 'pending',
    supplier: user.user?.role_id === 2 ? user.supplier?.supplier_alias : (supplier || Cookies.get('filterSupplier') || ''),
    page: 1,
    keyword: ''
  })

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }

    setParams(paramsChange)
    fetchGetInvoiceList(paramsChange, setInvoiceList, setIsLoading, setErr)
  }

  useEffect(() => {
    if (!supplier && isEmpty(countDisplayed) && count.count_order) {
      setCountDisplayed(count.count_order)
    }
  }, [count.count_order, countDisplayed, supplier])

  useEffect(() => {
    const supplierValue = supplier || Cookies.get('filterSupplier') || ''
    if (isEmpty(invoiceList)) {
      handleParamsChange(params)
    }
    if (supplierValue) {
      dispatch(CountActions.countRequest({ supplier: supplierValue }))
      fetchCountInvoice({ supplier: supplierValue }, setCountDisplayed, setCountFetching, null)
    }
    if (count.count_order) {
      setCountDisplayed(count.count_order)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (list.supplier === null && user.user?.role_id !== 2 && !supplier) {
      dispatch(ListActions.supplierListRequest())
    }
    if (list.carrier === null) {
      dispatch(ListActions.carrierRequest())
    }
    if (list.comment_topic === null) {
      dispatch(ListActions.commentTopicRequest())
    }
    // eslint-disable-next-line
  }, [])

  function handleChangeStatus (e) {
    const { value } = e.target
    const paramsChange = {
      ...params, status: value, keyword: ''
    }
    setParams(paramsChange)
    fetchGetInvoiceList(paramsChange, setInvoiceList, setIsLoading, setErr)
  }

  function handleFilter (e) {
    const { value, name } = e.target
    let paramsChange = params
    paramsChange = { ...params, [name]: value, status: 'pending', page: 1 }

    if (name === 'supplier') {
      Cookies.set('filterSupplier', value)
      dispatch(CountActions.countRequest({ supplier: value }))
      fetchCountInvoice({ supplier: value }, setCountDisplayed, setCountFetching, null)
    }

    if (name === 'keyword') {
      if (value?.length < 3) {
        notifyError('Keyword harus lebih dari 3 karakter')
      } else {
        setParams(paramsChange)
        fetchGetInvoiceList(paramsChange, setInvoiceList, setIsLoading, setErr)
      }
    } else {
      fetchGetInvoiceList(paramsChange, setInvoiceList, setIsLoading, setErr)
    }
  }

  if (isMobileView) {
    return (
      <PendingMobile
        handleFilter={handleFilter}
        handleChangeStatus={handleChangeStatus}
        params={params}
        handleParamsChange={handleParamsChange}
        invoices={invoiceList}
        err={err}
        countDisplayed={countDisplayed}
        isLoading={isLoading}
        countFetching={countFetching}
        supplier={supplier}
        supplierName={supplierName}
        supplierId={supplierId}
      />
    )
  } else {
    return (
      <PendingDesktop
        handleFilter={handleFilter}
        handleChangeStatus={handleChangeStatus}
        params={params}
        countDisplayed={countDisplayed}
        handleParamsChange={handleParamsChange}
        invoices={invoiceList}
        err={err}
        isLoading={isLoading}
        countFetching={countFetching}
        supplier={supplier}
        supplierName={supplierName}
        supplierId={supplierId}
      />
    )
  }
}
