import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
// import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { startCase } from 'lodash'

import Loading from '../Components/Behaviors/Loading'
import { fetchGetInvoiceList, fetchGetSellerList } from '../Services/FetchingGroup'

import NotificationCard from '../Components/Cards/NotificationCard'
import ProductStatusModal from './Modals/Home/ProductStatusModal'

export default function HomeCenter () {
  const history = useHistory()

  const user = useSelector(state => state.user)
  const count = useSelector(state => state.count)

  const [tempClosedSupplier, setTempClosedSupplier] = useState()
  const [permClosedSupplier, setPermClosedSupplier] = useState()
  const [gosendInvoices, setGosendInvoices] = useState(null)

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  // const [success, setSuccess] = useState({ success: false, data: {} })

  const [selectedProductStatus, setSelectedProductStatus] = useState({ status: '', total: 0 })
  const [onClickProductStatusModal, setOnClickProductStatusModal] = useState(false)

  useEffect(() => {
    if (err.err) {
      console.log(err.message)
      setErr({ err: false })
    }
  }, [err])

  const handleToogleProductStatusModal = (status, statusProduct, totalProduct) => {
    if (status === 'open') {
      setSelectedProductStatus({ status: statusProduct, total: totalProduct })
      setOnClickProductStatusModal(true)
    } else {
      setSelectedProductStatus({ status: '', total: '' })
      setOnClickProductStatusModal(false)
    }
  }

  useEffect(() => {
    let supplier = user.user?.supplier?.supplier_alias || ''
    const role = user.user?.role_id
    if (role === 1) {
      supplier = ''
      fetchGetSellerList({ status: 0 }, setTempClosedSupplier, setIsLoading, setErr)
      // timespan value is in day that represent the store only shows up until
      // the timespan value + the time when they closed it
      fetchGetSellerList({ status: -1, timespan: 2 }, setPermClosedSupplier, setIsLoading, setErr)
    }
    fetchGetInvoiceList({ status: 'new,processing,stand_by', carrier_id: '7,8', page: 1, supplier: supplier }, setGosendInvoices, setIsLoading, setErr)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (user.user) {
    if (user.fetching || count.fetching || count.fetching_product || isLoading) {
      return (
        <div className='col-12 col-lg'>
          <div className='home-center-container'>
            <div className='container'>
              <Loading type='alt' />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='col-12 col-lg'>
          <div className='home-center-container'>
            <div className='container'>
              <h2 className='font-weight-bold'>Hai, {startCase(user.user?.first_name)}</h2>
              <h5 className='mt-4 mb-4'>Agenda dan notifikasi hari ini</h5>
              {
                gosendInvoices?.total_invoice > 0 &&
                  <NotificationCard
                    status='default'
                    type='withData'
                    action='/order/search?keyword='
                    total={gosendInvoices?.total_invoice}
                    dataGroup={gosendInvoices.invoices}
                    picked='invoice_no'
                    text={`<b>${gosendInvoices?.total_invoice}</b> Pesanan baru <b>Go-Send</b> menunggu diproses:`}
                  />
              }
              {count?.count_order?.new > 0 &&
                <NotificationCard
                  status='default'
                  type='withButton'
                  action='/order/new'
                  text={`<b>${count?.count_order?.new}</b> Pesanan <b>New Order</b> menunggu diproses`}
                />}
              {
                count?.count_order?.pending_delivered > 0 &&
                  <NotificationCard
                    status='default'
                    type='withButton'
                    action='/order/shipped'
                    text={`<b>${count?.count_order?.pending_delivered}</b> Pesanan <b>Pending Delivered</b> menunggu diproses`}
                  />
              }
              {
                count?.count_order?.pending_received > 0 &&
                  <NotificationCard
                    status='default'
                    type='withButton'
                    action='/order/shipped'
                    text={`<b>${count?.count_order?.pending_received}</b> Pesanan <b>Pending Received</b> menunggu diproses`}
                  />
              }
              {user.user?.role_id === 2 && count.count_product?.approved > 0 &&
              (
                <NotificationCard
                  status='success'
                  type='default'
                  action={() => handleToogleProductStatusModal('open', 'approved', count.count_product?.approved)}
                  text={`<b>${count.count_product?.approved}</b> produk yang baru ditambahkan/diubah mendapatkan status <b>authorized<b /></b>`}
                />
              )}
              {(user.user?.role_id === 1 || user.user?.role_id === 2) && count.count_product?.pending > 0 &&
              (
                <NotificationCard
                  status={user.user?.role_id === 1 ? 'danger' : 'warning'}
                  type='default'
                  action={() => handleToogleProductStatusModal('open', 'pending', count.count_product?.pending)}
                  text={`<b>${count.count_product?.pending}</b> produk yang baru ditambahkan/diubah sedang <b>menunggu otorisasi<b /></b>`}
                />
              )}
              {user.user?.role_id === 2 && count.count_product?.rejected > 0 &&
              (
                <NotificationCard
                  status='danger'
                  type='default'
                  action={() => handleToogleProductStatusModal('open', 'rejected', count.count_product?.rejected)}
                  text={`<b>${count.count_product?.rejected}</b> produk yang baru ditambahkan/diubah mendapatkan status <b>unauthorized<b /></b>`}
                />
              )}
              {user?.role?.role_id === 1 && tempClosedSupplier?.total > 0 &&
              (
                <NotificationCard
                  status='warning'
                  type='default'
                  action={() => history.push('/seller-list/lists?status=0')}
                  text={`Terdapat <b>${tempClosedSupplier?.total}</b> toko yang <b>Tutup Sementara</b>`}

                />
              )}

              {user?.role?.role_id === 1 && permClosedSupplier?.total > 0 &&
              (
                <NotificationCard
                  status='danger'
                  type='default'
                  action={() => history.push('/seller-list/lists?status=-1&timespan=2')}
                  text={`Terdapat <b>${permClosedSupplier?.total}</b> toko yang <b>Tutup Permanen</b>`}
                />
              )}

              {onClickProductStatusModal &&
                <ProductStatusModal
                  onClickProductStatusModal={onClickProductStatusModal}
                  productStatus={selectedProductStatus}
                  handleToogleProductStatusModal={handleToogleProductStatusModal}
                />}

            </div>
          </div>
        </div>
      )
    }
  } else return null
}
