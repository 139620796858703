import React from 'react'
import { Modal } from 'react-bootstrap'
import InfoBox from '../../InfoBox'
import ThumbnailProduct from '../ThumbnailProduct'

export default function UpdateStock ({
  product,
  stock,
  isInStock,
  fetching,
  error,
  handleToggleUpdateStockModal,
  handleUpdateStock,
  handleRadioBtnClick,
  handleOnChange
}) {
  return (
    <Modal
      show
      size='update'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => handleToggleUpdateStockModal('close')}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--xm font-weight-bold p-3'>Ubah Stock</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-container pt-3 pb-3'>
          <ThumbnailProduct product={product} />
          <div className='row align-items-center mt-5'>
            <div className='col-5 col-lg-3'>Stock Lama</div>
            <div className='col col-lg'>{product.quantity_on_hand}</div>
          </div>
          <div className='row py-4 align-items-center'>
            <div className='col-12 col-lg-3 my-3'>Stock Baru</div>
            <div className='col-12 col-lg-5'>
              <div className='input-text-bar'>
                <input name='qty_on_hand' placeholder='Masukkan Stock Baru' type='text' onBlur={(e) => handleOnChange(e)} />
              </div>
            </div>
          </div>
          <div className='row py-4 align-items-center'>
            <div className='col-12 col-lg-3 my-3'>Is In Stock</div>
            <div className='row col'>
              <div className='col-6 col-lg-3 text-left '>
                <div className='container-radio'>
                  <label>Yes
                    <input type='radio' name='status' id='radioBtn1' checked={parseInt(stock.status) === 1 ? 'checked' : ''} value={1} onChange={(e) => handleOnChange(e)} />
                    <span className='checked' />
                  </label>
                </div>
              </div>
              <div className='col-6 col-lg-3 text-left'>
                <div className='container-radio'>
                  <label>No
                    <input type='radio' name='status' id='radioBtn2' checked={parseInt(stock.status) === 0 ? 'checked' : ''} value={0} onChange={(e) => handleOnChange(e)} />
                    <span className='checked' />
                  </label>
                </div>
              </div>
            </div>
          </div>
          {error.err && (
            <div className='row spacing-add-product mt-4'>
              <div className='col-12'>
                <InfoBox
                  text={error.message}
                  icon='danger'
                  status='danger'
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <div className='modal-container pb-3'>
        <Modal.Footer className=' justify-content-center text-center'>
          <div className='col'>
            <button type='button' onClick={() => handleToggleUpdateStockModal('close')} className='btn-ghost-primary btn-m btn-full'>Cancel</button>
          </div>
          <div className='col'>
            <button disabled={fetching} type='button' onClick={(e) => handleUpdateStock()} className='btn-primary btn-m btn-full'>{fetching ? 'Mohon Menunggu' : 'Update'}</button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
