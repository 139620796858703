import React, { useEffect } from 'react'
import SearchBar from '../../Containers/SearchBar'
import logo from '../../static/images/ruparupa-white.svg'
import $ from 'jquery'
import { useDispatch } from 'react-redux'
import UserActions from '../../Redux/UserRedux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

export default function Header (props) {
  const { isOpenMenu, pages } = props
  const dispatch = useDispatch()
  useEffect(() => {
    $(document).ready(function () {
      $('.dark-overlay').click(function () {
        $('.dark-overlay').hide()
        props.handleMenuMobile(true)
      })
    })
  })

  if (pages === 'forgot-password') {
    return (
      <div className='top-search-bar'>
        <div className='container-fluid'>
          <div className=' row align-items-center'>
            <div className='col-12'>
              <div className=' d-lg-block'>
                <Link to='/login'>
                  <div className='margin-header text-center'>
                    <img src={logo} alt='logo' width='180' />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  } else {
    return (
      <div className='top-search-bar'>
        <div className={`dark-overlay ${isOpenMenu ? ' d-block' : ' d-none'}`} id='dark-overlay' />
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-2 col-lg-2'>
              <div className='d-none d-lg-block'>
                <Link to='/home'>
                  <div className='margin-header text-center'>
                    <img src={logo} alt='logo' width='180' />
                  </div>
                </Link>
              </div>

              <div className='d-block d-lg-none'>
                <div className='margin-header text-center'>
                  <div className='menu-fold' onClick={() => props.handleMenuMobile(isOpenMenu)}>
                    <i className='anticon icon-menufold fs--l' />
                  </div>
                </div>
              </div>

            </div>
            <div className='col col-lg-6'>
              <div className='margin-header'>
                <SearchBar />
              </div>
            </div>
            <div className='col-12 col-lg-4 header-text-color'>
              <div className='d-none d-lg-block'>
                <div className='row align-items-center'>
                  <div className='col-12 col-lg-7'>
                    <div className='margin-header'>
                      <div className='fs--s text-center'>
                        {dayjs().locale('id').format('dddd, DD MMM YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-5'>
                    <div className='margin-header'>
                      <div className='fs--s'>
                        <button onClick={() => dispatch(UserActions.userLogout())} className='btn btn-transparent btn-full fs--s '>Log Out</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
