import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { startCase, startsWith } from 'lodash'
import config from '../../config'

import imageDefault from '../../static/images/no-thumbnail.png'
import noImagePlacehoder from '../../static/images/noImage.png'

export default function SneakPeekProfile (props) {
  const { viewType } = props
  const user = useSelector(state => state.user)
  // const [userName] = useState('Anker')
  const screen = window.screen.width * window.devicePixelRatio

  let imageUrl = imageDefault
  if (user.supplier?.logo) {
    if (startsWith(user.supplier?.logo, '/v')) {
      imageUrl = config.imageURL + user.supplier?.logo
    } else {
      imageUrl = user.supplier?.logo
    }
  } else {
    imageUrl = imageDefault
  }
  if (viewType === 'desktop') {
    return (
      <>
        <div className={screen <= 1368 ? 'col-4' : 'col-3'}>
          <img src={imageUrl} width='50px' alt='profile' className='border' onError={(e) => { e.target.src = noImagePlacehoder }} />
        </div>
        <div className='col'>
          <h4 className='font-weight-bold'>{startCase(user.user?.first_name)}</h4>
          {user?.role?.role_id === 2 && <Link to={`/profile/${user.user?.supplier?.supplier_id}?type=default`}> <h5 className='link'>Lihat Akun &gt;</h5></Link>}
          {/* {user?.user?.role_id !== 100 && <Link to={`/profile/${user?.supplier?.supplier_id}?type=rating_review`}> <h5 className='link'>Lihat Akun &gt;</h5></Link>} */}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='col-12'>
          <h3 className='font-weight-bold'>{startCase(user?.user?.first_name)}</h3>
        </div>
        <div className='col-12'>
          {user?.role?.role_id === 2 && <Link to={`/profile/${user.user?.supplier?.supplier_id}?type=default`}> <h5 className='link'>Lihat Akun &gt;</h5></Link>}
        </div>
      </>
    )
  }
}
