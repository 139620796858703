import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ProfileContext } from '../../../Context/ProfileContext'

export default function ProfileHomeDesktop ({
  supplierId,
  type,
  renderContent
}) {
  const [attContextProfile] = useContext(ProfileContext)

  return (
    <div className='col-12 col-lg offset-lg-2 '>
      <div className='row'>
        <div className=' col-12 border-bottom'>
          <div className='p-5'>
            {(type === 'settings')
              ? <Link to={`/profile/${supplierId}?type=default`}> <h2 className='font-weight-bold'> <i className='anticon icon-left' /> <span className='pl-2'>Pengaturan Toko</span></h2></Link>
              : <h2 className='font-weight-bold'>Profil Toko</h2>}
          </div>
        </div>
        <div className='col-12 p-5'>
          {attContextProfile?.supplier_data && renderContent()}
        </div>
      </div>
    </div>
  )
}
