import React, { useEffect, useState } from 'react'

import DeleteProduct from '../../../Components/Products/Modals/DeleteProduct'

import CountActions from '../../../Redux/CountRedux'

import { useDispatch } from 'react-redux'
import { fetchDeleteProduct } from '../../../Services/FetchingGroup'

export default function DeleteProductModal ({ params, handleChangeParams, handleToggleDeleteProductModal, product, onClickedDeleteProductModal }) {
  const dispatch = useDispatch()

  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  function handleDeleteProduct () {
    const body = {
      store_code: params.store_code,
      sku: product.sku
    }
    fetchDeleteProduct(body, setIsLoading, setErr, setSuccess)
  }

  useEffect(() => {
    if (success.success) {
      handleToggleDeleteProductModal('close', product)
      handleChangeParams(params)
      dispatch(CountActions.countProductRequest({}))
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <DeleteProduct
      product={product}
      fetching={isLoading}
      error={err}
      handleToggleDeleteProductModal={handleToggleDeleteProductModal}
      handleDeleteProduct={handleDeleteProduct}
    />
  )
}
