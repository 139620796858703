import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ScaleContext } from '../../Context/ScaleContext'

export default function ProductHeader ({
  handleToggleBulkUploadModal,
  type,
  supplierId,
  params,
  supplierDetail
}) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext
  const user = useSelector(state => state.user)

  const renderDesktop = () => {
    if (type === 'listProduct') {
      return (
        <div className='row align-items-center'>
          <div className='col-8'>
            <h2 className='font-weight-bold'>
              {user?.user?.role_id !== 2 && (
                <Link to={`/profile/${supplierId}?type=default`}>
                  <i className='anticon icon-left' />
                </Link>
              )}
              &nbsp;&nbsp;Product List {supplierDetail?.name ? `- ${supplierDetail?.name}` : ''}
            </h2>
          </div>
          <div className='col text-right'>
            <button className='btn-secondary btn-s' id='btnBulkUploadModal' onClick={() => handleToggleBulkUploadModal('open')}>
              <i className='anticon icon-copy1' /> Bulk Upload
            </button>
          </div>
          <div className='col'>
            <Link to={`/product-view/add?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${supplierId}`}>
              <button className='btn-primary btn-s'>
                <i className='anticon icon-plus' />&nbsp;Add&nbsp;New&nbsp;Product
              </button>
            </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row align-items-center'>
          <div className='col-8'>
            <h2 className='font-weight-bold'>
              {user?.user?.role_id !== 2 && (
                <Link className='mr-3' to={`/profile/${supplierId}?type=default`}>
                  <i className='anticon icon-left' />
                </Link>
              )}
              <b>Produk Bermasalah {supplierDetail?.name ? `- ${supplierDetail?.name}` : ''} </b>
            </h2>
          </div>
        </div>
      )
    }
  }

  const renderMobile = () => {
    if (type === 'listProduct') {
      return (
        <>
          <div className='py-3 centralized-content'>
            <div className='col-12 pl-0 fs--m font-weight-bold text-center border-bottom pb-3'>
              Product List {supplierDetail?.name ? `- ${supplierDetail?.name}` : ''}
              <span className='pl-4' style={{ float: 'left' }}>
                <Link to={user?.user?.role_id !== 2 ? `/profile/${supplierId}?type=default` : '/home'}>
                  <span><i className='anticon icon-arrowleft' /></span>
                </Link>
              </span>
            </div>
          </div>
          <div className='col-12 pt-3 pb-4'>
            <div className='row'>
              <div className='col-6'>
                <button style={{ width: '100%' }} className='btn-secondary btn-s' id='btnBulkUploadModal' onClick={() => handleToggleBulkUploadModal('open')}>
                  <i className='anticon icon-copy1' /> Bulk Upload
                </button>
              </div>
              <div className='col-6'>
                <Link to={`/product-view/add?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${supplierId}`}>
                  <button style={{ width: '100%' }} className='btn-primary btn-s'>
                    <i className='anticon icon-plus' /> Add New Product
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='py-3 centralized-content'>
            <div className='col-12 pl-0 fs--m font-weight-bold text-center border-bottom pb-3'>
              Product Bermasalah {supplierDetail?.name ? `- ${supplierDetail?.name}` : ''}
              <span className='pl-4' style={{ float: 'left' }}>
                <Link to={user?.user?.role_id !== 2 ? `/profile/${supplierId}?type=default` : '/home'}>
                  <span><i className='anticon icon-arrowleft' /></span>
                </Link>
              </span>
            </div>
          </div>
        </>
      )
    }
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
