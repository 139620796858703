import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { isEmpty, isEqual } from 'lodash'
import { fetchUpdateInvoice } from '../../../Services/FetchingGroup'
// import api from '../../../Services/IndexApi'

function SelectAndSeeItemsListsModal ({ invoices, handleToogleModal }) {
  const [checkedValues, setCheckedValues] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [itemChecked, setItemChecked] = useState(false)

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedValues([...checkedValues, parseInt(value)])
    } else {
      setCheckedValues(checkedValues.filter(item => item !== parseInt(value)))
    }
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target
    const collection = []
    if (checked && invoices) {
      invoices.map((invoice, key) => {
        return collection.push(invoice.invoice_id)
      })
    }
    setCheckedValues(collection)
    setItemChecked(checked)
  }

  useEffect(() => {
    if (invoices && !isEmpty(invoices)) {
      if (isEqual(checkedValues.length, invoices.length)) {
        setItemChecked(true)
      } else {
        setItemChecked(false)
      }
    }
  }, [checkedValues, invoices])

  const handleUpdateInvoices = () => {
    const body = {
      invoices: checkedValues,
      status: 'processing'
    }
    fetchUpdateInvoice(body, setIsLoading, null, null)
  }

  const init = () => {
    setCheckedValues([])
    setItemChecked(false)
  }

  return (
    <Modal
      show
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => { handleToogleModal('seeMultipleProductListModal', 'close'); init() }}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--m font-weight-bold p-3'>Pilih order yang akan di print bulking</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='container pt-3 pb-3'>
          <table className='table table-layout table-layout__multiple-order'>
            <thead>
              <tr className='fs--s my-auto'>
                <th className='' width='1%'>
                  <label className='container-checkbox'>
                    <input
                      type='checkbox'
                      checked={itemChecked}
                      onChange={(e) => handleSelectAll(e)}
                    />
                    <span className='checkbox' />
                  </label>
                </th>
                <th className='' width='5%'>Order ID</th>
                <th className=''>Invoice ID</th>
                <th className=''>Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, idx) => {
                return (
                  <tr key={idx} className='fs--s'>
                    <td>
                      <label className='container-checkbox'>
                        <input
                          type='checkbox'
                          value={invoice.invoice_id}
                          checked={checkedValues.includes(invoice.invoice_id)}
                          onChange={(e) => handleCheckboxClick(e)}
                        />
                        <span className='checkbox' />
                      </label>
                    </td>
                    <td width='3%'>{invoice.order_no}</td>
                    <td width='5%'>{invoice.invoice_no}</td>
                    <td width='8%'>{invoice.created_at}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer className=' justify-content-center text-center m-2'>
        <div className='col-12'>
          {/* <Link to='/order/stand_by'> */}
          <button onClick={() => handleUpdateInvoices()} disabled={isLoading} type='button' className='btn-primary-border btn-full btn-m margin-top-m center'>Pilih Order yang akan di print</button>
          {/* </Link> */}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectAndSeeItemsListsModal
