import React, { createContext, useState, useEffect } from 'react'

const ProfileContext = createContext([{}, () => {}])

const ProfileProvider = (props) => {
  const [attbContext, setattbContext] = useState({})

  useEffect(() => {
    setattbContext(props)
  }, [props])

  return (
    <ProfileContext.Provider value={[attbContext, setattbContext]}>
      {props.children}
    </ProfileContext.Provider>
  )
}

export { ProfileProvider, ProfileContext }
