import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ForgotPasswordContext } from '../../Context/ForgotPasswordContext'
import { fetchVerifyEmail } from '../../Services/FetchingGroup'

export default function VerifyEmail () {
  const history = useHistory()
  const [email, setEmail] = useState({})
  const [attbContext] = useContext(ForgotPasswordContext)
  const [isLoading, setIsLoading] = useState(false)
  const [successVerifyEmail, setSuccessVerifyEmail] = useState({ success: false, data: {} })

  useEffect(() => {
    if (successVerifyEmail.success) {
      // this is for verify email success
      // if check email success it will generate OTP before pushed to verify OTP
      if (!isEmpty(successVerifyEmail.data)) {
        attbContext.handleOnChange(successVerifyEmail?.data)
        setSuccessVerifyEmail({ success: false, data: {} })
        return () => history.push('/login?page=forgot-password&type=otp-choices')
      }
    }
  }, [successVerifyEmail, history, attbContext])

  function handleVerifyEmail () {
    const body = {
      email: email
    }
    fetchVerifyEmail(body, setIsLoading, null, setSuccessVerifyEmail)
  }
  return (
    <div className='row fs--m justify-content-center'>
      <div className='col-12'>
        <div className=' text-center content--text'>
            Masukkan alamat email atau nomor telepon yang terdaftar. Kami akan mengirimkan kode verifikasi.
        </div>
      </div>
      <form className='form-width' onSubmit={() => handleVerifyEmail()}>
        <div className='col-12 mt-4 content--button'>
          <div className='input-text-bar '>
            <input name='email' type='text' placeholder='Masukkan email' onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <div className='col-12 mt-4 content--button'>
          <div className='input-text-bar'>
            <button type='submit' onClick={() => handleVerifyEmail()} disabled={isLoading} className='btn btn-primary btn-full btn-m'>{isLoading ? 'Mohon Menunggu' : 'Kirim'}</button>
          </div>
        </div>
      </form>
    </div>
  )
}
