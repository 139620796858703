import { put, fork, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import CountActions, { CountTypes } from '../Redux/CountRedux'
import UserActions from '../Redux/UserRedux'
import isEmpty from 'lodash/isEmpty'

export function * getCount (api, getToken) {
  let action = yield take(CountTypes.COUNT_REQUEST)
  while (action !== END) {
    yield fork(fetchGetCount, api, getToken, action)
    action = yield take(CountTypes.COUNT_REQUEST)
  }
}

export function * getCountProduct (api, getToken) {
  let action = yield take(CountTypes.COUNT_PRODUCT_REQUEST)
  while (action !== END) {
    yield fork(fetchGetCountProduct, api, getToken, action)
    action = yield take(CountTypes.COUNT_PRODUCT_REQUEST)
  }
}

export function * fetchGetCount (api, getToken, { data }) {
  const token = yield call(getToken)
  if (token) {
    try {
      const res = yield call(api.getCountInvoice, data, token)
      if (!res.ok) {
        yield put(CountActions.countFailure())
      } else {
        if (isEmpty(res.data.errors)) {
          const data = res.data.data
          if (data) {
            yield put(CountActions.countSuccess(data))
          }
        } else {
          if (!isEmpty(res.data.errors.message)) {
            yield put(CountActions.userFailure(res.data.errors.message))
          } else {
            yield put(CountActions.countFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
          }
        }
      }
    } catch (error) {
      yield put(CountActions.countFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
    }
  } else yield put(UserActions.userFailure('Token tidak ditemukan, mohon untuk melakukan login ulang'))
}

export function * fetchGetCountProduct (api, getToken, { data }) {
  const token = yield call(getToken)
  if (token) {
    try {
    // the differences only with the count value, if its true, it only brought back the total count
      const res = yield call(api.getCountProduct, data, token)
      if (!res.ok) {
        yield put(CountActions.countProductFailure())
      } else {
        if (isEmpty(res.data.errors)) {
          const data = res.data.data
          if (data) {
            yield put(CountActions.countProductSuccess(data))
          }
        } else {
          if (!isEmpty(res.data.errors.message)) {
            yield put(CountActions.countProductFailure(res.data.errors.message))
          } else {
            yield put(CountActions.countProductFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
          }
        }
      }
    } catch (error) {
      yield put(CountActions.countProductFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
    }
  } else yield put(UserActions.userFailure('Token tidak ditemukan, mohon untuk melakukan login ulang'))
}
