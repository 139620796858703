import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import BaseQuery from '../Base/BaseQuery'
import Header from '../Components/DefaultLayout/Header'
import config from '../config'
import ForgotPassword from '../Containers/ForgotPassword/ForgotPassword'
import LoginPage from '../Containers/LoginPage'
import localforage from 'localforage'

export default function Login () {
  const user = useSelector(state => state.user)
  const page = BaseQuery().get('page')
  const type = BaseQuery().get('type')

  useEffect(() => {
    if (user.user && user.supplier) {
      window.location.replace(config.baseURL + '/home')
    }
  }, [user])

  if (page === 'forgot-password') {
    return (
      <>
        <Header pages={page} />
        <ForgotPassword type={type} />
      </>
    )
  } else {
    return <LoginPage />
  }
}
