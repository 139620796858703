import React, { Fragment, useContext, useState, useEffect } from 'react'
import SummaryReport from '../Components/SummaryReport'
import { ScaleContext } from '../Context/ScaleContext'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../Components/Behaviors/Loading'
import { fetchSettlementDownload, fetchSendReport, fetchGetReportList, fetchGetSettlementReportSupplier } from '../Services/FetchingGroup'
import Pagination from './Pagination'

export default function SummaryReportList ({ supplierId, supplierName }) {
  const user = useSelector(state => state.user)
  const [settlementList, setSettlementList] = useState()

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [paramsSeller, setParamsSeller] = useState({ supplier_id: user?.user?.role_id === 2 ? user.user?.supplier?.supplier_id : supplierId, size: 10, page: 1 })
  const [paramsAdmin, setParamsAdmin] = useState({ report_type: 'summary', start_periode: '2016-12-01', size: 10, page: 1 })
  const [success, setSuccess] = useState({ success: false, data: {} })

  function handleSettlementDownload (body) {
    fetchSettlementDownload(body, setIsLoading, setErr, setSuccess)
  }

  function handleSendReport (body) {
    const bodyModified = {
      reports: [
        body
      ]
    }
    fetchSendReport(bodyModified, setIsLoading, setErr, null)
  }

  function handleParamsChanges (paramsModified) {
    let params = {}
    if (user.user?.role_id !== 2) {
      if (supplierId) {
        params = { ...paramsSeller, ...paramsModified }
        setParamsSeller(params)
        fetchGetSettlementReportSupplier({ ...paramsSeller, ...paramsModified }, setSettlementList, setIsLoading, setErr)// seller
      } else {
        params = { ...paramsAdmin, ...paramsModified }
        setParamsAdmin(params)
        fetchGetReportList(params, setSettlementList, setIsLoading, setErr)// admin
      }
    } else {
      params = { ...paramsSeller, ...paramsModified }
      setParamsSeller(params)
      fetchGetSettlementReportSupplier({ ...paramsSeller, ...paramsModified }, setSettlementList, setIsLoading, setErr)// seller
    }
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    const params = user.user?.role_id === 1 ? paramsAdmin : paramsSeller
    if ((selected + 1) !== params.page) {
      handleParamsChanges({ page: selected + 1 })
    }
  }

  useEffect(() => {
    if (user.user?.role_id === 1) {
      handleParamsChanges(paramsAdmin)
    } else {
      handleParamsChanges(paramsSeller)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (success.success) {
      window.open(success.data?.url)
      setSuccess({ success: false, data: {} })
    }
  }, [success])

  // Uncomment this if you want to use is mobile trigger
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  function render () {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={4}>
            <Loading type='alt' />
          </td>
        </tr>
      )
    } else if (err.err) {
      return (
        <tr>
          <td colSpan={6}>
            <div className='row text-center align-content-center' style={{ height: '400px' }}>
              <div className='col-12'>
                <span className='fs--m error'>{err.message}</span>
              </div>
            </div>
          </td>
        </tr>
      )
    } else if (settlementList) {
      return settlementList.report.map((settlement, idx) => {
        return (
          <Fragment key={idx}>
            <SummaryReport
              key={idx}
              id={idx}
              paramsAdmin={paramsAdmin}
              paramsSeller={paramsSeller}
              periode={settlement.periode}
              startPeriode={settlement.start_periode}
              endPeriode={settlement.end_periode}
              fileName={settlement.file_name}
              handleSettlementDownload={handleSettlementDownload}
              handleSendReport={handleSendReport}
              supplierId={supplierId}
              supplierName={supplierName}
              // amount={settlement.amount}
            />
          </Fragment>
        )
      }
      )
    } else return null
  }

  const renderMobile = () => {
    return (
      <div className='row'>
        <div className='col-12 py-3 border-bottom mb-5 centralized-content'>
          <div className='col-12 pl-0 fs--m font-weight-bold text-center'>
            Settlement Report {(supplierName && supplierId) && '- ' + supplierName}
            <span style={{ float: 'left' }}>
              <Link to='/home'>
                <span><i className='anticon icon-arrowleft' /></span>
              </Link>
            </span>
          </div>
        </div>
        {settlementList?.total > 10 &&
          <div className='col-12 mt-5'>
            <Pagination
              total={settlementList.total}
              show={10}
              classNames='pagination pull-right'
              handlePageClick={handlePageClick}
            />
          </div>}
        <div className='col-12 px-5'>
          {render()}
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className='row'>
        <div className='col'>
          <div className='settlement-report-container'>
            <div className='row'>
              <div className='col-12 p-5 border-bottom'>
                <h2 className='font-weight-bold'>Settlement Report {(supplierName && supplierId) && '- ' + supplierName}</h2>
              </div>

              <div className='col-12 mt-5'>
                {settlementList?.total > 10 &&
                  <Pagination
                    total={settlementList?.total}
                    show={10}
                    classNames='pagination pull-right'
                    handlePageClick={handlePageClick}
                  />}
              </div>
              <div className='col-12 mt-5'>
                <table className='table table-layout table-layout__settlement'>
                  <thead>
                    <tr className=''>
                      <th width='20%'>Periode</th>
                      <th width='50%'>Download File</th>
                      <th width='15%' />
                      <th width='15%' />
                    </tr>
                  </thead>
                  <tbody>
                    {render()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* [STARTS HERE] ====
      //todo akan berubah jika width layar kurang dari 992px
      */}
      {isMobileView ? renderMobile() : renderDesktop()}
      {/* [ENDS HERE] --- akan berubah jika width layar kurang dari 992px */}
    </>
  )
}
