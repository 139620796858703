import React from 'react'
import bounceColor from '../../static/images/loading-ruparupa.gif'

const Loading = ({ type }) => {
  if (type === 'alt') {
    return (
      <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
        <div className='col-12 '>
          <img src={bounceColor} style={{ width: '10rem' }} alt='loading' />
        </div>
        <div className='col-12'>
          <span className='fs--xm'>Mohon Menunggu ...</span>
        </div>
      </div>
    )
  } else if (type === 'bounce') {
    return (
      <div className='spinner'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
      </div>
    )
  } else return null
}

export default Loading
