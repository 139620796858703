import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Modal, Alert } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { fetchUploadReport } from '../../Services/FetchingGroup'
import InfoBox from '../../Components/InfoBox'

export default function UploadSetllementModal ({ settlement, handleToogleUploadModal, isUploadOnClick }) {
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [err, setErr] = useState({ err: false, message: '' })
  const maxSize = 26214400 // 25 MB

  const ext = {
    xls: 'application/vnd.ms-excel', // xls, xlt, xla
    xlxs: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone(
    {
      accept: `${ext.xls}, ${ext.xlxs}, .csv `,
      maxSize: maxSize,
      multiple: false
    }
  )
  const [file, setFile] = useState()

  // Upload csv or excel
  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = e => {
      setFile(e.target.result)
    }
    if (!isEmpty(file)) {
      return (
        <div className='container mb-4'>
          <div className='mb-4'>
            File terpilih:  <b> <a href={file} download={acceptedFiles[0].name}>{acceptedFiles[0].path}</a></b>
          </div>
          <InfoBox
            text='Pastikan untuk mengecek file yang sudah dipilih dengan mendownload kembali data yang sudah diupload sebelum melanjutkan.'
            icon='info'
            status=''
          />
        </div>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((error) => {
          return error.code === 'file-invalid-type' ? 'Tipe file harus .xls, .xlxs, .csv' : 'Ukuran file lebih dari 25 MB  '
        })
        setFile({})
        return setErr({ err: true, message: errorMessage })
      })
    }
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      return setErr({ err: false, message: '' })
    }
  }, [acceptedFiles])

  const uploadProduct = () => {
    return (
      <div className='col-lg-12 fs--m mb-5 text-center'>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {/* <div style={{ height: '10rem', width: '40rem' }} className='d-flex align-items-center justify-content-center'><i className='anticon icon-upload' /> Upload Template</div> */}
          <button type='button' className='btn btn-outline-light text-dark btn-s border'>Choose File</button>
        </div>
      </div>
    )
  }

  const handleUpload = () => {
    const body = { ...settlement, xls_file: file }
    fetchUploadReport(body, setIsLoading, setErr, setSuccess)
  }

  const handleInit = () => {
    setFile({})
    setErr({ err: false, message: '' })
  }

  useEffect(() => {
    if (success.success) {
      handleToogleUploadModal('close')
      setSuccess({ success: false, data: {} })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (isUploadOnClick) {
    return (
      <Modal
        show
        size='bulk'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => { handleToogleUploadModal('close'); handleInit() }}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold p-3'>Upload Settlement</div>
          </Modal.Title>
        </Modal.Header>
        <div className='border-bottom'>
          <Modal.Body className=''>
            <div className='container fs--s '>
              <div className='row align-items-center '>
                <div className='col-lg-12 mb-5 fs--m'>
                  <InfoBox
                    text='
            - File yang dapat diterima: .xls, .xlsx, dan .csv <br>
            - Ukuran File, tidak lebih dari 25 MB <br>
            '
                    icon='info'
                    status=''
                  />
                </div>
                <div className='col-lg-12 mb-5 fs--m text-center'>Silahkan upload settlement report yang sudah direvisi</div>
                {uploadProduct()}
                <div className='col-12 '>
                  {acceptedFiles.length > 0 && fileAccepted(acceptedFiles)}
                  {err.err && <Alert variant='danger'> {err.message}</Alert>}
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer className=' justify-content-center text-center m-4'>
          <div className='col'>
            <button type='button' onClick={() => { handleToogleUploadModal('close'); handleInit() }} className='btn-primary btn-m btn-full'>Close</button>
          </div>
          <div className='col'>
            <button type='button' onClick={() => handleUpload()} className='btn-ghost-primary btn-m btn-full'>
              {isLoading ? 'Loading' : 'Update'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  } else return null
}
