import React, { useState, useContext, useEffect } from 'react'
import { ScaleContext } from '../../Context/ScaleContext'
import Loading from '../../Components/Behaviors/Loading'
import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'
import CheckItemsModal from '../Modals/Order/CheckItemsModal'
import PrintPackingSlipModal from '../Modals/Order/PrintPackingSlipModal'
import BookingCodeModal from '../Modals/Order/BookingCodeModal'
import InputAWBModal from '../Modals/Order/InputAWBModal'
import ReceivedModal from '../Modals/Order/ReceivedModal'
import CommentModal from '../Modals/CommentModal'
import ShipmentStatusModal from '../Modals/Order/ShipmentStatusModal'
import { fetchUpdateInvoice } from '../../Services/FetchingGroup'
import config from '../../config'
import OrderGroupContainer from './OrderGroupContainer'
import ShipmentFeeModal from '../Modals/Order/ShipmentFeeModal'
import TrackingNumberModal from '../Modals/Order/TrackingNumberModal'

export default function SearchedDetail ({ keyword, params, handleParamsChange, fetchUpdateInvoiceStatus, invoiceList, fetching, error }) {
  const [paramsModified] = useState({ ...params, status: '', page: 1, keyword: keyword, supplier: params?.supplier || '' })
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [selectedInvoice, setSelectedInvoice] = useState()
  const [searchInvoiceList, setSearchInvoice] = useState()

  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    checkItemsModal: false,
    printPackingSlipModal: false,
    bookingCodeModal: false,
    inputAwbModal: false,
    receivedModal: false,
    commentModal: false,
    shipmentStatusModal: false,
    shipmentFeeModal: false,
    seeTrackingNumberModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  useEffect(() => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    handleParamsChange(paramsChange)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (invoiceList !== searchInvoiceList) setSearchInvoice(invoiceList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceList])

  const handleToogleSingleListItem = (invoice) => {
    const body = {
      invoices: [invoice.invoice_id],
      status: 'processing'
    }
    fetchUpdateInvoice(body, null, null, null)
  }

  function renderSeeItemList () {
    return onClickedModal.seeProductListModal &&
      <SeeItemsListsModal
        invoice={selectedInvoice}
        onClickedModal={onClickedModal}
        handleToogleModal={handleToogleModal}
      />
  }

  function renderTrackNumberModal () {
    return onClickedModal.seeTrackingNumberModal &&
      <TrackingNumberModal
        invoice={selectedInvoice}
        onClickedModal={onClickedModal}
        handleToogleModal={handleToogleModal}
      />
  }

  function renderShipmentFeeModal () {
    return onClickedModal.shipmentFeeModal &&
      <ShipmentFeeModal
        invoice={selectedInvoice}
        onClickedModal={onClickedModal}
        handleToogleModal={handleToogleModal}
      />
  }

  function renderCommentModal () {
    if (onClickedModal.commentModa) {
      return (
        <CommentModal
          invoice={selectedInvoice}
          onClickedModal={onClickedModal}
          handleToogleModal={handleToogleModal}
        />
      )
    } else return null
  }

  const renderShipmentStatusModal = () => {
    if (onClickedModal.shipmentStatusModal) {
      return (
        <ShipmentStatusModal
          invoice={selectedInvoice}
          onClickedModal={onClickedModal}
          handleToogleModal={handleToogleModal}
        />
      )
    } else return null
  }

  const handleTooglePrintPackingSlip = (status, invoice) => {
    if (status === 'open') {
      window.location.replace(config.baseURL + '/print?status=' + invoice.invoice_status + '&type=packing_slip&invoice_id=' + invoice.invoice_id)
    }
  }

  const handleRenderingModal = (shipmentStatus) => {
    switch (shipmentStatus) {
      case 'processing':
        return (
          <>
            {onClickedModal.checkItemsModal &&
              <CheckItemsModal
                invoice={selectedInvoice}
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
                handleParamsChange={handleParamsChange}
              />}
            {renderSeeItemList()}
            {renderCommentModal()}
            {renderShipmentFeeModal()}
          </>
        )
      case 'stand_by':
        return (
          <>
            {renderSeeItemList()}
            {onClickedModal.printPackingSlipModal &&
              <PrintPackingSlipModal
                invoice={selectedInvoice}
                type='stand_by'
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
                fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
              />}
            {selectedInvoice && onClickedModal.checkItemsModal &&
              <CheckItemsModal
                invoice={selectedInvoice}
                handleParamsChange={handleParamsChange}
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
              />}
            {renderShipmentStatusModal()}
            {renderCommentModal()}
            {renderShipmentFeeModal()}
          </>
        )

      case 'ready_to_ship':
        return (
          <>
            {renderSeeItemList()}
            {renderTrackNumberModal()}
            {onClickedModal.printPackingSlipModal &&
              <PrintPackingSlipModal
                invoice={selectedInvoice}
                type='ready_to_ship'
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
                fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
              />}
            {onClickedModal.bookingCodeModal &&
              <BookingCodeModal
                invoice={selectedInvoice}
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
              />}
            {onClickedModal.inputAwbModal && (
              <InputAWBModal
                invoice={selectedInvoice}
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
                fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
              />
            )}
            {renderShipmentStatusModal()}
            {renderCommentModal()}
            {renderShipmentFeeModal()}
          </>
        )
      case 'shipped':
      case 'pending_delivered':
      case 'pending_received':
        return (
          <>
            {renderSeeItemList()}
            {renderTrackNumberModal()}
            {onClickedModal.receivedModal &&
              <ReceivedModal
                invoice={selectedInvoice}
                onClickedModal={onClickedModal}
                handleToogleModal={handleToogleModal}
                fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
              />}
            {renderShipmentStatusModal()}
            {renderCommentModal()}
            {renderShipmentFeeModal()}
          </>
        )

      case 'received':
        return (
          <>
            {renderSeeItemList()}
            {renderTrackNumberModal()}
            {renderShipmentStatusModal()}
            {renderCommentModal()}
            {renderShipmentFeeModal()}
          </>
        )
      default: return console.log('Unrecognized Status')
    }
  }

  const renderDesktop = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>

      )
    } else if (fetching) {
      return (
        <div className='col-12'>
          <Loading type='alt' />
        </div>
      )
    } else if (searchInvoiceList) {
      return (
        <div className='row'>
          <div className='col-12'>
            <h4 className='py-3 pl-4'>Menampilkan Hasil Pencarian dari kata: <b>{keyword}</b></h4>
            <OrderGroupContainer
              type='search'
              invoices={searchInvoiceList}
              handleToogleSingleListItem={handleToogleSingleListItem}
              handleToogleModal={handleToogleModal}
              handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlip}
              handleRenderingModal={handleRenderingModal}
            />
          </div>
        </div>
      )
    } else return null
    // else {
    //   return (
    //     <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
    //       <div className='col-12 '>
    //         <span className='fs--m'>Tidak terdapat Invoice baru</span>
    //       </div>
    //     </div>
    //   )
    // }
  }

  const renderMobile = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>

      )
    } else if (fetching) {
      return (
        <div className='col-12'>
          <Loading type='alt' />
        </div>
      )
    } else if (searchInvoiceList) {
      return (
        <div className='row'>
          <div className='col-12 p-3'>
            <div className='row'>
              <div className='col-12'>
                <h4 className='py-3 pl-4'>Menampilkan Hasil Pencarian dari kata: <b>{keyword}</b></h4>
              </div>
            </div>
          </div>
          <OrderGroupContainer
            type='search'
            invoices={searchInvoiceList}
            handleToogleSingleListItem={handleToogleSingleListItem}
            handleToogleModal={handleToogleModal}
            handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlip}
            handleRenderingModal={handleRenderingModal}
          />
          <div className='col-12 mt-3 centralized-content'>
            {/* <Pagination totalRow={totalRow} offset={offset} limit={limit} /> */}
          </div>
        </div>
      )
    } else return null
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
