import React from 'react'
import BaseQuery from '../Base/BaseQuery'

import DefaultLayout from '../Containers/DefaultLayout'
import SellerListDetail from '../Containers/SellerListDetail'
import NoAccessRules from '../Containers/NoAccessRules'

import { useSelector } from 'react-redux'

import { checkRole } from '../Services/CheckRole'

function SellerList (props) {
  const user = useSelector(state => state.user)
  const keyword = BaseQuery().get('keyword')
  const status = BaseQuery().get('status')
  const time = BaseQuery().get('time')

  return (
    <DefaultLayout pages='seller-list'>
      {checkRole({
        menu: 'supplier_list',
        method: 'view',
        attribute: '',
        yes: (access) => {
          return <SellerListDetail keyword={keyword} status={status} time={time} />
        },
        no: (access) => { return <NoAccessRules /> },
        userRules: user?.role
      })}

    </DefaultLayout>
  )
}

export default SellerList
