import React, { useState, useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ScaleContext } from '../../Context/ScaleContext'
import Loading from '../../Components/Behaviors/Loading'

import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'
import PrintPackingSlipModal from '../Modals/Order/PrintPackingSlipModal'
import CommentModal from '../Modals/CommentModal'
import Pagination from '../Pagination'
import ShipmentStatusModal from '../Modals/Order/ShipmentStatusModal'
import CheckItemsModal from '../Modals/Order/CheckItemsModal'
import BulkPrintPackingSlipModal from '../Modals/Order/BulkPrintPackingSlipModal'
import OrderGroupContainer from './OrderGroupContainer'
import ShipmentFeeModal from '../Modals/Order/ShipmentFeeModal'

export default function StandbyDetail ({ params, handleParamsChange, fetchUpdateInvoiceStatus, invoiceList, fetching, error }) {
  const count = useSelector(state => state.count)

  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const [paramsModified, setParamsModified] = useState({ status: 'stand_by', page: 1, keyword: '', supplier: params?.supplier || '' })
  const [standByInvoiceList, setStandByInvoiceList] = useState()
  const [selectedInvoice, setSelectedInvoice] = useState()

  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    printPackingSlipModal: false,
    bulkPrintPackingSlipModal: false,
    checkItemsModal: false,
    commentModal: false,
    shipmentStatusModal: false,
    shipmentFeeModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  useEffect(() => {
    handleParamsChange({ ...paramsModified })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (invoiceList !== standByInvoiceList) setStandByInvoiceList(invoiceList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceList, standByInvoiceList])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      setParamsModified({ ...params, page: selected + 1 })
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }

  function renderModal () {
    return (
      <>
        {onClickedModal.bulkPrintPackingSlipModal &&
          <BulkPrintPackingSlipModal
            invoices={standByInvoiceList.invoices}
            fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.printPackingSlipModal &&
          <PrintPackingSlipModal
            invoice={selectedInvoice}
            type='stand_by'
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
            fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
          />}
        {onClickedModal.seeProductListModal &&
          <SeeItemsListsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {/* this is cancel, its the same like Check Items, the difference only with the items that shows up were the complete one, not the incomplete */}
        {selectedInvoice && onClickedModal.checkItemsModal &&
          <CheckItemsModal
            invoice={selectedInvoice}
            handleParamsChange={handleParamsChange}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentStatusModal &&
          <ShipmentStatusModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentFeeModal &&
          <ShipmentFeeModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }
  const renderDesktop = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='row align-items-center'>
              <div className='col-12 px-5 py-4'>
                <div className='row align-items-center'>
                  <div className='col-4'>
                    <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.stand_by} standby</h1>
                  </div>
                  <div className='col-4'>
                    <button
                      onClick={() => handleToogleModal('bulkPrintPackingSlipModal', 'open')}
                      className='btn-primary btn-s w-100 fs--s '
                    >
                      Bulk Print Packing Slip
                    </button>
                  </div>
                  <div className='col-4' style={{ display: 'flex', justifyContent: 'center' }}>
                    {standByInvoiceList?.total_invoice > 10 &&
                      <Pagination
                        total={standByInvoiceList?.total_invoice}
                        show={10}
                        classNames='pagination pull-right'
                        handlePageClick={handlePageClick}
                      />}
                  </div>
                </div>
              </div>
              {/* <div className='col-12 col-lg-8'>
                <div className='container-card-default d-flex'>
                  <div className='row'>
                    <div className='col-12'>
                      <i className='anticon icon-smileo' /><span className='pt-1 my-auto font-weight-bold fs--s '>&nbsp;Instruksi</span>
                    </div>
                    <div className='col-12 pt-3 fs--s '>
                  Silahkan melakukan<b>&nbsp;Product Checklist ketika packing.</b> Setelah melakukan Product Checklist, Tombol Print packing slip akan muncul.
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='stand_by'
                invoices={standByInvoiceList}
                handleToogleModal={handleToogleModal}
              />}
          {standByInvoiceList?.invoices && renderModal()}
        </div>
      )
    }
  }

  const renderMobile = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 p-3'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.stand_by} standby</h1>
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='stand_by'
                invoices={standByInvoiceList}
                handleToogleModal={handleToogleModal}
              />}
          {standByInvoiceList?.invoices && renderModal()}
          <div className='col-12'>
            {standByInvoiceList?.total_invoice > 10 &&
              <Pagination
                total={standByInvoiceList?.total_invoice}
                show={10}
                classNames='pagination pull-right'
                handlePageClick={handlePageClick}
              />}
          </div>
        </div>
      )
    }
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
