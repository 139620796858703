import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useHistory } from 'react-router'
import config from '../../config'
import { ForgotPasswordContext } from '../../Context/ForgotPasswordContext'
import { fetchGenerateOtp, fetchValidateOtp } from '../../Services/FetchingGroup'

const otpInputStyle = {
  border: 0,
  marginLeft: '5px',
  borderBottom: '2px solid #E0E6ED',
  outline: 'none',
  width: '35px',
  fontSize: '18px',
  transition: 'all .2s ease-in-out',
  textAlign: 'center'
}

export default function OtpVerification ({ error }) {
  const history = useHistory()
  const [attbContext] = useContext(ForgotPasswordContext)

  const counterOTPId = useRef()

  const [accessCode, setAccessCode] = useState('')
  const [sendOtpTime, setSendOtpTime] = useState(30)

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({ success: false, data: {} })

  useEffect(() => {
    if (isEmpty(attbContext.value)) {
      window.location.replace(config.baseURL + '/login?page=forgot-password&type=forgot-password')
      return () => {
        timer('sendOtpTime', false)
      }
    }
  }, [history, attbContext])

  const getEmailProvider = (email) => {
    return email.substring(email.indexOf('@'), email.length)
  }

  function handleInputOtp (value) {
    if (value.match(/^\d+$/) || isEmpty(value)) {
      setAccessCode(value)
    }
  }

  function timer (type, boolean) {
    const offset = dayjs().add(30, 's').unix()
    if (boolean) {
      const interval = setInterval(() => {
        const currentTime = dayjs().unix()
        const diffTime = offset - currentTime
        setSendOtpTime(diffTime <= 0 ? 0 : diffTime)
        counterOTPId.current = interval
      }, 1000)
    } else {
      clearInterval(counterOTPId.current)
    }
  }

  useEffect(() => {
    timer('sendOtpTime', true)
    return () => {
      timer('sendOtpTime', false)
    }
  }, [])

  useEffect(() => {
    if (sendOtpTime <= 0) {
      timer('sendOtpTime', false)
    }
  }, [sendOtpTime])

  useEffect(() => {
    if (success.success) {
      // this is for verify OTP success
      // it will be pushed to change password while bringing the token for the header
      if (success.data) {
        attbContext.handleOnChange(success.data)
        history.push('/login?page=forgot-password&type=reset-password')
      }
      setSuccess({ success: false, data: {} })
    }
  }, [success, history, attbContext])

  function handleGetNewOtp () {
    if (attbContext.value?.channel === 'email') {
      const body = {
        email: attbContext.value?.email || '',
        first_name: attbContext.value?.first_name || '',
        channel: attbContext.value?.channel,
        action: 'forgot-password'
      }
      timer('sendOtpTime', true)
      fetchGenerateOtp(body, setIsLoading, null, null)
    }
  }

  function handleVerifyOTP (e) {
    e.preventDefault()
    const body = {
      email: attbContext.value?.email,
      access_code: accessCode,
      action: 'forgot-password'
    }
    fetchValidateOtp(body, setIsLoading, null, setSuccess)
  }

  return (
    <div className='row fs--m justify-content-center'>
      <div className='col-12'>
        <div className='text-center px-5'>
        Kode verifikasi sudah dikirimkan ke email {attbContext.value?.email && '*******' + getEmailProvider(attbContext?.value?.email)}
        </div>
      </div>
      <form className='form-width' onSubmit={(e) => { handleVerifyOTP(e) }}>
        <div className='col-12 mt-5'>
          <OtpInput
            onChange={otp => handleInputOtp(otp)}
            value={accessCode}
            numInputs={6}
            shouldAutoFocus
            containerStyle='otp-input-container'
            hasErrored={error?.err}
            focusStyle={{ borderBottom: 'solid 1px #F26525' }}
            errorStyle={{ borderBottom: 'solid 1px #F3251D' }}
            inputStyle={otpInputStyle}
          />
        </div>
        <div className='col-12 mt-4'>
          <div className='input-text-bar'>
            <button type='submit' onClick={(e) => handleVerifyOTP(e)} disabled={accessCode?.length < 6 || isLoading} className='btn btn-primary btn-full btn-m'>
              {isLoading ? 'Mohon Menunggu' : 'Verifikasi'}
            </button>
          </div>
        </div>
        <div className='col-12 mt-3 text-center'>
          {config.nodeEnv === 'development' && <div>{attbContext.value?.message}</div>}
          <div>Tidak menerima kode verifikasi ?</div>
          <div
            className={`link ${sendOtpTime <= 0 ? '' : 'not-allowed'}`}
            onClick={() => { handleGetNewOtp() }}
          ><b>Kirim Ulang {sendOtpTime > 0 ? `(${sendOtpTime})` : ''}</b>
          </div>
        </div>
      </form>
    </div>
  )
}
