import React, { createContext, useState, useEffect } from 'react'

const ForgotPasswordContext = createContext([{}, () => {}])

const ForgotPasswordProvider = (props) => {
  const [attbContext, setattbContext] = useState({})

  useEffect(() => {
    setattbContext({ ...attbContext, ...props })
    // eslint-disable-next-line
  }, [props])

  return (
    <ForgotPasswordContext.Provider value={[attbContext, setattbContext]}>
      {props.children}
    </ForgotPasswordContext.Provider>
  )
}

export { ForgotPasswordProvider, ForgotPasswordContext }
