import React, { createContext, useState, useEffect } from 'react'

const OrderContext = createContext([{}, () => {}])

const OrderProvider = (props) => {
  const [attbContext, setattbContext] = useState({})

  useEffect(() => {
    setattbContext(props)
  }, [props])

  return (
    <OrderContext.Provider value={[attbContext, setattbContext]}>
      {props.children}
    </OrderContext.Provider>
  )
}

export { OrderProvider, OrderContext }
