import React, { useState, useContext, useEffect } from 'react'
// import ListRejectedProduct from '../../Components/Products/ListRejectedProduct'
import { ScaleContext } from '../../Context/ScaleContext'

import { fetchGetListProduct, fetchGetProfileInfo } from '../../Services/FetchingGroup'
import RejectedProductListDesktop from '../../Components/Products/List/RejectedProductListDesktop'
import RejectedProductListMobile from '../../Components/Products/List/RejectedProductListMobile'
import { useSelector } from 'react-redux'

export default function RejectedProductGroup ({ supplierId, supplierAlias, storeCode, sortBy, sortType }) {
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const user = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  const sortList = [{ id: 0, type: 'desc', name: 'Newer', by: 'created_at' }, { id: 1, type: 'asc', name: 'Older', by: 'created_at' }]

  const [supplierDetail, setSupplierDetail] = useState()
  const [params, setParams] = useState({
    limit: '10',
    page: 1,
    keyword: '',
    supplier_alias: supplierAlias,
    store_code: storeCode,
    status: 'rejected',
    count: false,
    sort_by: sortBy || 'created_at',
    sort_type: sortType || 'desc'
  })
  const [listProduct, setListProduct] = useState()

  function handleChangeParams (paramsModified) {
    const newParams = { ...params, ...paramsModified }
    if (params !== newParams) {
      setParams(newParams)
    }
    fetchGetListProduct(newParams, setListProduct, setIsLoading, setErr)
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      handleChangeParams({ page: selected + 1 })
    }
  }

  useEffect(() => {
    // setRes({ isLoading: true })
    let paramsModified = params
    if (storeCode) {
      paramsModified = { ...paramsModified, store_code: storeCode }
    }
    if (supplierAlias) {
      paramsModified = { ...paramsModified, supplier_alias: supplierAlias }
    }
    if (sortType) {
      paramsModified = { ...paramsModified, sort_type: sortType }
    }
    if (sortBy) {
      paramsModified = { ...paramsModified, sort_by: sortBy }
    }

    if (supplierId && user.user?.role_id !== 2) {
      const params = { supplier_id: parseInt(supplierId) !== 0 ? parseInt(supplierId) : '' }
      fetchGetProfileInfo(params, setSupplierDetail, setIsLoading, setErr)
    }
    handleChangeParams(paramsModified)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.user?.role_id !== 2) {
      if (storeCode !== params.store_code) {
        handleChangeParams({ store_code: storeCode })
      }
      if (supplierAlias !== params.supplier_alias) {
        handleChangeParams({ supplier_alias: supplierAlias })
      }
    }
    if (sortType && sortType !== params.sort_type) {
      handleChangeParams({ sort_type: sortType })
    }
    if (sortBy && sortBy !== params.sort_by) {
      handleChangeParams({ sort_by: sortBy })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierAlias, storeCode, sortType, sortBy])

  function handleSelect (e, list) {
    const { value, name } = e.target
    const filter = list.filter(element => element.id === parseInt(value))
    if (name === 'sort') {
      if (filter?.length > 0) {
        handleChangeParams({ sort_by: filter[0].by, sort_type: filter[0].type, page: 1 })
      } else return null
    }
  }

  if (isMobileView) return <RejectedProductListMobile supplierDetail={supplierDetail} supplierId={supplierId} fetching={isLoading} error={err} listProduct={listProduct} sortList={sortList} params={params} handleSelect={handleSelect} handleChangeParams={handleChangeParams} handlePageClick={handlePageClick} />
  else return <RejectedProductListDesktop supplierDetail={supplierDetail} supplierId={supplierId} fetching={isLoading} error={err} listProduct={listProduct} sortList={sortList} params={params} handleSelect={handleSelect} handleChangeParams={handleChangeParams} handlePageClick={handlePageClick} />
}
