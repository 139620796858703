import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  userRequest: ['data'],
  userSuccess: ['data'],
  userFailure: ['err'],
  userLogout: null,
  userInit: null,
  userForgotPasswordInit: null,
  supplierRequest: ['data'],
  supplierSuccess: ['data'],
  supplierFailed: ['err'],
  userRoleRequest: ['data'],
  userRoleSuccess: ['data'],
  userRoleFailure: ['err'],
  userForgotPasswordRequest: ['data'],
  userForgotPasswordSuccess: ['data'],
  userForgotPasswordFailure: ['err']

})

export const UserTypes = Types
export default Creators

// Initial State
export const INITIAL_STATE = Immutable({
  err: null,
  fetching: false,
  success: false,
  user: null,
  supplier: null,
  role: null,
  forgot_password: null
})

export const init = (state) => {
  return ({
    ...state,
    fetching: false,
    err: null,
    user: null,
    success: false,
    supplier: null,
    role: null,
    forgot_password: null
  })
}

export const initForgotPasswod = (state) => {
  return ({
    ...state,
    forgot_password: null
  })
}

export const request = (state) => {
  return ({
    ...state,
    success: false,
    fetching: true
  })
}

export const success = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    user: data,
    err: null
  })
}

export const failure = (state, { err }) => {
  return ({
    ...state,
    success: false,
    fetching: false,
    err
  })
}

export const supplierSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    err: null,
    supplier: data
  })
}

export const userForgotPasswordSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    forgot_password: data,
    err: null
  })
}

export const userRoleSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    role: data,
    err: null
  })
}

export const logout = (state) => {
  return ({ ...state, fetching: false })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_REQUEST]: request,
  [Types.USER_SUCCESS]: success,
  [Types.USER_FAILURE]: failure,
  [Types.USER_LOGOUT]: logout,
  [Types.USER_INIT]: init,
  [Types.SUPPLIER_REQUEST]: request,
  [Types.SUPPLIER_SUCCESS]: supplierSuccess,
  [Types.SUPPLIER_FAILED]: failure,
  [Types.USER_ROLE_REQUEST]: request,
  [Types.USER_ROLE_SUCCESS]: userRoleSuccess,
  [Types.USER_ROLE_FAILURE]: failure,
  [Types.USER_FORGOT_PASSWORD_INIT]: initForgotPasswod,
  [Types.USER_FORGOT_PASSWORD_REQUEST]: request,
  [Types.USER_FORGOT_PASSWORD_SUCCESS]: userRoleSuccess,
  [Types.USER_FORGOT_PASSWORD_FAILURE]: failure

})
