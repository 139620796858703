// BARU SIAPIN MODAL AJA
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { isEmpty } from 'lodash'
import { Modal } from 'react-bootstrap'
import Loading from '../../../Components/Behaviors/Loading'
import { fetchShipmentAwb, fetchUpdateInvoice } from '../../../Services/FetchingGroup'

import { inputAwbSchema } from '../../../Validations/Order'
import InfoBox from '../../../Components/InfoBox'

function InputAWBModal ({ invoice, fetchUpdateInvoiceStatus, onClickedModal, handleToogleModal }) {
  const maxSize = 1048576

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxSize: maxSize,
    multiple: false
  })
  const [file, setFile] = useState()
  const [awbNo, setAwbNo] = useState('')
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [fileName, setFileName] = useState('No File Choosen')

  // Upload csv or excel
  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = e => {
      setFileName(acceptedFiles[0].name)
      setFile(e.target.result)
    }
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((error) => {
          return error.code === 'file-invalid-type' ? 'Tipe foto harus .jpg, .jpeg, .png' : 'Ukuran file lebih dari 1 MB  '
        })
        setFile({})
        return setErr({ err: true, message: errorMessage })
      })
    }
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      return setErr({ err: false, message: '' })
    }
  }, [acceptedFiles])

  const handleShipmentAwb = async () => {
    const body = {
      invoice_id: invoice.invoice_id,
      awb: awbNo,
      awb_image: file
    }
    const isValid = await inputAwbSchema.isValid(body)
    if (isValid) {
      setErr({ err: false, message: '' })
      fetchShipmentAwb(body, setIsLoading, setErr, setSuccess)
    } else {
      const errorMessage = await inputAwbSchema.validate(body).catch(function (err) {
        return err.errors[0]
      })
      setErr({ err: true, message: errorMessage })
    }
  }

  function handleInit () {
    setFile([])
    setFileName('No File Choosen')
    setAwbNo('')
    setErr({ err: false, message: '' })
    setIsLoading(false)
  }

  useEffect(() => {
    if (success.success) {
      const body = {
        invoices: [invoice.invoice_id],
        status: 'shipped'
      }
      fetchUpdateInvoice(body, setIsLoading, null, null)
      setSuccess({ success: false, data: {} })
    }
  }, [success, invoice])

  if (onClickedModal.inputAwbModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => { handleInit(); handleToogleModal('inputAwbModal', 'close') }}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <h2 className='mt-3'><b>Input AWB</b></h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isLoading
              ? (
                <div className='row pl-5 mr-5 d-flex py-4'>
                  <div className='col-12'>
                    <center>
                      <Loading type='bounce' />
                    </center>
                  </div>
                </div>
                )
              : (
                <>
                  <div className='row px-4'>
                    <div className='col-5 fs--s font-weight-bold px-5 my-auto'>Masukkan AWB</div>
                    <div className='col-7'>
                      <div className='input-text-bar'>
                        <input className='fs--s my-auto input' onChange={(e) => setAwbNo(e.target.value)} placeholder='No. AWB' />
                      </div>
                    </div>
                  </div>
                  <div className='row px-4 mt-4'>
                    <div className='col-12 col-lg-5 fs--s font-weight-bold px-5 my-3'>Foto Bukti Pengiriman</div>
                    <div {...getRootProps({ className: 'col-lg-2 col-12' })}>
                      <input {...getInputProps()} />
                      <button type='button' className='btn btn-outline-light text-dark btn-s border btn-full'>Choose File</button>
                    </div>
                    <div className='col-12 col-lg my-3'>
                      <p className='fs--s pl-3 my-auto'>{fileName}</p>
                    </div>
                    <div className='col-12'>
                      {acceptedFiles.length > 0 && fileAccepted(acceptedFiles)}
                      {
                        (!isEmpty(file)) &&
                          <div className='container pb-4'>
                            <img id='myImg' src={file} alt={acceptedFiles[0].name} style={{ width: '100%', maxWidth: '180px' }} />
                            <InfoBox
                              text='Pastikan untuk mengecek file yang sudah dipilih dengan mendownload kembali data yang sudah diupload sebelum melanjutkan.'
                              icon='info'
                              status=''
                            />
                          </div>
                      }
                    </div>
                    <div className='col-12'>
                      {
                        (err.err) &&
                          (
                            <div className='row px-4'>
                              <div className='col-12'>
                                <span className='fs--s error'>{err.message}<br /></span>
                              </div>
                            </div>
                          )
                      }
                    </div>
                  </div>
                </>
                )
          }
        </Modal.Body>
        <Modal.Footer className=' justify-content-center text-center m-2'>
          <div className='col-12'>
            <button onClick={(e) => handleShipmentAwb()} type='button' className='btn-primary btn-m btn-full'>Konfirmasi</button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  } else return null
}

export default InputAWBModal
