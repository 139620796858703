const config = {
  nodeEnv: 'production',
  baseURL: 'https://seller.ruparupa.com',
  assetsURL: 'https://seller.ruparupa.com/assets',
  apiURL: 'https://seller.ruparupa.com/wapi',
  miscURL: 'https://wapi.ruparupa.com',
  imageURL: 'https://res.cloudinary.com/ruparupa-com/image/upload',
  googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAN_MGKh_1pLi6O1wXFRFBphZKB3Vdz4YU&v=3.37&libraries=geometry,drawing,places',
  carrierAuto: [6, 9, 33],
  carrierGosend: [7, 8],
  textEditorConfig: {
    readonly: false,
    tabIndex: -10,
    triggerChangeEvent: true,
    zIndex: 0,
    buttons: ['source', '|', 'bold', 'strikethrough', 'underline', 'italic', 'eraser', '|', 'fontsize', 'align', 'ol', 'brush', 'paragraph', '|', 'image', '\n', 'video', '|', 'undo',
      'redo', 'selectall', 'cut', 'copy', 'paste', '|', 'hr', 'symbol', 'print', 'find', 'preview'],
    toolbarAdaptive: false,
    // autofocus: true,
    // defaultMode: '3', //sorce code di kanan
    // allowResizeX: false //sorce code di kanan
    theme: 'summer', // dark mode
    // preset: 'inline',// preset inline
    // auto block ctrl B , ctrl i italic
    colors: {
      greyscale: null,
      palette: null,
      full: {
        rupaRupaColor1: ['#ff7f45', '#00a7f5', '#f4fbfe', '#fff943', '#1e824d', '\n;display: none'],
        rupaRupaColor2: ['#f26625', '#008dcf', '#fff7ff', '#f3e21d', '#049372', '\n;display: none'],
        rupaRupaColor3: ['#d6551d', '#0071a9', '#d7e9f0', '#dccc1a', '#228b22', '\n;display: none'],
        rupaRupaColor4: ['#ff3a33', '#e25141', '#555761', '#a38f84', '#efefef', '\n;display: none'],
        rupaRupaColor5: ['#f3251d', '#d4dce6', '#757886', '#f9fafc', '#f0f2f7', '\n;display: none'],
        rupaRupaColor6: ['#da211b', '#e0e6ed', '#757885', '#e5e9f2', '\n;display: none']
      }
    },
    hotkeys: {
      redo: ['ctrl+z'],
      undo: ['ctrl+shift+z'],
      underline: 'ctrl + u',
      bold: ['ctrl+b'],
      italic: ['ctrl+i'],
      removeFormat: ['ctrl+shift+m'],
      insertOrderedList: ['ctrl+shift+&'],
      insertUnorderedList: ['ctrl+shift+8'],
      openSearchDialog: ['ctrl + f'],
      openReplaceDialog: ['ctrl+q']
    }
  }
}

export default config
