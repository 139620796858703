import React, { useContext } from 'react'

import OrderGroup from './OrderGroup'
import OrderCommentGroup from './OrderCommentGroup'
import { ScaleContext } from '../../Context/ScaleContext'

export default function OrderGroupContainer ({
  type, invoices, handleToogleModal, handleRenderingModal, handleToogleSingleListItem, handleTooglePrintPackingSlipPrint
}) {
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext

  if (invoices?.invoices) {
    if (!isMobileView) {
      return invoices?.invoices?.map((invoice, index) => {
        let grandTotal = 0
        let shipmentFeeTotal = 0
        if (invoice?.items?.length > 0) {
          const items = invoice?.items
          items.forEach(element => {
            grandTotal += element.rowtotal
            shipmentFeeTotal += ((element.shipping_amount - element.shipping_discount_amount) * (element.qty_ordered - element.qty_refunded))
          })
        }
        return (
          <div className='col-12' key={index}>
            <div className='row'>
              <div className='col-12 col-lg-8 my-3'>
                <OrderGroup
                  invoice={invoice}
                  grandTotal={grandTotal}
                  shipmentFeeTotal={shipmentFeeTotal}
                  handleToogleModal={handleToogleModal}
                  handleToogleSingleListItem={handleToogleSingleListItem}
                  handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlipPrint}
                />
              </div>
              <div className='col my-3'>
                <OrderCommentGroup invoice={invoice} from='order' />
              </div>
            </div>
            {type === 'search' && handleRenderingModal(invoice.shipment.shipment_status)}
          </div>
        )
      })
    } else {
      return invoices?.invoices?.map((invoice, index) => {
        return (
          <div className='col-12 my-3' key={index}>
            <OrderGroup
              invoice={invoice}
              handleToogleModal={handleToogleModal}
            />
          </div>
        )
      }
      )
    }
  } else return null
}
