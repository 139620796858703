import { notify } from 'react-notify-toast'

function notifySuccess (message) {
  const myColor = { background: '#049372', text: '#FFFFFF' }
  return notify.show(message, 'custom', 1500, myColor)
}

function notifyError (message) {
  const myColor = { background: '#f3251d', text: '#FFFFFF' }
  return notify.show(message, 'custom', 1500, myColor)
}

export {
  notifySuccess,
  notifyError
}
