import { string, object, number, array, ref } from 'yup'

const insertProductSchema = object().shape({
  name: string().required('Nama produk harus diisi'),
  images: array().min(1, 'Foto tidak boleh kosong').required().of(
    object().shape({
      angle: number().required(),
      image: string().required(),
      status: number().required()
    }) // check this
  ),
  category: array().required('Category tidak boleh kosong'),
  brand: string().required('Brand tidak boleh kosong'),
  description: string().required('Deskripsi tidak boleh kosong'),
  specification: string().required('Spesifikasi tidak boleh kosong'),
  sku_seller: string().required('Sku seller tidak boleh kosong'),
  meta_title: string().required('Meta Title tidak boleh kosong'),
  tag_search: string().required('Tag Search tidak boleh kosong'),
  stock: string().required('Stock tidak boleh kosong'),
  weight: string().required('Berat tidak boleh kosong'),
  package_content: string().required('Package content tidak boleh kosong'),
  packaging_height: string().required('Tinggi packaging tidak boleh kosong'),
  packaging_length: string().required('Panjang packaging tidak boleh kosong'),
  packaging_width: string().required('Lebar packaging tidak boleh kosong'),
  packaging_uom: string().required('Satuan ukuran packaging tidak boleh kosong'),
  product_height: string().required('Tinggi product tidak boleh kosong'),
  product_length: string().required('Panjang product  tidak boleh kosong'),
  product_width: string().required('Lebar product tidak boleh kosong')
})

const updateProductSchema = object().shape({
  name: string().required('Nama produk harus diisi'),
  images: array().min(1, 'Foto tidak boleh kosong').required().of(
    object().shape({
      angle: number().required(),
      image: string().required(),
      status: number().required()
    }) // check this
  ),
  brand: string().required('Brand tidak boleh kosong'),
  description: string().required('Deskripsi tidak boleh kosong'),
  specification: string().required('Spesifikasi tidak boleh kosong'),
  sku_seller: string().required('Sku seller tidak boleh kosong'),
  meta_title: string().required('Meta Title tidak boleh kosong'),
  tag_search: string().required('Tag Search tidak boleh kosong'),
  weight: string().required('Berat tidak boleh kosong'),
  package_content: string().required('Package content tidak boleh kosong'),
  packaging_height: string().required('Tinggi packaging tidak boleh kosong'),
  packaging_length: string().required('Panjang packaging tidak boleh kosong'),
  packaging_width: string().required('Lebar packaging tidak boleh kosong'),
  packaging_uom: string().required('Satuan ukuran packaging tidak boleh kosong'),
  product_height: string().required('Tinggi product tidak boleh kosong'),
  product_length: string().required('Panjang product  tidak boleh kosong'),
  product_width: string().required('Lebar product tidak boleh kosong')
})

const updatePriceSchema = object().shape({
  sku: string().required('Sku tidak boleh kosong'),
  old_price: number().required(),
  old_special_price: number().required(),
  price: number().required().moreThan(ref('special_price'), 'Harga awal harus lebih dari harga spesial')
})

const updateStockSchema = object().shape({
  qty_on_hand: number().required('Stock harus tidak boleh kosong').positive().integer('Stock harus angka'),
  status: string().required('Status Is in stock harus diisi')
})

export { insertProductSchema, updatePriceSchema, updateProductSchema, updateStockSchema }
