import React, { useEffect, useState } from 'react'
import StatusProduct from '../../../Components/Products/Modals/StatusProduct'
import { fetchUpdateProductStatus } from '../../../Services/FetchingGroup'

export default function UpdateStatusModal ({ params, handleChangeParams, onClickedUpdateStatusModal, handleToggleUpdateStatusModal, product }) {
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  // const [err, setErr] = useState({ err: false, message: '' })

  const handleUpdateStatusProduct = () => {
    let status = 0

    if (product.status === 0) status = 10
    else status = 0

    const body = {
      store_code: params.store_code,
      sku: product.sku,
      status: status
    }

    fetchUpdateProductStatus(body, setIsLoading, setErr, setSuccess)
  }

  useEffect(() => {
    if (success.success) {
      handleToggleUpdateStatusModal('close', product)
      handleChangeParams(params)
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (onClickedUpdateStatusModal) {
    return (
      <StatusProduct
        product={product}
        fetching={isLoading}
        error={err}
        handleUpdateStatusProduct={handleUpdateStatusProduct}
        handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
      />
    )
  } else return null
}
