import rootSaga from '../Sagas/index'
import CreateStore from './CreateStore'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

// ALL REDUCER MUST BE IMPORTED HERE
import { reducer as userRedux } from './UserRedux'
import { reducer as countRedux } from './CountRedux'
import { reducer as listRedux } from './ListRedux'
import { reducer as commentRedux } from './CommentRedux'

export const history = createBrowserHistory()

export default initialState => {
  const reducers = {
    user: userRedux,
    count: countRedux,
    list: listRedux,
    comment: commentRedux
  }

  const rootReducer = { ...reducers, router: connectRouter(history) }

  return CreateStore(rootReducer, rootSaga, initialState, history)
}
