import React from 'react'
import BaseQuery from '../Base/BaseQuery'
import DefaultLayout from '../Containers/DefaultLayout'
import InsertProductFormGroup from '../Containers/Products/Insert/InsertProductFormGroup'
import UpdateProductFormGroup from '../Containers/Products/Update/UpdateProductFormGroup'
import ViewProductDetailGroup from '../Containers/Products/View/ViewProductDetailGroup'

export default function ProductView (props) {
  const { type } = props.match.params
  const sku = BaseQuery().get('sku')
  const storeCode = BaseQuery().get('store_code')
  const supplierAlias = BaseQuery().get('supplier_alias')
  const supplierId = BaseQuery().get('supplier_id')

  function render () {
    switch (type) {
      case 'add':
        return <InsertProductFormGroup type={type} storeCode={storeCode} supplierAlias={supplierAlias} supplierId={supplierId} />
      case 'view':
        return <ViewProductDetailGroup type={type} sku={sku} storeCode={storeCode} supplierAlias={supplierAlias} supplierId={supplierId} />
      case 'update':
        return <UpdateProductFormGroup type={type} sku={sku} storeCode={storeCode} supplierAlias={supplierAlias} supplierId={supplierId} />
      default:
        break
    }
  }
  return (
    <DefaultLayout pages='product-form'>
      {render()}
    </DefaultLayout>
  )
}
