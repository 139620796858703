import React from 'react'
import { Modal } from 'react-bootstrap'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import InfoBox from '../../InfoBox'
import ThumbnailProduct from '../ThumbnailProduct'
import Pagination from '../../../Containers/Pagination'

export default function UpdateCommission ({ product, error, commission, commissionModified, handleInsertSpecialCommission, handleSetCommission, handleOnChange, handleToggleSetCommissionModal, handleDelete, handlePageClick }) {
  function renderDateTime (name, initialValue, index) {
    return (
      <DateTimePicker
        disableClock
        calendarIcon={false}
        yearPlaceholder='YYYY'
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        format='dd-MM-y'
        name={name}
        value={initialValue && new Date(initialValue)}
        onChange={value => handleOnChange(name, index, value)}
      />
    )
  }
  return (
    <Modal
      show
      size='update'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => handleToggleSetCommissionModal('close')}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--xm font-weight-bold p-3'>Atur Komisi</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-container pt-3 pb-3'>
          <ThumbnailProduct product={product} />
          <div className='row my-5 align-items-center'>
            <div className='col-5 col-lg-3'>Komisi Normal</div>
            <div className='col-5 col-lg-2'>
              <b>{commission.commission} % </b>
            </div>
          </div>
          <div className='input-box'>

            <div className='row'>
              <table className='table table-layout table-layout__multiple-order text-center'>
                <thead>
                  <tr className='fs--s my-auto'>
                    <th width='30%'>Komisi Spesial</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {commissionModified?.special_commissions?.map((item, index) => {
                    return (
                      <tr key={index} className='fs--s'>
                        <td>
                          <div className='row align-items-center'>
                            <div className='col-7'>
                              <div className='input-text-bar'>
                                <input placeholder='0' defaultValue={item.special_commission || 0} value={commission.special_commission} name='special_commission' type='text' onBlur={(e) => handleOnChange(e.target.name, index, e.target.value)} />
                              </div>
                            </div>
                            <div className='col-1 width-rate fs--m pl-0'>%</div>
                          </div>
                          {/* {item.special_commission} */}
                        </td>
                        <td>
                          <div className='input-text-bar'>
                            {renderDateTime('special_from_date', item.special_from_date, index)}
                          </div>
                        </td>
                        <td>
                          <div className='input-text-bar'>
                            {renderDateTime('special_to_date', item.special_to_date, index)}
                          </div>
                        </td>
                        <td>
                          <button className='btn btn-s btn-negative' onClick={() => handleDelete(index)}><i className='anticon icon-delete' /></button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div className='col-8'>
                {commission?.total > 3 &&
                  <Pagination
                    total={commission?.total}
                    show={3}
                    classNames='pagination pull-right'
                    handlePageClick={handlePageClick}
                  />}
              </div>
              <div className='col-4 pull-right text-right'>
                <button className='btn btn-s btn-secondary' onClick={() => handleInsertSpecialCommission()}>Add New Schedule</button>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-12'>
                <InfoBox
                  text='Pengaturan komisi spesial akan berjalan h+1 setelah disimpan'
                  icon='info'
                  status=''
                />
              </div>
            </div>
            {error.err && (
              <div className='row my-3'>
                <div className='col-12'>
                  <InfoBox
                    text={error.message}
                    icon='danger'
                    status='danger'
                  />
                </div>
              </div>

            )}
          </div>
        </div>
      </Modal.Body>
      <div className='modal-container pb-3'>
        <Modal.Footer className=' justify-content-center text-center'>
          <div className='col'>
            <button type='button' onClick={() => handleToggleSetCommissionModal('close')} className='btn-ghost-primary btn-m btn-full'>Cancel</button>
          </div>
          <div className='col'>
            <button type='button' onClick={(e) => handleSetCommission()} className='btn-primary btn-m btn-full'>Simpan</button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
