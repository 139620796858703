import { put, fork, take, call } from 'redux-saga/effects'
import { END } from 'redux-saga'
import Cookies from 'cookies-js'
import UserActions, { UserTypes } from '../Redux/UserRedux'
import isEmpty from 'lodash/isEmpty'
import localforage from 'localforage'

export function * login (api) {
  let action = yield take(UserTypes.USER_REQUEST)
  while (action !== END) {
    yield fork(fetchLogin, api, action)
    action = yield take(UserTypes.USER_REQUEST)
  }
}

export function * fetchLogin (api, { data }) {
  try {
    const res = yield call(api.login, data)
    if (!res.ok) {
      yield put(UserActions.userFailure())
    } else {
      if (isEmpty(res.data.errors)) {
        const data = res.data.data
        const date = new Date()
        if (data.token) {
          // set the access token for token
          yield localforage.setItem('token_access', data.token)
          // set the access date for expired
          yield localforage.setItem('access_date', date)
          // for more detail data of the supplier
          if (data.user?.supplier) {
            // using the supplier id from the user data
            const supplierId = { supplier_id: data.user.supplier.supplier_id }
            yield put(UserActions.supplierRequest(supplierId))
          }
          // send the data to redux
          yield put(UserActions.userSuccess(data.user))
        }
      } else {
        if (!isEmpty(res.data.errors.message)) {
          yield put(UserActions.userFailure(res.data.errors.message))
        } else {
          yield put(UserActions.userFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
        }
      }
    }
  } catch (error) {
    console.log(error)
    yield put(UserActions.userFailure())
  }
}

export function * getSupplierData (api, getToken) {
  let action = yield take(UserTypes.SUPPLIER_REQUEST)
  while (action !== END) {
    yield fork(fetchGetSupplierData, api, getToken, action)
    action = yield take(UserTypes.SUPPLIER_REQUEST)
  }
}

export function * fetchGetSupplierData (api, getToken, { data }) {
  const token = yield call(getToken)
  if (token) {
    try {
      const res = yield call(api.getSupplierInfo, data, token)
      if (!res.ok) {
        yield put(UserActions.userFailure())
      } else {
        if (isEmpty(res.data.errors)) {
          const data = res.data.data
          if (data) {
            yield put(UserActions.supplierSuccess(data))
          } else {
            // ON DEVELOPMENT
          }
        } else {
          if (!isEmpty(res.data.errors.message)) {
            yield put(UserActions.userFailure(res.data.errors.message))
          } else {
            yield put(UserActions.userFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
          }
        }
      }
    } catch (error) {
      yield put(UserActions.userFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
    }
  } else yield put(UserActions.userFailure('Token tidak ditemukan, mohon untuk melakukan login ulang'))
}

export function * logout (api) {
  let action = yield take(UserTypes.USER_LOGOUT)
  while (action !== END) {
    yield fork(doLogout, api, action)
    action = yield take(UserTypes.USER_LOGOUT)
  }
}

export function * doLogout () {
  const isRemoveAccessToken = yield window.localStorage.removeItem('token_access')
  yield window.localStorage.removeItem('token_access')
  Cookies.set('filterSupplier', '')
  Cookies.set('filterCarrier', '')
  if (!isRemoveAccessToken) {
    yield put(UserActions.userInit())
    // yield window.location.replace(config.baseURL + '/login')
  } else {
    yield put(UserActions.userFailure('Terjadi kendala, silahkan coba kembali'))
  }
}

export function * getUserRole (api, getToken) {
  let action = yield take(UserTypes.USER_ROLE_REQUEST)
  while (action !== END) {
    yield fork(fetchGetUserRole, api, getToken, action)
    action = yield take(UserTypes.USER_ROLE_REQUEST)
  }
}

export function * fetchGetUserRole (api, getToken, { data }) {
  const token = yield call(getToken)
  try {
    const res = yield call(api.getUserRole, data, token)
    if (!res.ok) {
      yield put(UserActions.userRoleFailure())
    } else {
      if (isEmpty(res.data.errors)) {
        const data = res.data.data
        if (data) {
          data.rules = JSON.parse(data.rules)
          yield put(UserActions.userRoleSuccess(data))
        } else {
          // ON DEVELOPMENT
        }
      } else {
        if (!isEmpty(res.data.errors.message)) {
          yield put(UserActions.userRoleFailure(res.data.errors.message))
        } else {
          yield put(UserActions.userRoleFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
        }
      }
    }
  } catch (error) {
    yield put(UserActions.userRoleFailure('Terjadi kesalahan, ulangi beberapa saat lagi.'))
  }
}
