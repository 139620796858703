import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ScaleContext } from '../../Context/ScaleContext'

import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'
import CheckItemsModal from '../Modals/Order/CheckItemsModal'
import CheckItemsBulkModal from '../Modals/Order/CheckItemsBulkModal'
import ShipmentStatusModal from '../Modals/Order/ShipmentStatusModal'

import Loading from '../../Components/Behaviors/Loading'
import CommentModal from '../Modals/CommentModal'
import Pagination from '../Pagination'
import OrderGroupContainer from './OrderGroupContainer'
import ShipmentFeeModal from '../Modals/Order/ShipmentFeeModal'

export default function ProcessingDetail ({ params, handleParamsChange, invoiceList, fetching, error }) {
  // const [attbContext] = useContext(OrderContext)
  const count = useSelector(state => state.count)

  const [paramsModified, setParamsModified] = useState({ status: 'processing', page: 1, keyword: '', supplier: params?.supplier || '' })
  // const [params, setParams] = useState({ status: 'processing', supplier: user?.supplier?.supplier_alias, carrier_id: '', page: 1, keyword: '' })
  const [processingInvoiceList, setProcessingInvoiceList] = useState()

  const [selectedInvoice, setSelectedInvoice] = useState()

  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext

  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    checkItemsModal: false,
    checkItemsBulkModal: false,
    commentModal: false,
    shipmentStatusModal: false,
    shipmentFeeModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  useEffect(() => {
    handleParamsChange({ ...paramsModified })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (invoiceList !== processingInvoiceList) setProcessingInvoiceList(invoiceList)
  }, [invoiceList, processingInvoiceList])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      setParamsModified({ ...params, page: selected + 1 })
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }

  function renderModal () {
    return (
      <>
        {onClickedModal.seeProductListModal &&
          <SeeItemsListsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.checkItemsModal &&
          <CheckItemsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
            handleParamsChange={handleParamsChange}
          />}
        {onClickedModal.checkItemsBulkModal &&
          <CheckItemsBulkModal
            invoices={processingInvoiceList?.invoices}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentStatusModal &&
          <ShipmentStatusModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentFeeModal &&
          <ShipmentFeeModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }
  const renderDesktop = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.err}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='row align-items-center'>
              <div className='col-12 px-5 py-4'>
                <div className='row align-items-center'>
                  <div className='col-4'>
                    <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.processing} processing</h1>
                  </div>
                  <div className='col-4'>
                    <button
                      onClick={() => handleToogleModal('checkItemsBulkModal', 'open')}
                      className='btn-primary btn-s w-100 fs--s '
                    >
                      Bulk Cek Kelengkapan Barang
                    </button>
                  </div>
                  <div className='col-4' style={{ display: 'flex', justifyContent: 'center' }}>
                    {processingInvoiceList?.total_invoice > 10 &&
                      <Pagination
                        total={processingInvoiceList?.total_invoice}
                        show={10}
                        classNames='pagination pull-right'
                        handlePageClick={handlePageClick}
                      />}
                  </div>

                </div>
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='processing'
                invoices={processingInvoiceList}
                handleToogleModal={handleToogleModal}
              />}
          {processingInvoiceList?.invoices && renderModal()}
        </div>
      )
    }
  }

  const renderMobile = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 p-3'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.processing} processing</h1>
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='processing'
                invoices={processingInvoiceList}
                handleToogleModal={handleToogleModal}
              />}
          {processingInvoiceList?.invoices && renderModal()}
          <div className='col-12'>
            {processingInvoiceList?.total_invoice > 10 &&
              <Pagination
                total={processingInvoiceList.total_invoice}
                show={10}
                classNames='pagination pull-right'
                handlePageClick={handlePageClick}
              />}
          </div>
        </div>
      )
    }
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
