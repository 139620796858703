import React from 'react'
import { Link } from 'react-router-dom'

export default function NotificationCard ({ status, type, action, text, total, dataGroup, picked }) {
  if (type === 'default') {
    return (
      <div className={`container-card-${status}`} onClick={action}>
        <div className='title'>
          <span className='fs--s' dangerouslySetInnerHTML={{ __html: text || '-' }} />
        </div>
      </div>
    )
  } else if (type === 'withButton') {
    return (
      <div className={`container-card-${status}`}>
        <div className='row fs--s  align-items-center'>
          <div className='col-12 col-md-7 col-lg-6'>
            <p dangerouslySetInnerHTML={{ __html: text || '-' }} />
          </div>
          <div className='col'>
            <Link to={action}>
              <button className='btn btn-full btn-secondary fs--s '><div className='my-1'>Proses Sekarang</div></button>
            </Link>
          </div>
        </div>
      </div>
    )
  } else if (type === 'withData') {
    return (
      <div className={`container-card-${status}`}>
        <div className='title'>
          <p className='fs--s' dangerouslySetInnerHTML={{ __html: text || '-' }} />
        </div>
        <div className='description fs--s '>
          {dataGroup.map((data, index) => {
            let show = false
            if (total > 1 && index > 0) {
              show = true
            }
            return (
              <b key={index}>
                <span>{show && ', '}
                  <Link to={action + data[picked]}>
                    <span className='link'><u>{data[picked]}</u></span>
                  </Link>
                </span>
              </b>
            )
          })}
        </div>
      </div>
    )
  }
}
