import React from 'react'
import BaseQuery from '../Base/BaseQuery'
import DefaultLayout from '../Containers/DefaultLayout'
import SettlementReportDetail from '../Containers/SettlementReport'

export default function SettlementReport (props) {
  const { periodDetails } = props.match.params
  const startPeriod = BaseQuery().get('start_periode')
  const endPeriod = BaseQuery().get('end_periode')
  const supplierId = BaseQuery().get('supplier_id')
  const supplierName = BaseQuery().get('supplier_name')

  return (
    <DefaultLayout pages='settlement-report'>
      <SettlementReportDetail tab={periodDetails} startPeriod={startPeriod} endPeriod={endPeriod} supplierId={supplierId} supplierName={supplierName} />
    </DefaultLayout>
  )
}
