import { isEmpty } from 'lodash'
import React from 'react'
import InfoBox from '../../InfoBox'
import { useSelector } from 'react-redux'

import { checkRole } from '../../../Services/CheckRole'

export default function ProfileInfoDesktop ({
  uploadProduct,
  renderSelect,
  renderMap,
  supplierAddress,
  fetching,
  error,
  clickedInstantCourier,
  setClickedInstantCourier,
  handleInit,
  handleChangeSupplierInfo,
  handleChangeAddress
}) {
  const user = useSelector(state => state.user)

  return (
    <div className='row fs--m'>
      <div className='col-2'>
        {uploadProduct()}
      </div>
      <div className='col-10'>

        <div className='row fs--m'>
          <div className='col-2'>
            No Telepon
          </div>
          <div className='col-10'>
            <div className='input-text-bar'>
              <input name='phone' type='text' value={supplierAddress?.phone || ''} onChange={(e) => handleChangeAddress(e)} />
            </div>
          </div>
          <div className='col-2 pt-4'>
            Alamat Toko
          </div>
          <div className='col-10 pt-4'>
            <div className='input-text-bar input-text-area'>
              <textarea name='address' type='text' className='form-control fs--m' rows='7' value={supplierAddress?.address || ''} onChange={(e) => handleChangeAddress(e)} />
            </div>
          </div>

          {renderSelect()}

          <div className='col-12 pt-5 pb-3'>
            <InfoBox
              text='Jika ingin mengaktifkan Instant Courier, nomor telepon harus di isi terlebih dahulu'
              icon='info'
              status=''
            />

          </div>
          <div className='col-12 pb-5'>
            Instant Courier
            <span className='ml-4'>
              <label className='switch'>
                <input disabled={isEmpty(supplierAddress.phone)} type='checkbox' checked={clickedInstantCourier} onChange={(e) => setClickedInstantCourier(!clickedInstantCourier)} />
                <span className='slider round' />
              </label>
            </span>
          </div>
          {clickedInstantCourier &&
              (
                <>
                  <div className='col-12 mb-4'><i className='anticon icon-enviroment' />&nbsp;&nbsp;Pilih Koordinat Lokasi</div>
                  <div className='col-12'>
                    {renderMap()}
                  </div>
                </>
              )}
        </div>
      </div>

      <div className='col-12 pt-5'>
        {error.err && (
          <InfoBox
            text={error.message}
            icon='danger'
            status='danger'
          />
        )}
        {checkRole({
          menu: 'supplier_profile',
          method: 'edit',
          attribute: '',
          yes: (access) => {
            return (
              <div className='row'>
                <div className='col-6'>
                  <button disabled={fetching} onClick={() => handleInit()} className='btn btn-ghost-secondary btn-m btn-full'><p className='mb-0 fs--s font-weight-bold'>Reset</p></button>
                </div>
                <div className='col-6'>
                  <button disabled={fetching} onClick={handleChangeSupplierInfo} className='btn btn-primary btn-m btn-full'>
                    <p className='mb-0 fs--s font-weight-bold'>
                      {fetching
                        ? 'Mohon Menunggu ...'
                        : 'Simpan'}
                    </p>
                  </button>
                </div>
              </div>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

      </div>
    </div>
  )
}
