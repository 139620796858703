import React, { useEffect, useState } from 'react'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import { fetchGetSalesSummary } from '../Services/FetchingGroup'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import NumberWithCommas from '../Utils/NumberWithCommas'
import Loading from '../Components/Behaviors/Loading'

import ListActions from '../Redux/ListRedux'
import { isEmpty } from 'lodash'

export default function HomeRight () {
  const user = useSelector(state => state.user)
  const list = useSelector(state => state.list)

  const dispatch = useDispatch()

  const [res, setRes] = useState({})
  // need to change to role rules
  const [params, setParams] = useState({
    supplier_id: user.user?.role_id === 2 ? user.supplier?.supplier_id : '',
    start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs().endOf('month').format('YYYY-MM-DD')
  })
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    setParams(paramsChange)
    fetchGetSalesSummary(paramsChange, setRes, setIsLoading, setErr)
  }

  useEffect(() => {
    if (isEmpty(res)) {
      handleParamsChange(params)
    }
    if (user?.user?.role_id !== 2 && list.supplier === null) {
      dispatch(ListActions.supplierListRequest())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (err.err) {
      console.log(err.message)
      setErr({ err: false, message: '' })
    }
  }, [err])

  function renderLoading (position) {
    return (
      <div className={position}>
        <Loading type='bounce' />
      </div>
    )
  }

  function renderSelect () {
    return (
      <div className='input-text-bar my-4'>
        <h4>
          <select className='input' name='supplier_id' defaultValue='' value={params.supplier_id} onChange={(e) => { handleParamsChange({ supplier_id: e.target.value }) }}>
            <option value=''>Seluruh Seller</option>
            {list.supplier?.suppliers?.map((supplier, index) => {
              return <option value={supplier.supplier_id} key={index}>{supplier.name} ({supplier.supplier_alias})</option>
            })}
          </select>
        </h4>
      </div>
    )
  }
  if (user.user) {
    if (user.fetching || isLoading) {
      return (
        <div className='col-12 col-lg-5'>
          <div className='container'>
            <Loading type='alt' />
          </div>
        </div>
      )
    } else {
      return (
        <div className='col-12 col-lg-5'>
          <div className='home-right-container'>
            <div className='container'>
              <h3 className='font-weight-bold'>Report Untuk:</h3>
              {
              user.user?.role_id !== 2
                ? renderSelect()
                : <h4 className='font-weight-bold' id='sellerName'>{user.supplier?.name}</h4>
              }
              <hr />
              <div className='day-picker-container '>
                <div className='row '>
                  <div className='col-5'>
                    <h4>Start Date</h4>
                  </div>
                  <div className='col-5 offset-1'>
                    <h4>End Date</h4>
                  </div>
                </div>
                <div className='mt-1 mb-1 fs--xs'>
                  <div className='row align-items-center'>
                    <div className='col-5'>
                      <div className='input-text-bar fs--s '>
                        <DateTimePicker
                          disableClock
                          calendarIcon={false}
                          yearPlaceholder='YYYY'
                          dayPlaceholder='DD'
                          monthPlaceholder='MM'
                          format='dd-MM-y'
                          name='start_date'
                          value={params.start_date && new Date(params.start_date)}
                          onChange={value => handleParamsChange({ start_date: dayjs(value).format('YYYY-MM-DD') })}
                        />
                      </div>
                    </div>
                    <div className='col-1 text-center'>s/d</div>
                    <div className='col-5'>
                      <div className='input-text-bar fs--s '>
                        <DateTimePicker
                          disableClock
                          calendarIcon={false}
                          yearPlaceholder='YYYY'
                          dayPlaceholder='DD'
                          monthPlaceholder='MM'
                          format='d-MM-yy'
                          name='special_price_to'
                          value={params.end_date && new Date(params.end_date)}
                          onChange={value => handleParamsChange({ end_date: dayjs(value).format('YYYY-MM-DD') })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 mt-3'><h3>Total Sales</h3></div>
                <div className='col-12'>{isLoading
                  ? renderLoading('pull-left')
                  : <h3 className='font-weight-bold'>Rp.&nbsp;{(NumberWithCommas(res?.total_sales || 0) || '-')}</h3>}
                </div>
                <div className='col-12 mt-3'><h3>Jumlah Order</h3></div>
                <div className='col-12'>
                  {isLoading
                    ? renderLoading('pull-left')
                    : <h3 className='font-weight-bold'>{(NumberWithCommas(res?.count_order || 0) || '-')}</h3>}
                </div>
                <div className='col-12 mt-3'><h3>Jumlah Credit Memo</h3></div>
                <div className='col-12'>
                  {isLoading
                    ? renderLoading('pull-left')
                    : <h3 className='font-weight-bold'>{(NumberWithCommas(res?.count_credit_memo || 0) || '-')}</h3>}
                </div>
              </div>
              <hr />
              <div className='row text-left'>
                <div className='col-6'>
                  <h3>Total Order</h3>
                </div>
                <div className='col-6 text-right'>
                  {isLoading
                    ? renderLoading('pull-right')
                    : <h3><b>Rp.&nbsp;{(NumberWithCommas(res?.total_order || 0) || '-')}</b></h3>}
                </div>
                <div className='col-6'>
                  <h3>Total Credit Memo</h3>
                </div>
                <div className='col-6 text-right'>
                  {isLoading
                    ? renderLoading('pull-right')
                    : <h3><b>- Rp.&nbsp;{(NumberWithCommas(res?.total_credit_memo || 0) || '-')}</b></h3>}
                </div>
              </div>
              <hr />
              <div className='row text-left mb-5'>
                <div className='col-6'>
                  <h3>Total Sales</h3>
                </div>
                <div className='col-6 text-right'>
                  {isLoading
                    ? renderLoading('pull-right')
                    : <h3><b>Rp.&nbsp;{(NumberWithCommas(res?.total_sales || 0) || '-')}</b></h3>}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  } else return null
}
