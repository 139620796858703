import localforage from 'localforage'

const getToken = () => {
  return localforage.getItem('token_access').then((value) => {
    return value
  }).catch(() => {
    return false
  })
}
export default { getToken }
