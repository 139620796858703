import React, { useState } from 'react'
import config from '../config'
import { notifyError } from '../Services/Notify'

export default function SearchBar () {
  const [keyword, setKeyword] = useState('')

  const handleSearch = (e) => {
    e.preventDefault()

    if (keyword?.length < 3) {
      notifyError('Keyword harus lebih dari 3 karakter')
    } else {
      window.location.replace(config.baseURL + '/order/search?keyword=' + keyword)
    }
  }
  return (
    <div className='input-bar fs--s '>
      <form onSubmit={(e) => handleSearch(e)} method='GET'>
        <input placeholder='Cari nomor order, nomor invoice, nama pelanggan' type='text' onChange={(e) => setKeyword(e.target.value)} />
        <button type='submit' className='search-btn'><i className='anticon icon-search1' /></button>
      </form>
    </div>
  )
}
