import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  supplierListRequest: ['data'],
  supplierListSuccess: ['data'],
  supplierListFailure: ['err'],
  carrierRequest: ['data'],
  carrierSuccess: ['data'],
  carrierFailure: ['err'],
  commentTopicRequest: ['data'],
  commentTopicSuccess: ['data'],
  commentTopicFailure: ['err'],
  listInit: null
})

export const ListTypes = Types
export default Creators

// Initial State
export const INITIAL_STATE = Immutable({
  err: null,
  fetching: false,
  success: false,
  supplier: null,
  carrier: null,
  comment_topic: null
})

export const init = (state) => {
  return ({
    ...state,
    fetching: false,
    err: null,
    supplier: null,
    carrier: null
  })
}

export const request = (state) => {
  return ({
    ...state,
    success: false,
    fetching: true
  })
}

export const supplierSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    supplier: data,
    err: null
  })
}

export const carrierSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    carrier: data,
    err: null
  })
}

export const commentTopicSuccess = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    comment_topic: data,
    err: null
  })
}

export const failure = (state, { err }) => {
  return ({
    ...state,
    success: false,
    fetching: false,
    err
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SUPPLIER_LIST_REQUEST]: request,
  [Types.SUPPLIER_LIST_SUCCESS]: supplierSuccess,
  [Types.SUPPLIER_LIST_FAILURE]: failure,
  [Types.CARRIER_REQUEST]: request,
  [Types.CARRIER_SUCCESS]: carrierSuccess,
  [Types.CARRIER_FAILURE]: failure,
  [Types.COMMENT_TOPIC_REQUEST]: request,
  [Types.COMMENT_TOPIC_SUCCESS]: commentTopicSuccess,
  [Types.COMMENT_TOPIC_FAILURE]: failure,
  [Types.LIST_INIT]: init
})
