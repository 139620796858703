import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import config from '../../config'
import { ForgotPasswordContext } from '../../Context/ForgotPasswordContext'
import { fetchGenerateOtp } from '../../Services/FetchingGroup'

export default function OtpChoices ({ handleVerifyEmail }) {
  const history = useHistory()
  const [attbContext] = useContext(ForgotPasswordContext)
  const [isLoading, setIsLoading] = useState(false)
  const [successGenerateOtp, setSuccessGenerateOtp] = useState({ success: false, data: {} })
  const getEmailProvider = (email) => {
    return email.substring(email.indexOf('@'), email.length)
  }
  useEffect(() => {
    if (successGenerateOtp.success) {
      // this message containts the OTP code that only being shown if you were not in production
      // only for development purpose
      if (config.nodeEnv === 'development') {
        attbContext.handleOnChange({ message: successGenerateOtp.data?.message || '' })
      }
      setSuccessGenerateOtp({ success: false, data: {} })
      return () => history.push('/login?page=forgot-password&type=otp-verification')
    }
    return () => null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successGenerateOtp, history])

  function handleChoices (type) {
    if (type === 'email') {
      const body = {
        email: attbContext.value?.email || '',
        first_name: attbContext.value?.first_name || '',
        channel: 'email',
        action: 'forgot-password'
      }
      attbContext.handleOnChange(body)
      fetchGenerateOtp(body, setIsLoading, null, setSuccessGenerateOtp)
    }
  }

  return (
    <div className='row fs--m justify-content-center'>
      <div className='col-12'>
        <div className=' text-center content-text'>
        Kami akan melakukan verifikasi OTP untuk menjaga keamanan akun Anda
          <br />Pilih salah satu metode pengiriman kode OTP
        </div>
      </div>
      <div className='col-12 mt-4 content--button'>
        <div className='input-text-bar'>
          <button onClick={() => handleChoices('email')} disabled={isLoading} className='btn btn-primary-border btn-full btn-m'>
            {isLoading ? 'Mohon Menunggu' : ('Kirim Email ke *******' + (attbContext.value?.email && getEmailProvider(attbContext?.value?.email)))}
          </button>
        </div>
      </div>
    </div>
  )
}
