// buat nampung gambar2 yg akan dimapping
// anak dari SellerListContainer
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ScaleContext } from '../Context/ScaleContext'
import { startsWith, isEmpty } from 'lodash'
import config from '../config'
import imageDefault from '../static/images/no-thumbnail.png'
import noImagePlacehoder from '../static/images/noImage.png'

export default function SellerList ({ supplierId, image, name, status }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const statusValidation = status === 10 ? 'Open' : status === -1 ? 'Permanent Closed' : status === 0 ? 'Temporary Closed' : '-'
  let imageUrl = imageDefault
  if (!isEmpty(image)) {
    if (startsWith(image, '/v')) {
      imageUrl = config.imageURL + image
    } else {
      imageUrl = image
    }
  } else {
    imageUrl = imageDefault
  }

  const renderMobile = () => {
    return (
      <div className='pb-4'>
        <div className='pl-4'>
          <Link to={`/profile/${supplierId}?type=default`}>
            <div className='row border py-5 pl-1 mr-1'>
              <div className='col-4'>
                <div className='border'>
                  <img className='image-Seller p-4' src={imageUrl} alt='seller' onError={(e) => { e.target.src = noImagePlacehoder }} />
                </div>
              </div>
              <div className='col fs--m my-auto'>
                <div className='seller-name-container'><strong>{name}</strong></div>
                <div className='status-container color__positive--darker'>{statusValidation}</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className='col-4 pb-4'>
        <div className='row '>
          <div className='col-12 '>
            <Link to={`/profile/${supplierId}?type=default`}>
              <div className='border px-4 py-5'>
                <div className='row '>
                  <div className='col text-center'>
                    <div className='mx-3'>
                      <img className='image-Seller border p-4' src={imageUrl} alt='seller' width='100px' height='100px' onError={(e) => { e.target.src = noImagePlacehoder }} />
                    </div>
                  </div>

                  {/* my auto buat vertical-align text */}
                  <div className='col-7 my-auto'>
                    <div className='seller-name-container fs--s '><strong>{name}</strong></div>
                    <div className='status-container color__positive--darker fs--s '>{statusValidation}</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
