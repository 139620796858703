import React from 'react'
import DefaultLayout from '../Containers/DefaultLayout'
import HomeCenter from '../Containers/HomeCenter'
import HomeRight from '../Containers/HomeRight'

export default function Home () {
  return (
    <DefaultLayout pages='home'>
      <div className='col-12 col-lg offset-lg-2'>
        <div className='row'>
          <HomeCenter />
          <HomeRight />
        </div>
      </div>
    </DefaultLayout>
  )
}
