import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ProductAdditionalInformationForm from '../../../Components/Products/Forms/ProductAdditionalInformationForm'
import ProductDescriptionForm from '../../../Components/Products/Forms/ProductDescriptionForm'
import ProductInformationForm from '../../../Components/Products/Forms/ProductInformationForm'
import ProductMetaInformationForm from '../../../Components/Products/Forms/ProductMetaInformationForm'
import ProductStockAndPriceForm from '../../../Components/Products/Forms/ProductStockAndPriceForm'
import ProductVariantInformationForm from '../../../Components/Products/Forms/ProductVariantInformationForm'
import ProductWeightAndDeliveryForm from '../../../Components/Products/Forms/ProductWeightAndDeliveryForm'
import { ScaleContext } from '../../../Context/ScaleContext'
import { fetchGetOwnfleetTemplate, fetchGetExpressCarriers, fetchGetAttributes, fetchGetBrands, fetchGetCategories, fetchInsertProduct } from '../../../Services/FetchingGroup'
import InsertProductFormMobile from './InsertProductFormMobile'
import InsertProductFromDesktop from './InsertProductFromDesktop'

import { insertProductSchema } from '../../../Validations/Product'
import { notifyError } from '../../../Services/Notify'

export default function InsertProductFormGroup ({ type, storeCode, supplierAlias, supplierId }) {
  const history = useHistory()

  const user = useSelector(state => state.user)

  const [categoryListLvl1, setCategoryListLvl1] = useState()
  const [categoryListLvl2, setCategoryListLvl2] = useState()
  const [categoryListLvl3, setCategoryListLvl3] = useState()
  const [categoryListLvl4, setCategoryListLvl4] = useState()
  const [expressCarrierList, setExpressCarrierList] = useState()
  const [attributeList, setAttributeList] = useState()
  const [brandList, setBrandList] = useState()

  const [isLoading, setIsLoading] = useState(false)

  const [success, setSuccess] = useState({ success: false, data: {} })
  const [successRepeat, setSuccessRepeat] = useState({ success: false, data: {} })

  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  /// //////////////////////
  const [imageCollections, setImageCollections] = useState([])
  const [validationErr, setValidationErr] = useState([])

  // const angle = ['Utama', 'Depan', 'Samping', 'Detail 1', 'Detail 2', 'Detail 3']
  const angle = [{ angle: 1, label: 'Utama' }, { angle: 2, label: 'Depan' }, { angle: 3, label: 'Samping' }, { angle: 4, label: 'Detail 1' }, { angle: 5, label: 'Detail 2' }, { angle: 6, label: 'Detail 3' }]
  const warrantyLabel = [
    'warranty_unit', 'warranty_part', 'warranty_service', 'warranty_contributor'
  ]
  const warrantyUnitsValue = [
    'No period', '1 Months', '3 Months', '6 Months', '12 Months', '6 Months', '18 Months', '24 Months', '36 Months', '60 Months', '10 Years', 'Lifetime'
  ]
  const [product, setProduct] = useState({
    is_sparepart: 0,
    is_accessories: 0,
    packaging_uom: 'CM',
    warranty_contributor: '0',
    description: '',
    specification: '',
    hot_to_use: '',
    tips_trick: ''
  })
  const [categorySelected, setCategorySelected] = useState(['', '', '', ''])
  const [attributesSelected, setAttributesSelected] = useState({ additional_attributes: [], attribute_variants: [] })
  const [expressCarrierSelected, setExpressCarrierSelected] = useState([])
  const [checkedAttributes, setCheckedAttributes] = useState([])
  const [ownfleetList, setOwnfleetList] = useState([])

  const [price, setPrice] = useState({ price: '', special_price: '', discount: '' })

  const handleImage = (type, file, angle, status) => {
    const collection = imageCollections

    const find = collection.findIndex(e => e.angle === angle)
    if (type === 'insert') {
      if (find !== -1) {
        collection[find].image = file
      } else {
        const packet = {
          angle: angle,
          image: file,
          status: status
        }
        collection.push(packet)
      }
    } else if (type === 'delete') {
      if (find !== -1) {
        collection.splice(find, 1)
        handleValidationErr(type, '', angle)
      } else {
        handleValidationErr(type, 'Foto tidak ditemukan', angle)
      }
    } else if (type === 'status') {
      if (find !== -1) {
        collection[find].status = status
      } else {
        handleValidationErr(type, 'Foto belum dimasukan', angle)
      }
    }
    setImageCollections([...collection])
  }

  const handleCategoryLevel = (e, level) => {
    const { value } = e.target
    const params = {
      level: level + 1,
      parent_id: value
    }
    let attributeParams = {}
    const index = parseInt(level) - 2
    const collection = categorySelected
    if (level === 2) {
      fetchGetCategories(params, setCategoryListLvl2, setIsLoading, null)
      setCategoryListLvl3()
      setCategoryListLvl4()
    } else if (level === 3) {
      fetchGetCategories(params, setCategoryListLvl3, setIsLoading, null)
      setCategoryListLvl4()
    } else if (level === 4) {
      fetchGetCategories(params, setCategoryListLvl4, setIsLoading, null)
    } else if (level === 5) {
      const filter = categoryListLvl4.data.filter(category => category.category_id === value)
      if (!isEmpty(filter)) {
        attributeParams = {
          attribute_set_id: filter[0].attribute_set_id,
          attribute_variant_set_id: filter[0].attribute_variant_set_id
        }
        fetchGetAttributes(attributeParams, setAttributeList, setIsLoading, null)
      }
    }
    collection[index] = value
    for (let i = index + 1; i < collection.length; i++) {
      collection[i] = ''
    }
    setAttributesSelected({ additional_attributes: [], attribute_variants: [] }) // clean the attributes everytimes the category changes
    setCheckedAttributes([])
    setCategorySelected(collection)
    setProduct({ ...product, ...attributeParams })
  }

  const handleSetAttribute = (e, type, attribute, value, group) => {
    let collection = []
    const valueTarget = e.target.value || ''
    const { name } = e.target
    // there are two types of attributes

    if (name === 'attribute_variants') { // contains color
      collection = attributesSelected.attribute_variants || []
    } else collection = attributesSelected.additional_attributes || [] // contains the most type of the attributes that was given after choosing the category
    // construct the package first
    const choosen = {
      attribute_id: parseInt(attribute.attribute_id), // need to make sure first, is it in int or not before parsing
      attribute_value: '',
      attribute_unit_value: ''
    }
    if (group === 'attribute_variants') {
      const filter = attribute?.option_list?.filter(element => element.option_id === valueTarget)
      if (!isEmpty(filter)) {
        choosen.option_value = filter[0]?.option_value
        choosen.attribute_value = filter[0]?.option_id
      }
    } else {
      if (type === 'checkbox') {
        choosen.attribute_value = value // the value is string, not boolean related to handleCheckboxClick
      } else if (type === 'option' || type === 'short_text') {
        choosen.attribute_value = valueTarget
      } else if (type === 'option_unit') {
        choosen.attribute_unit_value = valueTarget
      }
    }
    if (collection.length > 0) {
      const find = collection.findIndex(element => element.attribute_id === choosen.attribute_id)
      if (find !== -1) {
        const findCollection = collection[find]
        if (type === 'checkbox') {
          collection.splice(find, 1)
        } else {
          // we didn't delete it just change the value
          if (group === 'attribute_variants') {
            findCollection.attribute_value = choosen.option_id
            findCollection.option_value = choosen.attribute_value
          } else {
            if (type === 'option_unit') {
              findCollection.attribute_unit_value = choosen.attribute_unit_value
            } else if (type === 'option' || type === 'short_text') {
              findCollection.attribute_value = choosen.attribute_value
            }
          }
          collection[find] = findCollection
        }
      } else {
        collection.push(choosen)
      }
    } else {
      collection.push(choosen)
    }
    setAttributesSelected({ ...attributesSelected, [name]: collection })
  }

  const handleCheckboxClick = (e, packet) => {
    const { value, checked, name } = e.target
    if (checked) {
      if (name === 'additional_attributes') {
        setCheckedAttributes([...checkedAttributes, value])
        handleSetAttribute(e, 'checkbox', packet, 'true', 'additional_attributes')
      } else if (name === 'express_carrier') {
        setExpressCarrierSelected([...expressCarrierSelected, parseInt(value)])
      }
    } else {
      if (name === 'additional_attributes') {
        setCheckedAttributes(checkedAttributes.filter(item => item !== value))
        handleSetAttribute(e, 'checkbox', packet, 'false', 'additional_attributes')
      } else if (name === 'express_carrier') {
        setExpressCarrierSelected(expressCarrierSelected.filter(item => parseInt(item) !== parseInt(value)))
      }
    }
  }

  function handleSetPrice (type, values, e) {
    let value = e.target?.value || values
    const name = e.target?.name || type
    let specialPrice = parseInt(price.special_price || 0)
    const inputPrice = parseInt(price.price || 0)
    let discount = parseInt(price.discount || 0)
    switch (name) {
      case 'price':
        value = parseInt(value)
        if (specialPrice !== 0) { specialPrice = value - (value * discount) / 100 }
        if (discount !== 0) { discount = (((value - specialPrice) / value)) * 100 }
        return setPrice({ ...price, price: value, special_price: specialPrice, discount: discount })
      case 'discount':
        value = parseInt(value)
        specialPrice = inputPrice - (inputPrice * value) / 100
        return setPrice({ ...price, special_price: specialPrice || '', discount: value || '' })
      case 'special_price':
        value = parseInt(value)
        discount = (((inputPrice - value) / inputPrice)) * 100
        return setPrice({ ...price, discount: discount || '', special_price: value || '' })
      default:
        return setPrice({ ...price, [name]: value })
    }
  }

  function handleValidationErr (type, errMsg, name) {
    const collection = validationErr
    const packet = {
      name: name,
      message: errMsg
    }
    const find = collection.findIndex(e => e.name === name)
    if (find !== -1) {
      if (errMsg === '') {
        collection.splice(find, 1)
      } else {
        collection[find].message = errMsg
      }
    } else {
      collection.push(packet)
    }
    setValidationErr([...collection])
  }

  async function handleSubmit (type) {
    if (price.special_price_from) {
      const format = new Date(price.special_price_from)
      price.special_price_from = dayjs(format).format('YYYY-MM-DD HH:mm')
    }
    if (price.special_price_to) {
      const format = new Date(price.special_price_to)
      price.special_price_to = dayjs(format).format('YYYY-MM-DD HH:mm')
    }

    const body = {
      ...product,
      attributes: attributesSelected.additional_attributes || [],
      attribute_variants: attributesSelected.attribute_variants || [],
      category: categorySelected,
      express_courier: expressCarrierSelected,
      prices: price,
      images: imageCollections,
      supplier_alias: supplierAlias,
      supplier_id: supplierId
    }
    let isValid = await insertProductSchema.isValid(body)
    if (!isEmpty(imageCollections)) {
      const filter = imageCollections.filter(element => element.angle === 1).length
      if (filter !== 1) {
        isValid = false
        notifyError('Foto Utama tidak boleh kosong')
      }
    }
    if (isValid) {
      if (type === 'single') {
        fetchInsertProduct(body, setIsLoading, null, setSuccess)
      } else if (type === 'another') {
        fetchInsertProduct(body, setIsLoading, null, setSuccessRepeat)
      }
    } else {
      const errorMessage = await insertProductSchema.validate(body).catch(function (err) {
        if (err.errors.length > 0) {
          return err.errors[0]
        }
      })
      notifyError(errorMessage)
    }
  }

  useEffect(() => {
    if (success.success) {
      history.push(`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}&supplier_id=${supplierId}&status=`)
      setSuccess({ success: false, data: {} })
    }
    if (successRepeat.success) {
      // need to wait for the notify out first before reloading
      setTimeout(() => {
        history.go(0)
      }, 1000)
      setSuccessRepeat({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, successRepeat, history, storeCode, supplierAlias])

  useEffect(() => {
    fetchGetCategories({ level: '2' }, setCategoryListLvl1, setIsLoading, null) // start from category level 2-5
    fetchGetExpressCarriers({ carrier_type: 'instant,sameday' }, setExpressCarrierList, setIsLoading, null)
    fetchGetBrands({}, setBrandList, setIsLoading, null)
    // todo: change to the user redux
    const supplierID = user?.user?.role_id !== 2 ? supplierId : user?.supplier?.supplier_id
    fetchGetOwnfleetTemplate({ supplier_id: supplierID }, setOwnfleetList, setIsLoading, null)
    // return () => {
    //   setProduct({ is_sparepart: 0, is_accessories: 0, packaging_uom: 'CM', warranty_contributor: '0' })
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderComponent () {
    return (
      <>
        {categoryListLvl1 && brandList && categorySelected &&
          <ProductInformationForm
            categoryListLvl1={categoryListLvl1}
            categoryListLvl2={categoryListLvl2}
            categoryListLvl3={categoryListLvl3}
            categoryListLvl4={categoryListLvl4}
            categorySelected={categorySelected}
            handleCategoryLevel={handleCategoryLevel}
            product={product}
            brandList={brandList}
            isLoading={isLoading}
            setProduct={setProduct}
            method='add'
          />}
        <ProductVariantInformationForm
          setProduct={setProduct}
          product={product}
          method='add'
        />
        <ProductMetaInformationForm
          setProduct={setProduct}
          product={product}
          method='add'
        />
        <ProductDescriptionForm
          setProduct={setProduct}
          product={product}
          method='add'
        />
        <ProductStockAndPriceForm
          price={price}
          handleSetPrice={handleSetPrice}
          setProduct={setProduct}
          method='add'
        />
        <ProductWeightAndDeliveryForm
          ownfleetList={ownfleetList}
          expressCarrierList={expressCarrierList}
          expressCarrierSelected={expressCarrierSelected}
          handleCheckboxClick={handleCheckboxClick}
          product={product}
          supplierId={supplierId}
          setProduct={setProduct}
          method='add'
        />
        <ProductAdditionalInformationForm
          warrantyUnitsValue={warrantyUnitsValue}
          attributeList={attributeList}
          warrantyLabel={warrantyLabel}
          checkedAttributes={checkedAttributes}
          handleSetAttribute={handleSetAttribute}
          handleCheckboxClick={handleCheckboxClick}
          setProduct={setProduct}
          product={product}
          method='add'
        />
      </>
    )
  }
  return (
    isMobileView
      ? (
        <InsertProductFormMobile
          storeCode={storeCode}
          supplierAlias={supplierAlias}
          supplierId={supplierId}
          handleImage={handleImage}
          handleValidationErr={handleValidationErr}
          validationErr={validationErr}
          renderComponent={renderComponent}
          handleSubmit={handleSubmit}
          fetching={isLoading}
          angles={angle}
        />
        )
      : (
        <InsertProductFromDesktop
          storeCode={storeCode}
          supplierAlias={supplierAlias}
          supplierId={supplierId}
          handleImage={handleImage}
          handleValidationErr={handleValidationErr}
          validationErr={validationErr}
          renderComponent={renderComponent}
          handleSubmit={handleSubmit}
          fetching={isLoading}
          angles={angle}
        />
        )
  )
}
