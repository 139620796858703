import React, { useContext, useState } from 'react'
import { ScaleContext } from '../Context/ScaleContext'
import { notifyError } from '../Services/Notify'

export default function SearchSellerList ({ keyword, handleParamsChange }) {
  // Uncomment this if you want to use is mobile trigger
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [keywords, setKeywords] = useState({ keyword: keyword })

  function handleSearch (e) {
    const { name, value } = e.target
    if (name === 'keyword') {
      setKeywords({ [name]: value })
    }
  }

  function handleKeyword (keyword) {
    if (keyword?.length < 1) {
      notifyError('Nama seller tidak boleh kosong')
    } else {
      const paramsChange = { keyword }
      handleParamsChange(paramsChange)
    }
  }

  function onKeyDown (e) {
    if (e.key === 'Enter') {
      const { value } = e.target
      handleKeyword(value)
    }
  }
  const renderMobile = () => {
    return (
      <div className='input-bar' style={{ width: '100%' }}>
        <input className='fs--s' name='keyword' placeholder='Cari store' type='text' onChange={(e) => handleSearch(e)} defaultValue={keywords.keyword} onKeyDown={(e) => onKeyDown(e)} />
        <button className='search-btn-product' type='submit' onClick={() => handleKeyword(keywords)}><i className='anticon icon-search1' /></button>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className='row pl-4'>
        <div className='input-bar input-bar-sellerList'>
          <input className='fs--s' name='keyword' placeholder='Cari store' type='text' onChange={(e) => handleSearch(e)} defaultValue={keywords.keyword} onKeyDown={(e) => onKeyDown(e)} />
          <button className='search-btn-product' type='submit' onClick={() => handleKeyword(keywords)}><i className='anticon icon-search1' /></button>
        </div>
      </div>
    )
  }

  // todo akan berubah jika width layar kurang dari 992px
  return isMobileView ? renderMobile() : renderDesktop()
}
