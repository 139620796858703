import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserActions from '../Redux/UserRedux'
import { Link } from 'react-router-dom'
import backgroundImage from '../static/images/seller-center.svg'

import { loginSchema } from '../Validations/Login'

export default function LoginPage () {
  const user = useSelector(state => state.user)
  const [values, setValues] = useState({ })
  const [err, setErr] = useState({ err: false, message: '' })
  const dispatch = useDispatch()

  const handleOnChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const isValid = await loginSchema.isValid(values)

    if (isValid) {
      setErr({ err: false, message: '' })
      dispatch(UserActions.userRequest(values))
    } else {
      const errorMessage = await loginSchema.validate(values).catch(function (err) {
        return err.errors[0]
      })
      setErr({ err: true, message: errorMessage })
    }
  }

  return (
    <div className='container-fluid'>
      <div className='row align-items-center' style={{ height: window.innerHeight }}>
        <img className='background-login' alt='background' src={backgroundImage} />
        <div className='col-12 col-lg-5 align-items-center px-5'>
          <div className='px-5'>
            <div className='login-title'>Welcome to Seller Center!</div>
            <h2 className='py-3'>Please login to your account</h2>
            <form onSubmit={(e) => onSubmit(e)} method='GET'>
              <div className='py-2  '>
                <div className='input-text-bar'>
                  <input placeholder='Email' type='text' name='email' onChange={(e) => handleOnChange(e)} />
                </div>
              </div>
              <div className='py-3'>
                <div className='input-text-bar'>
                  <input placeholder='Password' type='password' name='password' onChange={(e) => handleOnChange(e)} />
                </div>
              </div>
              <div className='my-3 fs--s text-left  error'>
                {user.err || (err.err && err.message)}
              </div>
              <Link to='/login?page=forgot-password&type=forgot-password'>
                <div className='mb-4 fs--m text-right font-weight-bold link'>
              Forgot Password?
                </div>
              </Link>
              <button onClick={onSubmit} disabled={user.fetching} className='btn btn-m btn-full btn-primary'>
                {user.fetching ? 'Loading ...' : 'Log In'}
              </button>
            </form>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  )
}
