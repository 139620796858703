import { startCase } from 'lodash'
import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { ScaleContext } from '../../../Context/ScaleContext'

function SeeItemsListsModal ({ invoice, onClickedModal, handleToogleModal, status }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  function renderItems (data, idx) {
    let show = false
    if (invoice.shipment.shipment_status !== 'new' && invoice.shipment.shipment_status !== 'processing' && status !== 'pending') {
      // if (data.status_fulfillment === 'complete') {
      show = true
      // } else show = false
    } else {
      show = true
    }
    if (show) {
      return (
        <tr key={idx}>
          <td width='15%'>{data.sku}</td>
          <td width='50%%'>{data.name}</td>
          <td width='5%' className='text-center'>{data.qty_ordered}</td>
          <td width='10%' className={data.status_fulfillment + ' text-center'}><b>{startCase(data.status_fulfillment)}</b></td>
        </tr>
      )
    } else return null
  }

  if (onClickedModal.seeProductListModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('seeProductListModal', 'close')}
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold pt-4 px-5'>Daftar Barang {invoice.invoice_no}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container pt-3 pb-3'>
            <table className={`table table-borderless ${isMobileView ? 'fs--s' : 'fs--m'}`}>
              <thead>
                <tr className=' my-auto'>
                  <th className='' width='15%'>SKU</th>
                  <th className='' width='50%'>Nama Barang</th>
                  <th className='text-center'>Qty</th>
                  <th className='text-center'>Status</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.length > 0
                  ? invoice.items.map((data, idx) => {
                      return renderItems(data, idx)
                    })
                  : (
                    <tr className='text-center my-3'>
                      <td colSpan={3}>Tidak ada barang</td>
                    </tr>
                    )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  } else return null
}

export default SeeItemsListsModal
