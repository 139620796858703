import React from 'react'
import { Modal } from 'react-bootstrap'
import InfoBox from '../../InfoBox'
import ThumbnailProduct from '../ThumbnailProduct'

export default function StatusProduct ({
  product,
  fetching,
  error,
  handleToggleUpdateStatusModal,
  handleUpdateStatusProduct
}) {
  return (
    <Modal
      show
      size='update'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => handleToggleUpdateStatusModal('close')}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--xm font-weight-bold p-3'>{(product.status === 10) ? 'Mengnonaktifkan' : 'Mengaktifkan'} Produk</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-container pb-3'>
          <div className='text-center'>Apakah Anda yakin ingin <b>{(product.status === 10) ? 'menonaktifkan' : 'mengaktifkan'}</b> produk ini?</div>
        </div>
        <div className='modal-container pb-2'>
          <ThumbnailProduct product={product} />
          {error.err && (
            <div className='row spacing-add-product'>
              <div className='col-12'>
                <InfoBox
                  text={error.message}
                  icon='danger'
                  status='danger'
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <div className='modal-container pb-3'>
        <Modal.Footer className=' justify-content-center text-center'>
          <div className='col'>
            <button type='button' onClick={() => handleToggleUpdateStatusModal('close')} className='btn-ghost-primary btn-m btn-full'>Batal</button>
          </div>
          <div className='col'>
            <button type='button' onClick={() => handleUpdateStatusProduct()} className='btn-primary btn-m btn-full' disabled={fetching}>{fetching ? 'Mohon Menunggu' : 'Konfirmasi'}</button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
