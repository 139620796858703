import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ProductListDesktop from '../../../Components/Products/List/ProductListDesktop'
import ProductListMobile from '../../../Components/Products/List/ProductListMobile'
import { ScaleContext } from '../../../Context/ScaleContext'
import { fetchGetListProduct, fetchGetProfileInfo } from '../../../Services/FetchingGroup'

import BulkUploadModal from '../../Modals/Product/BulkUploadModal'
import DeleteProductModal from '../../Modals/Product/DeleteProductModal'
import SetCommissionModal from '../../Modals/Product/SetCommissionModal'
import UpdateStatusModal from '../../Modals/Product/UpdateStatusModal'
import UpdatePriceModal from '../../Modals/Product/UpdatePriceModal'
import UpdateStockModal from '../../Modals/Product/UpdateStockModal'
import { isEmpty } from 'lodash'

export default function ProductListGroup ({ storeCode, supplierAlias, status, sortBy, sortType, supplierId }) {
  const user = useSelector(state => state.user)
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext

  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  const [onClickedBulkModal, setOnClickedBulkModal] = useState(false)
  const [onClickedUpdatePriceModal, setOnClickedUpdatePriceModal] = useState(false)
  const [onClickedUpdateStatusModal, setOnClickedUpdateStatusModal] = useState(false)
  const [onClickedUpdateStockModal, setOnClickedUpdateStockModal] = useState(false)
  const [onClickedDeleteProductModal, setOnClickedDeleteProductModal] = useState(false)
  const [onClickedSetCommissionModal, setOnClickedSetCommissionModal] = useState(false)

  const [productSelected, setProductSelected] = useState({})
  const [supplierDetail, setSupplierDetail] = useState()
  const [params, setParams] = useState({
    limit: '10',
    page: 1,
    keyword: '',
    supplier_alias: supplierAlias,
    store_code: storeCode,
    status: status,
    count: false,
    sort_by: sortBy || 'created_at',
    sort_type: sortType || 'desc'
  })
  const [listProduct, setListProduct] = useState()
  const [countProduct, setCountProduct] = useState()
  const sortList = [{ id: 0, type: 'desc', name: 'Newer', by: 'created_at' }, { id: 1, type: 'asc', name: 'Older', by: 'created_at' }]
  const [filterList, setfilterList] = useState([{ id: 0, value: '', count: 0, name: 'Semua Produk' }, { id: 1, value: 'approved', count: 0, name: 'Approved Produk' }, { id: 2, value: 'pending', count: 0, name: 'Pending Produk' }])

  function handleChangeParams (paramsModified) {
    const newParams = { ...params, ...paramsModified }
    if (params !== newParams) {
      setParams(newParams)
    }
    fetchGetListProduct(newParams, setListProduct, setIsLoading, setErr)
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      handleChangeParams({ page: selected + 1 })
    }
  }

  function handleSelect (e, list) {
    const { value, name } = e.target
    const filter = list.filter(element => element.id === parseInt(value))
    if (name === 'sort') {
      if (filter?.length > 0) {
        handleChangeParams({ sort_by: filter[0].by, sort_type: filter[0].type, page: 1 })
      } else return null
    } else if (name === 'filter') {
      if (filter?.length > 0) {
        handleChangeParams({ status: filter[0].value, page: 1 })
      } else return null
    }
  }

  useEffect(() => {
    // setRes({ isLoading: true })
    let paramsModified = params
    if (storeCode) {
      paramsModified = { ...paramsModified, store_code: storeCode }
    }
    if (supplierAlias) {
      paramsModified = { ...paramsModified, supplier_alias: supplierAlias }
    }
    if (status) {
      paramsModified = { ...paramsModified, status: status || '' }
    }
    if (sortType) {
      paramsModified = { ...paramsModified, sort_type: sortType }
    }
    if (sortBy) {
      paramsModified = { ...paramsModified, sort_by: sortBy }
    }

    if (supplierId && user.user?.role_id !== 2) {
      const params = { supplier_id: parseInt(supplierId) !== 0 ? parseInt(supplierId) : '' }
      fetchGetProfileInfo(params, setSupplierDetail, setIsLoading, setErr)
    }
    // get Count
    fetchGetListProduct({ ...paramsModified, count: true }, setCountProduct, setIsLoading, setErr)
    handleChangeParams(paramsModified)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user.user?.role_id !== 2) {
      if (storeCode !== params.store_code) {
        handleChangeParams({ store_code: storeCode })
      }
      if (supplierAlias !== params.supplier_alias) {
        handleChangeParams({ supplier_alias: supplierAlias })
      }
    }
    // if its set by link
    if (sortType && sortType !== params.sort_type) {
      handleChangeParams({ sort_type: sortType })
    }
    if (sortBy && sortBy !== params.sort_by) {
      handleChangeParams({ sort_by: sortBy })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierAlias, storeCode, sortType, sortBy])

  useEffect(() => {
    if (!isEmpty(countProduct)) {
      const collection = [...filterList]
      collection.forEach(element => {
        if (element.value === 'approved') {
          element.count = (countProduct?.approved || 0)
        } else if (element.value === 'pending') {
          element.count = (countProduct?.pending || 0)
        } else if (element.value === '') {
          element.count = (countProduct?.approved || 0) + (countProduct?.pending || 0) + (countProduct?.discontinued || 0)
        } else return null
      })
      setfilterList(collection)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countProduct])
  /* ===================== START ===================== */
  // All handle for Modal

  const handleToggleBulkUploadModal = (state) => {
    if (state === 'open') {
      setOnClickedBulkModal(true)
    } else {
      setOnClickedBulkModal(false)
    }
  }

  const handleToggleUpdatePriceModal = (state, payload) => {
    if (state === 'open') {
      setProductSelected(payload)
      setOnClickedUpdatePriceModal(true)
    } else {
    // todo: triggering fetching product list
      setOnClickedUpdatePriceModal(false)
    }
  }

  const handleToggleUpdateStatusModal = (state, payload) => {
    if (state === 'open') {
      setProductSelected(payload)
      setOnClickedUpdateStatusModal(true)
    } else {
    // todo: triggering fetching product list
      setOnClickedUpdateStatusModal(false)
    }
  }

  const handleToggleUpdateStockModal = (state, payload) => {
    if (state === 'open') {
      setProductSelected(payload)
      setOnClickedUpdateStockModal(true)
    } else {
    // todo: triggering fetching product list
      setOnClickedUpdateStockModal(false)
    }
  }

  const handleToggleDeleteProductModal = (state, payload) => {
    if (state === 'open') {
      setProductSelected(payload)
      setOnClickedDeleteProductModal(true)
    } else {
    // todo: triggering fetching product list
      setOnClickedDeleteProductModal(false)
    }
  }

  const handleToggleSetCommissionModal = (state, payload) => {
    if (state === 'open') {
      setProductSelected(payload)
      setOnClickedSetCommissionModal(true)
    } else {
      setOnClickedSetCommissionModal(false)
    }
  }

  function renderModal () {
    return (
      <>
        {onClickedBulkModal &&
      (
        <BulkUploadModal
          params={params}
          handleChangeParams={handleChangeParams}
          onClickedBulkModal={onClickedBulkModal}
          handleToggleBulkUploadModal={handleToggleBulkUploadModal}
        />
      )}
        {onClickedUpdatePriceModal &&
      (
        <UpdatePriceModal
          params={params}
          handleChangeParams={handleChangeParams}
          onClickedUpdatePriceModal={onClickedUpdatePriceModal}
          handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
          product={productSelected}
        />

      )}
        {onClickedUpdateStatusModal &&
        (
          <UpdateStatusModal
            params={params}
            handleChangeParams={handleChangeParams}
            onClickedUpdateStatusModal={onClickedUpdateStatusModal}
            handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
            product={productSelected}
          />
        )}
        {
          onClickedUpdateStockModal &&
          (
            <UpdateStockModal
              params={params}
              handleChangeParams={handleChangeParams}
              onClickedUpdateStockModal={onClickedUpdateStockModal}
              handleToggleUpdateStockModal={handleToggleUpdateStockModal}
              product={productSelected}
            />
          )
        }
        {onClickedDeleteProductModal &&
        (
          <DeleteProductModal
            params={params}
            handleChangeParams={handleChangeParams}
            onClickedDeleteProductModal={onClickedDeleteProductModal}
            handleToggleDeleteProductModal={handleToggleDeleteProductModal}
            product={productSelected}
          />
        )}
        {onClickedSetCommissionModal &&
        (
          <SetCommissionModal
            params={params}
            handleChangeParams={handleChangeParams}
            onClickedSetCommissionModal={onClickedSetCommissionModal}
            handleToggleSetCommissionModal={handleToggleSetCommissionModal}
            product={productSelected}
          />
        )}
      </>
    )
  }

  /* ===================== END ===================== */

  if (isMobileView) {
    return (
      <ProductListMobile
        listProduct={listProduct}
        params={params}
        fetching={isLoading}
        error={err}
        supplierDetail={supplierDetail}
        supplierId={supplierId}
        sortList={sortList}
        filterList={filterList}
        renderModal={renderModal}
        handleSelect={handleSelect}
        handleToggleBulkUploadModal={handleToggleBulkUploadModal}
        handlePageClick={handlePageClick}
        handleChangeParams={handleChangeParams}
        handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
        handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
        handleToggleUpdateStockModal={handleToggleUpdateStockModal}
        handleToggleDeleteProductModal={handleToggleDeleteProductModal}
        handleToggleSetCommissionModal={handleToggleSetCommissionModal}
      />
    )
  } else {
    return (
      <ProductListDesktop
        listProduct={listProduct}
        params={params}
        fetching={isLoading}
        error={err}
        supplierDetail={supplierDetail}
        supplierId={supplierId}
        sortList={sortList}
        filterList={filterList}
        renderModal={renderModal}
        handleSelect={handleSelect}
        handlePageClick={handlePageClick}
        handleChangeParams={handleChangeParams}
        handleToggleBulkUploadModal={handleToggleBulkUploadModal}
        handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
        handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
        handleToggleUpdateStockModal={handleToggleUpdateStockModal}
        handleToggleDeleteProductModal={handleToggleDeleteProductModal}
        handleToggleSetCommissionModal={handleToggleSetCommissionModal}
      />
    )
  }
}
