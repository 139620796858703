// import React, { useState, Fragment, useEffect, useContext } from 'react'
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../Components/Behaviors/Loading'
import SearchSellerList from '../Components/SearchSellerList'
import SellerList from '../Components/SellerList'
import { ScaleContext } from '../Context/ScaleContext'
import { fetchGetSellerList } from '../Services/FetchingGroup'
import Pagination from './Pagination'

export default function SellerListDetail ({ keyword, status, time }) {
  const [sellerParameter, setSellerParameter] = useState({ size: '15', keyword: keyword, status: status, page: 1 })
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)

  const [res, setRes] = useState()
  // todo: move this to fetching Group

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...sellerParameter, ...paramsModified
    }
    setSellerParameter(paramsChange)
    fetchGetSellerList(paramsChange, setRes, setIsLoading, setErr)
  }

  useEffect(() => {
    if (time && time !== '') {
      const param = { timespan: time }
      handleParamsChange(param)
    } else {
      handleParamsChange(sellerParameter)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (keyword && keyword !== '') {
      const param = { keyword: keyword }
      handleParamsChange(param)
    }
    // eslint-disable-next-line
  }, [keyword])

  useEffect(() => {
    if (err.err) {
      console.log(err.message)
      setErr({ err: false })
    }
  }, [err])

  const handleOnChangeCondition = (e) => {
    const { name, value } = e.target
    if (name === 'status') {
      handleParamsChange({ [name]: value, keyword: '', page: 1 })
    }
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    handleParamsChange({ page: selected + 1 })
  }

  // Uncomment this if you want to use is mobile trigger

  const renderMobile = () => {
    return (
      <div className='col'>
        <div className='seller-list-container'>
          <div className='row py-3 border-bottom '>
            <div className='col-12 pl-0 fs--m font-weight-bold text-center'>
              Seller List
              <span className='pl-5' style={{ float: 'left' }}>
                <Link to='/home'>
                  <span><i className='anticon icon-arrowleft' /></span>
                </Link>
              </span>
            </div>
          </div>
          <div className='row align-items-center justify-content-center'>
            <div className='col-12 py-4 border-bottom'>
              <SearchSellerList keyword={sellerParameter.keyword} handleParamsChange={handleParamsChange} />
            </div>
            <div className='col-12 mt-5 px-3'>
              {res?.total > 15 &&
                <Pagination
                  total={res?.total}
                  show={15}
                  classNames='pagination'
                  handlePageClick={handlePageClick}
                />}
            </div>
            <div className='mx-5'>
              {isLoading
                ? (
                  <div className='col-12 mt-5 px-3'>
                    <Loading type='alt' />
                  </div>)
                : (res?.suppliers?.map((supplier, idx) => {
                    return (
                      <SellerList
                        key={idx}
                        id={idx}
                        supplierId={supplier.supplier_id}
                        image={supplier.logo}
                        name={supplier.name}
                        status={supplier.status}
                      />
                    )
                  }
                  )
                  )}
            </div>
          </div>
        </div>
      </div>

    )
  }

  const renderDesktop = () => {
    return (
      <div className='col-12 col-lg offset-lg-2 '>
        <div className='seller-list-container'>
          <div className='row align-items-center header'>
            <div className='col-12 p-5 border-bottom'>
              <h2 className='font-weight-bold'>Seller List</h2>
            </div>
            <div className='pl-4 pr-4 col-12 mt-5'>
              <div className='row'>
                <div className='col-8'>
                  <SearchSellerList keyword={sellerParameter.keyword} handleParamsChange={handleParamsChange} />
                </div>
                <div className='col-3 ml-5'>
                  <div className='row align-items-center'>
                    <div className='col-2'>
                      <div className='text-right font-weight-bold fs--s '>Filter</div>
                    </div>
                    <div className='col'>
                      <div className='input-text-bar'>
                        <select name='status' className='input input-sellerList fs--s ' onChange={(e) => handleOnChangeCondition(e)} defaultValue={sellerParameter.status}>
                          <option value=''>Semua Status</option>
                          <option value={10}>Open</option>
                          <option value={0}>Temporary Closed</option>
                          <option value={-1}>Permanent Closed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-5'>
                  {res?.total > 15 &&
                    <Pagination
                      total={res?.total}
                      show={15}
                      classNames='pagination pull-right'
                      handlePageClick={handlePageClick}
                    />}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            {isLoading
              ? (
                <div className='col-12'>
                  <Loading type='alt' />
                </div>
                )
              : (res?.suppliers?.map((supplier, idx) => {
                  return (
                    <SellerList
                      key={idx}
                      id={idx}
                      supplierId={supplier.supplier_id}
                      image={supplier.logo}
                      name={supplier.name}
                      status={supplier.status}
                    />
                  )
                }
                )
                )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* [STARTS HERE] ====
      //todo akan berubah jika width layar kurang dari 992px
      */}
      {isMobileView ? renderMobile() : renderDesktop()}
      {/* [ENDS HERE] --- akan berubah jika width layar kurang dari 992px */}
    </>
  )
}
