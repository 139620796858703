import React from 'react'
import BaseQuery from '../Base/BaseQuery'
import DefaultLayout from '../Containers/DefaultLayout'
import Orders from '../Containers/Orders/Orders'

export default function Order (props) {
  const { menu } = props.match.params
  const keyword = BaseQuery().get('keyword')
  const supplier = BaseQuery().get('supplier')
  const supplierId = BaseQuery().get('supplier_id')
  const supplierName = BaseQuery().get('supplier_name')
  const carrier = BaseQuery().get('carrier')
  return (
    <DefaultLayout pages='order' menu={menu} supplierId={supplierId}>
      <Orders menu={menu} keyword={keyword} supplier={supplier} supplierId={supplierId} supplierName={supplierName} carrier={carrier} />
    </DefaultLayout>
  )
}
