import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { startCase } from 'lodash'

import { ScaleContext } from '../../../Context/ScaleContext'

function ShipmentFeeModal ({ invoice, onClickedModal, handleToogleModal, status }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  function renderItems (data, idx) {
    return (
      <tr key={idx}>
        <td width='15%'>{data.sku}</td>
        <td width='40%'>{data.name}</td>
        <td width='5%' className='text-center'>{data.qty_ordered}</td>
        <td width='15%' className='text-center'><b>{startCase(data.modified_description) || '-'}</b></td>
      </tr>
    )
  }

  if (onClickedModal.shipmentFeeModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('shipmentFeeModal', 'close')}
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold pt-4 px-5'>Daftar Promo Gratis Ongkir {invoice.invoice_no}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container pt-3 pb-3'>
            <table className={`table table-borderless ${isMobileView ? 'fs--s' : 'fs--m'}`}>
              <thead>
                <tr className=' my-auto'>
                  <th className='' width='15%'>SKU</th>
                  <th className='' width='40%'>Nama Barang</th>
                  <th className='text-center'>Qty</th>
                  <th className='text-center' width='25%'>Nama Promo</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.length > 0
                  ? invoice.items.map((data, idx) => {
                      return renderItems(data, idx)
                    })
                  : (
                    <tr className='text-center my-3'>
                      <td colSpan={3}>Tidak ada barang</td>
                    </tr>
                    )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  } else return null
}

export default ShipmentFeeModal
