import React, { useEffect, useState } from 'react'
import { isEmpty, isEqual } from 'lodash'
import { Modal } from 'react-bootstrap'

import Loading from '../../../Components/Behaviors/Loading'

import { fetchGetCarrierList, fetchShipmentBooking, fetchUpdateInvoice, fetchUpdateShipmentCarrier } from '../../../Services/FetchingGroup'
import config from '../../../config'

import { validationMpFlag } from '../../../Services/ValidationMpFlag'

export default function BulkPrintPackingSlipModal ({ invoice, invoices, handleToogleModal }) {
  const carrierAutoList = config.carrierAuto?.concat(config?.carrierGosend)
  const [checkedValues, setCheckedValues] = useState([])
  const [type, setType] = useState('select_invoice')

  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  const [invoiceCollection, setInvoiceCollection] = useState([])
  const [refetchBooking, setRefetchBooking] = useState({ state: false, message: '' })
  const [successUpdateInvoice, setSuccessUpdateInvoice] = useState({ success: false, data: {} })
  const [successShipmentCarrier, setSuccessShipmentCarrier] = useState({ success: false, data: {} })
  const [successShipmentBooking, setSuccessShipmentBooking] = useState({ success: false, data: {} })
  const [itemChecked, setItemChecked] = useState(false)
  const [carriers, setCarriers] = useState()
  const [carrierAuto, setCarrierAuto] = useState()
  const [choosenCarrier, setChoosenCarrier] = useState()

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedValues([...checkedValues, parseInt(value)])
    } else {
      setCheckedValues(checkedValues.filter(item => item !== parseInt(value)))
    }
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target
    const collection = []
    if (checked && invoices) {
      invoices.map((invoice, key) => {
        return collection.push(invoice.invoice_id)
      })
    }
    setCheckedValues(collection)
    setItemChecked(checked)
  }

  useEffect(() => {
    if (invoices && !isEmpty(invoices)) {
      if (isEqual(checkedValues.length, invoices.length)) {
        setItemChecked(true)
      } else {
        setItemChecked(false)
      }
    }
  }, [checkedValues, invoices])

  const init = () => {
    setCheckedValues([])
    setItemChecked(false)
  }

  const handleOnChangeCarrier = (e, status) => {
    const { value } = e.target
    if (status === 'lvl1') {
      setCarrierAuto(parseInt(value))
      if (value !== 0) {
        setChoosenCarrier(parseInt(value))
      }
    } else if (status === 'lvl2') {
      setChoosenCarrier(parseInt(value))
    }
  }

  function handleChooseDelivery () {
    const initCollection = checkedValues
    const modCollection = []
    const collection = []

    setErr({ err: false, message: '' })

    initCollection.forEach(invoiceId => {
      invoices.some(invoice => {
        if (invoice?.invoice_id === invoiceId) {
          const body = {
            invoice_id: invoiceId,
            carrier_id: choosenCarrier
          }
          // check for gosend because it didn't need to update shipment carrier anymore
          if (![7, 8].includes(invoice.shipment?.carrier?.carrier_id)) {
            collection.push(body)
            return modCollection.push(body)
          } else {
            body.carrier_id = invoice.shipment?.carrier?.carrier_id
            return collection.push(body)
          }
        } else return false
      })
    })

    setInvoiceCollection(collection)
    const body = {
      invoices: modCollection
    }
    fetchUpdateShipmentCarrier(body, setIsLoading, setErr, setSuccessShipmentCarrier)
  }

  function handleShipmentBooking (filter) {
    const collection = []

    for (let i = 0; i < filter?.length; i++) {
      collection.push(filter[i]?.invoice_id)
    }

    setRefetchBooking({ state: false, message: '' })
    const body = {
      invoices: collection
    }
    fetchShipmentBooking(body, setIsLoading, setErr, setSuccessShipmentBooking)
  }

  useEffect(() => {
    if (type === 'choose_delivery') {
      const initCollection = checkedValues
      const collection = []
      initCollection.forEach(invoiceId => {
        invoices.some(invoice => {
          if (invoice?.invoice_id === invoiceId) {
            const body = {
              invoice_id: invoiceId,
              carrier_id: invoice.shipment?.carrier?.carrier_id
            }
            // check for gosend because it didn't need to update shipment carrier anymore
            if ([7, 8].includes(body.carrier_id)) {
              return collection.push(body)
            } else return false
          } else return false
        })
      })
      if (collection?.length === checkedValues?.length) {
        setInvoiceCollection(collection)
        handleShipmentBooking(collection)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (successShipmentCarrier.success) {
      const collectionUpdated = []
      const filter = invoiceCollection.filter(e => carrierAutoList.includes(e.carrier_id))

      if (filter.length > 0) {
        handleShipmentBooking(filter)
      } else {
        const filterCarrier = invoiceCollection.filter(e => !carrierAutoList.includes(e.carrier_id))
        if (filterCarrier.length > 0) {
          for (let i = 0; i < filterCarrier.length; i++) {
            collectionUpdated.push(filterCarrier[i].invoice_id)
          }
        }
        const bodyUpdate = {
          invoices: collectionUpdated,
          status: 'ready_to_ship'
        }
        fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, setSuccessUpdateInvoice)
      }
      setSuccessShipmentCarrier({ success: false, data: {} })
    }

    if (successShipmentBooking.success) {
      const data = successShipmentBooking?.data
      const collectionUpdated = []
      // Need only 1 invoices that already have booking code
      const filterAutoCarrier = invoiceCollection.filter(e => carrierAutoList.includes(e.carrier_id))
      const filterCarrier = invoiceCollection.filter(e => !carrierAutoList.includes(e.carrier_id))

      if (filterCarrier.length > 0) {
        for (let i = 0; i < filterCarrier.length; i++) {
          collectionUpdated.push(filterCarrier[i].invoice_id)
        }
      }
      if (filterAutoCarrier.length > 0) {
        for (let i = 0; i < filterAutoCarrier.length; i++) {
          for (let j = 0; j < data?.invoices?.length; j++) {
            if (data?.invoices[j]?.invoice_id === filterAutoCarrier[i]?.invoice_id && data?.invoices[j]?.is_awb_generated === true) {
              collectionUpdated.push(filterAutoCarrier[i].invoice_id)
            }
          }
        }
      }

      if (collectionUpdated?.length > 0) {
        const bodyUpdate = {
          invoices: collectionUpdated,
          status: 'ready_to_ship',
          type: 'bulk'
        }
        fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, setSuccessUpdateInvoice)
      } else {
        setRefetchBooking({ state: true, message: 'Try to refetch the booking code again to continue' })
      }
      setSuccessShipmentBooking({ success: false, data: {} })
    }

    if (successUpdateInvoice.success) {
      window.location.replace(config.baseURL + '/print?type=packing_slip&status=ready_to_ship&invoice_id=' + checkedValues.toString())
      setSuccessUpdateInvoice({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successShipmentCarrier, successShipmentBooking, successUpdateInvoice])

  useEffect(() => {
    fetchGetCarrierList({}, setCarriers, setIsLoading, setErr)
    // eslint-disable-next-line
  }, [])

  function handleRender () {
    switch (type) {
      case 'select_invoice':
        return (
          <table className='table table-layout table-layout__multiple-order'>
            <thead>
              <tr className='fs--s my-auto'>
                <th className='' width='1%'>
                  <label className='container-checkbox'>
                    <input
                      type='checkbox'
                      checked={itemChecked}
                      onChange={(e) => handleSelectAll(e)}
                    />
                    <span className='checkbox' />
                  </label>
                </th>
                <th className='' width='5%'>Order ID</th>
                <th className=''>Invoice ID</th>
                <th className=''>Invoice Date</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, idx) => {
                return (
                  <tr key={idx} className='fs--s'>
                    <td>
                      <label className='container-checkbox'>
                        <input
                          type='checkbox'
                          value={invoice.invoice_id}
                          checked={checkedValues.includes(invoice.invoice_id)}
                          onChange={(e) => handleCheckboxClick(e)}
                        />
                        <span className='checkbox' />
                      </label>
                    </td>
                    <td width='3%'>{invoice.order_no}</td>
                    <td width='5%'>{invoice.invoice_no}</td>
                    <td width='8%'>{invoice.created_at}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )
      case 'choose_delivery':
        return (
          <>
            <div className='row pl-5 mr-5 d-flex'>
              <div className='col-6 col-lg-5 fs--s font-weight-bold px-5 my-auto'>Pilih metode delivery</div>
              {refetchBooking.state
                ? (
                  <div>
                    <button className='btn btn-s' onClick={() => handleShipmentBooking(invoiceCollection)}>
                      REFETCH BOOKING CODE
                    </button>
                    {/* it's not really an error,just for awb generate value is false which means the booking codes isn't ready
                  to continue the booking code need to be ready */}
                  </div>
                  )
                : isLoading
                  ? <center> <Loading type='bounce' /></center>
                  : (
                    <div className='col-6 col-lg-7'>
                      <div className='input-text-bar'>
                        <select disabled={isLoading} className='input fs--s ' defaultValue='' onChange={(e) => handleOnChangeCarrier(e, 'lvl1')}>
                          <option disabled value='' hidden>Pilih metode delivery</option>
                          {carriers?.carriers?.map((carrier, index) => {
                            // 6 JNE JOB          7 gosend instant
                            // 8 gosend sameday   9 NCS
                            if (config?.carrierAuto?.includes(carrier.carrier_id)) {
                              const show = validationMpFlag(carrier.carrier_id, invoice?.supplier?.mp_flag_shipment)
                              if (show) return <option key={index} value={carrier.carrier_id}>{carrier.carrier_name}</option>
                              else return null
                            } else return null
                          })}
                          <option value={0}>Lainnya</option>
                        </select>
                      </div>
                    </div>
                    )}
            </div>
            {carrierAuto === 0
              ? (
                <div className='row pl-5 mr-5 pt-5 d-flex'>
                  <div className='col-6 col-lg-5 fs--s font-weight-bold px-5 my-auto'>Masukkan nama 3PL</div>
                  <div className='col-6 col-lg-7'>
                    <div className='input-text-bar'>
                      <select className='input fs--s ' defaultValue={-1} onChange={(e) => handleOnChangeCarrier(e, 'lvl2')}>
                        <option disabled value={-1} hidden>Nama 3PL</option>
                        {carriers?.carriers?.map((carrier, index) => {
                        // 6 JNE JOB          7 gosend instant
                        // 8 gosend sameday   9 NCS
                          if (!carrierAutoList.includes(carrier.carrier_id)) {
                            const show = validationMpFlag(carrier.carrier_id, invoice?.supplier?.mp_flag_shipment)
                            if (show) return <option key={index} value={carrier.carrier_id}>{carrier.carrier_name}</option>
                            else return null
                          } else return null
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                )
              : null}
            {err?.err && (
              <div className='row text-left'>
                <div className='col-12'>
                  <span className='fs--m error'>{err.message}</span>
                </div>
              </div>
            )}
          </>
        )

      default:
        break
    }
  }
  return (
    <Modal
      show
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => { handleToogleModal('bulkPrintPackingSlipModal', 'close'); init() }}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div className='fs--m font-weight-bold p-3'>Pilih order yang akan di print packing slip</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='container pt-3 pb-3'>
          {handleRender(type)}
        </div>
      </Modal.Body>
      <Modal.Footer className=' justify-content-center text-center m-2'>
        {type === 'select_invoice'
          ? (
            <div className='col-12'>
              <button onClick={() => setType('choose_delivery')} disabled={isEmpty(checkedValues)} type='button' className='btn-primary-border btn-full btn-m margin-top-m center'>Pilih Order yang akan di print</button>
            </div>
            )
          : type === 'choose_delivery'
            ? (
              <div className='col-12'>
                <button disabled={!choosenCarrier || isLoading} onClick={() => handleChooseDelivery()} type='button' className='btn-primary btn-m btn-full'>Pilih Delivery</button>
              </div>
              )
            : null}

      </Modal.Footer>
    </Modal>
  )
}
