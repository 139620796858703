import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { ScaleContext } from '../../../Context/ScaleContext'

import { useDropzone } from 'react-dropzone'
import { fetchGetMiscFile } from '../../../Services/FetchingGroup'
import Loading from '../../../Components/Behaviors/Loading'

export default function BulkUpdateProduct ({ handleFile, tab, initialFile, setError }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [file, setFile] = useState(initialFile)
  const [urlCsv, setUrlCsv] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const maxSize = 26214400 // 25 MB

  useEffect(() => {
    if (file !== initialFile) {
      handleFile(file)
      setFile(file)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  function handleDownload () {
    const params = {
      key: 'product-seller-batch/template-csv/MP_template_bulk_update.csv'
    }
    fetchGetMiscFile(params, setUrlCsv, setIsLoading, setError)
  }
  useEffect(() => {
    if (!isEmpty(urlCsv)) {
      window.open(urlCsv?.url)
      setUrlCsv({})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCsv])

  const { fileRejections, acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.csv ',
    maxFiles: 1,
    maxSize: maxSize,
    onDrop: acceptedFiles => {
      if (acceptedFiles[0]?.size > maxSize) {
        setFile({})
        setError({ err: true, message: 'Ukuran file lebih dari 25 MB ' })
        handleFile({ file: '', tab: tab })
      } else {
        if (!isEmpty(acceptedFiles)) {
          const acceptedFile = (acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })))
          const file = {
            file: acceptedFile,
            name: acceptedFiles[0].name,
            path: acceptedFiles[0].path,
            tab: tab
          }
          fileAccepted(acceptedFile)
          setFile(file)
        }
      }
    },
    multiple: false
  })

  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = e => {
      const file = {
        file: e.target.result,
        tab: tab
      }
      handleFile(file)
    }
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((error) => {
          return error.code === 'file-invalid-type' ? 'Tipe file harus .csv' : 'Ukuran file lebih dari 25 MB  '
        })
        setFile({})
        handleFile({ file: '', tab: tab })
        return setError({ err: true, message: errorMessage })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      return setError({ err: false, message: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const uploadProduct = () => {
    return (
      <div className={`pt-5 pb-5 ${isMobileView ? 'col-12 border text-left' : 'col-6 border-left'}`}>
        Upload template {tab} Product
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className={`dropzone-bulk-product ${isMobileView ? 'mt-3' : 'mt-5 m-4'}`}>
            <div className=' dropzone-space__bulk d-flex align-items-center justify-content-center'><i className='anticon icon-upload' /> Upload Template</div>
          </div>
        </div>
      </div>
    )
  }
  const downloadTemplate = () => {
    return (
      <div className={`pt-5 pb-5 ${isMobileView ? 'col-12 border text-left' : 'col-6 '}`}>
        {isLoading
          ? (<div className='text-center'><Loading type='bounce' /></div>)
          : (
            <>
              Download Template {tab} Product
              <div className='dropzone dropzone-bulk-product' onClick={() => handleDownload()}>
                <div className={`dropzone-bulk-product ${isMobileView ? 'mt-3' : 'mt-5 m-4'}`}>
                  <div style={isMobileView ? { height: '35px' } : {}} className='dropzone-space__bulk d-flex align-items-center justify-content-center'><i className='anticon icon-download' /> Download Template </div>
                </div>
              </div>
            </>
            )}
      </div>

    )
  }

  return (
    <div className='container fs--s '>
      <div className='row align-items-center text-center'>
        {downloadTemplate()}
        {uploadProduct()}
        <div className='col-6 offset-6 border-left'>
          {
            (!isEmpty(file)) &&
              <div className='container pb-4'>
                <a href={file} download={file.name}>{file.path}</a>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
