import React from 'react'

export default function InfoBox ({ text, icon, status }) {
  return (
    <div className={`info-box ${status} mb-4`}>
      {icon && (icon === 'info' ? <i className='anticon icon-infocirlceo' /> : icon === 'danger' ? <i className='anticon icon-close' /> : null)}
      {text && <span className='text' dangerouslySetInnerHTML={{ __html: text || '-' }} />}
    </div>
  )
}
