import React, { useEffect } from 'react'
// import './static/css/index.css'
// import './static/fonts/anticon/iconfont.css'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import UserActions from './Redux/UserRedux'

import Home from './Pages/Home'
import Product from './Pages/Product'
import ProductView from './Pages/ProductView'
import Order from './Pages/Order'
import Print from './Pages/Print'
import SellerList from './Pages/SellerList'
import Pending from './Pages/Pending'
import SettlementReport from './Pages/SettlementReport'
import Profile from './Pages/Profile'
import Login from './Pages/Login'
import CommentSeller from './Pages/CommentSeller'
import NoAccess from './Pages/NoAccess'

import Toast from 'react-notify-toast'

function App () {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  useEffect(() => {
    if (user.user?.role_id) {
      dispatch(UserActions.userRoleRequest({ role_id: user.user?.role_id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const accessDate = localforage.getItem('acces_date')

  //   localforage.getItem('access_date', function (err, value) {
  //     const expiration = moment() - moment(value)
  //     if (value) {
  //       if (parseInt(expiration) > 1000) {
  // localforage.removeItem('access_date')
  // localforage.removeItem('token_access')
  // dispatch(UserActions.userInit())
  //         window.location.replace('/expired-page')
  //       }
  //     }

  //     if (err) {
  //       console.log('TRY AGAIN LATER')
  //     }
  //   })
  // }, [])

  return (
    <>
      <Toast />
      {/* <ToasterComponent /> */}
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/home' component={Home} />
        <Route path='/product/:menu' component={Product} />
        <Route path='/product-view/:type' component={ProductView} />
        <Route path='/order/:menu' component={Order} />
        <Route path='/print' component={Print} />
        <Route path='/pending' component={Pending} />
        <Route path='/comment-seller' component={CommentSeller} />
        <Route path='/seller-list' component={SellerList} />
        <Route path='/settlement-report/:periodDetails' component={SettlementReport} />
        <Route path='/profile/:supplierId' component={Profile} />
        <Route path='/expired-page' component={NoAccess} />
        <Redirect from='/' to='/login' />
      </Switch>
    </>
  )
}

export default App
