import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// import { ScaleContext } from '../../Context/ScaleContext'

import { checkRole } from '../../Services/CheckRole'
import { fetchHandleRefund } from '../../Services/FetchingGroup'

export default function PendingStatusGroup ({ status, invoice }) {
  const user = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({ success: false, data: {} })

  // useEffect(() => {
  //   if (success.success) {
  //     setSuccess({ success: false, data: {} })
  //   }
  // }, [success])

  function handleRefund (e) {
    const body = {
      invoice
    }
    fetchHandleRefund(body, setIsLoading, null, setSuccess)
  }
  // NOTE!
  // with is_refund_processed (status) is true and invoice status partial_refund or full refund means refund already processed
  // if only status that true it means the orders is onprocess for refund
  // Seller can't do anything except they only saw the status of their order

  if (status) { // is_refund_processed
    if (invoice.invoice_status === 'partial_refund' || invoice.invoice_status === 'full_refund') {
      return (
        <>
          <div className='processed'><b>Selesai Refund</b></div>
          {checkRole({
            menu: 'pending',
            method: 'edit',
            attribute: '',
            yes: (access) => {
              return (
                <div>
                  <b>{invoice.credit_memo?.credit_memo_no || ''}</b>
                </div>
              )
            },
            no: (access) => { return null },
            userRules: user?.role
          })}
        </>
      )
    } else {
      return (
        <div className='onprocessed'><b>Proses Refund</b></div>
      )
    }
  } else {
    if (user?.role) {
      return checkRole({
        menu: 'pending',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          if (success.success) {
            return (
              <div className='onprocessed'> <b>Proses Refund</b> </div>
            )
          } else if (invoice.shipment.shipment_status === 'received' || invoice.status_fulfillment === 'batal') {
            return <button name='refund' disabled={isLoading} className='btn btn-secondary-border btn-s btn-full' onClick={(e) => handleRefund(e)} style={{ height: '100%' }}>{isLoading ? 'Mohon Menunggu' : 'Refund'}</button>
          } else {
            // Only Order that have shipment_status received that can make the credit memo for refund
            return (
              <div className='onprocessed'><b>Pastikan status orderan sudah mencapai received</b></div>
            )
          }
        },
        no: (access) => {
          return (
            <div className=''><b>Belum Refund</b></div>
          )
        },
        userRules: user?.role
      })
    }
  }
}
