// untuk mobile web
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '../../Components/Button'
import config from '../../config'
// import config from '../../config'
// import { Link } from 'react-router-dom'
import { ScaleContext } from '../../Context/ScaleContext'

import { checkRole } from '../../Services/CheckRole'

export default function ButtonOrderGroup (props) {
  const carrierAutoList = config.carrierAuto?.concat(config?.carrierGosend)
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const user = useSelector(state => state.user)
  const {
    invoice,
    handleToogleModal,
    handleToogleSingleListItem,
    handleTooglePrintPackingSlipPrint
  } = props

  const btnLihatDaftarBarangSecondary = () => {
    return (
      <Button
        name='Lihat Daftar Barang'
        action={() => handleToogleModal('seeProductListModal', 'open', invoice)}
        type='ghost-secondary'
        size='s'
        icon='icon-bars'
        addsOn='btn-full'
      />
    )
  }

  const btnCommentModal = () => {
    return (
      <Button
        name='Lihat Semua Komentar'
        action={() => handleToogleModal('commentModal', 'open', invoice)}
        type='ghost-secondary'
        size='s'
        addsOn='btn-full'
      />
    )
  }

  const btnPrintInvoice = (status) => {
    let statusName = status
    if (statusName === 'pending_delivered' || statusName === 'pending_received') {
      statusName = 'shipped'
    }
    return (
      <Link
        to={`/print?status=${statusName}&type=invoice&invoice_id=${invoice.invoice_id}`}
        className='btn btn-secondary-border btn-full btn-s'
      >
        Print Invoice&nbsp;
        <i className='anticon icon-printer' />
      </Link>
    )
  }

  const btnPrintPackingSlip = (type, name) => {
    return (
      <Button
        name={name}
        action={() => {
          if (name === 'received' || type === 'shipped' || (type === 'ready_to_ship' && name === 'Print Packing Slip')) {
            handleTooglePrintPackingSlipPrint('open', invoice)
          } else {
            handleToogleModal('printPackingSlipModal', 'open', invoice)
            // handleTooglePrintPackingSlipModal('open', invoice)
          }
        }}
        icon='icon-printer'
        type='secondary-border'
        size='s'
        addsOn='btn-full'
      />
    )
  }

  switch (invoice.shipment.shipment_status) {
    case 'new':
      return checkRole({
        menu: 'new_order',
        method: 'view',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row pt-3'>
              <div className='col-12'>
                <Button
                  name='Lihat Daftar Barang'
                  action={() => handleToogleSingleListItem(invoice)}
                  type='primary'
                  size='s'
                  addsOn='btn-full'
                />
              </div>
              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

    case 'processing':
      return checkRole({
        menu: 'processing',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row'>
              <div className='col-12 pt-2'>
                <Button
                  name='Cek kelengkapan Barang'
                  action={() => handleToogleModal('checkItemsModal', 'open', invoice)}
                  type='secondary'
                  size='s'
                  addsOn='btn-full'
                  icon='icon-bars'
                />
              </div>
              <div className='col-12 pt-2'>
                {btnLihatDaftarBarangSecondary()}
              </div>
              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

    case 'stand_by':
      return checkRole({
        menu: 'standby',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row'>
              <div className='col pt-2'>
                {btnPrintPackingSlip(invoice.shipment.shipment_status, 'Print Packing Slip')}
              </div>
              <div className='col pt-2'>
                {btnLihatDaftarBarangSecondary()}
              </div>
              <div className='col  pt-2'>
                {btnPrintInvoice(invoice.shipment.shipment_status)}
              </div>
              <div className='col-12 pt-2'>
                <Button
                  name='Cek kelengkapan Barang'
                  action={() => handleToogleModal('checkItemsModal', 'open', invoice)}
                  type='secondary'
                  size='s'
                  addsOn='btn-full'
                  icon='icon-bars'
                />
              </div>

              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

    case 'ready_to_ship':
      return checkRole({
        menu: 'ready_to_ship',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row'>
              {!(invoice.shipment.carrier.carrier_id === 7 || invoice.shipment.carrier.carrier_id === 8) &&
                <div className='col pt-2'>
                  {btnPrintPackingSlip(invoice.shipment.shipment_status, 'Ganti Delivery')}
                </div>}
              <div className='col pt-2'>
                {btnLihatDaftarBarangSecondary()}
              </div>
              <div className='col pt-2'>
                {btnPrintInvoice(invoice.shipment.shipment_status)}
              </div>
              <div className='col-12 pt-3'>
                {btnPrintPackingSlip(invoice.shipment.shipment_status, 'Print Packing Slip')}
              </div>
              {carrierAutoList.includes(invoice.shipment?.carrier?.carrier_id)
                ? (
                  <div className='col-12 py-2'>
                    <Button
                      name='Kode Booking'
                      action={() => handleToogleModal('bookingCodeModal', 'open', invoice)}
                      type='secondary-border'
                      size='s'
                      addsOn='btn-full'
                      icon='icon-barcode'
                    />
                  </div>
                  )
                : (
                  <div className='col-12 py-2'>
                    <Button
                      name=' Input AWB'
                      action={() => handleToogleModal('inputAwbModal', 'open', invoice)}
                      type='secondary-border'
                      size='s'
                      addsOn='btn-full'
                    />
                  </div>
                  )}
              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

    case 'shipped':
    case 'pending_delivered':
    case 'pending_received':
      return checkRole({
        menu: 'shipped',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row'>
              <div className='col-12 col-lg pt-3'>
                {btnLihatDaftarBarangSecondary()}
              </div>
              <div className='col-12 col-lg pt-3'>
                {btnPrintInvoice(invoice.shipment.shipment_status)}
              </div>
              <div className='col-12 col-lg pt-3'>
                {btnPrintPackingSlip(invoice.shipment.shipment_status, 'Print Packing Slip')}
              </div>
              {/* received button only appear if it's not automatic carrier */}
              {(!carrierAutoList.includes(invoice.shipment?.carrier?.carrier_id))
                ? ((user?.role?.role_id !== 2 && (invoice.shipment.shipment_status === 'pending_received' || invoice.shipment.shipment_status === 'pending_delivered')) || (user?.role?.role_id === 2 && invoice.shipment.shipment_status === 'shipped'))
                    ? (
                      <div className='col-12 pt-3'>
                        <Button
                          name='Received'
                          action={() => handleToogleModal('receivedModal', 'open', invoice)}
                          type='secondary-border'
                          size='s'
                          icon='icon-check'
                          addsOn='btn-full'
                        />
                      </div>
                      )
                    : null
                : null}
              {user?.role?.role_id === 2 && invoice?.shipment?.do_image &&
                <div className='col-12 pt-3'>
                  <Button
                    name='Bukti Input Awb'
                    action={() => handleToogleModal('receivedModal', 'open', invoice)}
                    type='primary'
                    size='s'
                    addsOn='btn-full'
                  />
                </div>}
              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

    case 'received':
      return checkRole({
        menu: 'received',
        method: 'view',
        attribute: '',
        yes: (access) => {
          return (
            <div className='row'>
              <div className='col'>
                {btnPrintPackingSlip(invoice.shipment.shipment_status, 'Print Packing Slip')}
              </div>
              <div className='col'>
                {btnLihatDaftarBarangSecondary()}
              </div>
              {isMobileView &&
                <div className='col-12 pt-2'>
                  {btnCommentModal()}
                </div>}
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })

      // Sama seperti newOrder
    default:
      if (isMobileView) {
        return (
          null
        )
      } else {
        return (
          <div className='row'>
            <div className='col-12'>
              <Button
                name='Lihat Daftar Barang'
                action={() => handleToogleSingleListItem(invoice)}
                type='primary'
                size='s'
                addsOn='btn-full'
              />
            </div>
            {isMobileView &&
              <div className='col-12 pt-2'>
                {btnCommentModal()}
              </div>}
          </div>
        )
      }
  }
}
