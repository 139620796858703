import dayjs from 'dayjs'
import React from 'react'
import Barcode from 'react-barcode'
import config from '../../config'

export default function PrintPackingSlip ({ invoices, supplierId, group, method }) {
  const carrierAutoList = config.carrierAuto?.concat(config?.carrierGosend)
  if (invoices && invoices.invoices.length > 0) {
    return invoices.invoices.map((invoice, index) => {
      let autoCarrier = false
      if (invoice.shipment?.track_number !== '' || invoice.shipment?.carrier?.carrier_id === 7 || invoice.shipment?.carrier?.carrier_id === 8 || invoice.shipment?.carrier?.carrier_id === 5 || invoice.shipment?.carrier?.carrier_id === 6) {
        autoCarrier = true
      }
      return (
        <div className='container-fluid filter print-font' key={index}>
          <div className='row py-5 fs--m'>
            <div className='col'>
              <img src={`${config.assetsURL}/images/logo_ruparupa.png`} alt='logo-print' />
            </div>
            <div className='col text-right'>
              {dayjs().locale('id').format('dddd, DD MMMM YYYY')}
            </div>
            <div className='col-12 text-center pt-3'>Order Number: {invoice.order_no}</div>
            {/* ============== FOR Carrier that have automatic booking code ============= */}
            {autoCarrier &&
              <table className='col-12 border border-bottom-0 border__size--medium border__color--primary'>
                <tbody>
                  <tr className='text-center'>
                    <td>
                      <div className='fs--xs pt-2'> Booking Number </div>

                      {invoice?.shipment?.track_number === ''
                        ? (
                          <div className='fs--m font-weight-bold'>
                            Booking code untuk {invoice?.order_no} gagal terbentuk. Silahkan coba kembali untuk melanjutkan proses order
                          </div>
                          )
                        : (carrierAutoList.includes(invoice?.shipment?.carrier?.carrier_id)) // NCS, JNE JOB
                            ? (
                              <div className='fs--xxl font-weight-bold'>
                                <Barcode value={invoice?.shipment?.track_number} format='CODE39' width={2} height={55} />
                              </div>
                              )
                            : (
                              <div className='fs--xxl font-weight-bold'>
                                {invoice?.shipment?.track_number}
                              </div>
                              )}
                    </td>
                  </tr>
                </tbody>
              </table>}
            {/*  ============== ==============  */}
            <table className='col-12 border border-bottom-0 border__size--medium border__color--primary'>
              <tbody>
                <tr className='text-center fs--s '>
                  <td>3PL: {invoice.shipment.carrier.carrier_name}</td>
                </tr>
              </tbody>
            </table>

            {/* Content Information -- START -- */}
            <table className='col-12 border border__size--medium border__color--primary'>
              <thead>
                <tr className='text-center'>
                  <th className='border-bottom border__size--small border__color--primary '>Shipper:</th>
                  <th className='border-left border-bottom border__size--small border__color--primary'>Recipient:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width='50%'>
                    <div className='pl-3'>
                      <div>{invoice.supplier.name}</div>
                      {/* <div>{invoice.supplier.dikirim_oleh}</div> */}
                      <div>{invoice.supplier.supplier_address.address}</div>
                      <div>T: {invoice.supplier.supplier_address.phone}</div>
                    </div>
                  </td>
                  <td width='50%' className='border-left border__size--small border__color--primary'>
                    <div className='pl-3'>
                      <div>{invoice.shipping_address.first_name}</div>
                      <div>{invoice.shipping_address.full_address}</div>
                      <div>{invoice.shipping_address.kecamatan.kecamatan_name}, {invoice.shipping_address.city.city_name}</div>
                      <div>{invoice.shipping_address.province.province_name}, {invoice.shipping_address.post_code}</div>
                      <div>{invoice.shipping_address.country.country_name}</div>
                      <div>T: {invoice.shipping_address.phone}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Content Information -- END -- */}

            <table className='col-12 border border-top-0 border__size--medium border__color--primary'>
              <thead>
                <tr className='border-bottom border__size--medium border__color--primary '>
                  <th className='border-left border__color--primary pl-3' width='5%'>SKU</th>
                  <th className='border-left border__color--primary pl-3' width='50%'>Name</th>
                  <th className='border-left border__color--primary pl-3' width='5%'>Qty</th>
                </tr>
              </thead>
              <tbody>

                {invoice.items.map((item, index) => {
                  if (item.status_fulfillment === 'complete') {
                    return (
                      <tr className='border-bottom border__size--small border__color--primary' key={index}>
                        <td className='border-left border__color--primary pl-3'>{item.sku}</td>
                        <td className='border-left border__color--primary pl-3'>{item.name}</td>
                        <td className='border-left border__color--primary pl-3'>{item.qty_ordered}</td>
                      </tr>
                    )
                  } else return null
                })}

                {/* Only for total weight --START-- */}
                {/* <tr>
                  <td colSpan='2' className='border-left border__color--primary text-right pr-3'>Total Weight</td>
                  <td colSpan='1' className='border-left border__color--primary text-center'>9 Kg</td>
                </tr> */}
                {/* Only for total weight --START-- */}
              </tbody>
            </table>
          </div>
          {invoices?.invoices?.length - 1 !== index && <hr className='my-5' />}
        </div>
      )
    })
  } else return null
}
