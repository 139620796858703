import React from 'react'

export default function NoAccessRules () {
  return (
    <div className='col-12 offset-lg-2 col-lg text-center'>
      <div className='row align-items-center justify-content-center' style={{ height: '400px' }}>
        <div className='col'>
          <span className='fs--m error'>Anda Tidak Memiliki Akses Untuk Melanjutkan</span>
        </div>
      </div>
    </div>
  )
}
