import { isEmpty, startCase } from 'lodash'
import config from '../config'

import API from './Api'
import { notifyError, notifySuccess } from './Notify'

/* Kumpulan link API */
const api = API.create()

const handleErrorMessage = (message, setErr) => {
  if (setErr !== null) {
    setErr({ err: true, message: message })
  }
  notifyError(message)
}

const message = { name: '', success: '', error: 'Terjadi kesalahan, ulangi beberapa saat lagi' }

// =======================================//
// ==  TEMPLATE FETCHING  ==
// =======================================//
//

const fetchingGet = async (params, setRes, setIsLoading, setErr, api, name) => {
  if (setIsLoading !== null) setIsLoading(true)
  try {
    const response = await (api)
    // if (!response.ok) {
    //   const errMsg = (response.data?.message || 'Terjadi kesalahan, ulangi beberapa saat lagi ')
    //   if (setErr !== null)setErr({ err: true, message: errMsg })
    // } else {
    if (response.status === 200) {
      if (!isEmpty(response.data?.errors)) {
        let errMsg = response.data.errors.message
        if (setErr !== null) {
          if (response.data.errors.title === 'Unauthorized') {
            errMsg = 'Anda tidak dapat mengakses data ini, pastikan Anda memeriksa kembali parameter yang dikirimkan sesuai dengan otoritas role Anda.'
          }
          handleErrorMessage(errMsg, setErr)
        }
      } else {
        if (setRes !== null) setRes(response.data.data)
        if (setErr !== null) setErr({ err: false, message: '' })
      }
    } else {
      const errMsg = `Error response status: ${response.status}, while trying to ${name}`
      if (setErr !== null) setErr({ err: true, message: errMsg })
    }
    // }
  } catch (error) {
    const errMsg = 'Terjadi kesalahan, ulangi beberapa saat lagi '
    if (setErr !== null) setErr({ err: true, message: errMsg })
  }
  if (setIsLoading !== null) setIsLoading(false)
}

const fetchingPostPut = async (body, setIsLoading, setErr, setSuccess, api, message) => {
  if (setIsLoading !== null) setIsLoading(true)
  try {
    const response = await (api)
    // if (!response.ok) {
    //   handleErrorMessage(message.error, setErr)
    // } else {
    if (response.status === 200) {
      if (!isEmpty(response.data?.errors)) {
        handleErrorMessage(response.data.errors.message, setErr)
      } else {
        if (setErr !== null) {
          setErr({ err: false, message: '' })
        }
        if (message.success !== '') {
          notifySuccess(startCase(message.success))
        }
        if (setSuccess !== null) {
          setSuccess({ success: true, data: response.data.data })
        }
      }
    } else {
      message.error = `Error response status: ${response.status}, while trying to ${message.name}`
      handleErrorMessage(message.error, setErr)
    }
    // }
  } catch (error) {
    handleErrorMessage(message.error, setErr)
  }
  if (setIsLoading !== null) setIsLoading(false)
}
// =======================================//
// ==  FORGOT PASSWORD  ==
// =======================================//
const fetchVerifyEmail = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchVerifyEmail'
  message.success = ''
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.checkVerifyEmail(body), message)
}
const fetchGenerateOtp = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchGenerateOtp'
  message.success = ''
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.generateOtp(body), message)
}
const fetchValidateOtp = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchValidateOtp'
  message.success = ''
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.validateOtp(body), message)
}

const fetchResetPassword = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchResetPassword'
  message.success = 'Password berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.resetPassword(body), message)
}
// =======================================//
// ==  HOME  ==
// =======================================//
const fetchGetSalesSummary = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSalesSummary(params), 'fetchGetSalesSummary')
}

const fetchGetSellerList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSupplierList(params), 'fetchGetSellerList')
}

const fetchGetDownloadCsvProduct = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getDownloadCsvProduct(params), 'fetchGetDownloadCsvProduct')
}

const fetchGetMiscFile = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getMiscKey(params), 'fetchGetMiscFile')
}

// =======================================//
// ==  PROFILE  ==
// =======================================//
const fetchGetProfileInfo = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSupplierInfo(params), 'fetchGetProfileInfo')
}
const fetchChangeSupplierStatus = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchCreateComment'
  message.success = 'Profile status berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.changeSupplierStatus(body), message)
}

const fetchChangePassword = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchChangePassword'
  message.success = 'Password berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.changePassword(body), message)
}

const fetchChangeSupplierInfo = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchChangeSupplierInfo'
  message.success = 'Profile berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.changeSupplierInfo(body), message)
}

const fetchGetProvinceList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getProvince(params), 'fetchGetProvinceList')
}

const fetchGetCityList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getCity(params), 'fetchGetCityList')
}

const fetchGetDistrictList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getDistrict(params), 'fetchGetDistrictList')
}

// =======================================//
// ==  ORDERS  ==
// =======================================//

const fetchGetInvoiceList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getInvoiceList(params), 'fetchGetInvoiceList')
}

const fetchCountInvoice = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getCountInvoice(params), 'fetchCountInvoice')
}

const fetchGetCommentList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getCommentList(params), 'fetchGetCommentList')
}

const fetchGetRejectReasonList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getRejectReasonList(params), 'fetchGetRejectReasonList')
}

const fetchCreateComment = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchCreateComment'
  message.success = 'Comment berhasil ditambahkan'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.createComment(body), message)
}

const fetchResolveComment = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchResolveComment'
  message.success = 'Comment berhasil diresolve'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.resolveComment(body), message)
}
const fetchUpdateInvoiceItemAvailability = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateInvoiceItemAvailability'
  message.success = 'Cek kelengkapan berhasil'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateInvoiceItemAvailability(body), message)
}

const fetchShipmentAwb = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchShipmentAwb'
  message.success = 'Upload AWB berhasil'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.shipmentAwb(body), message)
}

const fetchUpdateShipmentCarrier = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateShipmentCarrier'
  message.success = 'Update Shipment Carrier berhasil'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.shipmentCarrier(body), message)
}

const fetchShipmentBooking = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchShipmentBooking'
  message.success = ''
  // Shipment booking has a chance for the booking code isn't ready yet but backend still give back the response of success
  // That's why the notification need to turned off for not giving false information of success for getting the booking code
  // If the booking isn't ready user can try to call it again until they get the booking code
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.shipmentBooking(body), message)
}

const fetchShipmentRemarkReceived = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchShipmentRemarkReceived'
  message.success = 'Shipment Remark Received berhasil'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.shipmentRemarkReceived(body), message)
}

const fetchUpdateInvoice = async (body, setIsLoading, setErr, setSuccess) => {
  if (setIsLoading !== null) setIsLoading(true)
  const params = { invoices: [] }
  const status = body.status
  const type = body.type || ''
  body.invoices.forEach(invoiceId => {
    params.invoices.push({
      invoice_id: parseInt(invoiceId),
      status: body.status
    })
  })
  try {
    const response = await (api.updateInvoiceStatus(params))
    if (!response.ok) {
      const errMsg = (response.data?.message || 'Terjadi kesalahan, ulangi beberapa saat lagi ')
      handleErrorMessage(errMsg, setErr)
    } else {
      if (response.status === 200) {
        if (!isEmpty(response.data?.errors)) {
          handleErrorMessage(response.data.errors.message, setErr)
        } else {
          if (status === 'stand_by' || status === 'shipped' || status === 'received') {
            window.location.replace(config.baseURL + '/order/' + status)
          } else if (status === 'processing') {
            window.location.replace(config.baseURL + '/print?status=new&invoice_id=' + body.invoices.toString())
          } else if (status === 'ready_to_ship') {
            if (type === 'bulk') setSuccess({ success: true, data: response.data.data })
            else window.location.replace(config.baseURL + '/print?type=packing_slip&status=ready_to_ship&invoice_id=' + body.invoices.toString())
          } else if (status === 'pending_delivered') {
            window.location.reload()
          }
        }
      } else {
        const errMsg = `Error response status: ${response.status}, saat update invoice status ke ${status}`
        handleErrorMessage(errMsg, setErr)
      }
    }
    if (setIsLoading !== null) setIsLoading(false)
  } catch (error) {
    const errMsg = 'Terjadi kesalahan, ulangi beberapa saat lagi '
    handleErrorMessage(errMsg, setErr)
  }
}

const fetchSupplierList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSupplierList(params), 'fetchSupplierList')
}

const fetchGetCarrierList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getCarrierList(params), 'fetchGetCommentList')
}

const fetchHandleRefund = async (body, setIsLoading, setErr, setSuccess) => {
  const bodyModified = {
    invoice_id: body.invoice?.invoice_id,
    topic_id: 9,
    comment: 'INFO PROD OOS_ASK REFUND'
  }
  message.name = 'fetchHandleRefund'
  message.success = `Refund ${body.invoice.order_no} Sedang Diproses`
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.createComment(bodyModified), message)
}

const fetchGetInvoiceItems = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getInvoiceItems(params), 'fetchGetInvoiceItems')
}

// =======================================//
// ==  PRODUCT  ==
// =======================================//

const fetchInsertProduct = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchInsertProduct'
  message.success = 'Produk berhasil ditambahkan'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.insertProduct(body), message)
}
const fetchGetListProduct = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getListProduct(params), 'fetchGetListProduct')
}

const fetchGetListProductStatus = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getListProductStatus(params), 'fetchGetListProductStatus')
}

const fetchGetProductDetail = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getProductDetail(params), 'fetchGetProductDetail')
}
const fetchGetBrands = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getBrands(), 'fetchGetBrands')
}

const fetchGetCategories = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getCategories(params), 'fetchGetCategories')
}

const fetchGetAttributes = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getAttributes(params), 'fetchGetAttributes')
}

const fetchGetExpressCarriers = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getExpressCarriers(params), 'fetchGetExpressCarriers')
}

const fetchGetOwnfleetTemplate = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getOwnfleetTemplate(params), 'fetchGetOwnfleetTemplate')
}

const fetchGetProductCommission = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSpecialCommission(params), 'fetchGetOwnfleetTemplate')
}

const fetchUpdatePrice = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdatePrice'
  message.success = 'Harga berhasil diupdate'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updatePriceProduct(body), message)
}

const fetchUpdateProductDetailBulk = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateProductDetailBulk'
  message.success = 'Update berhasil'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateDetailBulkProduct(body), message)
}

const fetchUpdateProductStatus = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateProductStatus'
  message.success = 'Status berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateStatusProduct(body), message)
}

const fetchUpdateProductStatusBulk = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateProductStatusBulk'
  message.success = 'Status berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateStatusBulkProduct(body), message)
}

const fetchUpdateStock = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateStock'
  message.success = 'Stock berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateStockProduct(body), message)
}

const fetchUpdateProduct = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateProduct'
  message.success = 'Produk berhasil diubah'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateProductDetail(body), message)
}

const fetchUpdateCommission = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUpdateCommission'
  message.success = 'Komisi berhasil diupdate'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.updateCommission(body), message)
}

const fetchDeleteProduct = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchDeleteProduct'
  message.success = 'Product berhasil dihapus'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.deleteProduct(body), message)
}

const fetchDeleteBulkProduct = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchDeleteBulkProduct'
  message.success = 'Product berhasil dihapus'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.deleteBulkProduct(body), message)
}

// =======================================//
// ==  SETTLEMENT REPORT  ==
// =======================================//

const fetchGetSettlementReportSupplier = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getSettlementReportSupplier(params), 'fetchGetSettlementReportSupplier')
}

const fetchGetReportList = async (params, setRes, setIsLoading, setErr) => {
  fetchingGet(params, setRes, setIsLoading, setErr, api.getReportList(params), 'getReportList')
}

const fetchSettlementDownload = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchSettlementDownload'
  message.success = ''
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.getSettlementDownload(body), message)
}

const fetchSendReport = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchSendReport'
  message.success = 'Report berhasil di post'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.sendReportToSeller(body), message)
}

const fetchUploadReport = async (body, setIsLoading, setErr, setSuccess) => {
  message.name = 'fetchUploadRepport'
  message.success = 'Report berhasil di upload'
  fetchingPostPut(body, setIsLoading, setErr, setSuccess, api.uploadReport(body), message)
}

export {
  fetchVerifyEmail,
  fetchValidateOtp,
  fetchGenerateOtp,
  fetchResetPassword,
  fetchGetSalesSummary,
  fetchGetDownloadCsvProduct,
  fetchGetMiscFile,
  fetchGetSellerList,
  fetchGetProfileInfo,
  fetchChangeSupplierStatus,
  fetchChangePassword,
  fetchChangeSupplierInfo,
  fetchGetProvinceList,
  fetchGetCityList,
  fetchGetDistrictList,
  fetchGetInvoiceList,
  fetchCountInvoice,
  fetchUpdateInvoice,
  fetchGetCommentList,
  fetchCreateComment,
  fetchHandleRefund,
  fetchResolveComment,
  fetchGetInvoiceItems,
  fetchGetRejectReasonList,
  fetchUpdateInvoiceItemAvailability,
  fetchShipmentAwb,
  fetchUpdateShipmentCarrier,
  fetchShipmentBooking,
  fetchShipmentRemarkReceived,
  fetchSupplierList,
  fetchGetCarrierList,
  fetchGetBrands,
  fetchGetCategories,
  fetchGetAttributes,
  fetchGetExpressCarriers,
  fetchGetOwnfleetTemplate,
  fetchGetProductCommission,
  fetchUpdatePrice,
  fetchUpdateStock,
  fetchUpdateProductDetailBulk,
  fetchUpdateProduct,
  fetchUpdateProductStatus,
  fetchUpdateProductStatusBulk,
  fetchUpdateCommission,
  fetchInsertProduct,
  fetchGetProductDetail,
  fetchGetListProductStatus,
  fetchGetListProduct,
  fetchDeleteProduct,
  fetchDeleteBulkProduct,
  fetchSettlementDownload,
  fetchSendReport,
  fetchGetReportList,
  fetchGetSettlementReportSupplier,
  fetchUploadReport
}
