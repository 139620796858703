import React from 'react'

export default function SelectInput ({ label, labelClass, inputClass, name, optionGroup, initialOption, defaultValue, action }) {
  return (
    <>
      <div className={labelClass}>
        {label}
      </div>
      <div className={inputClass}>
        <div className='input-text-bar'>
          <select className='input input-product' name={name} defaultValue={defaultValue} onChange={(e) => action(e, optionGroup)}>
            {initialOption && <option value=''>{initialOption}</option>}
            {optionGroup.map((option, index) => {
              return <option value={option.id} key={index}>{option.name} {option.count && `(${option.count})`}</option>
            })}
          </select>
        </div>
      </div>
    </>
  )
}
