import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect, useState, Fragment } from 'react'

import { Modal } from 'react-bootstrap'

export default function ShipmentStatusModal ({ onClickedModal, invoice, handleToogleModal }) {
  const [shipmentTracking, setShipmentTracking] = useState(null)

  useEffect(() => {
    if (invoice?.shipment) {
      setShipmentTracking(invoice.shipment?.shipment_tracking)
    }
  }, [invoice, onClickedModal.shipmentStatusModal])

  function renderStatusPesananPending () {
    return (
      <tr>
        <td className='from'>
          <span className='ruparupa-text'>rup<span className='ruparupa-text--red'>a</span>rup<span className='ruparupa-text--blue'>a</span></span>
        </td>
        <td className='info'>
          <span className='date'>{dayjs(invoice.created_at).format('DD-MMM-YYYY HH:mm:ss')}</span>
          <p>Anda telah melakukan pemesanan dan menunggu konfirmasi pembayaran</p>
        </td>
      </tr>
    )
  }

  function renderInfoDrivers () {
    if (shipmentTracking === null) {
      return null
    }
    const statusDriver = JSON.parse(shipmentTracking.status)
    if (statusDriver) {
      const statusInfoDriver = statusDriver.manifest[statusDriver.manifest.length - 1]
      return (
        <div className='margin-m' style={{ borderWidth: 1, borderColor: '#D4DCE6', borderRadius: 3 }}>
          <div className='row'>
            <div className='col-xs-5 col-sm-5 col-md-3 col-xl-4 padding-s col-md-offset-2'>
              <img
                alt='driver' src={
                    statusInfoDriver.driver_info?.driver_photo || 'http://chittagongit.com//images/icon-head/icon-head-13.jpg'
                  }
              />
            </div>
            <div className='col-xs-7 col-sm-7 col-md-7 col-xl-8'>
              <div className='row'>
                <div className='col-12'>
                  <span><b>Live Tracking :</b></span>
                  <a className='underline' target='_blank' rel='noopener noreferrer' href={statusInfoDriver.live_tracking || '#'}> {statusInfoDriver.live_tracking || '-'}</a>
                </div>
                <div className='col-12'>
                  <span><b>Nama Driver :</b></span> {statusInfoDriver.driver_info.driver_name || '-'}
                </div>
                <div className='col-12'>
                  <span><b>No Telp :</b></span>
                  <a rel='noopener noreferrer' href={`tel: ${statusInfoDriver.driver_info?.driver_phone || '-'}`} target='_blank'>{statusInfoDriver.driver_info.driver_phone || '-'}</a>
                </div>
                <div className='col-12'>
                  <span><b>Cancel Reason :</b></span> {statusInfoDriver.cancellation_reason || '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  function renderStatusPesananPaid () {
    return (
      <tr>
        <td className='from'><span className='ruparupa-text'>rup<span className='ruparupa-text--red'>a</span>rup<span className='ruparupa-text--blue'>a</span></span></td>
        <td className='info'>
          <span className='date'>{dayjs(invoice.created_at).format('DD-MMM-YYYY HH:mm:ss')}</span>
          <p>Terima kasih sudah berbelanja di Ruparupa! Pesanan Anda telah kami terima dan sedang dalam proses verifikasi. Kami akan mengirimkan update selanjutnya ke email Anda.</p>
        </td>
      </tr>
    )
  }

  function renderStatusPesananDelivered (deliveredDate) {
    return (
      <tr className='success'>
        <td className='from'><span className='ruparupa-text'>rup<span className='ruparupa-text--red'>a</span>rup<span className='ruparupa-text--blue'>a</span></span></td>
        <td className='info'>
          <span className='date'>{deliveredDate}</span>
          <p>Pesanan Anda telah diterima . Terima kasih sudah berbelanja di Ruparupa!</p>
        </td>
      </tr>
    )
  }

  function renderStatusPesanan (manifest, index) {
    const shipmentStatus = JSON.parse(invoice.shipment?.shipment_tracking?.status)
    // split date to reformat date
    let newManifestDate = ''
    if (manifest.cn_date) {
      const tempManifestDate = manifest.cn_date.split(' ')
      const manifestDate = tempManifestDate[0].split('-')
      newManifestDate = manifestDate[1] + '-' + manifestDate[0] + '-' + manifestDate[2] + ' ' + tempManifestDate[1]
    }
    if (manifest.manifest_date) {
      newManifestDate = manifest.manifest_date
    }

    let successLogo = false
    if (manifest.cn_status !== 'DELIVERED' && manifest.keterangan !== 'Completed' && manifest.keterangan !== 'Pesanan telah tiba di tujuan.' && manifest.keterangan !== 'Received On Destination') {
      successLogo = true
    }
    return (
      <Fragment key={index}>
        <tr className={(shipmentStatus.manifest?.length === (index + 1) && successLogo) ? 'success' : ''}>
          <td className='from'>
            <span>{(invoice.shipment?.carrier) ? invoice.shipment.carrier.carrier_name : '3PL'} </span>
          </td>
          <td className='info'>
            <span className='date'>{(newManifestDate !== '') ? newManifestDate : null}</span>
            <p>{(manifest.cn_status) ? manifest.cn_status : null} {(manifest.keterangan) ? manifest.keterangan : null}</p>
          </td>
        </tr>
        {((manifest.cn_status === 'DELIVERED' || manifest.keterangan === 'Completed' || manifest.keterangan === 'Pesanan telah tiba di tujuan.' || manifest.keterangan === 'Received On Destination')) ? renderStatusPesananDelivered(newManifestDate) : null}
      </Fragment>
    )
  }

  function renderShipment (shipmentTracking) {
    const shipmentStatus = JSON.parse(shipmentTracking.status)
    return (shipmentStatus && shipmentStatus.manifest) && shipmentStatus.manifest.map((manifest, index) => { return renderStatusPesanan(manifest, index) })
  }

  if (shipmentTracking === null || isEmpty(shipmentTracking)) {
    return null
  }
  const statusDriver = JSON.parse(shipmentTracking.status)
  let statusInfoDriver = ''
  if (statusDriver.manifest) {
    statusInfoDriver = statusDriver.manifest[statusDriver.manifest.length - 1]
  }

  function renderBody () {
    if (isEmpty(statusDriver.manifest)) {
      return (
        <div className='m-3'>
          <div className='detail-transaction-status'>
            {(statusInfoDriver.cancellation_reason === 'Dibatalkan oleh partner e-commerce' ||
            statusInfoDriver.cancellation_reason === 'System GoSend Membatalkan permintaan pickup.')
              ? (
                <div className='info-box-pcp margin-bottom-s'>
                  <i className='icon anticon icon-infocirlceo' />
                  <div className='text bold'>alasan pembatalan = {statusInfoDriver.cancellation_reason || '-'}</div>
                </div>
                )
              : null}
            <table>
              <tbody>
                {renderStatusPesananPending()}
                {renderStatusPesananPaid()}
                {(!isEmpty(shipmentTracking)) ? renderShipment(shipmentTracking) : null}
                {(invoice.delivery_method === 'pickup' && invoice.shipment?.shipment_status === 'received') ? renderStatusPesananDelivered(invoice.updated_at) : null}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else if (invoice.shipment.carrier.carrier_id === 7 || invoice.shipment.carrier.carrier_id === 8 || invoice.shipment.carrier.carrier_id === 10) {
      return (
        <div className='m-4'>
          <div className='detail-transaction-status'>
            {(statusInfoDriver.mfcnote_no === '-' || statusInfoDriver.cancellation_reason === 'Dibatalkan oleh partner e-commerce' || statusInfoDriver.keterangan === 'Driver not found') ? null : renderInfoDrivers()}
            {(statusInfoDriver.cancellation_reason === 'Dibatalkan oleh partner e-commerce' || statusInfoDriver.cancellation_reason === 'System GoSend Membatalkan permintaan pickup.')
              ? (
                <div className='info-box-pcp margin-bottom-s'>
                  <i className='icon anticon icon-infocirlceo' />
                  <div className='text bold'>alasan pembatalan = {statusInfoDriver.cancellation_reason === '' ? '-' : statusInfoDriver.cancellation_reason}</div>
                </div>
                )
              : null}
            <table>
              <tbody>
                {renderStatusPesananPending()}
                {renderStatusPesananPaid()}
                {(!isEmpty(shipmentTracking)) ? renderShipment(shipmentTracking) : null}
                {(invoice.delivery_method === 'pickup' && invoice.shipment.shipment_status === 'received') ? renderStatusPesananDelivered(invoice.updated_at) : null}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return (
        <div className='margin-m'>
          {(statusInfoDriver.cancellation_reason === 'Dibatalkan oleh partner e-commerce' || statusInfoDriver.cancellation_reason === 'System GoSend Membatalkan permintaan pickup.')
            ? (
              <div className='info-box-pcp margin-bottom-s'>
                <i className='icon anticon icon-infocirlceo' />
                <div className='text bold'>alasan pembatalan = {statusInfoDriver.cancellation_reason === '' ? '-' : statusInfoDriver.cancellation_reason}</div>
              </div>
              )
            : null}
          <div className='detail-transaction-status'>
            <table>
              <tbody>
                {renderStatusPesananPending()}
                {renderStatusPesananPaid()}
                {(!isEmpty(shipmentTracking)) ? renderShipment(shipmentTracking) : null}
                {(invoice.delivery_method === 'pickup' && invoice.shipment.shipment_status === 'received') ? renderStatusPesananDelivered(invoice.updated_at) : null}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  if (statusDriver.manifest === null || !onClickedModal.shipmentStatusModal) {
    return null
  } else {
    return (
      <Modal
        show
        size='m'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToogleModal('shipmentStatusModal', 'close')}
      >
        <Modal.Header className='text-center' closeButton>
          {/* <button
            type='button'
            className='close'
            onClick={(e) => { handleToogleModal('close') }}
          >
            <i className='anticon icon-closecircle' />
          </button> */}
          <Modal.Title id='myModalLabel'>
            <div className='fs--xm font-weight-bold p-3'>
              Status pengiriman {invoice?.invoice_no || null}
            </div>
            <div className='fs--m' style={{ color: 'black' }}>
              {(invoice?.shipment?.track_number === '') ? '' : 'No.AWB ' + invoice?.shipment?.track_number}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderBody()}
        </Modal.Body>
      </Modal>
    )
  }
}
