// import React, { useEffect, useState } from 'react'
import React, { useState, useEffect } from 'react'
// import config from '../config'
import Header from '../Components/DefaultLayout/Header'
import NavigationMenu from './NavigationMenu'
import { ScaleProvider } from '../Context/ScaleContext'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import config from '../config'
import localforage from 'localforage'
import UserActions from '../Redux/UserRedux'

export default function DefaultLayout (props) {
  const { pages, menu, supplierId } = props
  const [clickedMenuMobile, setClickedMenuMobile] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [isMobileView, setIsMobileView] = useState(false)
  const user = useSelector(state => state.user)

  const dispatch = useDispatch()

  const handleMenuMobile = (isOpen) => {
    setClickedMenuMobile(!isOpen)
  }

  useEffect(() => {
    const tokenForage = async () => {
      let token = ''
      try {
        token = await localforage.getItem('token_access')
        if (!token) {
          dispatch(UserActions.userInit())
          window.location.replace(config.baseURL + '/login')
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    tokenForage()
  }, [])

  useEffect(() => {
    const handleResize = () => setWidth(window.screen.width)
    window.addEventListener('resize', handleResize)

    if (width <= 992) {
      setIsMobileView(true)
    } else {
      setIsMobileView(false)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  useEffect(() => {
    if (isEmpty(user.user)) {
      window.location.replace(config.baseURL + '/')
    }
  }, [user])

  return (
    <ScaleProvider
      isMobileView={isMobileView}
      width={width}
    >
      <Header pages={pages} handleMenuMobile={handleMenuMobile} isOpenMenu={clickedMenuMobile} setClickedMenuMobile={setClickedMenuMobile} />
      <div className='container-fluid'>
        <div className='row'>
          {pages !== 'product-form' &&
            <NavigationMenu isOpenMenu={clickedMenuMobile} handleMenuMobile={handleMenuMobile} supplierId={supplierId} pages={pages} menu={menu} />}
          {
            // (pages === 'product-form' || pages === 'product-detail')
            // ? isMobileView :
            // <NavigationMenu isOpenMenu={clickedMenuMobile} handleMenuMobile={handleMenuMobile} pages={pages} menu={menu} />
            // : null
            // : <NavigationMenu isOpenMenu={clickedMenuMobile} handleMenuMobile={handleMenuMobile} pages={pages} menu={menu} />
          }
          {props.children}
          {/* {setIsLoading(true)} */}
        </div>
      </div>
    </ScaleProvider>
  )
}
