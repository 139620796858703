import { isEmpty, startsWith } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Loading from '../../../Components/Behaviors/Loading'
import config from '../../../config'
import Cookies from 'cookies-js'

import { ProfileContext } from '../../../Context/ProfileContext'
import { ScaleContext } from '../../../Context/ScaleContext'

import { fetchGetListProduct } from '../../../Services/FetchingGroup'

import AuthorizationImage from '../../../static/images/Authorized_st38h6.svg'
import noImagePlacehoder from '../../../static/images/noImage.png'

export default function ProfileBrief ({ supplierId, type, countOrder, fetching, error }) {
  const user = useSelector(state => state.user)
  const [attContextProfile] = useContext(ProfileContext)
  const [countProduct, setCountProduct] = useState({})

  let imageUrl = ''
  const image = attContextProfile.supplier_data?.logo
  if (!isEmpty(image)) {
    // this only for clodinary image
    if (startsWith(image, '/v')) {
      imageUrl = config.imageURL + image
    } else {
      // this for cdn
      imageUrl = image
    }
  } else {
    // default image no thumbnail
    imageUrl = config.assetsURL + '/images/no-thumbnail.png'
  }

  // Uncomment this if you want to use is mobile trigger
  const [attbContextUpper] = useContext(ScaleContext)
  const { isMobileView } = attbContextUpper

  useEffect(() => {
    if (user?.user?.role_id !== 2 && isEmpty(countProduct)) {
      const storeCode = attContextProfile?.supplier_data?.supplier_addresses[0]?.store?.store_code
      const supplierAlias = attContextProfile?.supplier_data?.supplier_alias
      const params = {
        limit: '10',
        page: 1,
        keyword: '',
        supplier_alias: supplierAlias,
        store_code: storeCode,
        count: true,
        sort_by: 'created_at',
        sort_type: 'desc'
      }
      fetchGetListProduct(params, setCountProduct, null, null)
    }
    Cookies.set('filterSupplier', '')
    Cookies.set('filterCarrier', '')
    return () => {
      setCountProduct({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleCookies (params) {
    Cookies.set('filterSupplier', params.supplier_alias)
  }

  function renderCount (type, className, count, title) {
    const storeCode = attContextProfile.supplier_data?.supplier_addresses[0]?.store?.store_code
    const supplierAlias = attContextProfile.supplier_data?.supplier_alias
    // set here if you want the link to contains the parameter that will be consume in destination page
    // right now it's only consuming, store_code, supplier_alias, status, sort_by, sort_type, status
    const params = {
      store_code: storeCode,
      supplier_alias: supplierAlias,
      status: ''
    }
    let link = ''
    switch (type) {
      case 'new_order':
        link = `/order/new?supplier=${supplierAlias}&supplier_name=${attContextProfile.supplier_data.name}&supplier_id=${supplierId}`
        break
      case 'pending_order':
        link = `/pending?supplier=${supplierAlias}&supplier_name=${attContextProfile.supplier_data.name}&supplier_id=${supplierId}`
        break
      case 'all_product':
        link = `/product/list-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}${params.status ? '&status=' + params.status : ''}&supplier_id=${supplierId}`
        break
      case 'rejected_product':
        link = `/product/rejected-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${supplierId}`
        break
      default:
        break
    }
    return (
      <div className={'col ' + className}>
        <a href={link} onClick={() => handleCookies(params)}>
          <h1 className='link'><b>{count}</b></h1>
          <h5>{title}</h5>
        </a>
      </div>
    )
  }

  function renderDesktop () {
    const sumOrder = countOrder?.new + countOrder?.processing + countOrder?.stand_by + countOrder?.shipped + countOrder?.ready_to_ship
    const sumProduct = (countProduct?.approved || 0) + (countProduct?.pending || 0) + (countProduct?.discontinued || 0)
    return (
      <div className='row border p-5'>
        <div className='col-2 text-center align-items-center'>
          <Link to={`/profile/${supplierId}?type=settings&tab=information`}>
            <div className='row justify-content-center text-center'>
              <div className='col-12'>
                <img className='shop-img border p-4' width='100px' src={imageUrl} alt='sellerLogo' onError={(e) => { e.target.src = noImagePlacehoder }} />
                {/* <div className='row text-center'>
                  <img className='authorize-status-img' width='125px' src={AuthorizationImage} alt='TES' />
                </div> */}
              </div>
            </div>
          </Link>
        </div>
        <div className='col '>
          <div className='row fs--s '>
            <div className='col-4 align-self-center'>
              <div><b>{attContextProfile.supplier_data?.name}</b></div>
              <div className='pt-3'>
                <Link to={`/settlement-report/report?supplier_id=${attContextProfile.supplier_data?.supplier_id}&supplier_name=${attContextProfile.supplier_data?.name}`}>
                  <div className='row'>
                    <div className='col link d-flex align-items-center'>Settlement Report&nbsp;&nbsp;<i className='anticon icon-right' /></div>
                  </div>
                </Link>
              </div>
              <div className='mt-3'>
                <button
                  onClick={() => window.open('https://docs.google.com/presentation/d/1I0Lwu8Gldn-4sjOpnxWkAGxaYeDgTn9aUphcuk2Q4is/edit#slide=id.p1')}
                  className='btn btn-secondary btn-s'
                >
                  User Manual
                </button>
              </div>
            </div>
            {user.user?.role_id !== 2 && (
              <div className='col '>
                <div className='row text-center' style={{ height: '10rem' }}>
                  {renderCount('pending_order', 'border-right py-4', countOrder?.pending_unprocessed || 0, 'Pending Order')}
                  {renderCount('new_order', 'border-right py-4', sumOrder || 0, 'Order')}
                  {renderCount('all_product', 'border-right py-4', sumProduct || 0, 'Produk')}
                  {renderCount('rejected_product', 'py-4', countProduct?.rejected || 0, 'Produk Bermasalah')}
                </div>
              </div>
            )}

            {/* <div className='stars-rating pt-3'>
                            <div className='row'>
                              <div className='col-12'>
                                <StarRatings
                                  rating={5}
                                  starRatedColor='#F6C54C'
                                  numberOfStars={5}
                                  starDimension='20px'
                                  starSpacing='1px'
                                  svgIconPath={config.starSvgIconPath}
                                />
                                <span className='ml-4'>{sellerDatas[0].shop_rating}</span>
                              </div>
                            </div>
                          </div> */}

          </div>
        </div>
        <div className='col-2 text-right'>
          <Link to={`/profile/${supplierId}?type=settings&tab=information`}>
            <button className='btn btn-ghost-secondary fs--s '><span className=''><i className='anticon icon-setting' /> <span className='px-3'>Pengaturan Toko</span></span></button>
          </Link>
        </div>
      </div>
    )
  }

  function renderMobile () {
    const sumOrder = countOrder?.new + countOrder?.processing + countOrder?.stand_by + countOrder?.shipped + countOrder?.ready_to_ship
    const sumProduct = (countProduct?.approved || 0) + (countProduct?.pending || 0)

    return (
      <div className='col-12 py-5 px-5'>
        <div className='row border px-3'>
          <div className='col-4 text-center'>
            <Link to={`/profile/${supplierId}?type=settings&tab=information`}>
              <div className='row py-5'>
                <div className='col-12'>
                  <img className='border p-3' width='85px' src={imageUrl} alt='sellerLogo' onError={(e) => { e.target.src = noImagePlacehoder }} />
                  <div className='row'>
                    <img style={{ left: '0px' }} className='col-12 authorize-status-img' width='100px' src={AuthorizationImage} alt='logo-tes' />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className='middle-side col-8 align-self-center centralized-content'>
            <div className='shopName-shopStatus fs--s '>
              <div className='row'>
                <div className='col-12 font-weight-bold fs--s '>
                  {attContextProfile.supplier_data?.name}
                </div>
                <div className='col-12 '>
                  <Link to='/settlement-report/report'>
                    <span className='link'>Settlement Report&nbsp;&nbsp;<i className='anticon icon-right' /></span>
                  </Link>
                </div>
              </div>
              {/* <div className='stars-rating pt-3'>
                        <div className='row'>
                          <div className='col-12'>
                            <StarRatings
                              rating={5}
                              starRatedColor='#F6C54C'
                              numberOfStars={5}
                              starDimension='20px'
                              starSpacing='1px'
                              svgIconPath={config.starSvgIconPath}
                            />
                            <span className='fs--s pl-3'>
                              {sellerDatas[0].shop_rating}
                            </span>
                          </div>
                        </div>
                      </div> */}
              {/* <div className='stars-rating fs--s '>
                        {sellerDatas[0].shop_status}
                      </div> */}
            </div>
          </div>
          {user.user?.role_id !== 2 && (
            <div className='col-12 mb-5'>
              <div className='row text-center' style={{ height: '10rem' }}>
                {renderCount('pending_order', 'border-right py-4', countOrder?.pending_unprocessed || 0, 'Pending Order')}
                {renderCount('new_order', 'border-right py-4', sumOrder || 0, 'Order')}
                {renderCount('all_product', 'border-right py-4', sumProduct || 0, 'Produk')}
                {renderCount('rejected_product', 'py-4', countProduct?.rejected || 0, 'Produk Bermasalah')}
              </div>
            </div>
          )}
          <div className='col-12 mb-4'>
            <Link to={`/profile/${supplierId}?type=settings&tab=information`}>
              <div className='btn-s btn-full btn-ghost-primary text-center'>
                Pengaturan Toko<span><i className='anticon icon-setting pl-2' /></span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  if (attContextProfile?.supplier_data) {
    if (fetching) {
      return (
        <div className='col-12'>
          <Loading type='alt' />
        </div>
      )
    } else if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12'>
            <span className='fs--xm'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      if (isMobileView) {
        // mobile view
        return renderMobile()
      } else {
        // desktop view
        return renderDesktop()
      }
    }
  } else return null
}
