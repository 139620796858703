import React from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../../Components/Behaviors/Loading'

export default function ViewProductDetailDesktop ({ renderComponent, fetching, attributesSelected, renderImages, product, method, categorySelected, warrantyLabel, attributeList, expressCarrierList, storeCode, supplierAlias }) {
  return (
    <div className='container container-add-product'>
      <div className='my-5'>
        <div className='fs--xxm font-weight-bold'>Detail Produk</div>
        <div className='container-card-default fs--m'>
          <div className='fs--xxm font-weight-bold mb-5'>Foto Produk</div>
          <div className='row text-center justify-content-center'>
            {renderImages()}
          </div>
        </div>
        {
          fetching
            ? (
              <div className='row spacing-add-product justify-content-center'>
                <Loading type='alt' />
              </div>
            )
            : renderComponent()
        }
        <div className='row spacing-add-product'>
          <div className='col'>
            <Link to={`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}`}>
              <button className='btn btn-full btn-primary-border btn-m font-weight-bold'>Kembali Ke Halaman Produk</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
