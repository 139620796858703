import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SettlementReport from '../Components/SettlementReport'
import BaseQuery from '../Base/BaseQuery'
import { isEqual, isEmpty } from 'lodash'

import Loading from '../Components/Behaviors/Loading'
import UploadSetllementModal from './Modals/UploadSettlementModal'
import { fetchGetReportList, fetchSendReport } from '../Services/FetchingGroup'
import Pagination from './Pagination'
import dayjs from 'dayjs'

function SettlementReportList () {
  const startPeriode = BaseQuery().get('start_periode')
  const endPeriode = BaseQuery().get('end_periode')
  const [params, setParams] = useState({ report_type: 'settlement', start_periode: startPeriode, size: 10, page: 1 })
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [isUploadOnClick, setUploadOnClick] = useState(false)
  const [settlementList, setSettlementList] = useState()

  // for upload
  const [selectedSettlement, setSelectedSettlement] = useState()

  const [checkedValues, setCheckedValues] = useState([])
  const [itemChecked, setItemChecked] = useState(false)

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      setParams({ ...params, page: selected + 1 })
      fetchGetReportList({ ...params, page: selected + 1 }, setSettlementList, setIsLoading, setErr)
    }
  }

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target

    if (checked) {
      setCheckedValues([...checkedValues, parseInt(value)])
    } else {
      setCheckedValues(checkedValues.filter(item => item !== parseInt(value)))
    }
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target
    const collection = []
    if (checked && settlementList) {
      settlementList.report.map((report, key) => {
        return collection.push(report.supplier_id)
      })
    }
    setCheckedValues(collection)
    setItemChecked(checked)
  }

  function handleToogleUploadModal (status, settlement) {
    if (status === 'open') {
      setSelectedSettlement(settlement)
      setUploadOnClick(true)
    } else {
      setUploadOnClick(false)
    }
  }

  useEffect(() => {
    if (!isEmpty(settlementList?.report)) {
      if (isEqual(checkedValues.length, settlementList.report.length)) {
        setItemChecked(true)
      } else {
        setItemChecked(false)
      }
    }
  }, [checkedValues, settlementList])

  useEffect(() => {
    if (isEmpty(settlementList)) {
      handleParamsChange(params)
    }
    // only want to works when settlementList state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    setParams(paramsChange)
    fetchGetReportList(paramsChange, setSettlementList, setIsLoading, setErr)
  }

  // for enter purpose, right now it set when onchange, if there's trouble changes it with onKeyDown
  function onKeyDown (e) {
    if (e.key === 'Enter') {
      const { value } = e.target
      const paramsChange = { keyword: value }
      handleParamsChange(paramsChange)
    }
  }

  const handleSendMultipleReport = () => {
    const items = []

    // tinggal sesuain sama format params nya
    checkedValues.map((supplierId, index) => {
      const payload = {
        start_periode: startPeriode,
        supplier_id: supplierId,
        report_type: params.report_type
      }
      return items.push(payload)
    })

    const collection = {
      reports: items
    }
    fetchSendReport(collection, setIsLoading, setErr, null)
  }

  const init = () => {
    setCheckedValues([])
    setItemChecked(false)
  }

  if (err.err) {
    return (
      <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
        <div className='col-12'>
          <span className='fs--m error'>{err.message}</span>
        </div>
      </div>
    )
  } else if (settlementList?.report) {
    return (
      <>
        <div className='row'>
          <div className='col'>
            <div className='container-seller-list active'>
              <div className='row align-items-center filter.header'>
                <div className='col-12 p-5 border-bottom'>
                  <h2 className='font-weight-bold d-flex align-items-center'>
                    <Link to='/settlement-report/report'>
                      <i className='anticon icon-left' />
                    </Link>
              &nbsp;&nbsp;Settlement Report - {dayjs(startPeriode).locale('id').format('DD MMMM YYYY')}
                  </h2>
                </div>
                <div className='col-12 mt-5'>
                  <div className='input-text-bar input-bar-sellerList'>
                    <input placeholder='Cari nama supplier' type='text' onChange={(e) => { if (e.target.value.length > 2) { handleParamsChange({ keyword: e.target.value }) } }} onKeyDown={(e) => onKeyDown(e)} />
                    <button type='submit' className='search-btn'><i className='anticon icon-search1' /></button>
                  </div>
                </div>
                {isLoading
                  ? (
                    <div className='col-12'>
                      <Loading type='alt' />
                    </div>
                    )
                  : (
                    <div className='col-12 mt-5'>
                      <table className='table table-layout table-layout__settlement'>
                        <thead>
                          <tr className=''>
                            <th width='4%'>
                              <label className='container-checkbox'>
                                <input
                                  type='checkbox'
                                  checked={itemChecked}
                                  onChange={(e) => handleSelectAll(e)}
                                />
                                <span className='checkbox' />
                              </label>
                            </th>
                            <th className='pt-5 pb-5' width='15%'>Nama Toko</th>
                            <th className='pt-5 pb-5' width='20%'>Download File</th>
                            <th className='pt-5 pb-5' width='15%' />
                            <th className='pt-5 pb-5' width='15%' />
                          </tr>
                        </thead>
                        <tbody>
                          {settlementList?.report?.map((settlement, idx) => {
                            if (settlement?.file_name && settlement?.file_name !== '') {
                              return (
                                <Fragment key={idx}>
                                  <SettlementReport
                                    key={idx}
                                    id={idx}
                                    startPeriode={startPeriode}
                                    endPeriode={endPeriode}
                                    settlement={settlement}
                                    reportType={params.report_type}
                                    checkedValues={checkedValues}
                                    itemChecked={itemChecked}
                                    init={init}
                                    handleToogleUploadModal={handleToogleUploadModal}
                                    handleCheckboxClick={handleCheckboxClick}
                                    loading={isLoading}
                                  />
                                </Fragment>
                              )
                            } else return null
                          })}
                        </tbody>
                      </table>
                    </div>
                    )}
                {settlementList?.total > 10 &&
                  <div className='col-12 mt-5'>
                    <Pagination
                      total={settlementList?.total}
                      show={10}
                      classNames='pagination pull-right'
                      handlePageClick={handlePageClick}
                    />
                  </div>}
              </div>
            </div>
          </div>
        </div>
        {checkedValues && checkedValues.length > 0 &&
          <div className='container-checklist settlement'>
            <div className='row align-items-center'>
              <div className='col-10'>
                <b>{`${checkedValues.length} dipilih`}</b>
              </div>
              <div className='col'>
                <button onClick={() => handleSendMultipleReport()} className='btn btn-transparent btn-m btn-full'><b>Post</b></button>
              </div>
            </div>
          </div>}
        {isUploadOnClick &&
          <UploadSetllementModal
            settlement={selectedSettlement}
            handleToogleUploadModal={handleToogleUploadModal}
            isUploadOnClick={isUploadOnClick}
          />}
      </>
    )
  } else {
    return null
  }
}
// }

export default SettlementReportList
