import config from '../config'
import localforage from 'localforage'
import apisauce from 'apisauce'

const apiURL = config.apiURL
// const headers = {
//   // headers: {
//   'Content-Type': 'application/json'
//   // 'Access-Control-Allow-Origin': 'http://local.sellercenter.ruparupa.io'
//   // }
// }

const create = () => {
  const apiWrapper = apisauce.create({
    baseURL: apiURL,
    timeout: 30000
  })

  const header = () => {
    return {
      headers: {
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Origin': 'http://local.sellercenter.ruparupa.io'
      }
    }
  }

  const headerCategories = (level, Authorization) => {
    return {
      headers: {
        Authorization: `Bearer ${Authorization}`,
        'rr-level': level,
        'rr-master-app-id': '5',
        'rr-access-type': 'cms',
        'rr-company-code': 'ODI'
      }
    }
  }

  const headerWithToken = (Authorization) => {
    return {
      headers: { ...header, 'Content-Type': 'application/json', Authorization: `Bearer ${Authorization}` }
    }
  }

  // const token = window.localStorage.getItem('token_access')
  const tokenForage = async () => {
    let token = ''
    try {
      token = await localforage.getItem('token_access')
    } catch (error) {
      console.log(error)
    }
    return token
  }

  const login = (data) => {
    return apiWrapper.post('/auth/login', data, header())
  }

  const getUserRole = (data, token) => {
    return apiWrapper.get('/supplier/role', data, headerWithToken(token))
    // return apiWrapper.get(`/supplier/role?role_id=${data.role_id}`, headerWithToken(token))
  }

  const getMiscKey = async (data) => {
    const token = await tokenForage()
    // for avoiding URL encoding
    return apiWrapper.get(`/misc/signed-url?key=${data.key}`, {}, headerWithToken(token))
    // return apiWrapper.get(`/supplier/role?role_id=${data.role_id}`, headerWithToken(token))
  }

  const getProvince = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/misc/province', {}, headerWithToken(token))
  }

  const getCity = async (provinceId) => {
    const token = await tokenForage()
    return apiWrapper.get(`/misc/city/${provinceId}`, {}, headerWithToken(token))
  }

  const getDistrict = async (cityId) => {
    const token = await tokenForage()
    return apiWrapper.get(`/misc/kecamatan/${cityId}`, {}, headerWithToken(token))
  }

  // =======================================//
  // ==  FORGOT PASSWORD  ==
  // =======================================//

  const checkVerifyEmail = async (data) => {
    // Accepted Data
    // "email": string
    return apiWrapper.post('/auth/forgot-password', data, header())
  }

  const generateOtp = async (data) => {
    // Accepted Data
    // "email": string
    // "first_name": string
    // "channel": string
    // "action": string
    return apiWrapper.post('/auth/otp/generate', data, header())
  }

  const validateOtp = async (data) => {
    // Accepted Data
    // "email": string
    // "access_code": string
    // "action": string
    return apiWrapper.post('/auth/otp/validate', data, header())
  }

  const resetPassword = async (data) => {
    // Accepted Data
    // "password": string
    return apiWrapper.post('/auth/reset-password', data, headerWithToken(data.token))
  }

  // =======================================//
  // ==  HOME REPORT  ==
  // =======================================//
  const getSalesSummary = async (data) => {
    const token = await tokenForage()
    return apiWrapper.get('/home/sales-summary', data, headerWithToken(token))
  }

  const getDownloadCsvProduct = async (data) => {
    // Accepted Data
    // "status": string
    // "limit": int
    // "total": int
    const token = await tokenForage()
    return apiWrapper.get('/home/product/detail/download', data, headerWithToken(token))
  }
  // =======================================//
  // ==  SUPPLIER PROFILE  ==
  // =======================================//

  const getSupplierList = async (data) => {
    const token = await tokenForage()
    return apiWrapper.get('/supplier/', data, headerWithToken(token))
  }

  const getSupplierInfo = async (data) => {
    const token = await tokenForage()
    return apiWrapper.get('/supplier/detail', data, headerWithToken(token))
  }

  const changeSupplierInfo = async (data) => {
    const token = await tokenForage()
    return apiWrapper.post('/supplier/info', data, headerWithToken(token))
  }

  const changeSupplierStatus = async (data) => {
    const token = await tokenForage()
    return apiWrapper.put('/supplier/status', data, headerWithToken(token))
  }

  const changePassword = async (data) => {
    const token = await tokenForage()
    return apiWrapper.put('/auth/password', data, headerWithToken(token))
  }

  // =======================================//
  // ==  PRODUCT  ==
  // =======================================//

  const getListProduct = async (data) => {
    // Accepted Data
    // "keyword": string
    // "limit": int
    // "store_code": string
    // "status": string
    const token = await tokenForage()
    return apiWrapper.get('/product/all', data, headerWithToken(token))
  }

  const getListProductStatus = async (data) => {
    // Accepted Data
    // "status": string
    // "limit": int
    // "total": int
    const token = await tokenForage()
    return apiWrapper.get('/home/product/detail', data, headerWithToken(token))
  }

  const getBrands = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/product/brand', {}, headerWithToken(token)) // no-token (?)
  }

  const getAttributes = async (data) => {
    const token = await tokenForage()
    return apiWrapper.get('/product/attribute', data, headerWithToken(token)) // no-token (?)
    // return apiWrapper.get(`/product/attribute?attribute_set_id=184&attribute_set_variant_id=1`, data, headerWithToken(token)) // no-token (?)
  }

  const getCategories = async (data) => {
    // Accepted Data
    // "category_id": string
    // "parent_id": int
    const token = await tokenForage()
    const level = data.level
    return apiWrapper.get('/product/category', data, headerCategories(level, token)) // no-token (?)
  }

  const getExpressCarriers = async (data) => {
    // Accepted Data
    // "carrier_type": string
    const token = await tokenForage()
    return apiWrapper.get('/product/shipment/carrier', data, headerWithToken(token))
  }

  const getOwnfleetTemplate = async (data) => {
    // Accepted Data
    // "supplier_id": string
    const token = await tokenForage()
    return apiWrapper.get('/product/shipment/ownfleet', data, headerWithToken(token))
  }

  const getSpecialCommission = async (data) => {
    // Accepted Data
    // "sku": string
    const token = await tokenForage()
    return apiWrapper.get('/product/commission', data, headerWithToken(token))
  }

  const insertProduct = async (data) => {
    const token = await tokenForage()
    return apiWrapper.post(`/product?supplier_alias=${data?.supplier_alias}`, data, headerWithToken(token))
  }

  const updateStockProduct = async (data) => {
    // Accepted Data
    // "sku": string
    // "qty_on_hand": int
    // "status": int
    // "store_code": string
    const token = await tokenForage()
    return apiWrapper.put(`/product/stock?store_code=${data.store_code}`, data, headerWithToken(token))
  }

  const updateCommission = async (data) => {
    // Accepted Data
    // "sku": string,
    // "commission": int,
    // "special_commission": int,
    // "special_commission_from": string date with format YYYY-MM-YY,
    // "special_commission_to": string date with format YYYY-MM-YY
    const token = await tokenForage()
    return apiWrapper.put('/product/commission', data, headerWithToken(token))
  }

  const updatePriceProduct = async (data) => {
    // Accepted Data
    // "sku": string,
    // "price": int,
    // "old_price": int,
    // "old_special_price": int,
    // "special_price": int,
    // "special_to_date": string date with format YYYY-MM-YY,
    // "special_from_date": string date with format YYYY-MM-YY
    const token = await tokenForage()
    return apiWrapper.put('/product/price', data, headerWithToken(token))
  }

  const updateProductDetail = async (data) => {
    const token = await tokenForage()
    return apiWrapper.put(`/product?supplier_alias=${data?.supplier_alias}&supplier_id=${data?.supplier_id}&sku=${data?.sku}`, data, headerWithToken(token))
  }

  const getProductDetail = async (data) => {
    const token = await tokenForage()
    return apiWrapper.get('/product', data, headerWithToken(token))
  }

  const getListPromblematicProduct = (data) => {
    return apiWrapper.get('product/list-problematic-product', data, header())
  }

  const updateDetailBulkProduct = async (data) => {
    const token = await tokenForage()
    return apiWrapper.put('/product/bulk', data, headerWithToken(token))
    // return apiWrapper.delete('/product', { ...headerWithToken(token), data: data })
  }

  const updateStatusProduct = async (data) => {
    const token = await tokenForage()
    const storeCode = data.store_code || ''
    return apiWrapper.put(`/product/status?store_code=${storeCode}`, data, headerWithToken(token))
  }

  const updateStatusBulkProduct = async (data) => {
    const token = await tokenForage()
    return apiWrapper.put('/product/bulk/status', data, headerWithToken(token))
  }

  const deleteProduct = async (data) => {
    const token = await tokenForage()
    return apiWrapper.delete('/product', data, headerWithToken(token))
  }

  const deleteBulkProduct = async (data) => {
    const token = await tokenForage()
    return apiWrapper.delete('/product/bulk', data, headerWithToken(token))
  }

  const getCountProduct = (data, token) => {
    return apiWrapper.get('/home/product', data, headerWithToken(token))
  }

  // =======================================//
  // ==  SETTLEMENT  ==
  // =======================================//

  const getSettlementReportSupplier = async (data) => {
    // Accepted Data
    // "start_periode": string
    // "report_type":string
    // "supplier": string
    const token = await tokenForage()
    return apiWrapper.get('/order/report/seller', data, headerWithToken(token))
  }

  const getSettlementDownload = async (data) => {
    // Accepted Data
    // "supplier_id": int
    // "size": int
    // "page": int
    const token = await tokenForage()
    return apiWrapper.get('/order/report/download', data, headerWithToken(token))
  }
  const getReportList = async (data) => {
    // Accepted Data
    // "report_type": string      *settlement, summary
    // "start_periode": string
    // "size": int
    // "page": int
    const token = await tokenForage()
    return apiWrapper.get('/order/report', data, headerWithToken(token))
  }

  const sendReportToSeller = async (data) => {
    // Accepted Data
    // "start_periode": string,
    // "supplier": string,
    // "report_type":string
    const token = await tokenForage()
    return apiWrapper.post('/order/report/post', data, headerWithToken(token))
  }

  const uploadReport = async (data) => {
    // Accepted Data
    // "start_periode": string,
    // "end_periode": string,
    // "supplier_id": int,
    // "report_type": string,
    // "xls_file": base64
    const token = await tokenForage()
    return apiWrapper.post('/order/report/upload', data, headerWithToken(token))
  }

  // =======================================//
  // ==  ORDER  ==
  // =======================================//
  const getInvoiceList = async (data) => {
    // Accepted Data
    // "status": string       *order status
    // "supplier": string     *supplier_alias
    // "carrier_id": string   *ex: gosend instant 8, sameday 7, jne job 6, for multivalue then just use comma
    // "page":int
    // "keyword": string      *order_no / invoice_no / customer_firstname
    const token = await tokenForage()
    return apiWrapper.get('/order/invoice', data, headerWithToken(token))
  }

  const getCountInvoice = async (data, token) => {
    // Accepted Data
    // "supplier": string     *supplier_alias
    // "carrier_id": string   *ex gosend instant 8, sameday 7, jne job 6, multivalue then just use comma
    let getToken = token
    if (!token) getToken = await tokenForage()
    return apiWrapper.get('/order/invoice/count', data, headerWithToken(getToken))
  }

  const getInvoiceItems = async (data) => {
    // Accepted Data
    // "invoice_no_list": string     *multiple invoice number seperated by ,
    const token = await tokenForage()
    return apiWrapper.get('/order/invoice/print', data, headerWithToken(token))
  }

  const updateInvoiceItemAvailability = async (data) => {
    // Accepted Data
    // "invoices": [
    // {
    // "invoice_id": int,
    //     "items": [ //suppoer multiple items
    //         {
    //             "invoice_item_id": int,
    //             "status_fulfillment": string,
    //             "reason": int,
    //             "note": string
    //         }
    //     ]
    // }
    // ]
    const token = await tokenForage()
    return apiWrapper.put('/order/invoice/item-availability', data, headerWithToken(token))
  }

  const getRejectReasonList = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/order/invoice/reject-reason', {}, headerWithToken(token))
  }

  const updateInvoiceStatus = async (data) => {
    // Accepted Data
  //   "invoices": [
  //     {
  //         "invoice_id": int,
  //         "status": string
  //     }
  // ]
    const token = await tokenForage()
    return apiWrapper.put('/order/invoice/status', data, headerWithToken(token))
  }

  const shipmentBooking = async (data) => {
    // Accepted Data
  //   "invoices": [ 549066 ] // invoice id
    const token = await tokenForage()
    return apiWrapper.post('/order/shipment/booking', data, headerWithToken(token))
  }

  const shipmentCarrier = async (data) => {
    // Accepted Data
    // "invoice_id": int,
    // "carrier_id": int
    const token = await tokenForage()
    return apiWrapper.post('/order/shipment/carrier', data, headerWithToken(token))
  }

  const shipmentAwb = async (data) => {
    // Accepted Data
    // "invoice_id": int,
    // "awb": string,
    // "awb_image": base64
    const token = await tokenForage()
    return apiWrapper.post('/order/shipment/awb', data, headerWithToken(token))
  }

  const shipmentRemarkReceived = async (data) => {
    // Accepted Data
    // "invoice_id": int,
    // "do_image": base64
    const token = await tokenForage()
    return apiWrapper.post('/order/shipment/remark-received', data, headerWithToken(token))
  }

  const getShipmentAwbSignedUrl = async (data) => {
    // Accepted Data
    // "key": string      *ex: shipment/pod_image/INV377413932_1606382516.png,
    const token = await tokenForage()
    return apiWrapper.get('/order/shipment/awb-signed-url', data, headerWithToken(token))
  }

  const getCommentTopicList = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/order/comment/topic', {}, headerWithToken(token))
  }

  const getCarrierList = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/order/shipment/carrier', {}, headerWithToken(token))
  }

  const getCommentList = async (data) => {
    // Accepted Data
    // "supplier": int, *in alias
    // "limit": int,
    // "page": int,
    // "topic": int
    const token = await tokenForage()
    return apiWrapper.get('/order/comment', data, headerWithToken(token))
  }

  const createComment = async (data) => {
    // Accepted Data
    // "invoice_id": int,
    // "topic_id": int,
    // "comment": string
    const token = await tokenForage()
    return apiWrapper.post('/order/comment', data, headerWithToken(token))
  }

  const resolveComment = async (data) => {
    // Accepted Data
    // "invoice_id": 575590,
    const token = await tokenForage()
    return apiWrapper.post('/order/comment/resolve', data, headerWithToken(token))
  }

  const getOrderSupplierList = async () => {
    const token = await tokenForage()
    return apiWrapper.get('/order/supplier', {}, headerWithToken(token))
  }

  // =======================================//

  return {
    login,
    getUserRole,
    checkVerifyEmail,
    generateOtp,
    validateOtp,
    resetPassword,
    getMiscKey,
    // =======================================//
    // ==  PRODUCT  ==
    // =======================================//
    getListProduct,
    getListProductStatus,
    getBrands,
    getAttributes,
    getCategories,
    getExpressCarriers,
    getOwnfleetTemplate,
    insertProduct,
    updateCommission,
    updateStockProduct,
    updateDetailBulkProduct,
    updateStatusProduct,
    updateStatusBulkProduct,
    updatePriceProduct,
    getProductDetail,
    updateProductDetail,
    getListPromblematicProduct,
    getSpecialCommission,
    deleteProduct,
    deleteBulkProduct,
    getCountProduct,
    getDownloadCsvProduct,
    // =======================================//
    // == SUPPLIER PROFILE ==
    // =======================================//
    getSupplierList,
    getSupplierInfo,
    changeSupplierInfo,
    changeSupplierStatus,
    changePassword,
    // =======================================//
    // == SETTLEMENT ==
    // =======================================//
    getSettlementReportSupplier,
    getSettlementDownload,
    getReportList,
    sendReportToSeller,
    uploadReport,
    // =======================================//
    // == ORDER ==
    // =======================================//
    getInvoiceList,
    getCountInvoice,
    getInvoiceItems,
    updateInvoiceItemAvailability,
    getRejectReasonList,
    updateInvoiceStatus,
    shipmentBooking,
    shipmentCarrier,
    shipmentAwb,
    shipmentRemarkReceived,
    getShipmentAwbSignedUrl,
    getCommentTopicList,
    getCarrierList,
    createComment,
    resolveComment,
    getOrderSupplierList,
    // =======================================//
    getCommentList,
    getProvince,
    getCity,
    getDistrict,
    getSalesSummary
  }
}

export default {
  create
}
