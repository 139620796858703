import React from 'react'
import SummarySupplierReportList from './SummaryReportList'
import SettlementReportList from './SettlementReportList'

import NoAccessRules from './NoAccessRules'

import { checkRole } from '../Services/CheckRole'

import { useSelector } from 'react-redux'

function SettlementReport ({ tab, supplierName, supplierId }) {
  const user = useSelector(state => state.user)
  if (user?.role) {
    if (tab === 'details') {
      return checkRole({
        menu: 'settlement_report',
        method: 'edit',
        attribute: '',
        yes: (access) => {
          if (tab === 'details') {
            return (
              <div className='col-12 col-lg offset-lg-2'>
                <SettlementReportList />
              </div>
            )
          }
        },
        no: (access) => { return <NoAccessRules /> },
        userRules: user?.role
      })
    } else if (tab === 'report') {
      return checkRole({
        menu: 'settlement_report',
        method: 'view',
        attribute: '',
        yes: (access) => {
          return (
            <div className='col-12 col-lg offset-lg-2'>
              <SummarySupplierReportList supplierName={supplierName} supplierId={supplierId} />
            </div>
          )
        },
        no: (access) => { return null },
        userRules: user?.role
      })
    }
  }
}

export default SettlementReport
