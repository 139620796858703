import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'cookies-js'

import { OrderProvider } from '../../Context/OrderContext'

import OrderHeader from './OrderHeader'

import { fetchGetInvoiceList, fetchUpdateInvoice } from '../../Services/FetchingGroup'

import NewOrderDetail from './NewOrderDetail'
import ProcessingDetail from './ProcessingDetail'
import StandbyDetail from './StandbyDetail'
import ReadyToShipDetail from './ReadyToShipDetail'
import ShippedDetail from './ShippedDetail'
import ReceivedDetail from './ReceivedDetail'
import SearchOrderDetail from './SearchOrderDetail'

import ListActions from '../../Redux/ListRedux'

import { checkRole } from '../../Services/CheckRole'

export default function Orders ({ menu, keyword, supplier, supplierId, carrier, supplierName }) {
  const user = useSelector(state => state.user)
  const list = useSelector(state => state.list)

  const dispatch = useDispatch()

  const [active, setActive] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceList, setInvoiceList] = useState()
  const [params, setParams] = useState({
    status: menu,
    supplier: user.user?.role_id === 2 ? user.supplier?.supplier_alias : (Cookies.get('filterSupplier') || ''),
    page: 1,
    keyword: '',
    carrier_id: carrier || Cookies.get('filterCarrier') || ''
  })

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    setParams(paramsChange)
    fetchGetInvoiceList(paramsChange, setInvoiceList, setIsLoading, setErr)
  }

  const handleButton = () => {
    setActive(!active)
  }

  useEffect(() => {
    if (list.comment_topic === null) {
      dispatch(ListActions.commentTopicRequest())
    }
    if (list.supplier === null && user.user?.role_id !== 2) {
      dispatch(ListActions.supplierListRequest())
    }
    if (list.carrier === null) {
      dispatch(ListActions.carrierRequest())
    }

    if (user?.user?.role_id !== 2 && (supplierId || supplier)) {
      if (supplier) {
        Cookies.set('filterSupplier', supplier || '')
      } else if (carrier) {
        Cookies.set('filterCarrier', carrier || '')
      }
      setParams({ ...params, supplier: supplier || '', carrier_id: carrier || '' })
    }

    // eslint-disable-next-line
  }, [])

  //! FIX THIS
  // useEffect(() => {
  //   const filterSupplier = Cookies.get('filterSupplier') || supplier || ''
  //   const filterCarrier = Cookies.get('filterCarrier') || carrier || ''
  //   if (user?.user?.role_id !== 2) {
  //     if (filterSupplier || filterCarrier) {
  //       dispatch(CountActions.countRequest({ supplier: filterSupplier, carrier_id: filterCarrier }))
  //     } else {
  //       Cookies.set('filterSupplier', '')
  //       Cookies.set('filterCarrier', '')
  //       dispatch(CountActions.countRequest({ supplier: '', carrier_id: '' }))
  //     }
  //   } else {
  //     if (filterCarrier && filterCarrier !== '') {
  //       dispatch(CountActions.countRequest({ supplier: user.supplier?.supplier_alias, carrier_id: filterCarrier }))
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [Cookies.get('filterSupplier'), Cookies.get('filterCarrier'), user?.user?.role_id])

  function fetchUpdateInvoiceStatus (invoices, status) {
    const body = {}
    body.invoices = invoices
    body.status = status
    fetchUpdateInvoice(body, setIsLoading, setErr, null)
  }
  if (user?.role) {
    return (
      <OrderProvider
        menu={menu}
        handleButton={handleButton}
      >
        <div className='col-12 col-lg offset-lg-2 pl-0'>
          {menu !== 'search' &&
        (
          <div className='order-header'>
            <div className='px-5 pt-5'>
              <OrderHeader params={params} supplier={supplier} supplierName={supplierName} carrier={carrier} supplierId={supplierId} handleParamsChange={handleParamsChange} menu={menu} fetchGetInvoiceList={fetchGetInvoiceList} />
            </div>
          </div>

        )}
          <div className='container-fluid'>
            <div className='py-4'>
              {checkRole({
                menu: 'new_order',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'new') {
                    return <NewOrderDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}

              {checkRole({
                menu: 'processing',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'processing') {
                    return <ProcessingDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
              {checkRole({
                menu: 'standby',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'stand_by') {
                    return <StandbyDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
              {checkRole({
                menu: 'ready_to_ship',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'ready_to_ship') {
                    return <ReadyToShipDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
              {checkRole({
                menu: 'shipped',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'shipped') {
                    return <ShippedDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
              {checkRole({
                menu: 'received',
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'received') {
                    return <ReceivedDetail params={params} handleParamsChange={handleParamsChange} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                  }
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
              {checkRole({
                menu: 'new_order', // temp using new_order
                method: 'view',
                attribute: 'all',
                yes: (access) => {
                  if (menu === 'search') return <SearchOrderDetail params={params} handleParamsChange={handleParamsChange} keyword={keyword} fetchGetInvoiceList={fetchGetInvoiceList} invoiceList={invoiceList} fetching={isLoading} error={err} fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus} />
                },
                no: (access) => { return null },
                userRules: user?.role
              })}
            </div>
          </div>
        </div>
      </OrderProvider>
    )
  } else return null
}
