import React from 'react'

export default function PrintHeader (props) {
  const { print, status } = props

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 col-lg text-left p-0'>
          {/* STUCK DI LINK */}
          <a href={`/order/${(status === 'new' || status === 'processing')
          ? 'processing'
          : (status === 'ready_to_ship' || status === 'stand_by' || status === 'received' || status === 'shipped')
          ? status
          : null}`}
          >
            <button className='btn btn-primary btn-m mb-3'>
              <i className='anticon icon-arrowleft' />&nbsp;Kembali Ke Dashboard
            </button>
          </a>
        </div>
        <div className='col-12 col-lg text-right'>
          {status !== 'new'
            ? <div className='btn btn-primary btn-m' onClick={(e) => print(e)}>Print Halaman Ini</div>
            : null}
        </div>
      </div>
    </div>
  )
}
