import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import UpdateStock from '../../../Components/Products/Modals/UpdateStock'
import { fetchUpdateStock } from '../../../Services/FetchingGroup'
import { notifyError } from '../../../Services/Notify'

import { updateStockSchema } from '../../../Validations/Product'

export default function UpdateStockModal ({ params, handleToggleUpdateStockModal, product, handleChangeParams }) {
  // const user = useSelector(state => state.user)
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)
  const [err, setErr] = useState({ err: false, message: '' })

  const [stock, setStock] = useState({ status: 1 })

  const [isInStock, setIsInStock] = useState(1)

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStock({ ...stock, [name]: value })
  }

  const handleRadioBtnClick = (e) => {
    const isInStock = e.target.value
    setIsInStock(parseInt(isInStock))
  }

  async function handleUpdateStock () {
    const body = {
      sku: product.sku,
      status: parseInt(stock.status),
      qty_on_hand: parseInt(stock.qty_on_hand),
      store_code: params.store_code
    }
    setErr({ err: false, message: '' })
    const isValid = await updateStockSchema.isValid(body)
    if (isValid) {
      return fetchUpdateStock(body, setIsLoading, setErr, setSuccess)
    } else {
      const errorMessage = await updateStockSchema.validate(body).catch(function (err) {
        return err.errors[0]
      })
      setErr({ err: true, message: errorMessage })
      return notifyError(errorMessage)
    }
  }

  useEffect(() => {
    if (success.success) {
      handleToggleUpdateStockModal('close', product)
      handleChangeParams(params)
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <UpdateStock
      product={product}
      stock={stock}
      isInStock={isInStock}
      fetching={isLoading}
      error={err}
      handleToggleUpdateStockModal={handleToggleUpdateStockModal}
      handleUpdateStock={handleUpdateStock}
      handleRadioBtnClick={handleRadioBtnClick}
      handleOnChange={handleOnChange}
    />
  )
}
