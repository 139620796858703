import { string, object } from 'yup'

const phoneNumberPattern = /(^(08))\d{8,12}$/

const infoSchema = object().shape({
  phone: string()
    .matches(phoneNumberPattern, 'Nomor telepon tidak valid, hanya boleh angka')
    .min(5, 'Panjang nomor telepon minimal 5 karakter')
})

const statusSchema = object().shape({
  closed_until: string().required('Waktu penutupan toko harus di isi'),
  closed_at: string().required('Waktu penutupan toko harus di isi'),
  closed_notes: string().required('Catatan perlu diisi jika ingin menutup toko')

})

export { infoSchema, statusSchema }
