import React, { useState, useEffect, useRef, useContext } from 'react'
import dayjs from 'dayjs'

import { ScaleContext } from '../../Context/ScaleContext'
import { fetchCreateComment, fetchResolveComment } from '../../Services/FetchingGroup'

import { useSelector } from 'react-redux'
import { isEmpty, startCase } from 'lodash'

export default function OrderComment ({ invoice, from, params, handleParamsChange}) {
  const [status, setStatus] = useState('New Comment')
  const list = useSelector(state => state.list)
  const user = useSelector(state => state.user)
  const [comments, setComments] = useState(invoice.comments)
  const [chosenTopic, setChosenTopic] = useState(9)

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({ success: false, data: {} })

  const commentsEndRef = useRef(null)
  const [insertComment, setInsertComment] = useState('')
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  useEffect(() => {
    if(comments !== invoice.comments){
      setComments(invoice.comments)
    }
    
    if(isEmpty(comments)){
      setStatus('New Comment')
    } else {
      setStatus('Comment Activity')
      setChosenTopic(comments[comments.length-1].topic_id)
      
    }
  }, [comments, invoice.comments])
  
  useEffect(() => {
    if (err.err) {
      console.log(err.message)
      setErr({ err: false })
    }
  }, [err])

  useEffect(() => {
    if (success.success) {
      handleParamsChange(params)
      setSuccess({ success: false, data: {} })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
  
// for auto scroll to the bottom (newest comment)
useEffect(() => {
  if(invoice.invoice_id && !isEmpty(invoice.comments)){
    const objDiv = document.getElementById(`commentBox-${invoice.invoice_id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (objDiv !== null) {
      objDiv.scrollTop = objDiv.scrollHeight
    }
  }
})

function handleResolveComment (e){
const body ={invoice_id: invoice.invoice_id}
  fetchResolveComment(body, setIsLoading, setErr, setSuccess )
}

const addNewComment = () => {
  return (
    <div className='content justify-content-center align-items-center text-center' onClick={() => setStatus('Choose Topic')}>
      <div className='row'>
        <div className='col-12'>
          <i className='anticon icon-plus' />
        </div>
        <div className='col-12'>
          <h4>Tambahkan komentar baru</h4>
        </div>
      </div>
    </div>
  )
}

  const commentRight = (comment, key) => {
    
    // Parsing Date
    const dateObj = new Date(comment.created_at)
    const parseObj = dayjs(dateObj)
    const parseStringDate = parseObj.format('DD MMM YYYY')
    const parseStringTime = parseObj.format('HH:mm')
    
    return (
      <div className='fs--s' key={key}>
        <div className='row mx-0 align-items-center pb-4'>
          <div className={`pl-0 ${isMobileView ? 'col-5' : 'col-4'}`}>
            {parseStringDate} <br /> {parseStringTime}
          </div>
          <div className='col text-right'>
            <div className='row align-items-center'>
              <div className='col'>
              <span className='font-weight-bold'>{startCase(comment.supplier_user.first_name + ' ' + comment.supplier_user.last_name)}</span>
              </div>
              <div className='col-2 px-0'>
                <i className='icon anticon icon-user fs--xxl' />
              </div>
            </div>
          </div>
        </div>
        {comment.comment}
        <hr />
      </div>
    )
  }

  const commentLeft = (comment, key) => {
    // Parsing Date
    const dateObj = new Date(comment.created_at)
    const parseObj = dayjs(dateObj)
    const parseStringDate = parseObj.format('DD MMM YYYY')
    const parseStringTime = parseObj.format('HH:mm')
    return (
      <div className='fs--s' key={key}>
        <div className='row mx-0 align-items-center pb-4'>

          <div className='col text-left'>
            <div className='row align-items-center'>
              <div className='col-2 px-0'>
                <i className='icon anticon icon-customerservice fs--xxl' />
              </div>
              <div className='col'>
              <span className='font-weight-bold'>{startCase(comment.supplier_user.first_name + ' ' + comment.supplier_user.last_name)}</span>
              </div>
            </div>
          </div>
          <div className={`pl-0 text-right ${isMobileView ? 'col-5' : 'col-3'}`}>
            {parseStringDate} <br /> {parseStringTime}
          </div>
        </div>
        {comment.comment}
        <hr />
      </div>
    )
  }

  const chooseCommentTopic = () => {
    return (
      <div className='content'>
        <div className='row m-4'>
          <div className='col-12'>
            <div className='py-2'>
              <h4 className='font-weight-bold'>Pilih Topik Pesan</h4>
              <hr />
            </div>
          </div>
          <div className='col-12 pr-0'>
            <div className='comment-scroll'>
              {list.comment_topic?.comments.map((topic, key) => {
                return (
                  <div className='container-radio pb-2' key={key}>
                    <label className='fs--s'>{topic.name}
                      <input type='radio' value={topic.topic_id} name='topic' id={`${topic.topic_id} - ${topic.name}`} onChange={(e)=> handleChange(e)}/>
                      <span className='checked' />
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='col-12 bottom'>
            <div className='btn btn-full btn-s btn-primary' onClick={() => {setStatus('Comment Activity')}}> Pilih Topik</div>
          </div>
        </div>
      </div>
    )
  }

  const insertNewComment = () => {
    let userGroup = user?.role?.role_id === 1 ? 'ruparupa' : user?.role?.role_id === 2 ? 'seller' : null
    let body ={
      comment: insertComment,
      topic_id: chosenTopic,
      created_at: dayjs().locale('id').format('YYYY-MM-DD HH:mm'),
      user_group: userGroup,
       supplier_user: user.user,
       invoice_id: invoice.invoice_id
    }
    fetchCreateComment(body, setIsLoading, setErr, null)
    comments.push(body)
    setInsertComment('')
  }

  const insertOnEnter = (e) => {
    if (e.key === 'Enter') {
      insertNewComment()
    }
  }

  const handleChange = (e) => {
    const {value,name} = e.target
    if(name === 'topic'){
      setChosenTopic(parseInt(value))
    } else {
      setInsertComment(e.target.value)

    }
  }

  const renderCommentActivity = () => {
    return (
      <div className='content'>
        <div className='row mx-1'>
          <div className='col-12 mt-4'>
            <div className='row align-items-center'>
              <div className='col-3'>
                <span className='font-weight-bold'>Topik</span>
              </div>
              <div className='col'>
                <div className='input-text-bar'>
                <select className='input' name='topic' value={parseInt(chosenTopic)} onChange={(e)=> handleChange(e)}>
                {list.comment_topic?.comments.map((topic, index) =>{
                  return(
                    <option value={parseInt(topic.topic_id)} key={index}>{topic.name}</option>
                  )
                })}
                </select>
                </div>
              </div>
            </div>
            <hr />
            <div className='col-12 pr-0'>
              <div className='comment-scroll pr-3 my-4' id={'commentBox-' + invoice.invoice_id}>
                {/* UseComment */}
                {comments && comments.map((comment, key) => {
                    if (comment.user_group === 'seller') {
                      return (
                        commentRight(comment, key)
                      )
                    } else if (comment.user_group === 'ruparupa' || comment.user_group === 'admin' || comment.user_group === 'system') {
                      return (
                        commentLeft(comment, key)
                      )
                    } else return null
                  })
                 }
                <div ref={commentsEndRef} />
              </div>
            </div>

          </div>
          <div className='col-12 bottom align-items-center fs--xs  py-3'>

            <div className='input-bar input-bar-comment'>
              <input placeholder='Masukkan komentar anda di sini' type='text' value={insertComment} onKeyPress={ e => insertOnEnter(e)} onChange={(e)=> setInsertComment(e.target.value)}  />
              <button disabled={isLoading} className='comment-btn btn btn-primary fs--s font-weight-bold' onClick={e => insertNewComment(e)}>Kirim</button>
            </div>

          </div>
          { user?.user?.role_id !== 2 &&
          <div className='col-12'>
            <button className='btn btn-s btn-full btn-secondary-border ' onClick={(e) => handleResolveComment(e)}><i className='anticon icon-checkcircleo fs--s ' /> Resolve</button>
          </div>
          }
        </div>
      </div>

    )
  }

  switch (status) {
    case 'New Comment':
      return (
        // <div className={`container-card-default m-0 p-0 ${isMobileView ? 'comment-box-mobile' : 'comment-box-default'}`}>
        <div className='container-card-default m-0 p-0 comment-box-default'>
          {addNewComment()}
        </div>
      )

    case 'Choose Topic':
      return (
        <div className='container-card-default m-0 p-0 comment-box-default'>
          {chooseCommentTopic()}
        </div>
      )

    case 'Comment Activity':
      return (
        <div className='container-card-default m-0 p-0 comment-box-default'>
          {renderCommentActivity()}
        </div>
      )

    default:
      return (
        <div className='container-card-default m-0 p-0 comment-box-default'>
          {addNewComment()}
        </div>
      )
  }
}
