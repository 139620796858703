import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Cookies from 'cookies-js'

import Loading from '../../Components/Behaviors/Loading'
import SearchPendingProduct from '../../Components/SearchPendingProduct'
import CommentModal from '../Modals/CommentModal'
import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'
import Pagination from '../Pagination'
import PendingOrderGroup from './PendingOrderGroup'

export default function PendingMobile ({
  params,
  invoices,
  err,
  isLoading,
  supplierId,
  supplier,
  supplierName,
  countDisplayed,
  handleFilter,
  handleChangeStatus,
  handleParamsChange
}) {
  const list = useSelector(state => state.list)
  const user = useSelector(state => state.user)

  const [invoiceList, setInvoiceList] = useState()
  const [selectedInvoice, setSelectedInvoice] = useState()
  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    commentModal: false
  })
  useEffect(() => {
    if (invoices !== invoiceList) {
      setInvoiceList(invoices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices])

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  function onKeyDown (e) {
    if (e.key === 'Enter') {
      handleFilter(e)
    }
  }

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }

  function handleRenderModal () {
    return (
      <>
        {onClickedModal.seeProductListModal &&
          <SeeItemsListsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }

  function handleRender () {
    // in comment because the count were different ( need to get new count if you want to show the new count)
    const sumCount = countDisplayed && countDisplayed.pending_unprocessed + countDisplayed.pending_inprocessed + countDisplayed.pending_processed
    return (
      <div className='row'>
        <div className='col-12 mt-5'>
          <nav className='fs--s menu-overflow '>
            <div className='menu-scroll-x-mobile pending'>
              <ul className='nav nav-tab pending'>
                <li className='container-radio' style={{ width: '15%' }}>
                  <label className='fs--s'>Semua {countDisplayed && `(${sumCount})`}
                    <input type='radio' name='status' value='pending' id='radioBtn1' defaultChecked onChange={(e) => handleChangeStatus(e)} />
                    <span className='checked' />
                  </label>
                </li>
                <li className='container-radio' style={{ width: '25%' }}>
                  <label className='fs--s'>Belum Diproses Refund {countDisplayed && `(${countDisplayed.pending_unprocessed})`}
                    <input type='radio' name='status' value='pending_unprocessed' id='radioBtn2' onChange={(e) => handleChangeStatus(e)} />
                    <span className='checked' />
                  </label>
                </li>
                <li className='container-radio' style={{ width: '25%' }}>
                  <label className='fs--s'>Sedang Diproses Refund  {countDisplayed && `(${countDisplayed.pending_inprocessed})`}
                    <input type='radio' name='status' value='pending_inprocessed' id='radioBtn3' onChange={(e) => handleChangeStatus(e)} />
                    <span className='checked' />
                  </label>
                </li>
                <li className='container-radio' style={{ width: '25%' }}>
                  <label className='fs--s'>Sudah Diproses Refund  {countDisplayed && `(${countDisplayed.pending_processed})`}
                    <input type='radio' name='status' value='pending_processed' id='radioBtn4' onChange={(e) => handleChangeStatus(e)} />
                    <span className='checked' />
                  </label>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }

  const defaultSupplier = Cookies.get('filterSupplier') || params.supplier || ''

  return (
    <div className='col'>
      <div className='pending-header'>
        <div className='row py-3 border-bottom centralized-content'>
          <div className='col-12 pl-0 fs--m font-weight-bold text-center'>
            Pending Order {supplierName ? ('- ' + supplierName?.replace(/%20/g, ' ')) : ''}
            <span className='pl-4' style={{ float: 'left' }}>
              <Link to={user?.user?.role_id !== 2 ? `/profile/${supplierId}?type=default` : '/home'}>
                <span><i className='anticon icon-arrowleft' /></span>
              </Link>
            </span>
          </div>
        </div>
        {/* SORT BY */}
        <div className='pl-4 pr-4 mt-4'>
          <div className='row fs--s '>
            <div className='col-12 mb-4'>
              <SearchPendingProduct onKeyDown={onKeyDown} />
            </div>
            <div className='col'>
              <div className='row align-items-center'>
                {(list.supplier && user.user?.role_id !== 2 && !supplier) &&
                  <div className='col-12 font-weight-bold fs--s mb-4'>
                    Filter by
                  </div>}
                <div className='col-12 mb-4'>
                  <div className='input-text-bar'>
                    {list.supplier?.suppliers && !supplier &&
                      <select name='supplier' className='supplier' value={defaultSupplier} onChange={(e) => handleFilter(e)}>
                        <option value=''>Semua Seller</option>
                        {list.supplier?.suppliers?.map((supplier, index) => {
                          return (
                            <option value={supplier.supplier_alias} key={index}>{supplier.name} ({supplier.supplier_alias})</option>
                          )
                        })}
                      </select>}
                  </div>
                </div>
                {/* <div className='col-12 mb-4'>
                    <div className='input-text-bar'>
                      <select name='carrier' className='carrier_id' onChange={(e) => handleFilter(e)}>
                        <option value=''>Semua Kurir</option>
                        {list.carrier?.carriers?.map((carrier, index) => {
                          return (
                            <option value={carrier.carrier_id} key={index}>{carrier.carrier_name}</option>
                          )
                        })}}
                      </select>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        {handleRender()}

      </div>

      <div className='row mt-5'>
        <div className='col-12'>
          {invoiceList?.total_invoice > 10 &&
            <Pagination
              total={invoiceList?.total_invoice}
              show={10}
              classNames='pagination pull-right'
              handlePageClick={handlePageClick}
            />}

        </div>
      </div>
      {isLoading
        ? <Loading type='alt' />
        : invoiceList
          ? (
            <div className='px-4 pb-5'>
              {invoiceList?.invoices?.map((invoice, index) => {
                return (
                  <PendingOrderGroup
                    key={index}
                    invoice={invoice}
                    handleToogleModal={handleToogleModal}
                  />
                )
              })}
            </div>
            )
          : null}
      {handleRenderModal()}
    </div>
  )
}
