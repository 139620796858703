import { startCase } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../config'
import ProductHeader from '../../../Containers/Products/ProductHeader'
import SearchProduct from '../SearchProduct'
import SelectInput from '../SelectInput'

import imageDefault from '../../../static/images/WaitingForApproval.jpg'
import noImagePlaceholder from '../../../static/images/noImage.png'

export default function RejectedProductListMobile ({
  listProduct,
  params,
  sortList,
  supplierId,
  supplierDetail,
  handleSelect,
  handleChangeParams
}) {
  const selectedSort = useRef(0)

  useEffect(() => {
    const filteredSort = sortList?.filter(element => element.type === params.sort_type && element.by === params.sort_by)
    if (filteredSort?.length > 0) {
      selectedSort.current = filteredSort[0].id
    } else selectedSort.current = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='col-12 col-lg p-0 border-left'>
      <div className='product-header'>
        <ProductHeader supplierDetail={supplierDetail} supplierId={supplierId} params={params} type='rejectedProduct' />
      </div>
      <div className='product-background'>
        <div className='px-4 pt-4 pb-3'>
          <div className='row '>
            <div className='col-12'>
              <SearchProduct handleChangeParams={handleChangeParams} type='' />
            </div>
          </div>
        </div>
        <SelectInput
          label='Sort By'
          optionGroup={sortList}
          action={handleSelect}
          defaultValue={selectedSort.current}
          name='sort'
          labelClass='col-12 py-1 text-left font-weight-bold fs--s '
          inputClass='col-12'
        />

        <div className='px-4 pb-5'>
          {listProduct?.items?.map((product, index) => {
            return (
              <div className='product-list-container' key={index}>
                <div className='row align-items-center'>
                  {/* <div className='col-1 pl-3' style={{ alignSelf: 'flex-end' }}>
                  <input className='container-checkbox' type='checkbox' />
                  <label className='fs--s font-weight-bold' />
                </div> */}
                  <div className='col-3'>
                    <img src={product.image_url ? config.imageURL + product.image_url : imageDefault} alt='thumbnail' onError={(e) => { e.target.src = noImagePlaceholder }}/>
                  </div>
                  <div className='col-8 pr-0'>
                  <Link to={`/product-view/view?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`}>
                    <div className='fs--s font-weight-bold pb-2 '>{startCase(product.name)}</div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='pb-2 '>SKU Ruparupa: </div>
                          </div>
                          <div className='col-6'>
                            <div className='pb-2 '>{product.sku}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='pb-2 '>SKU Toko: </div>
                          </div>
                          <div className='col-6'>
                            <div className='pb-2 '>{product.sku_seller}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                </Link>
                    <div className='row mt-4'>
                    <Link className='col-12 text-right link' to={`/product-view/update?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`}>
                    <div className='btn btn-secondary btn-s btn-full btn-non-activation text-center'><i className='anticon icon-edit' /> Update</div>
                    </Link>
                      </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
