import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Pagination from '../../../Containers/Pagination'
import ProductHeader from '../../../Containers/Products/ProductHeader'
import ProductList from '../../../Containers/Products/List/ProductList'
import SearchProduct from '../SearchProduct'
import SelectInput from '../SelectInput'

export default function ProductListDesktop ({
  handleToggleBulkUploadModal,
  handlePageClick,
  listProduct,
  params,
  fetching,
  error,
  supplierId,
  supplierDetail,
  sortList,
  filterList,
  checkedValues,
  itemChecked,
  renderModal,
  handleChangeParams,
  handleSelect,
  handleSelectAll,
  handleCheckboxClick,
  handleToggleUpdatePriceModal,
  handleToggleUpdateStatusModal,
  handleToggleUpdateStockModal,
  handleToggleDeleteProductModal,
  handleToggleSetCommissionModal
}) {
  const [status, setStatus] = useState(params.status)

  const selectedSort = useRef(0)
  const selectedFilter = useRef(0)

  useEffect(() => {
    const filteredSort = sortList?.filter(element => element.type === params.sort_type && element.by === params.sort_by)
    if (filteredSort?.length > 0) {
      selectedSort.current = filteredSort[0].id
    } else selectedSort.current = 0

    const filteredList = filterList?.filter(element => element.value === params.status)
    if (filteredList?.length > 0) {
      selectedFilter.current = filteredList[0].id
    } else selectedFilter.current = 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (status !== params.status) {
      setStatus(params.status)
    }
  }, [params, status])

  if (!isEmpty(listProduct)) {
    return (
      <div className='col-12 col-lg offset-lg-2 px-0'>
        <div className='product-header'>
          <div className='p-5'>
            <ProductHeader supplierDetail={supplierDetail} supplierId={supplierId} handleToggleBulkUploadModal={handleToggleBulkUploadModal} params={params} type='listProduct' />
          </div>
        </div>
        <div className='product-background'>
          <div className='pt-5 pl-4 pr-4 pb-4'>
            <div className='row'>
              <div className='col-7'>
                <SearchProduct handleChangeParams={handleChangeParams} type='' />
              </div>
              <div className='col'>
                <div className='row align-items-center fs--s '>
                  <SelectInput
                    label='Sort By'
                    optionGroup={sortList}
                    action={handleSelect}
                    defaultValue={selectedSort.current}
                    name='sort'
                    labelClass='col-3 text-center font-weight-bold'
                    inputClass='col'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='pl-4 pr-4 pb-5'>
            <div className='row align-items-center filter-header fs--m'>
              <SelectInput
                label='Filter '
                optionGroup={filterList}
                action={handleSelect}
                defaultValue={selectedFilter.current}
                name='filter'
                labelClass='col-1 text-center font-weight-bold'
                inputClass='col'
              />
              <div className='col'>
                {listProduct?.total > 10 &&
                  <Pagination
                    total={listProduct?.total}
                    show={10}
                    classNames='pagination pull-right'
                    handlePageClick={handlePageClick}
                  />}
              </div>
            </div>
            <ProductList
              params={params}
              listProduct={listProduct}
              fetching={fetching}
              error={error}
              supplierId={supplierId}
              handleSelectAll={handleSelectAll}
              handleCheckboxClick={handleCheckboxClick}
              handleChangeParams={handleChangeParams}
              checkedValues={checkedValues}
              itemChecked={itemChecked}
              handleToggleUpdatePriceModal={handleToggleUpdatePriceModal}
              handleToggleUpdateStatusModal={handleToggleUpdateStatusModal}
              handleToggleUpdateStockModal={handleToggleUpdateStockModal}
              handleToggleDeleteProductModal={handleToggleDeleteProductModal}
              handleToggleSetCommissionModal={handleToggleSetCommissionModal}
            />
          </div>
        </div>
        {renderModal()}
      </div>
    )
  } else return null
}
