import React, { useEffect, useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { fetchUpdateProductDetailBulk } from '../../../Services/FetchingGroup'

// import UpdateProduct from '../../Products/Bulk/UpdateProduct'
import BulkUpdateProduct from '../../Products/Bulk/BulkUpdateProduct'

// import InsertProduct from '../../Products/Bulk/InsertProduct'

export default function BulkUploadModal ({ params, handleChangeParams, onClickedBulkModal, handleToggleBulkUploadModal }) {
  const [tab] = useState('Update')
  const [fileUpdate, setFileUpdate] = useState()
  const [fileInsert, setFileInsert] = useState()

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  // function handleTab (tab) {
  //   setTab(tab)
  // }

  function handleFile (file) {
    if (file?.tab === 'insert') {
      setFileInsert(file)
    } else if (file?.tab === 'Update') {
      setFileUpdate(file)
    } else return null
  }

  function handleUpload () {
    const body = { file: '' }
    if (tab === 'insert') {
      console.log(fileInsert)
    } else if (tab === 'Update') {
      body.file = fileUpdate?.file
      fetchUpdateProductDetailBulk(body, setIsLoading, setErr, setSuccess)
    }
  }

  useEffect(() => {
    if (success.success) {
      handleToggleBulkUploadModal('close')
      handleChangeParams(params)
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (onClickedBulkModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => handleToggleBulkUploadModal('close')}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xm font-weight-bold p-3'>Bulk Upload Update Product</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container pt-3 pb-3'>
            <div className='fs--s text-center align-items-center'>
              {/* waiting for add */}
              {/* <div className='row justify-content-center align-items-center'>
                <div className='col-12 col-lg-4 d-inline-block mb-4'>
                  <b>Bulk Upload for</b>
                </div>
                <div className='col-6 col-lg-3 text-left my-2 '>
                  <div className='container-radio'>
                    <label className='fs--s'>Add Product
                      <input type='radio' name='radio' id='radioBtn1' value='insert' defaultChecked onChange={(e) => handleTab(e.target.value)} />
                      <span className='checked' />
                    </label>
                  </div>
                </div>
                <div className='col-6 col-lg-4 text-left my-2'>
                  <div className='container-radio'>
                    <label className='fs--s'>Update Product
                      <input type='radio' name='radio' id='radioBtn2' value='update' onChange={(e) => handleTab(e.target.value)} />
                      <span className='checked' />
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <BulkUpdateProduct setError={setErr} handleFile={handleFile} tab={tab} initialFile={tab === 'update' ? fileUpdate : fileInsert} />
          {/* <UpdateProduct handleFile={handleFile} tab={tab} initialFile={fileUpdate} /> */}
          <div className='row'>
            <div className='col-12'>
              {
                (err.err) &&
                    (
                      <div className='row px-4'>
                        <div className='col-12 mt-4 text-center fs--s '>
                          <Alert variant='danger'>{err.message}</Alert>
                        </div>
                      </div>
                    )
              }
            </div>
          </div>
          {/* <InsertProduct handleFile={handleFile} tab={tab} initialFile={fileInsert} /> */}
        </Modal.Body>
        <Modal.Footer className=' justify-content-center text-center m-4'>
          <div className='col'>
            <button type='button' onClick={() => handleToggleBulkUploadModal('close')} disabled={isLoading} className='btn-ghost-primary btn-m btn-full'>Close</button>
          </div>
          <div className='col'>
            <button type='button' onClick={() => handleUpload()} disabled={isLoading} className='btn-primary btn-m btn-full'>{isLoading ? 'Mohon Menunggu ...' : 'Upload'}</button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  } else return null
}
