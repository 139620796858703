import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ProfileContext } from '../../../Context/ProfileContext'

export default function ProfileHomeMobile ({
  supplierId,
  type,
  renderContent
}) {
  const [attContextProfile] = useContext(ProfileContext)
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 py-3 border-bottom centralized-content'>
          {(type === 'settings')
          // <Link to={`/profile/${supplierId}?type=rating_review`}> <h1 className='font-weight-bold fs--m text-center'><i className='anticon icon-left' /> Pengaturan Toko</h1></Link>
            ? (
              <div className='col-12 pl-0 fs--m font-weight-bold text-center'>
                Pengaturan Toko
                <span style={{ float: 'left' }}>
                  <Link to={`/profile/${supplierId}?type=default`}>
                    <span><i className='anticon icon-arrowleft' /></span>
                  </Link>
                </span>
              </div>
              )
            : (
              <div className='col-12 pl-0 fs--m font-weight-bold text-center'>
                Profil Toko
                <span style={{ float: 'left' }}>
                  <Link to='/home'>
                    <span><i className='anticon icon-arrowleft' /></span>
                  </Link>
                </span>
              </div>)}
        </div>
        {attContextProfile?.supplier_data && renderContent()}
      </div>
    </div>
  )
}
