
import { isEmpty } from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
// import { Alert } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import config from '../../../config'
import { ScaleContext } from '../../../Context/ScaleContext'
// import api from '../../../Services/IndexApi'
// import isEmpty from 'lodash'

export default function ProductImageUploadForm ({ index, pushImageProduct, method, handleValidationErr, handleImage, angle, product, imageCollections }) {
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext
  const [status, setStatus] = useState(10)
  const maxSize = 1048576

  const minDimension = 200
  const maxDimension = 1000

  //! Need to make the temp for default image
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      fileAccepted(acceptedFiles)
      setStatus(10)
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })))
    },
    multiple: false
  })
  const [initialFile, setInitialFile] = useState()
  const [files, setFiles] = useState([])
  const [errMessage, setErrMessage] = useState('')

  useEffect(() => {
    if (method === 'update' && !isEmpty(imageCollections) && initialFile !== '') {
      const filter = imageCollections?.filter(element => element.angle === angle.angle)
      let imageUrl = ''
      if (!isEmpty(filter)) {
        if (filter[0].image !== '') {
          imageUrl = config.imageURL + filter[0].image
        }
        if (filter[0].status !== status) {
          setStatus(filter[0].status)
        }
      }
      setInitialFile(imageUrl)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(files)) {
      handleValidationErr('err', errMessage, angle.label)
    }
    // eslint-disable-next-line
  }, [errMessage, files])

  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    let isErr = false
    reader.onload = e => {
      const result = e.target.result
      const size = e.total
      const image = new Image()
      image.src = result
      image.onload = () => {
        if (size > maxSize) {
          setErrMessage(` Foto ${angle.label} size melebih limit yang telah tertera`)
        } else if ((image.height >= minDimension && image.width >= minDimension) && (image.height <= maxDimension && image.width <= maxDimension)) {
          if (image.height !== image.width) {
            isErr = true
            setErrMessage(` Foto ${angle.label} harus berbentuk persegi`)
          }
        } else {
          isErr = true
          setErrMessage(`Foto ${angle.label} harus memiliki dimensi 500 - 1000 pixels`)
        }
      }
      if (!isErr) {
        handleImage('insert', result, angle.angle, 10)
        setStatus(10)
        setErrMessage('')
      }
    }
  }

  const handleCheckboxClick = (e) => {
    const { checked } = e.target
    let status = 0
    if (checked) {
      status = 10
    } else {
      status = 0
    }
    setStatus(status)
    handleImage('status', files, angle.angle, status)
  }

  const handleDeleteImage = (e) => {
    setFiles([])
    if (method === 'update') {
      setStatus(-1)
      setInitialFile('')
    }
    handleImage('delete', '', angle.angle, -1)
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((error, index) => {
          return error.code === 'file-invalid-type' ? 'Tipe foto harus .jpg, .jpeg, .png' : 'Ukuran Foto lebih dari 1 MB  '
        })
        return setErrMessage(errorMessage)
      })
    }
  }, [fileRejections])

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  function previewImage () {
    let src = ''
    if (!isEmpty(initialFile) && initialFile !== '') {
      src = initialFile
    }
    if (!isEmpty(files)) {
      src = files.map(file => {
        return file.preview
      })
    }
    // if (method === 'update' && status === -1) { src = '' }
    if (src !== '') {
      return (
        <label htmlFor={'image' + index} key={index} className='preview-container border'>
          <img src={src} alt='images' />
        </label>
      )
    } else return null
  }
  const uploadProduct = () => {
    if (isMobileView) {
      return (
        <div className='col-4'>
          <div className='row'>
            <div {...getRootProps({ className: 'dropzone ' })}>
              <div className='dropzone-product text-center m-auto'>
                <input {...getInputProps()} className='dropzone-area' />
                <div className={`dropzone-space ${(files.length !== 0 || !isEmpty(initialFile)) && 'd-none'}`}><i className='anticon icon-plus' /></div>
              </div>
              {previewImage()}
              <div className='dropzone-title fs--xs text-center'>{angle.label}{angle.label === 'Utama' && <span className='required'>*</span>}
              </div>
            </div>
            {
              !isEmpty(files) && <div className='dropzone-delete' onClick={(e) => handleDeleteImage(e)}>Hapus <i className='anticon icon-delete ' /></div>
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className='col align-content-center'>

          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className='dropzone-product'>
              <div className='dropzone-area text-center m-auto'>
                <div className='dropzone-space d-flex' style={(files.length !== 0 || !isEmpty(initialFile)) ? { opacity: 0 } : { opacity: 1 }}><i className='anticon icon-plus' /></div>
                <div className='dropzone-title'>{angle.label}{angle.label === 'Utama' && <span className='required'>*</span>}
                </div>
              </div>
            </div>
          </div>
          {previewImage()}
          {
            (!isEmpty(files) || !isEmpty(initialFile)) &&
              <div>
                <span className='dropzone-delete' onClick={(e) => handleDeleteImage(e)}>Hapus <i className='anticon icon-delete ' /></span>
                <label className='container-checkbox product-form mb-0' key={index}>
                  <input
                    type='checkbox'
                    name='status'
                    defaultChecked={status === 10}
                    onChange={(e) => handleCheckboxClick(e)}
                  />
                  <span className='checkbox product-form' />
                  <span>Aktif</span>
                </label>
              </div>
          }
        </div>
      )
    }
  }

  return (
    <>
      {uploadProduct()}
    </>
  )
}
