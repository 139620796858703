import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Pagination ({ total, show, classNames, handlePageClick, initialPage }) {
  return (
    <ReactPaginate
      previousLabel='<'
      nextLabel='>'
      breakLabel='...'
      breakClassName='break-me'
      containerClassName={classNames}
      subContainerClassName='pages pagination'
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      activeClassName='active'
      onPageChange={handlePageClick}
      initialPage={initialPage || 0}
      pageCount={Math.ceil(parseInt(total) / parseInt(show)) || 0}
    />

  )
}
