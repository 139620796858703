import React from 'react'
import dayjs from 'dayjs'

export default function PrintListProducts ({ invoices, invoiceId }) {
  if (invoices && invoices.invoices?.length > 0) {
    return (
      <div className='container-fluid'>
        {invoices?.invoices?.map((invoice, index) => {
          return (
            <div key={index}>
              <div className='fs--m py-2'>
                <b>{invoice.order_no} - ({dayjs(invoice.created_at).locale('id').format('DD MMM YYYY, HH:mm')}) - {invoice.invoice_no}</b>
              </div>
              <table className='table fs--s  border__size--medium'>
                <thead>
                  <tr className='font-weight-bold border__size--medium'>
                    <th width='10%'>SKU</th>
                    <th width='40%'>Nama Produk</th>
                    <th width='5%'>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.items.map((item, index) => {
                    return (
                      <tr className='fs--s' key={index}>
                        <td>{item.sku}</td>
                        <td>{item.name}</td>
                        <td>{item.qty_ordered} Pcs</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
        )}

      </div>

    )
  } else return null
}
