import React from 'react'
import { useSelector } from 'react-redux'

export default function ProfileStatusMobile ({
  supplierData,
  status,
  closedTime,
  fetching,
  error,
  checkedValues,
  renderStatus,
  renderDatePicker,
  setNotes,
  handleCheckboxClick,
  handleChangeStatus,
  handleInit
}) {
  const user = useSelector(state => state.user)
  return (
    <div className='row fs--s '>
      <div className='col-12  border p-4 mb-4'>
        <div className='row '>
          <div className='col-12 d-flex border-bottom'>
            <i className='anticon icon-appstore-o' />
            <p className='font-weight-bold pl-3 fs--s '>Status Toko</p>
          </div>
          <div className='col-12 pt-3 fs--s '>
            {renderStatus()}
          </div>
        </div>
      </div>
      <div className='col-12 border p-4 mb-4'>
        <div className='row'>
          <div className='col-12 d-flex border-bottom'>
            <i className='anticon icon-isv' />
            <p className='font-weight-bold pl-3 fs--s '>Tutup Toko</p>
          </div>
          {user.user?.role_id === 1 &&
            <div className='col-12 px-1 py-4'>
              <label className='container-checkbox mb-0'>
                <input
                  type='checkbox'
                  name='express_carrier'
                  value
                  checked={checkedValues || status === -1}
                  disabled={status === -1 || status === 0}

                  onChange={(e) => handleCheckboxClick(e)}
                />
                <span className='checkbox' />
                <span>Tutup Selamanya</span>
              </label>
            </div>}
          <div className='col-12  pb-3'>
            <div className='row centralized-content'>
              <p className='col-4 pt-2'>Mulai Tanggal</p>
              <div className='col-8'>
                <div className='input-text-bar'>
                  {renderDatePicker('closed_at', closedTime.closed_at)}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12  pb-3'>
            <div className='row centralized-content'>
              <p className='col-4 pt-2'>Sampai Tanggal</p>
              <div className='col-8'>
                <div className='input-text-bar'>
                  {renderDatePicker('closed_until', closedTime.closed_until)}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12  pb-1'>
            <p>Catatan</p>
          </div>

          <div className='col-12 pb-3'>
            <textarea disabled={fetching || status === -1 || status === 0} className='col-12 fs--s ' placeholder='Tulis alasan tutup toko disini...' rows='5' onChange={(e) => setNotes(e.target.value)} />
          </div>
        </div>
      </div>

      {status === 10 && (
        <div className='col-12 pb-4'>
          <button className='btn btn-secondary-border btn-m btn-full fs--s ' disabled={fetching || status === -1 || status === 0} onClick={() => handleInit()}>Reset</button>
        </div>
      )}
      {(supplierData?.closed_at && supplierData?.closed_until) || status === -1 || status === 0
        ? (
          <div className='col-12'>
            <button disabled={fetching} onClick={(e) => handleChangeStatus(e, 10)} className='btn btn-primary btn-m btn-full fs--s '><b>{fetching ? 'Mohon Menunggu ...' : 'Buka Sekarang'}</b></button>
          </div>
        )
        : (
          <div className='col-12'>
            <button disabled={fetching} onClick={(e) => handleChangeStatus(e, 0)} className='btn btn-primary btn-m btn-full fs--s '><b>{fetching ? 'Mohon Menunggu ...' : 'Simpan'}</b></button>
          </div>
        )}
    </div>
  )
}
