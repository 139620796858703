import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEqual, isEmpty } from 'lodash'

import config from '../../../config'
import NumberWithCommas from '../../../Utils/NumberWithCommas'
import Loading from '../../../Components/Behaviors/Loading'
import imageDefault from '../../../static/images/WaitingForApproval.jpg'

import noImagePlacholder from '../../../static/images/noImage.png'

import CountActions from '../../../Redux/CountRedux'

import { ScaleContext } from '../../../Context/ScaleContext'
import { fetchDeleteBulkProduct, fetchUpdateProductStatusBulk } from '../../../Services/FetchingGroup'

export default function ProductList ({
  params,
  listProduct,
  response,
  supplierId,
  fetching,
  error,
  handleChangeParams,
  handleToggleUpdatePriceModal,
  handleToggleUpdateStatusModal,
  handleToggleUpdateStockModal,
  handleToggleDeleteProductModal,
  handleToggleSetCommissionModal
}) {
  
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext
  const [checkedValues, setCheckedValues] = useState([])
  const [itemChecked, setItemChecked] = useState(false)

  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isLoadingActivation, setIsLoadingActivation] = useState(false)
  const [isLoadingNonActivation, setIsLoadingNonActivation] = useState(false)
  
  const [success, setSuccess] = useState({ success: false, data: {} })


  useEffect(() => {
    if (success.success) {
      handleChangeParams(params)
      setCheckedValues([])
      setItemChecked(false)
      dispatch(CountActions.countProductRequest({}))
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target

    if (checked) {
      setCheckedValues([...checkedValues, value])
    } else {
      setCheckedValues(checkedValues.filter(item => item !== value))
    }
  }  

  const handleSelectAll = (e) => {
    const { checked } = e.target
    const collection = []
    const items = listProduct.items
    if (checked && items) {
      items.forEach(element => {
        if(element.status_mp === 'approved'){
          collection.push(element.sku)
        }
      })
    }
    setCheckedValues(collection)
    setItemChecked(checked)
  }

  function handleDeleteMultipleProduct () {
    let body = {sku: checkedValues.toString()}
    fetchDeleteBulkProduct(body,  setIsLoadingDelete, null, setSuccess)
  }

  function handleActivationMultipleProduct () {
    let body = {
      products: []
    }
    checkedValues.forEach(element => {
      let split = {
        sku: element,
        status: 10
      }
       body.products.push(split)
    });
    fetchUpdateProductStatusBulk(body, setIsLoadingActivation, null, setSuccess)
  }

  function handleNonActivationMultipleProduct () {
    let body = {
      products: []
    }
    checkedValues.forEach(element => {
      let split = {
        sku: element,
        status: 0
      }
       body.products.push(split)
    });
    fetchUpdateProductStatusBulk(body, setIsLoadingNonActivation, null, setSuccess)

  }
  useEffect(() => {
    if (!isEmpty(listProduct?.items)) {
      if (isEqual(checkedValues.length, listProduct?.items?.length)) {
        setItemChecked(true)
      } else {
        setItemChecked(false)
      }
    }
  }, [checkedValues, listProduct])


  function renderBody () {
    if (fetching) {
      return (
        <tr>
          <td colSpan={6}>
            <Loading type='alt' />
          </td>
        </tr>
      )
    } else if (error.err) {
      return (
        <tr>
        <td colSpan={6}>
        <div className='row text-center align-content-center' style={{ height: '400px' }}>
      <div className='col-12'>
        <span className='fs--m error'>{error.message}</span>
      </div>
    </div>
        </td>
        </tr>
      )
    } else {
      if(listProduct?.items?.length > 0){
        return listProduct?.items?.map((product, key) => {
          let status = ''
          let colorStatus = ''
          if( product.status_mp === 'pending' ){
            status = 'Pending'
            colorStatus = 'pending-text'
          } else {
            if (product.status === 10) {
              status = 'Active'
            colorStatus = 'actived'
            } else {
              status = 'Non Active'
            }
          }
          let isDisabled = (product?.status_mp === 'pending' ? 'not-allowed pending-product' : '')
          return (
            <tr className='fs--m' key={key}>
              <td className='py-5'>
                <label className='container-checkbox'>
                  <input
                    type='checkbox'
                    value={product.sku}
                    checked={checkedValues.includes(product.sku)}
                    onChange={handleCheckboxClick}
                    disabled={product.status_mp === 'pending'}
                  />
                  <span className='checkbox' />
                </label>
              </td>
              <td className='py-5'>
                <div className='row'>
                  <div className={`col-3 ${product.status_mp === 'pending' ? 'pending-product' : ''}`}>
                    <img src={product.image_url ? config.imageURL + product.image_url : imageDefault} onError={(e) => { e.target.src = noImagePlacholder }} alt='thumbnail' />
                  </div>
                  <Link className={'col ' + isDisabled}  to={`/product-view/view?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`} >
                    <div className='title-product font-weight-bold'>{product.name}</div>
                    <div className='desc-product '>{product.categories}</div>
                  </Link>
                </div>
              </td>
              <td className='py-5'>{product.sku}</td>
              <td className='py-5'>{product.sku_seller}</td>
              <td className='py-5'>
                <div className='pb-2'>Rp. {NumberWithCommas(product.price)}</div>
                <div className='pb-2 link' onClick={() => { handleToggleUpdatePriceModal('open', product)}}>
                  <i className='anticon icon-tago' /> Ubah Harga</div>
                {user.user?.role_id !== 2 &&
                <div className={'pb-2 link' + isDisabled} onClick={() => {
                  if(isDisabled === ''){
                  handleToggleSetCommissionModal('open', product)
                }
                }
              }><i className='anticon icon-tago' /> Atur Komisi</div>
            }
            <div className={'pb-2 link ' + isDisabled} onClick={() => {
                   if(isDisabled === ''){
                  handleToggleUpdateStockModal('open', product)
                }
                }}>
                  <i className='anticon icon-inbox' /> Atur Stock</div>
              </td>
              <td className='py-5'>
                <div className='pb-2'><b>{product.quantity_on_hand}</b></div>
                <div className='pb-2 fs--m'>Status: <b className={colorStatus}>{status}</b></div>
                {product.status_mp !== 'pending' &&
                (
                  product.status !== 10
                  ? <button disabled={product.status_mp === 'pending'} className='btn btn-positive-border btn-full btn-non-activation fs--m' onClick={() => handleToggleUpdateStatusModal('open', product)} width='50%'><i className='anticon icon-checkcircleo' /> Set Aktif</button>
                  : <button disabled={product.status_mp === 'pending'} className='pb-2 btn btn-ghost-primary btn-s btn-full btn-non-activation fs--m' onClick={() => handleToggleUpdateStatusModal('open', product)}><i className='anticon icon-closesquareo' /> Set Non Aktif</button>
                )}
              </td>
              <td className='py-5 pl-4'>
                <Link className={`link ` + isDisabled} to={`/product-view/update?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${supplierId}`}>
                <div ><i className={`anticon icon-edit fs--xm ` + isDisabled} /></div>
                  </Link>
                <div className={`link ` + isDisabled} onClick={() => {
                   if(isDisabled === ''){
                     handleToggleDeleteProductModal('open', product)}
                     }
                     }><i className='anticon icon-delete fs--xm delete' /></div>
              </td>
            </tr>
          )
        })
      } else {
        return (
          <tr>
          <td colSpan={6}>
          <div className='row text-center align-content-center' style={{ height: '400px' }}>
        <div className='col-12'>
          <span className='fs--m'>Produk tidak ada</span>
        </div>
      </div>
          </td>
          </tr>
        )
      }
    }
  }
  const renderDesktop = () => {
    return (
      <>
        <table className='table fs--m'>
          <thead>
            <tr className=''>
              <th className='py-5' width='5%'>
                <label className='container-checkbox'>
                  <input
                    type='checkbox'
                    checked={itemChecked}
                    onChange={handleSelectAll}
                  />
                  <span className='checkbox' />
                </label>
              </th>
              <th className='py-5' width='35%'>Produk</th>
              <th className='py-5' width='15%'>SKU Ruparupa</th>
              <th className='py-5' width='10%'>SKU Toko</th>
              <th className='py-5' width='15%'>Harga</th>
              <th className='py-5' width='15%'>Stock</th>
              <th className='py-5' width='5%' />
            </tr>
          </thead>
          <tbody>    
            {renderBody()}
          </tbody>
        </table>
        {checkedValues && checkedValues.length > 0 &&
          <div className='container-checklist product'>
            <div className='row align-items-center'>
              <div className='col-1 text-center'>
                <b>{checkedValues.length} dipilih</b>
              </div>
              <div className='border-left col-2'>
                <button onClick={() => handleNonActivationMultipleProduct()} disabled={isLoadingNonActivation} className='btn btn-negative-border btn-m btn-full'><b>{isLoadingNonActivation ? 'Mohon Menunggu ...' : 'Set Non Aktif'}</b></button>
              </div>
              <div className='col-2'>
                <button onClick={() => handleActivationMultipleProduct()} disabled={isLoadingActivation} className='btn btn-primary btn-m btn-full'><b>{isLoadingActivation ? 'Mohon Menunggu ...' : 'Set Aktif'}</b></button>
              </div>
              <div className='offset-5 col-2 pull-right'>
                <button onClick={() => handleDeleteMultipleProduct()} disabled={isLoadingDelete} className='btn btn-negative btn-m btn-full'><b>{isLoadingDelete ? 'Mohon Menunggu ...' : 'Hapus'}</b></button>
              </div>
            </div>
          </div>}
        </>
    )
  }

  const renderMobile = () => {
    if(fetching){
      return(
        <Loading type='alt'></Loading>
      )
    } else if (error.err){
      return(
        <div className='row'>
          <div className='col-12 error fs--s '>{error.message}</div>
        </div>
      )
    } else {
      if(listProduct?.items?.length > 0){
        return listProduct?.items?.map((product, index) => {
          let status = ''
          if (product.status === 10) {
            status = 'Active'
          } else {
            status = 'Non Active'
          }
          let isDisabled = (product.status_mp === 'pending' ? 'not-allowed pending-product' : '')
          return (
            <div className='product-list-container' key={index}>
              <div className='row align-items-center'>
                {/* <div className='col-1 pl-3' style={{ alignSelf: 'flex-end' }}>
                  <input className='container-checkbox' type='checkbox' />
                  <label className='fs--s font-weight-bold' />
                </div> */}
                <div className='col-3'>
                <img src={product.image_url ? config.imageURL + product.image_url : imageDefault} alt='thumbnail' />
                </div>
                <div className='col-8 pr-0'>
                <Link to={`/product-view/view?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`} className='col'>
                  <div className='fs--s font-weight-bold pb-2 '>{product.name}</div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='pb-2 '>SKU Ruparupa: </div>
                        </div>
                        <div className='col-6'>
                          <div className='pb-2 '>{product?.sku}</div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='pb-2 '>SKU Toko: </div>
                        </div>
                        <div className='col-6'>
                          <div className='pb-2 '>{product?.sku_seller}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                </div>
              </div>
              <div className='row pt-2'>
                <div className='col-4'><b>Harga</b></div>
                <div className='col-8'>Rp {NumberWithCommas(product?.price)}</div>
              </div>
              <div className='row pt-4'>
                {/*  todo: need to  make it into modal */}
                <div className='col-12 py-2'>
                    <button className='btn-secondary btn-s w-100' disabled={product.status_mp === 'pending'} onClick={() => handleToggleUpdatePriceModal('open', product)}>
                      <i className='anticon icon-tagso' /> Ubah Harga
                    </button>
                </div>
                {user.user?.role_id !== 2 &&
                <div className='col-12 py-2'>
                    <button className='btn-secondary btn-s w-100' onClick={() => handleToggleSetCommissionModal('open', product)}>
                      <i className='anticon icon-tagso' /> Atur Komisi
                    </button>
                </div>}
                {/* <div className='col-6'>
                  <Link to='/review-rating'>
                    <button className='btn-secondary btn-s' style={{ width: '100%' }}>
                      <i className='anticon icon-staro' /> Review & Rating
                    </button>
                  </Link>
                </div> */}
              </div>
              <div className='row pt-4'>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-5'>
                      <b>Stok</b>
                    </div>
                    <div className={`col-3 link ${product.status_mp === 'pending' ? 'not-allowed' : ''}`} onClick={() => handleToggleUpdateStockModal('open', product)}>
              {product?.quantity_on_hand}
                    </div>
                    <Link className={`col-4 text-right link ` + isDisabled} to={`/product-view/update?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`}>
                      <i className='anticon icon-edit' />
                  </Link>
                    </div>
                </div>
                <div className='col-6'>
                  <div className='row'>
                    <div className='col-9'>Status:&nbsp;<b className={product.status === 10 ? 'actived' : 'non-actived'}>{status}</b></div>
                    <div className='col-3 link'onClick={() => handleToggleDeleteProductModal('open', product)}>
                      <i className='anticon icon-delete' />
                    </div>
                  </div>
                </div>
              </div>
                <div className='row text-center pt-3 py-3 px-4'>
                {product?.status !== 10
                      ? <div className='btn btn-secondary btn-full btn-non-activation text-center' onClick={() => handleToggleUpdateStatusModal('open', product)} width='50%'><i className='anticon icon-checkcircleo' /> Set Aktif</div>
                      : <div className='pb-2 btn btn-ghost-secondary btn-s btn-full btn-non-activation text-center' onClick={() => handleToggleUpdateStatusModal('open', product)}><i className='anticon icon-closesquareo' /> Set Non Aktif</div>
                      }
                </div>
            </div>
          )
        })
      } else {
        return(
          <div className='row'>
          <div className='col-12 fs--s '>Produk Tidak ada</div>
        </div>
        )
      }
    }

  }
  return isMobileView ? renderMobile() : renderDesktop()
  // }
}
