import React, { useContext, useEffect, useState } from 'react'

import { fetchChangeSupplierStatus } from '../../Services/FetchingGroup'
import { statusSchema } from '../../Validations/Profile'
import { ProfileContext } from '../../Context/ProfileContext'
import DateTimePicker from 'react-datetime-picker'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import ProfileStatusMobile from '../../Components/Profiles/Status/ProfileStatusMobile'
import { ScaleContext } from '../../Context/ScaleContext'
import ProfileStatusDesktop from '../../Components/Profiles/Status/ProfileStatusDesktop'
import { notifyError } from '../../Services/Notify'

export default function ProfileStatus () {
  const [attContextProfile] = useContext(ProfileContext)
  const supplierData = attContextProfile?.supplier_data
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)

  const [closedTime, setClosedTime] = useState({ closed_at: '', closed_until: '' })
  const [notes, setNotes] = useState('')
  const [status, setStatus] = useState(supplierData.status)
  const [checkedValues, setCheckedValues] = useState(0)

  const handleInit = () => {
    setNotes('')
    setClosedTime(
      {
        closed_at: supplierData.closed_at ? new Date(supplierData.closed_at) : '',
        closed_until: supplierData.closed_until ? new Date(supplierData.closed_until) : ''
      }
    )
    setSuccess({ success: false, data: {} })
    setStatus(supplierData.status)
  }

  useEffect(() => {
    handleInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleChangeStatus (e, status) {
    e.preventDefault()
    const parseClosedTime = { ...closedTime }
    let notesModified = notes
    let statusSupplier = status

    if (isEmpty(checkedValues)) {
      if (status === 0) {
        if (parseClosedTime.closed_at && parseClosedTime.closed_until) {
          parseClosedTime.closed_at = dayjs(parseClosedTime.closed_at).format('YYYY-MM-DD')
          parseClosedTime.closed_until = dayjs(parseClosedTime.closed_until).format('YYYY-MM-DD')
        }
      } else {
        parseClosedTime.closed_at = '-'
        parseClosedTime.closed_until = '-'
        notesModified = '-'
      }
    } else {
      statusSupplier = -1
      parseClosedTime.closed_at = '-'
      parseClosedTime.closed_until = '-'
    }

    const body = {
      supplier_id: supplierData.supplier_id,
      ...parseClosedTime,
      closed_notes: notesModified,
      status: statusSupplier
    }
    const isValid = await statusSchema.isValid(body)
    if (isValid) {
      setErr({ err: false, message: '' })
      fetchChangeSupplierStatus(body, setIsLoading, setErr, setSuccess)
    } else {
      const errorMessage = await statusSchema.validate(body).catch(function (err) {
        return err.errors[0]
      })
      notifyError(errorMessage)
      setErr({ err: true, message: errorMessage })
    }
  }

  useEffect(() => {
    if (success.success) {
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }, [success])

  function handleChangeDate (name, value) {
    setClosedTime({ ...closedTime, [name]: value })
  }

  function handleCheckboxClick (e) {
    const { value, checked } = e.target
    if (checked) {
      setCheckedValues(value)
    } else {
      setCheckedValues()
    }
  }

  function renderDatePicker (name, initialValue) {
    return (
      <DateTimePicker
        disableClock
        calendarIcon={false}
        yearPlaceholder='YYYY'
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        format='dd-MM-y'
        name={name}
        defaultValue={initialValue || ''}
        value={closedTime[name] || ''}
        onChange={value => handleChangeDate(name, value)}
        disabled={!isEmpty(checkedValues) || status === -1 || status === 0}
      />
    )
  }

  function renderStatus () {
    switch (status) {
      case 0:
        return (
          <span className='temporary-closed'><b>Temporary Closed</b></span>
        )
      case 10:
        return (
          <span className='open'><b>Open</b></span>
        )

      case -1:
        return (
          <span className='permanently-closed'><b>Permanently Closed</b></span>
        )

      default:
        break
    }
  }

  return (
    isMobileView
      ? (
        <ProfileStatusMobile
          supplierData={supplierData}
          status={status}
          closedTime={closedTime}
          fetching={isLoading}
          error={err}
          checkedValues={checkedValues}
          renderStatus={renderStatus}
          renderDatePicker={renderDatePicker}
          handleCheckboxClick={handleCheckboxClick}
          handleChangeStatus={handleChangeStatus}
          handleInit={handleInit}
          setNotes={setNotes}
        />
        )
      : (
        <ProfileStatusDesktop
          supplierData={supplierData}
          status={status}
          closedTime={closedTime}
          fetching={isLoading}
          error={err}
          checkedValues={checkedValues}
          renderStatus={renderStatus}
          renderDatePicker={renderDatePicker}
          handleCheckboxClick={handleCheckboxClick}
          handleChangeStatus={handleChangeStatus}
          handleInit={handleInit}
          setNotes={setNotes}
        />
        )
  )
}
