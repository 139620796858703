import React, { useEffect, useState } from 'react'
import Cookies from 'cookies-js'

import Pagination from '../Pagination'

import Loading from '../../Components/Behaviors/Loading'
import SearchPendingProduct from '../../Components/SearchPendingProduct'
import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'
import PendingOrderGroup from './PendingOrderGroup'
import CommentModal from '../Modals/CommentModal'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

export default function PendingDesktop ({
  params,
  invoices,
  err,
  isLoading,
  countFetching,
  supplier,
  supplierName,
  countDisplayed,
  handleFilter,
  handleChangeStatus,
  handleParamsChange,
  supplierId
}) {
  const list = useSelector(state => state.list)
  const user = useSelector(state => state.user)

  // const count = useSelector(state => state.count)
  const [invoiceList, setInvoiceList] = useState()
  const [selectedInvoice, setSelectedInvoice] = useState()

  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    commentModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  useEffect(() => {
    if (invoices !== invoiceList) {
      setInvoiceList(invoices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }

  function onKeyDown (e) {
    if (e.key === 'Enter') {
      handleFilter(e)
    }
  }

  function handleRenderModal () {
    return (
      <>
        {onClickedModal.seeProductListModal &&
          <SeeItemsListsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }

  function handleRender () {
    if (err.err) {
      return (
        <div className='row col-12 text-center justify-content-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <span className='fs--m err'>{err.message}</span>
        </div>
      )
    } else {
      // in comment because the count were different ( need to get new count if you want to show the new count)
      const sumCount = countDisplayed && countDisplayed.pending_unprocessed + countDisplayed.pending_inprocessed + countDisplayed.pending_processed
      return (
        <div className='px-5 pb-3'>
          <div className='row my-5 d-flex align-content-center '>
            <div className='col-2'>
              <div className='container-radio'>
                <label className='fs--s'>Semua {!countFetching && countDisplayed && `(${sumCount})`}
                  <input type='radio' name='status' value='pending' id='radioBtn1' defaultChecked onChange={(e) => handleChangeStatus(e)} />
                  <span className='checked' />
                </label>
              </div>
            </div>
            <div className='col'>
              <div className='container-radio'>
                <label className='fs--s'>Belum Diproses Refund  {!countFetching && countDisplayed && `(${countDisplayed.pending_unprocessed})`}
                  <input type='radio' name='status' value='pending_unprocessed' id='radioBtn2' onChange={(e) => handleChangeStatus(e)} />
                  <span className='checked' />
                </label>
              </div>
            </div>
            <div className='col'>
              <div className='container-radio'>
                <label className='fs--s'>Sedang Diproses Refund {!countFetching && countDisplayed && `(${countDisplayed.pending_inprocessed})`}
                  <input type='radio' name='status' value='pending_inprocessed' id='radioBtn3' onChange={(e) => handleChangeStatus(e)} />
                  <span className='checked' />
                </label>
              </div>
            </div>
            <div className='col'>
              <div className='container-radio'>
                <label className='fs--s'>Sudah Diproses Refund  {!countFetching && countDisplayed && `(${countDisplayed.pending_processed})`}
                  <input type='radio' name='status' value='pending_processed' id='radioBtn3' onChange={(e) => handleChangeStatus(e)} />
                  <span className='checked' />
                </label>
              </div>
            </div>

          </div>
          <div className='row'>
            <div className='col-12 p-0'>
              {isLoading
                ? (
                  <div className='row'>
                    <div className='col-12'>
                      <Loading type='alt' />
                    </div>
                  </div>
                  )
                : (
                    isEmpty(invoices?.invoices))
                    ? (
                      <div className='row text-center align-content-center' style={{ height: '400px' }}>
                        <div className='col-12'>
                          <span className='fs--m'><b>Saat ini Anda tidak memiliki orderan pending.</b></span>
                        </div>
                      </div>
                      )

                    : (
                      <div className='px-4 pb-5'>
                        {invoices?.invoices?.map((invoice, index) => {
                          return (
                            <PendingOrderGroup
                              key={index}
                              invoice={invoice}
                              handleToogleModal={handleToogleModal}
                            />
                          )
                        })}
                      </div>
                      )}
              {handleRenderModal()}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {invoiceList?.total_invoice > 10 &&
                <Pagination
                  total={invoiceList?.total_invoice}
                  show={10}
                  classNames='pagination pull-right'
                  handlePageClick={handlePageClick}
                />}
            </div>
          </div>
        </div>
      )
    }
  }

  const defaultSupplier = Cookies.get('filterSupplier') || params.supplier || ''

  return (
    <div className='col-12 col-lg offset-lg-2 p-0'>
      <div className='pending-header'>
        <div className='p-5'>
          <div className='row align-items-center'>
            <div className='col-8'>
              <h2 className='font-weight-bold'>
                {(supplier || supplierName) &&
            (
              <Link to={user?.user?.role_id !== 2 ? `/profile/${supplierId}?type=default` : '/home'}>
                <span className='mr-3'><i className='anticon icon-left' /></span>
              </Link>
            )}Pending Order {supplierName ? ('- ' + supplierName?.replace(/%20/g, ' ')) : ''}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-5 pl-4 pr-4 pb-4'>
        <div className='row'>
          <div className='col-7'>
            <SearchPendingProduct onKeyDown={onKeyDown} handleParamsChange={handleParamsChange} />
          </div>

          {/* SORT BY */}
          <div className='col'>
            <div className='row align-items-center fs--s '>
              <div className='col-3'>
                {(list.supplier && user.user?.role_id !== 2 && !supplier) && <div className='text-right font-weight-bold'>Filter by</div>}
              </div>
              {(list.supplier?.suppliers && !supplier) &&
                <div className='col'>
                  <div className='input-text-bar'>
                    <select name='supplier' className='supplier' value={defaultSupplier} onChange={(e) => handleFilter(e)}>
                      <option value=''>Semua Seller</option>
                      {list.supplier?.suppliers?.map((supplier, index) => {
                        return (
                          <option value={supplier.supplier_alias} key={index}>{supplier.name} ({supplier.supplier_alias})</option>
                        )
                      })}
                    </select>
                  </div>
                </div>}
              {/* <div className='col'>
                <div className='input-text-bar'>
                  <select name='carrier' className='carrier_id' onChange={(e) => handleFilter(e)}>
                    <option value=''>Semua Kurir</option>
                    {list.carrier?.carriers?.map((carrier, index) => {
                      return (
                        <option value={carrier.carrier_id} key={index}>{carrier.carrier_name}</option>
                      )
                    })}}
                  </select>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {handleRender()}
    </div>
  )
}
