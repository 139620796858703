import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import Cookies from 'cookies-js'
import dayjs from 'dayjs'

import { ScaleContext } from '../Context/ScaleContext'
import { checkRole } from '../Services/CheckRole'

import SneakPeekProfile from '../Components/DefaultLayout/SneakPeekProfile'
import CountActions from '../Redux/CountRedux'
import UserActions from '../Redux/UserRedux'
import CommentActions from '../Redux/CommentRedux'

import config from '../config'

export default function NavigationMenu (props) {
  const { isOpenMenu, pages, menu } = props
  const count = useSelector(state => state.count)
  const user = useSelector(state => state.user)
  const comment = useSelector(state => state.comment)

  const dispatch = useDispatch()
  // Uncomment this if you want to use is mobile trigger
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  useEffect(() => {
    const params = {
      supplier: '',
      carrier_id: ''
    }

    const paramsComment = {
      supplier: '',
      limit: '6',
      page: '',
      topic: ''
    }

    if (comment.comment === null) {
      paramsComment.supplier = user?.user?.role_id === 2 ? user?.user?.supplier?.supplier_alias : ''
      dispatch(CommentActions.commentRequest(paramsComment))
    }

    if (count.count_order === null) {
      if (user.user?.role_id === 2) {
        if (user.supplier?.supplier_alias) {
          params.supplier = user.supplier?.supplier_alias
        }
        dispatch(CountActions.countRequest(params))
      }
    }

    if (count.count_product === null) {
      dispatch(CountActions.countProductRequest({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleCookies () {
    let filterCarrier = Cookies.get('filterCarrier') || ''
    let filterSupplier = Cookies.get('filterSupplier') || ''
    if (user?.user?.role_id === 2) {
      filterSupplier = user.supplier?.supplier_alias
    }
    if (pages === 'order' || pages === 'pending') {
      if (filterSupplier || filterCarrier) {
        filterSupplier = user?.user?.role_id !== 2 ? (Cookies.get('filterSupplier') || '') : user.supplier?.supplier_alias
        filterCarrier = pages === 'pending' ? '' : (Cookies.get('filterCarrier') || '')
      } else {
        Cookies.set('filterSupplier', '')
        Cookies.set('filterCarrier', '')
      }
    } else {
      Cookies.set('filterSupplier', '')
      Cookies.set('filterCarrier', '')
    }
    dispatch(CountActions.countRequest({ supplier: filterSupplier, carrier_id: filterCarrier }))
  }

  useEffect(() => {
    handleCookies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get('filterSupplier'), Cookies.get('filterCarrier')])

  function renderlist () {
    return (
      <>
        {checkRole({
          menu: 'home',
          method: 'view',
          attribute: 'all',
          yes: () => {
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list content '>
                <a href={`${config.baseURL}/home`} className={`${pages === 'home' && 'active'}`}>
                  <span><i className='anticon icon-home fs--m' /></span>
                  <span className='pl-4 font-weight-bold'>Home</span>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'new_order',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list content'>
                <a href={`${config.baseURL}/order/new`} className={`${pages === 'order' && 'active'}`}>
                  <span><i className='anticon icon-shoppingcart fs--m' /></span>
                  <span className='pl-4 font-weight-bold'>Order</span>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'new_order',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/new' className={`${(!isEmpty(menu) && menu === 'new' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>New order</span></div>
                      <div className='col-4 text-right pr-4'>
                        {!count.fetching && count?.count_order?.new !== 0 && <span className='badge badge-secondary'>{count?.count_order?.new}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'processing',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/processing' className={`${(!isEmpty(menu) && menu === 'processing' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Processing</span></div>
                      <div className='col-4 font-weight-bold text-right pr-4'>
                        {!count.fetching && count?.count_order?.processing !== 0 && <span className='badge badge-secondary'>{count?.count_order?.processing}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'processing',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/stand_by' className={`${(!isEmpty(menu) && menu === 'stand_by' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Stand by</span></div>
                      <div className='col-4 font-weight-bold text-right pr-4'>
                        {!count.fetching && count?.count_order?.stand_by !== 0 && <span className='badge badge-secondary'>{count?.count_order?.stand_by}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'ready_to_ship',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/ready_to_ship' className={`${(!isEmpty(menu) && menu === 'ready_to_ship' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Ready&nbsp;to&nbsp;Ship</span></div>
                      <div className='col-4 font-weight-bold text-right pr-4'>
                        {!count.fetching && count?.count_order?.ready_to_ship !== 0 && <span className='badge badge-secondary'>{count?.count_order?.ready_to_ship}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'shipped',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/shipped' className={`${(!isEmpty(menu) && menu === 'shipped' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Shipped</span></div>
                      <div className='col-4 font-weight-bold text-right pr-4'>
                        {!count.fetching && count?.count_order?.shipped !== 0 && <span className='badge badge-secondary'>{count?.count_order?.shipped}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'received',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-primary navigation-child'>
                <Link to='/order/received' className={`${(!isEmpty(menu) && menu === 'received' && 'active')}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Received</span></div>
                      <div className='col-4 font-weight-bold text-right pr-4'>
                        {!count.fetching && count?.count_order?.received !== 0 && <span className='badge badge-secondary'>{count?.count_order?.received}</span>}
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'product_list',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            // set here if you want the link to contains the parameter that will be consume in destination page
            // right now it's only consuming, store_code, supplier_alias, status, sort_by, sort_type, status
            const params = {
              store_code: user.supplier?.supplier_addresses[0]?.store?.store_code,
              supplier_alias: user.user?.supplier?.supplier_alias,
              status: '',
              supplier_id: user?.user?.supplier?.supplier_id
            }
            const link = `/product/list-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${params.supplier_id}
            &status=${params.status}`
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list'>
                <a href={config.baseURL + link} className={`${pages === 'product' && 'active'}`}>
                  <span><i className='anticon icon-gift fs--m' /></span>
                  <span className='pl-4 font-weight-bold'>Product</span>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'product_list',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            // set here if you want the link to contains the parameter that will be consume in destination page
            // right now it's only consuming, store_code, supplier_alias, status, sort_by, sort_type, status
            const params = {
              store_code: user.supplier?.supplier_addresses[0]?.store?.store_code,
              supplier_alias: user.user?.supplier?.supplier_alias,
              status: '',
              supplier_id: user?.user?.supplier?.supplier_id
            }
            const link = `/product/list-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&status=${params.status}&supplier_id=${params.supplier_id}`
            return (
              <li className='navigation-primary navigation-child'>
                <a href={config.baseURL + link} className={`${pages === 'list-product' && 'active'}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>List Product</span></div>
                      <div className='col-4 text-right pr-4'>
                        {count?.count_product?.approved && count?.count_product?.pending && <span className='badge badge-secondary'>{parseInt(count?.count_product?.approved) + parseInt(count?.count_product?.pending)}</span>}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'product_list',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            // set here if you want the link to contains the parameter that will be consume in destination page
            // right now it's only consuming, store_code, supplier_alias, status, sort_by, sort_type, status
            const params = {
              store_code: user.supplier?.supplier_addresses[0]?.store?.store_code,
              supplier_alias: user.user?.supplier?.supplier_alias,
              supplier_id: user?.user?.supplier?.supplier_id
            }
            const link = `/product/rejected-product?store_code=${params.store_code}&supplier_alias=${params.supplier_alias}&supplier_id=${params.supplier_id}`
            return (
              <li className='navigation-primary navigation-child'>
                <a href={config.baseURL + link} className={`${pages === 'rejected-product' && 'active'}`}>
                  <div className='navigation-dashboard-list'>
                    <div className='row align-items-center font-weight-bold'>
                      <div className='col-8 content'><span>Produk&nbsp;Bermasalah</span></div>
                      <div className='col-4 text-right pr-4'>
                        {count.count_product?.rejected && <span className='badge badge-secondary'>{count.count_product?.rejected}</span>}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'comment_center',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list'>
                <a href={config.baseURL + '/comment-seller'} className={`${pages === 'comment-seller' && 'active'}`}>
                  <div className='row align-items-center font-weight-bold'>
                    <div className='col-8 content'>
                      <span><i className='anticon icon-filetext1 fs--m' /></span>
                      <span className='pl-4 font-weight-bold'>Comment&nbsp;Seller</span>
                    </div>
                    <div className='col-4 font-weight-bold text-right pr-4'>
                      {comment?.comment && parseInt(comment?.comment?.total_comment) !== 0 && <span className='badge badge-secondary'>{comment?.comment?.total_comment}</span>}
                    </div>
                  </div>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'supplier_list',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list'>
                <a href={config.baseURL + '/seller-list/lists'} className={`${pages === 'seller-list' && 'active'}`}>
                  <span><i className='anticon icon-filetext1 fs--m' /></span>
                  <span className='pl-4 font-weight-bold'>Seller List</span>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'pending',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            const counter = parseInt(count?.count_order?.pending_inprocessed) + parseInt(count?.count_order?.pending_unprocessed) || ''
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list'>
                <Link to='/pending' className={`${pages === 'pending' && 'active'}`}>
                  <div className='row align-items-center font-weight-bold'>
                    <div className='col-8 content'>
                      <span><i className='anticon icon-exclamationcircleo fs--m' /></span>
                      <span className='pl-4 font-weight-bold'>Pending</span>
                    </div>
                    <div className='col-4 font-weight-bold text-right pr-4'>
                      {!count.fetching && counter && <span className='badge badge-secondary'>{counter}</span>}
                    </div>
                  </div>
                </Link>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}

        {checkRole({
          menu: 'settlement_report',
          method: 'view',
          attribute: 'all',
          yes: (access) => {
            return (
              <li className='navigation-dashboard-primary navigation-dashboard-list'>
                <a href={config.baseURL + '/settlement-report/report'} className={`${pages === 'settlement-report' && 'active'}`}>
                  <span><i className='anticon icon-filetext1 fs--m' /></span>
                  <span className='pl-4 font-weight-bold'>Settlement Report</span>
                </a>
              </li>
            )
          },
          no: (access) => { return null },
          userRules: user?.role
        })}
      </>
    )
  }

  const renderDesktop = () => {
    if (user?.role) {
      // if (user.fetching || count.fetching) {
      //   return (
      //     <div className='col-lg-3 col-xl-2 navigation-view border-right'>
      //       <Loading type='alt' />
      //     </div>
      //   )
      // } else {
      return (
        <div className='col-lg-3 col-xl-2 navigation-view border-right'>
          <div className='d-none d-lg-block'>
            <div className='mt-5'>
              <div className='row'>
                <SneakPeekProfile viewType='desktop' />
              </div>
              {/* Navigation Menu */}
              <div className='navigation-dashboard'>
                <ul className='fs--xs'>
                  {renderlist()}
                  {/* ========================= */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
      // }
    } else return null
  }

  const renderMobile = () => {
    if (user?.role) {
      // if (user.fetching || count.fetching) {
      //   return (
      //     <div className='col-lg-3 col-xl-2 navigation-view border-right'>
      //       <Loading type='alt' />
      //     </div>
      //   )
      // } else {
      return (
        <div className='navigation-sticky' style={{ top: '0' }}>
          <div className={`${(isOpenMenu) ? 'navigation-transition show' : 'navigation-transition hidden '}`}>
            <div className='col-12 col-lg-3 pl-0 navigation-view scroll-mobile' width='70%' style={{ minWidth: 290 }}>
              <div className='row border-bottom'>
                <div className='py-4 pl-4'>
                  <div className='col-12 pb-3 fs--xs'>
                    {dayjs().locale('id').format('dddd, DD MMM YYYY')}
                  </div>
                  <SneakPeekProfile viewType='mobile' />
                </div>
              </div>
              {/* Navigation Menu */}
              <div className='navigation-dashboard pl-3'>
                <ul className='fs--m'>
                  {renderlist()}
                  <li className='navigation-dashboard-primary navigation-dashboard-list' onClick={() => dispatch(UserActions.userLogout())}>
                    <span><i className='anticon icon-logout fs--xm' /></span>
                    <span className='pl-4 font-weight-bold'>Log Out</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
    // } else return null
  }

  return (
    <>
      {/* [STARTS HERE] ====
      //todo akan berubah jika width layar kurang dari 992px
      */}
      {isMobileView ? renderMobile() : renderDesktop()}
      {/* [ENDS HERE] --- akan berubah jika width layar kurang dari 992px */}
    </>
  )
}
