import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScaleContext } from '../../Context/ScaleContext'
import { useSelector } from 'react-redux'

import Loading from '../../Components/Behaviors/Loading'
import config from '../../config'

import Cookies from 'cookies-js'

export default function OrderHeader ({ params, supplier, supplierName, supplierId, carrier, handleParamsChange, menu, fetchGetInvoiceList }) {
  const user = useSelector(state => state.user)
  const count = useSelector(state => state.count)
  const list = useSelector(state => state.list)

  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [paramsModified, setParamsModified] = useState({ status: menu, carrier_id: params.carrier_id || '', supplier: params.supplier })

  const handleOnChange = (event) => {
    event.preventDefault()
    const { value, name } = event.target
    if (user?.user?.role_id !== 2) {
      if (name === 'supplier') Cookies.set('filterSupplier', value)
      else if (name === 'carrier_id') Cookies.set('filterCarrier', value)
    } else {
      if (name === 'carrier_id') Cookies.set('filterCarrier', value)
    }
    setParamsModified({ ...paramsModified, status: menu, [name]: value })
    window.location.reload()
    // handleParamsChange({ ...paramsModified, status: menu, [name]: value })
  }

  useEffect(() => {
    if (Cookies.get('filterSupplier')) {
      setParamsModified({ ...paramsModified, status: menu, supplier: Cookies.get('filterSupplier') })
    } else if (Cookies.get('filterCarrier')) {
      setParamsModified({ ...paramsModified, status: menu, carrier_id: Cookies.get('filterCarrier') })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderFilterSupplier = () => {
    if (user?.user?.role_id !== 2 && (!supplier || supplier === '')) {
      const defaultSupplier = Cookies.get('filterSupplier') || params.supplier || ''
      return (
        <div className='col-6'>
          <div className='font-weight-bold fs--s pb-3'>
            Filter Berdasarkan Seller
          </div>
          {list.fetching
            ? (
              <div className='col-6 pl-0'>
                <center><Loading type='bounce' /></center>
              </div>
              )
            : list.err
              ? (
                <div className='col-6 pl-0'>
                  <span className='fs--s error'>{list.err.message}</span>
                </div>
                )
              : (
                  (
                    <div className='input-text-bar'>
                      <select className='input fs--s ' value={defaultSupplier} name='supplier' onChange={(e) => handleOnChange(e)}>
                        <option value=''>ALL {list?.total && `(${list.total})`}</option>
                        {list?.supplier?.suppliers?.map((supplier, index) => {
                          return (
                            <option value={supplier.supplier_alias} key={index}>{supplier.name} ({supplier.supplier_alias})</option>
                          )
                        })}
                      </select>
                    </div>
                  )
                )}
        </div>
      )
    } else return null
  }

  const renderFilterCarrier = () => {
    const defaultCarrier = Cookies.get('filterCarrier') || params.carrier_id || ''
    return (
      <div className='col-6'>
        <div className='font-weight-bold fs--s pb-3'>
          Filter Berdasarkan Kurir
        </div>
        {list.fetching
          ? (
            <div className='col-6 pl-0'>
              <center><Loading type='bounce' /></center>
            </div>
            )
          : list.err
            ? (
              <div className='col-6 pl-0'>
                <span className='fs--s error'>{list.err.message}</span>
              </div>
              )
            : (
              <div className='input-text-bar'>
                <select className='input fs--s ' value={defaultCarrier} name='carrier_id' onChange={(e) => handleOnChange(e)}>
                  <option value=''>ALL </option>
                  {list?.carrier?.carriers?.map((carrier, index) => {
                    return (
                      <option value={carrier.carrier_id} key={index}>{carrier.carrier_name}</option>
                    )
                  })}
                </select>
              </div>
              )}

      </div>
    )
  }

  function renderLink () {
    let url = ''

    if (supplier || carrier) {
      url = '&supplier=' + (supplier || '') + '&supplier_name=' + (supplierName || '') + '&carrier=' + (carrier || '') + '&supplier_id=' + (supplierId || '')
    }
    return url
  }

  const renderDesktop = () => {
    const backLink = (user?.user?.role_id !== 2 ? `${config.baseURL}/profile/${supplierId}?type=default` : '/home')
    return (
      <div className='row align-items-center'>
        <div className='col-12'>
          <h2 className='font-weight-bold pb-5'>
            {(supplier || carrier) &&
            (
              <a href={backLink} className='mr-3'><i className='anticon icon-left' /></a>
            )}Order {supplierName ? ('- ' + supplierName?.replace(/%20/g, ' ')) : ''}
          </h2>
        </div>
        {/* ==== [STARTS HERE] ==== */
          // todo insert FILTERED Here!
        /* ======================= */}
        <div className='col-12'>
          <div className='row'>
            {renderFilterSupplier()}
            {renderFilterCarrier()}
          </div>
        </div>
        <div className='col-12 mt-5'>
          <nav className='navbar navbar-expand-lg navbar-light fs--s '>
            <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNavAltMarkup' aria-controls='navbarNavAltMarkup' aria-expanded='false' aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
              <div className='menu-scroll-x'>
                <ul className='nav nav-tab'>
                  <li className={`navigation ${menu === 'new' && 'active'}`} id='new'>
                    <Link to={`/order/new?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>
                        New Order
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.new !== 0 && count?.count_order?.new}</span>}
                      </span>
                    </Link>
                  </li>
                  <li className={`navigation ${menu === 'processing' && 'active'}`} id='processing'>
                    <Link to={`/order/processing?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>
                        Processing
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.processing !== 0 && count?.count_order?.processing}</span>}
                      </span>
                    </Link>
                  </li>
                  <li className={`navigation ${menu === 'stand_by' && 'active'}`} id='stand_by'>
                    <Link to={`/order/stand_by?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>Stand by
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.stand_by !== 0 && count?.count_order?.stand_by}</span>}
                      </span>
                    </Link>
                  </li>
                  <li className={`navigation ${menu === 'ready_to_ship' && 'active'}`} id='ready_to_ship'>
                    <Link to={`/order/ready_to_ship?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>Ready to ship
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.ready_to_ship !== 0 && count?.count_order?.ready_to_ship}</span>}
                      </span>
                    </Link>
                  </li>
                  <li className={`navigation ${menu === 'shipped' && 'active'}`} id='shipped'>
                    <Link to={`/order/shipped?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>Shipped
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.shipped !== 0 && count?.count_order?.shipped}</span>}
                      </span>
                    </Link>
                  </li>
                  <li className={`navigation ${menu === 'received' && 'active'}`} id='received'>
                    <Link to={`/order/received?${renderLink()}`}>
                      <span className='nav-item nav-link store-dashboard'>Received
                        {!count?.fetching && count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.received !== 0 && count?.count_order?.received}</span>}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }

  const renderMobile = () => {
    const backLink = (user?.user?.role_id !== 2 ? `${config.baseURL}/profile/${supplierId}?type=default` : '/home')
    return (
      <div className='row align-items-center'>
        <div className='col-12'>
          <h2 className='font-weight-bold pb-5'>
            {(supplier || carrier) &&
            (
              <a href={backLink} className='mr-3'><i className='anticon icon-left' /></a>
            )} Order {supplierName ? ('- ' + supplierName?.replace(/%20/g, ' ')) : ''}
          </h2>
        </div>
        {/* ==== [STARTS HERE] ==== */
        // todo insert FILTERED Here!
          /* ======================= */}
        <div className='col-12'>
          <div className='row'>
            {renderFilterSupplier()}
            {renderFilterCarrier()}
          </div>
        </div>
        <div className='col-12 mt-5'>
          <nav className='order-slider-menu fs--s menu-overflow'>
            <div className='menu-scroll-x-mobile'>
              <ul className='nav nav-tab'>
                <li className={`navigation ${menu === 'new' && 'active'}`} id='new'>
                  <Link to={`/order/new?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard'>
                      New Order
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.new !== 0 && count?.count_order?.new}</span>}
                    </span>
                  </Link>
                </li>
                <li className={`navigation ${menu === 'processing' && 'active'}`} id='processing'>
                  <Link to={`/order/processing?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard' href='/order/processing'>
                      Processing
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.processing !== 0 && count?.count_order?.processing}</span>}
                    </span>
                  </Link>
                </li>
                <li className={`navigation ${menu === 'stand_by' && 'active'}`} id='stand_by'>
                  <Link to={`/order/stand_by?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard'>Stand by
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.stand_by !== 0 && count?.count_order?.stand_by}</span>}
                    </span>
                  </Link>
                </li>
                <li className={`navigation ${menu === 'ready_to_ship' && 'active'}`} id='ready_to_ship'>
                  <Link to={`/order/ready_to_ship?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard'>Ready to ship
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.ready_to_ship !== 0 && count?.count_order?.ready_to_ship}</span>}
                    </span>
                  </Link>
                </li>
                <li className={`navigation ${menu === 'shipped' && 'active'}`} id='shipped'>
                  <Link to={`/order/shipped?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard'>Shipped
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.shipped !== 0 && count?.count_order?.shipped}</span>}
                    </span>
                  </Link>
                </li>
                <li className={`navigation ${menu === 'received' && 'active'}`} id='received'>
                  <Link to={`/order/received?${renderLink()}`}>
                    <span className='nav-item nav-link store-dashboard'>Received
                      {count?.count_order && <span className='badge badge-secondary mt-2'>{count?.count_order?.received !== 0 && count?.count_order?.received}</span>}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
