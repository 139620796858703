import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Loading from '../../../Components/Behaviors/Loading'

export default function ViewProductDetailMobile ({ fetching, renderComponent, renderImages, product, storeCode, supplierAlias }) {
  const images = product.variants[0]?.images

  const settings = {
    className: 'w-100',
    dots: false,
    arrow: true,
    speed: 500,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    initialSlide: 0,
    autoplay: true,
    accessibility: true
  }
  return (
    <div className='container container-add-product'>
      <div className='mt-3 mb-5'>
        <div className='row border-bottom pb-3'>
          <div className='col-2 pl-0 ml-4'>
            <Link to='/product/list-product'>
              <span><i className='anticon icon-arrowleft' /></span>
            </Link>
          </div>
          <div className='col-9 pl-0'>
            <span className='fs--m font-weight-bold text-center'><p className='mb-0 mr-5'>Detail Produk</p></span>
          </div>
        </div>
        {/* ----------------------- */}
        <div className='col-12 mt-4 mb-4 border px-5 py-4'>
          <div className='text-center fs--m font-weight-bold mb-3'>Foto Produk</div>
          <div className='row justify-content-center'>
            <div className='col-12 d-block'>
              {images?.length > 0 &&
                <Slider {...settings}>
                  {renderImages()}
                </Slider>}
            </div>
          </div>
        </div>
        {
          fetching
            ? (
              <div className='row spacing-add-product justify-content-center'>
                <Loading type='alt' />
              </div>
            )
            : renderComponent()
        }
        <div className='row spacing-add-product'>
          <div className='col-12'>
            <Link to={`/product/list-product?store_code=${storeCode}&supplier_alias=${supplierAlias}`}>
              <button className='btn btn-full btn-primary-border btn-m font-weight-bold'>Kembali Ke Halaman Produk</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
