import React, { useState, useContext, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { ScaleContext } from '../../Context/ScaleContext'

export default function CheckProductDetail ({ item, productID, reasons, handleConditionalBoxes, showConditionalBoxes }) {
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const [choose, setChoose] = useState('')
  const [reason, setReason] = useState(0)
  const [note, setNote] = useState('')

  useEffect(() => {
    if (item.status_fulfillment !== '') {
      if (item.status_fulfillment === 'incomplete') {
        setNote(item.note)
        setReason(item.reason)
      }
      setChoose(item.status_fulfillment)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnChange = (e, item) => {
    const choose = e.target.value
    setChoose(choose)
    if (choose === 'incomplete') {
      // if (!isEmpty(reasons) && reasons) {
      //   setReason(parseInt(reasons[0].reject_reason_id))
      //   handleConditionalBoxes(item, true, choose, reasons[0].reject_reason_id, '')
      // }
      handleConditionalBoxes(item, true, choose, 0, '')
    } else if (choose === 'complete') {
      handleConditionalBoxes(item, false, choose, 0, '')
    }
  }

  const handleOnchageReason = (e) => {
    const { value, name } = e.target
    if (name === 'reason') {
      setReason(parseInt(value))
      handleConditionalBoxes(item, true, choose, parseInt(value), note)
    } else if (name === 'note') {
      setNote(value)
      handleConditionalBoxes(item, true, choose, reason, value)
    }
  }

  const renderDesktop = () => {
    return (
      <tr className='fs--m py-4'>
        <td className='pl-5 py-4' width='10%'>{item.sku}</td>
        <td className='py-4' width='30%'>{item.name}</td>
        <td className='py-4' width='5%'>{item.qty_ordered}</td>
        <td className='py-4'>
          <div className='row'>
            {item.status_fulfillment === 'refunded'
              ? <div className='col-12'>Refunded</div>
              : (
                <>
                  <div className='col-4'>
                    <div className='container-radio'>
                      <label className='fs--m'>Ada
                        <input type='radio' checked={choose === 'complete'} name={`radioBtn${item.invoice_item_id}`} id={`radioBtn-yes${item.invoice_item_id}`} onChange={(e) => handleOnChange(e, item)} value='complete' />
                        <label htmlFor={`radioBtn-yes${item.invoice_item_id}`} className='checked' />
                      </label>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='container-radio'>
                      <label className='fs--m'>Tidak Ada/ Kurang
                        <input type='radio' checked={choose === 'incomplete'} name={`radioBtn${item.invoice_item_id}`} id={`radioBtn-no${item.invoice_item_id}`} onChange={(e) => handleOnChange(e, item)} value='incomplete' />
                        <label htmlFor={`radioBtn-no${item.invoice_item_id}`} className='checked' />
                      </label>
                    </div>
                  </div>
                </>
              )}

          </div>
          <div className={!(showConditionalBoxes && productID === item.invoice_item_id)
            ? choose === 'complete' || choose === '' ? `d-none ${item.invoice_item_id}` : ''
            : ' '}
          >
            <div className='pt-5 pb-3 pr-3'>
              <div className='input-text-bar'>

                {!isEmpty(reasons) &&
              (
                <select name='reason' defaultValue={reason} className='input' onChange={(e) => handleOnchageReason(e)}>
                  <option value='' hidden>Pilih alasan</option>
                  {reasons.map((reason, idx) => {
                    return <option key={idx} value={reason.reject_reason_id}>{reason.name}</option>
                  })}
                </select>
              )}
              </div>
            </div>
            <div className='pt-3 pb-4 pr-3'>
              <div className='input-text-bar input-text-area'>
                <textarea name='note' rows='3' className='form-control fs--m' onChange={(e) => handleOnchageReason(e)} />
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  const renderMobile = () => {
    return (
      <>
        <div className='row pb-4'>
          <div className='col-12 fs--s font-weight-bold'>SKU</div>
          <div className='col-12 fs--m pt-2'>{item.sku}</div>
        </div>
        <div className='row pb-4'>
          <div className='col-12 fs--s font-weight-bold'>Nama Barang</div>
          <div className='col-12 fs--m pt-2'>{item.name}</div>
        </div>
        <div className='row pb-4'>
          <div className='col-12 fs--s font-weight-bold'>Qty</div>
          <div className='col-12 fs--m pt-2'>{item.qty_ordered}</div>
        </div>
        <div className='row border-bottom'>
          <div className='col-12 fs--s font-weight-bold'>Status</div>
          <div className='d-flex col-12'>
            {item.status_fulfillment === 'refunded'
              ? <div>Refunded</div>
              : (
                <>
                  <div className='container-radio'>
                    <label className='fs--m'>Ada
                      <input type='radio' checked={choose === 'complete'} name={`radioBtn${item.invoice_item_id}`} id={`radioBtn-yes${item.invoice_item_id}`} onChange={(e) => handleOnChange(e, item)} value='complete' />
                      <label htmlFor={`radioBtn-yes${item.invoice_item_id}`} className='checked' />
                    </label>
                  </div>
                  <div className='container-radio'>
                    <label className='fs--m'>Tidak Ada/ Kurang
                      <input type='radio' checked={choose === 'incomplete'} name={`radioBtn${item.invoice_item_id}`} id={`radioBtn-no${item.invoice_item_id}`} onChange={(e) => handleOnChange(e, item)} value='incomplete' />
                      <label htmlFor={`radioBtn-no${item.invoice_item_id}`} className='checked' />
                    </label>
                  </div>
                </>
              )}
          </div>
          <div className='col-12'>
            <div className={!(showConditionalBoxes && productID === item.invoice_item_id)
              ? choose === 'complete' || choose === '' ? `d-none ${item.invoice_item_id}` : ''
              : ' '}
            >
              {/* INI KALO MAU DIPAKE HARUS TARO LUAR */}
              {!isEmpty(reasons) && (
                <div className='pt-5 pb-3 pr-3'>
                  <div className='input-text-bar'>
                    <select name='reason' defaultValue={reason} className='input' onChange={(e) => handleOnchageReason(e)}>
                      {!isEmpty(reasons) && reasons.map((reason, idx) => {
                        return <option key={idx} value={reason.reject_reason_id}>{reason.name}</option>
                      })}
                    </select>
                  </div>
                </div>
              )}
              <div className='pt-3 pb-4 pr-3'>
                <div className='input-text-bar input-text-area'>
                  <textarea name='note' rows='3' className='form-control fs--m' onChange={(e) => handleOnchageReason(e)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
