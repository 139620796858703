import React from 'react'
import InfoBox from '../../InfoBox'
import { useSelector } from 'react-redux'

import { checkRole } from '../../../Services/CheckRole'

export default function ProfileInfoMobile ({
  uploadProduct,
  renderSelect,
  renderMap,
  supplierAddress,
  fetching,
  error,
  clickedInstantCourier,
  setClickedInstantCourier,
  handleInit,
  handleChangeSupplierInfo,
  handleChangeAddress
}) {
  const user = useSelector(state => state.user)

  return (
    <div className='row fs--s '>
      <div className='col-12'>
        <div className='row'>
          <div className='col-12 d-flex justify-content-center p-3'>
            {uploadProduct()}
          </div>
        </div>
        <div className='settings-container row pt-5'>
          <div className='password-card-container col-12 border p-4'>
            <div className='row'>
              <div className='col-12 d-flex border-bottom'>
                <i className='anticon icon-phone' />
                <p className='font-weight-bold pl-3 fs--s '>No Telepon</p>
              </div>
              <div className='col-12 pt-3 fs--s '>
                <div className='input-text-bar'>
                  <input name='phone' type='text' value={supplierAddress?.phone || ''} onChange={(e) => handleChangeAddress(e)} />
                </div>
              </div>
            </div>
          </div>
          <div className='address-card-container col-12 border mt-4 p-4'>
            <div className='row'>
              <div className='col-12 d-flex border-bottom'>
                <i className='anticon icon-enviromento' />
                <p className='font-weight-bold pl-3 fs--s '>Alamat Toko</p>
              </div>
              <div className='col-12 pt-3'>
                <div className='input-text-bar input-text-area'>
                  <textarea type='text' name='address' className='form-control fs--m' rows='7' value={supplierAddress?.address || ''} onChange={(e) => handleChangeAddress(e)} />
                </div>
              </div>
              {renderSelect()}
            </div>
          </div>
          <div className='shop-status-card-container col-12 border p-4 mt-4'>
            <div className='row'>
              <div className='col-12 d-flex border-bottom'>
                <i className='anticon icon-isv' />
                <p className='font-weight-bold pl-3 fs--s '>Instant Courier</p>
              </div>
              <div className='col-12 pt-4 pb-5'>
                Instant Courier
                <span className='ml-4'>
                  <label className='switch'>
                    <input type='checkbox' checked={clickedInstantCourier} onChange={(e) => setClickedInstantCourier(!clickedInstantCourier)} />
                    <span className='slider round' />
                  </label>
                </span>
              </div>
              {clickedInstantCourier &&
              (
                <>
                  <div className='col-12 mb-4'><i className='anticon icon-enviroment' />&nbsp;&nbsp;Pilih Koordinat Lokasi</div>
                  <div className='col-12'>
                    {renderMap()}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='col-12 pt-4 px-0'>
            {error.err && (
              <InfoBox
                text={error.message}
                icon='danger'
                status='danger'
              />
            )}
            {checkRole({
              menu: 'supplier_profile',
              method: 'view',
              attribute: 'all',
              yes: (access) => {
                return (
                  <div className='row'>
                    <div className='col-6'>
                      <button disabled={fetching} onClick={() => handleInit()} className='btn btn-ghost-secondary btn-m btn-full'><p className='mb-0 fs--s font-weight-bold'>Reset</p></button>
                    </div>
                    <div className='col-6'>
                      <button disabled={fetching} onClick={handleChangeSupplierInfo} className='btn btn-primary btn-m btn-full'>
                        <p className='mb-0 fs--s font-weight-bold'>
                          {fetching
                            ? 'Mohon Menunggu ...'
                            : 'Simpan'}
                        </p>
                      </button>
                    </div>
                  </div>
                )
              },
              no: (access) => { return null },
              userRules: user?.role
            })}

          </div>
        </div>
      </div>
    </div>
  )
}
