import React, { useState, useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { ScaleContext } from '../../Context/ScaleContext'
import Loading from '../../Components/Behaviors/Loading'
import PrintPackingSlipModal from '../Modals/Order/PrintPackingSlipModal'
import SeeItemsListsModal from '../Modals/Order/SeeItemsListsModal'

import BookingCodeModal from '../Modals/Order/BookingCodeModal'
import InputAWBModal from '../Modals/Order/InputAWBModal'
import CommentModal from '../Modals/CommentModal'
import Pagination from '../Pagination'
import ShipmentStatusModal from '../Modals/Order/ShipmentStatusModal'
import config from '../../config'
import OrderGroupContainer from './OrderGroupContainer'
import ShipmentFeeModal from '../Modals/Order/ShipmentFeeModal'
import TrackingNumberModal from '../Modals/Order/TrackingNumberModal'

export default function ReadyToShipDetail ({ params, handleParamsChange, fetchUpdateInvoiceStatus, invoiceList, fetching, error }) {
  const count = useSelector(state => state.count)

  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const [paramsModified, setParamsModified] = useState({ status: 'ready_to_ship', page: 1, keyword: '', supplier: params?.supplier || '' })
  const [readyToShipInvoiceList, setReadyToShipInvoiceList] = useState()
  const [selectedInvoice, setSelectedInvoice] = useState()

  const [onClickedModal, setOnClickedModal] = useState({
    seeProductListModal: false,
    printPackingSlipModal: false,
    bookingCodeModal: false,
    inputAwbModal: false,
    commentModal: false,
    shipmentStatusModal: false,
    shipmentFeeModal: false,
    seeTrackingNumberModal: false
  })

  function handleToogleModal (state, status, invoice) {
    if (status === 'open') {
      setSelectedInvoice(invoice)
      setOnClickedModal({ [state]: true })
    } else {
      setSelectedInvoice()
      setOnClickedModal({ [state]: false })
    }
  }

  const handleTooglePrintPackingSlip = (status, invoice) => {
    if (status === 'open') {
      window.location.replace(config.baseURL + '/print?status=ready_to_ship&type=packing_slip&invoice_id=' + invoice.invoice_id)
    }
  }

  useEffect(() => {
    handleParamsChange(paramsModified)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (invoiceList !== readyToShipInvoiceList) setReadyToShipInvoiceList(invoiceList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceList, readyToShipInvoiceList])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== params.page) {
      setParamsModified({ ...params, page: selected + 1 })
      handleParamsChange({ ...params, page: selected + 1 })
    }
  }

  function renderModal () {
    return (
      <>
        {onClickedModal.seeProductListModal &&
          <SeeItemsListsModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.seeTrackingNumberModal &&
          <TrackingNumberModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.printPackingSlipModal &&
          <PrintPackingSlipModal
            invoice={selectedInvoice}
            type='ready_to_ship'
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
            fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
          />}
        {onClickedModal.bookingCodeModal &&
          <BookingCodeModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.inputAwbModal && (
          <InputAWBModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
            fetchUpdateInvoiceStatus={fetchUpdateInvoiceStatus}
          />
        )}
        {onClickedModal.shipmentFeeModal &&
          <ShipmentFeeModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.commentModal &&
          <CommentModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
        {onClickedModal.shipmentStatusModal &&
          <ShipmentStatusModal
            invoice={selectedInvoice}
            onClickedModal={onClickedModal}
            handleToogleModal={handleToogleModal}
          />}
      </>
    )
  }

  const renderDesktop = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 px-5 py-4'>
            <div className='row align-items-center'>
              <div className='col-8 pb-3'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.ready_to_ship} ready to pick up</h1>
              </div>
              <div className='col-4' style={{ display: 'flex', justifyContent: 'center' }}>
                {readyToShipInvoiceList?.total_invoice > 10 &&
                  <Pagination
                    total={readyToShipInvoiceList?.total_invoice}
                    show={10}
                    classNames='pagination pull-right'
                    handlePageClick={handlePageClick}
                  />}
              </div>

            </div>
          </div>

          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='ready_to_ship'
                invoices={readyToShipInvoiceList}
                handleToogleModal={handleToogleModal}
                handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlip}
              />}
          {readyToShipInvoiceList?.invoices && renderModal()}
        </div>
      )
    }
  }

  const renderMobile = () => {
    if (error.err) {
      return (
        <div className='row text-center align-content-center mt-5 pt-5' style={{ height: '400px' }}>
          <div className='col-12 '>
            <span className='fs--m error'>{error.message}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row'>
          <div className='col-12 p-3'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='fs--m font-weight-bold'>Anda mempunyai {count?.count_order?.ready_to_ship} ready to ship</h1>
              </div>
            </div>
          </div>
          {fetching
            ? (
              <div className='col-12'>
                <Loading type='alt' />
              </div>
              )
            : <OrderGroupContainer
                type='ready_to_ship'
                invoices={readyToShipInvoiceList}
                handleToogleModal={handleToogleModal}
                handleTooglePrintPackingSlipPrint={handleTooglePrintPackingSlip}
              />}
          {readyToShipInvoiceList?.invoices && renderModal()}
          <div className='col-12'>
            {readyToShipInvoiceList?.total_invoice > 10 &&
              <Pagination
                total={readyToShipInvoiceList?.total_invoice}
                show={10}
                classNames='pagination pull-right'
                handlePageClick={handlePageClick}
              />}
          </div>
        </div>
      )
    }
  }

  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
