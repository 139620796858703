import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ScaleContext } from '../../../Context/ScaleContext'
import { fetchGetProfileInfo } from '../../../Services/FetchingGroup'
import InfoBox from '../../InfoBox'

export default function ProductWeightAndDeliveryForm ({ product, ownfleetList, expressCarrierList, expressCarrierSelected, setProduct, handleCheckboxClick, method, supplierId }) {
  const user = useSelector(state => state.user)
  const [attbContext] = useContext(ScaleContext)
  const { isMobileView } = attbContext

  const [profile, setProfile] = useState({})

  useEffect(() => {
    // need to check for the express courier compability first
    if (user?.user?.role_id === 1) {
      const params = { supplier_id: parseInt(supplierId) !== 0 ? parseInt(supplierId) : '' }
      fetchGetProfileInfo(params, setProfile, null, null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetProduct = (e) => {
    const { name, value } = e.target
    setProduct(product => ({ ...product, [name]: value }))
  }

  function renderInputText (name, placeholder, value) {
    if (method === 'add' || method === 'update') {
      return <input defaultValue={product[name] || ''} className={isMobileView ? 'fs--s' : 'fs--m'} placeholder={placeholder} name={name} type='text' onBlur={(e) => handleSetProduct(e)} />
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input className={isMobileView ? 'fs--s' : 'fs--m'} name={name} type='text' value={value || '-'} disabled />
        </div>
      )
    } else return null
  }

  function renderCarrier () {
    // if (method === 'add' || method === 'update') {
    let isInstantCourier = 0
    if (user?.user?.role_id !== 2 && !isEmpty(profile)) {
      isInstantCourier = profile?.supplier_addresses[0]?.instant_courier_status
    } else {
      isInstantCourier = user?.supplier?.supplier_addresses[0]?.instant_courier_status
    }
    if (isInstantCourier === 10) {
      return expressCarrierList?.map((carrier, index) => {
        if (+carrier?.carrier_id !== 23) {
          return (
            <label className='container-checkbox mb-0' key={index}>
              <input
                type='checkbox'
                name='express_carrier'
                disabled={method === 'view'}
                value={parseInt(carrier.carrier_id)}
                checked={expressCarrierSelected.includes(parseInt(carrier.carrier_id))}
                onChange={(e) => handleCheckboxClick(e, carrier)}
              />
              <span className='checkbox' />
              <span>{carrier.carrier_name}</span>
            </label>
          )
        } else return null
      })
    } else return <label>Tolong nyalakan instant courier untuk mengaktifkan ini</label>
  }

  function renderOwnfleet () {
    if (method === 'add' || method === 'update') {
      return (
        <select className='input' name='ownfleet_template_id' defaultValue={product?.ownfleet_template_id?.toString() || ''} onChange={(e) => handleSetProduct(e)}>
          <option value=''>Pilih Template Ownfleet</option>
          {ownfleetList.map((template, index) => {
          // need to search alternatif using selected in option because the selected defaultValue doesn't work
            return (
              <option key={index} value={template.ownfleet_template_id} selected={template.ownfleet_template_id === product?.ownfleet_template_id?.toString()}>{template.template_name}</option>
            )
          })}
        </select>
      )
    } else if (method === 'view') {
      return (
        <div className='view-only'>
          <input name='ownfleet_template_id' type='text' value={product?.ownfleet_template_name} disabled />
        </div>
      )
    } else return null
  }

  const renderDesktop = () => {
    return (
      <div className='container-card-default fs--m'>
        <div className='fs--xxm font-weight-bold mb-5'>Berat dan Pengiriman</div>
        <InfoBox
          text='Untuk mengaktifkan opsi pengiriman instant, silahkan aktifkan pada menu profil'
          icon='info'
          status=''
        />
        <div className='row spacing-add-product'>
          <div className='col-2'>Berat<span className='required'>*</span></div>

          <div className='col-10'>
            <div className='row align-items-center'>
              <div className='col-3'>
                <div className='input-text-bar'>
                  {renderInputText('weight', 'Masukkan Berat', product?.weight)}
                </div>
              </div>
              <div className='col-3'>
                <span>KG (Kilogram)</span>
              </div>
            </div>
          </div>
        </div>

        {/* ============================== */}
        {/* ============================== */}
        <div className='row spacing-add-product'>
          <div className='col-2'>Dimensi Packaging<span className='required'>*</span></div>
          <div className='col-10'>
            <div className='row align-items-center'>
              <div className='col-3'>
                <div className='fs--s'>Panjang</div>
                <div className='input-text-bar'>
                  {renderInputText('packaging_length', '0.00', product?.packaging_length)}
                </div>
                <div className='operator-position'>X</div>
              </div>

              <div className='col-3'>
                <div className='fs--s'>Tinggi</div>
                <div className='input-text-bar'>
                  {renderInputText('packaging_height', '0.00', product?.packaging_height)}
                </div>
                <div className='operator-position'>X</div>
              </div>

              <div className='col-3'>
                <div className='fs--s'>Lebar</div>
                <div className='input-text-bar'>
                  {renderInputText('packaging_width', '0.00', product?.packaging_width)}
                </div>
              </div>

              <div className='col-3 mt-3'>
                <span>{product?.packaging_uom}</span>
              </div>

            </div>
          </div>
        </div>

        {/* ============================== */}
        {/* ============================== */}
        {expressCarrierList && (method === 'add' || method === 'update' || !isEmpty(product.express_courier)) &&
          <div className='row spacing-add-product'>
            <div className='col-2'>Pengiriman</div>
            <div className='col'>
              <div className='row spacing-add-product'>
                {renderCarrier()}
              </div>
            </div>
          </div>}
        {!isEmpty(ownfleetList) &&
          <div className='row spacing-add-product'>
            <div className='col-2'>Ownfleet</div>
            <div className='col-3'>
              <div className='input-text-bar'>
                {renderOwnfleet()}
              </div>
            </div>
          </div>}

        {/* ============================== */}
        {/* ============================== */}
      </div>

    )
  }

  const renderMobile = () => {
    return (
      <div className='border p-3 mb-4  fs--s '>
        <div className='col-12 font-weight-bold fs--m my-4 text-center'>Berat dan Pengiriman</div>
        <InfoBox
          text='Untuk mengaktifkan opsi pengiriman instant, silahkan aktifkan pada menu profil'
          icon='info'
          status=''
        />
        <div className='row spacing-add-product'>
          <div className='col-4 mb-2 fs--s '>Berat<span className='required'>*</span></div>
          <div className='col-4'>
            <div className='input-text-bar'>
              {renderInputText('weight', '0', product?.weight)}
            </div>
          </div>
          <div className='col pl-0 fs--s '>
            <span>KG (Kilogram)</span>
          </div>
        </div>

        <div className='row spacing-add-product'>
          <div className='col-12 mb-2 fs--s '>Dimensi produk<span className='required'>*</span></div>
          <div className='col-12'>
            <div className='row'>
              <div className='col-3'>
                <div className='input-text-bar fs--s '>
                  {renderInputText('packaging_length', '0.00', product?.packaging_length)}
                </div>
                <div className='fs--xs text-center'>Panjang</div>
                <div className='mobile-operator-position'>X</div>
              </div>

              <div className='col-3'>
                <div className='input-text-bar fs--s '>
                  {renderInputText('packaging_height', '0.00', product?.packaging_height)}
                </div>
                <div className='fs--xs text-center'>Tinggi</div>
                <div className='mobile-operator-position'>X</div>
              </div>
              <div className='col-3'>
                <div className='input-text-bar fs--s '>
                  {renderInputText('packaging_width', '0.00', product?.packaging_width)}
                </div>
                <div className='fs--xs text-center'>Lebar</div>
              </div>
              <div className='col-3 my-3'>
                <span>{product?.packaging_uom || 'CM'}</span>

              </div>
            </div>
          </div>
        </div>

        {expressCarrierList && !isEmpty(product.express_courier) &&
          <div className='row spacing-add-product fs--s '>
            <div className='col-12'>Pengiriman</div>
            <div className='col-12'>
              <div className='row' style={{ padding: '1rem 0.5rem' }}>
                {renderCarrier()}
              </div>
            </div>
          </div>}
      </div>
    )
  }
  if (isMobileView) return renderMobile()
  else return renderDesktop()
}
