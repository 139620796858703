import React from 'react'
import { isEmpty, startCase } from 'lodash'
import NumberWithCommas from '../../Utils/NumberWithCommas'
import config from '../../config'
import dayjs from 'dayjs'

export default function PrintInvoice ({ invoices }) {
  // const { group, method } = props
  if (invoices && invoices.invoices.length > 0) {
    let itemComplete = 0
    let grandTotal = 0
    return invoices.invoices.map((invoice, index) => {
      return (
        <div className='container-fluid filter print-font' key={index}>
          <div className='row py-5 fs--m'>
            <div className='col text-right'>
              <img src={`${config.assetsURL}/images/logo_ruparupa.png`} alt='logo-print' />
            </div>
            <table className='col-12 border-0'>
              <tbody>
                <tr>
                  <td width='15%'>Delivery Type</td>
                  <td width='2%'>:</td>
                  <td className='font-weight-bold'>{startCase(invoice.delivery_method)}</td>
                </tr>
                <tr>
                  <td>Order</td>
                  <td>:</td>
                  <td>{invoice.order_no}</td>
                </tr>
                <tr>
                  <td>Invoice</td>
                  <td>:</td>
                  <td>{invoice.invoice_no}</td>
                </tr>
              </tbody>
            </table>

            {/* POS VOUCHER */}
            {/* <table className='col-12 border-0'>
              <tbody>
                <tr>
                  <td>POS Voucher</td>
                </tr>
                <tr>
                  <td>Barcode</td>
                </tr>
              </tbody>
            </table> */}

            <table className='col-12 border-0 my-3'>
              <tbody>
                <tr>
                  <td width='50%'>
                    <div>Date: {dayjs(invoice.created_at).locale('id').format('dddd, DD MMMM YYYY')}</div>
                  </td>
                  {/* <td width='50%'>
                    <div>Site: StoreCode - Store Location</div>
                    <div>Account Code</div>
                  </td> */}
                </tr>
              </tbody>
            </table>

            <table className='col-12 border-0 my-3'>
              <thead>
                <tr>
                  <th>Sold to:</th>
                  <th>Ship to:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width='50%'>
                    <div>
                      <div>{invoice.shipping_address.first_name}</div>
                      <div>{invoice.shipping_address.full_address}</div>
                      <div>{invoice.shipping_address.kecamatan.kecamatan_name}, {invoice.shipping_address.city.city_name}</div>
                      <div>{invoice.shipping_address.province.province_name}, {invoice.shipping_address.post_code}</div>
                      <div>{invoice.shipping_address.country.country_name}</div>
                      <div>T: {invoice.shipping_address.phone}</div>
                    </div>
                  </td>
                  <td width='50%'>
                    <div>
                      <div>{invoice.shipping_address.first_name}</div>
                      <div>{invoice.shipping_address.full_address}</div>
                      <div>{invoice.shipping_address.kecamatan.kecamatan_name}, {invoice.shipping_address.city.city_name}</div>
                      <div>{invoice.shipping_address.province.province_name}, {invoice.shipping_address.post_code}</div>
                      <div>{invoice.shipping_address.country.country_name}</div>
                      <div>T: {invoice.shipping_address.phone}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className='col-12 border-0 my-5'>
              <thead>
                <tr>
                  <th width='50%'>Product</th>
                  <th width='14%'>SKU</th>
                  <th width='15%'>POS Price</th>
                  <th width='5%'>Qty</th>
                  <th width='18%' className='text-right'>Row Total</th>
                </tr>
                <tr>
                  <td colSpan='5'>
                    <hr className='print-hr' />
                  </td>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, index) => {
                  if (item.status_fulfillment === 'complete') {
                    grandTotal = grandTotal + item.rowtotal
                    itemComplete++
                    return (
                      <tr key={index}>
                        <td className='text-left'>
                          <div>{item.name}</div>
                          {!isEmpty(item.modified_description) &&
                            <div className='fs--s'><b>Nama Promo :</b> {item.modified_description}</div>}
                        </td>
                        <td className='align-top'>{item.sku}</td>
                        <td className='align-top font-weight-bold'>Rp {NumberWithCommas(item.selling_price)}</td>
                        <td className='align-top font-weight-bold'>{item.qty_ordered}</td>
                        <td className='align-top font-weight-bold text-right'>Rp {NumberWithCommas(item.rowtotal)}</td>
                      </tr>
                    )
                  } else return null
                })}
                {itemComplete === 0 && <tr><td>Produk-produk dalam orderan ini belum di cek kelengkapannya atau semua produknya tidak complete.</td></tr>}
                <tr>
                  <td colSpan='5'>
                    <hr className='print-hr' />
                  </td>
                </tr>
                <tr>
                  <td className='font-weight-bold text-right' colSpan='4'>Grand Total :</td>
                  <td className='font-weight-bold text-right'>Rp {NumberWithCommas(grandTotal)}</td>
                </tr>
              </tbody>
            </table>

            <div className='fs--s my-5'>
              <h3><b>BAGAIMANA CARA MELAKUKAN PENGEMBALIAN</b></h3>
              <p>Untuk pengembalian, mohon mengisi formulir pada link berikut www.ruparupa.com/faq-pengembalian.
Pengembalian tidak akan diproses jika Anda tidak mengisi formulir di atas.
              </p>
              <p>Kami percaya bahwa kepuasan pelanggan adalah prioritas kami. Jika Anda tidak puas dengan produk yang dikirimkan oleh Ruparupa, maka Anda dapat melakukan proses pengembalian produk dalam jangka waktu 14 hari setelah produk diterima. Ketentuan ini hanya berlaku untuk produk yang dijamin oleh seller. Untuk proses pengembalian barang, Anda dapat menghubungi customer service kami melalui help@ruparupa.com atau (021) 582-9191.
              </p>
              <br /><br />
              <h3><b>TERIMAKASIH TELAH BERBELANJA DI RUPA-RUPA.com</b></h3>
              <p>Kami harap anda menyukai produk yang anda pesan. Apabila anda membutuhkan bantuan lainnya, silahkan kirim email ke help@ruparupa.com kami tunggu pembelanjaan anda berikutnya</p>
            </div>
          </div>
        </div>
      )
    }
    )
  } else return null
}
