import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  commentRequest: ['data'],
  commentSuccess: ['data'],
  commentFailure: ['err'],
  commentInit: null
})

export const CommentTypes = Types
export default Creators

// Initial State
export const INITIAL_STATE = Immutable({
  err: null,
  fetching: false,
  success: false,
  comment: null
})

export const init = (state) => {
  return ({
    ...state,
    fetching: false,
    err: null,
    comment: null
  })
}

export const request = (state) => {
  return ({
    ...state,
    success: false,
    fetching: true
  })
}

export const success = (state, { data }) => {
  return ({
    ...state,
    success: true,
    fetching: false,
    comment: data,
    err: null
  })
}

export const failure = (state, { err }) => {
  return ({
    ...state,
    success: false,
    fetching: false,
    err
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COMMENT_REQUEST]: request,
  [Types.COMMENT_SUCCESS]: success,
  [Types.COMMENT_FAILURE]: failure,
  [Types.COMMENT_INIT]: init
})
