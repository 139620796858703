import React, { useEffect, useRef } from 'react'
import config from '../../../config'
import imageDefault from '../../../static/images/WaitingForApproval.jpg'
import noImagePlaceholder from '../../../static/images/noImage.png'

import ProductHeader from '../../../Containers/Products/ProductHeader'
import SearchProduct from '../SearchProduct'
import { Link } from 'react-router-dom'
import { startCase } from 'lodash'
import SelectInput from '../SelectInput'
import Loading from '../../Behaviors/Loading'

export default function RejectedProductListDesktop ({
  listProduct,
  params,
  supplierId,
  supplierDetail,
  sortList,
  fetching,
  error,
  handleChangeParams,
  handleSelect
}) {
  const selectedSort = useRef(0)

  useEffect(() => {
    const filteredSort = sortList?.filter(element => element.type === params.sort_type && element.by === params.sort_by)
    if (filteredSort?.length > 0) {
      selectedSort.current = filteredSort[0].id
    } else selectedSort.current = 0

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderBody () {
    if (fetching) {
      return (
        <tr>
          <td colSpan={3}>
            <Loading type='alt' />
          </td>
        </tr>
      )
    } else if (error.err) {
      return (
        <tr>
        <td colSpan={3}>
        <div className='row text-center align-content-center' style={{ height: '400px' }}>
      <div className='col-12'>
        <span className='fs--m error'>{error.message}</span>
      </div>
    </div>
        </td>
        </tr>
      )
    } else {
      if(listProduct?.items?.length > 0){
        return (listProduct?.items?.map((product, key) => {
          return (
            <tr className='fs--m' key={key}>
              <td className='p-5'>
                <div className='row'>
                  <div className='col-3'>
                    <img src={product?.image_url ? config.imageURL + product?.image_url : imageDefault} alt='thumbnail' width='100' onError={(e)=> { e.target.src = noImagePlaceholder }}/>
                  </div>
                  <Link className='col' to={`/product-view/view?sku=${product?.sku || ''}&store_code=${params?.store_code || ''}&supplier_alias=${params?.supplier_alias || ''}`} >
                    <div className='title-product font-weight-bold'>{startCase(product.name)}</div>
                    <div className='row'>
                    <div className='col-4'>SKU Ruparupa: </div>
                    <div className='col'>{product.sku}</div>
                    </div>
                    <div className='row'>
                    <div className='col-4'>SKU Toko:</div>
                    <div className='col'>{product.sku_seller}</div>
                    </div>
                  </Link>
                </div>
              </td>
               <td className='p-5'>{product.reason}</td>
               <td className='p-5'>
               <Link className='link' to={`/product-view/update?sku=${product.sku}&store_code=${params.store_code}&supplier_alias=${params.supplier_alias}`}>
                <div ><i className='anticon icon-edit fs--xm' /></div>
                  </Link>
               </td>
          </tr>
          )
        })
        )
      } else {
        return (
          <tr>
          <td colSpan={3}>
          <div className='row text-center align-content-center' style={{ height: '400px' }}>
        <div className='col-12'>
          <span className='fs--m'>Product tidak ada</span>
        </div>
      </div>
          </td>
          </tr>
        )
      }
    }
  }
  return (
    <div className='col-12 col-lg offset-lg-2 pl-0'>
      <div className='product-header'>
        <div className='p-5'>
          <ProductHeader supplierDetail={supplierDetail} supplierId={supplierId} params={params} type='rejectedProduct' />
        </div>
      </div>
      <div className='product-background'>
        <div className='pt-5 pl-4 pr-4 pb-4'>
          <div className='row'>
            <div className='col-7'>
              <SearchProduct handleChangeParams={handleChangeParams} type='rejected' />
            </div>
            <div className='col'>
              <div className='row align-items-center fs--s '>
                <SelectInput
                  label='Sort By'
                  optionGroup={sortList}
                  action={handleSelect}
                  defaultValue={selectedSort.current}
                  name='sort'
                  labelClass='col-2 text-center font-weight-bold'
                  inputClass='col'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pl-4 pr-4 py-5'>
        <table className='table fs--m'>
          <thead>
            <tr className='fs--m'>
              <th className='p-5' width='35%'>Produk</th>
              <th className='p-5' width='30%'>Alasan Produk Tidak Authorized</th>
              <th className='p-5' width='5%%' />
            </tr>
          </thead>
          <tbody>
          {renderBody()}
          </tbody>
        </table>
      </div>
    </div>
  )
}
