// BARU SIAPIN MODAL AJA
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useDropzone } from 'react-dropzone'

import { Modal } from 'react-bootstrap'
// import { ScaleContext } from '../../../Context/ScaleContext'
import { useSelector } from 'react-redux'
import Loading from '../../../Components/Behaviors/Loading'
import { fetchGetMiscFile, fetchShipmentRemarkReceived, fetchUpdateInvoice } from '../../../Services/FetchingGroup'
import InfoBox from '../../../Components/InfoBox'

function ReceivedModal ({ invoice, onClickedModal, handleToogleModal, fetchUpdateInvoiceStatus }) {
  const user = useSelector(state => state.user)

  const maxSize = 1048576

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxSize: maxSize,
    multiple: false
  })

  const [err, setErr] = useState({ err: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [fileName, setFileName] = useState('No File Choosen')
  const [file, setFile] = useState()
  const [imageFile, setImageFile] = useState('')

  // Upload csv or excel
  const fileAccepted = (acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = e => {
      setFileName(acceptedFiles[0].name)
      setFile(e.target.result)
    }
    if (!isEmpty(file)) {
      return (
        <div className='container pb-4'>
          {/* File terpilih:  <b> <a href={file} download={acceptedFiles[0].name}>{acceptedFiles[0].path}</a></b> */}
          <img id='myImg' src={file} alt={acceptedFiles[0].name} style={{ width: '100%', maxWidth: '180px' }} />
          {/* <img src={file} width='150' height='150' /> */}
          <InfoBox
            text='Pastikan untuk mengecek file yang sudah dipilih dengan mendownload kembali data yang sudah diupload sebelum melanjutkan.'
            icon='info'
            status=''
          />
        </div>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    if (invoice?.shipment?.do_image) {
      const params = {
        key: invoice?.shipment?.do_image
      }
      fetchGetMiscFile(params, setImageFile, setIsLoading, setErr)
    }
  }, [invoice])

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.map(({ file, errors }) => {
        const errorMessage = errors.map((error) => {
          return error.code === 'file-invalid-type' ? 'Tipe foto harus .jpg, .jpeg, .png' : 'Ukuran file lebih dari 1 MB  '
        })
        setFile({})
        return setErr({ err: true, message: errorMessage })
      })
    }
  }, [fileRejections])

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      return setErr({ err: false, message: '' })
    }
  }, [acceptedFiles])

  const handleUpdateInvoiceStatus = (status) => {
    const body = {
      invoices: [invoice.invoice_id],
      status: status
    }
    if (user.user?.role_id !== 2) {
      fetchUpdateInvoice(body, setIsLoading, setErr, null)
    } else {
      if (invoice.shipment.carrier.carrier_id === 3) {
        const body = {
          invoice_id: invoice.invoice_id,
          do_image: file
        }
        fetchShipmentRemarkReceived(body, setIsLoading, setErr, setSuccess)
      } else {
        fetchUpdateInvoice(body, setIsLoading, setErr, null)
      }
    }
  }

  const handleInit = () => {
    setErr({ err: false, message: '' })
    setSuccess({ success: false, data: {} })
    setIsLoading(false)
    setFileName('No File Choosen')
    setFile()
  }

  useEffect(() => {
    // this should only happened when you were a seller, because this were feedback
    // after you upload the data for shipment remarked
    // ? is this need to be validation for seller (?)
    if (success.success) {
      const bodyUpdate = {
        invoices: [invoice.invoice_id],
        status: 'pending_delivered'
      }
      fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, null)
      handleInit()
    }
  }, [success, invoice])

  function renderModalBody () {
    if (user.user?.role_id !== 2) {
      return (
        <div className='row justify-content-center text-center pl-5 mr-5 d-flex py-3'>
          {isLoading
            ? <center><Loading type='bounce' /></center>
            : (
              <div className='col-12 fs--m px-5 my-auto'>
                {imageFile?.url && <div className='pb-5'><img src={imageFile?.url} width='230' alt='Input Awb' /></div>}
                Silahkan konfirmasi jika order sudah diterima oleh customer
              </div>
              )}
        </div>
      )
    } else {
      return (
        <div className='row justify-content-center text-center pl-5 mr-5 d-flex py-3'>
          {isLoading
            ? (
              <center><Loading type='bounce' /></center>
              )
            : (invoice?.shipment?.do_image)
                ? (
                  <div className='col-12'>
                    <div className='pb-5'><img src={imageFile?.url} width='230' alt='Input Awb' /></div>
                  </div>
                  )
                : (invoice.shipment.carrier.carrier_id === 3)
                    ? (
                      <>
                        <div className='col-5 fs--s font-weight-bold px-5 my-auto'>Foto Bukti Pengiriman</div>
                        <div {...getRootProps({ className: 'col-2' })}>
                          <input {...getInputProps()} />
                          <button type='button' className='btn btn-outline-light text-dark btn-s border'>Choose File</button>
                        </div>
                        <div className='col'>
                          <p className='fs--s pl-3 my-auto'>{fileName}</p>
                        </div>
                        <div className='col-12'>
                          {acceptedFiles.length > 0 && fileAccepted(acceptedFiles)}
                        </div>
                      </>
                      )
                    : <div className='col-12 fs--m px-5 my-auto'>Konfirmasi penerimaan barang telah diteruskan ke Admin untuk verifikasi.</div>}
        </div>
      )
    }
  }

  function renderModalButtons () {
    if (user.user?.role_id !== 2) {
      if (invoice.shipment?.shipment_status === 'pending_delivered') {
        return (
          <>
            <div className='col'>
              <button type='button' onClick={() => handleUpdateInvoiceStatus('pending_received')} className='btn-ghost-primary btn-m btn-full'>Belum Diterima</button>
            </div>
            <div className='col'>
              <button onClick={() => handleUpdateInvoiceStatus('received')} type='button' className='btn-primary btn-m btn-full'>Sudah Diterima</button>
            </div>
          </>
        )
      } else if (invoice.shipment?.shipment_status === 'pending_received') {
        return (
          <>
            <div className='col-5'>
              <button type='button' onClick={() => handleToogleModal('receivedModal', 'close')} className='btn-ghost-primary btn-m btn-full'>Cancel</button>
            </div>
            <div className='col-5'>
              <button onClick={() => handleUpdateInvoiceStatus('received')} type='button' className='btn-primary btn-m btn-full'>Sudah Diterima</button>
            </div>
          </>
        )
      }
    } else {
      return (
        <>
          <div className='col'>
            <button type='button' onClick={() => handleToogleModal('receivedModal', 'close')} className='btn-ghost-primary btn-m btn-full'>{invoice?.shipment?.do_image ? 'Tutup' : 'Batal'}</button>
          </div>
          {!invoice?.shipment?.do_image && (
            <div className='col'>
              <button onClick={() => handleUpdateInvoiceStatus('pending_delivered')} type='button' className='btn-primary btn-m btn-full'>Lanjutkan</button>
            </div>
          )}
        </>
      )
    }
  }
  if (onClickedModal.receivedModal) {
    return (
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => { handleToogleModal('receivedModal', 'close'); handleInit() }}
        centered
      >
        <Modal.Header className='text-center' closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='fs--xxm font-weight-bold p-3'>
              {(user?.user?.role_id === 2 && invoice?.shipment?.do_image
                ? 'Bukti Input Awb'
                : 'Konfirmasi Penerimaan Paket'
                )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderModalBody()}
          {(err.err) && (
            <div className='col-12 fs--m px-5 my-auto error'>{err.message}</div>
          )}
        </Modal.Body>
        <Modal.Footer className='justify-content-center text-center px-4'>
          {renderModalButtons()}
        </Modal.Footer>
      </Modal>
    )
  } else return null
}

export default ReceivedModal
