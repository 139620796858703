import { isEmpty } from 'lodash'

export const checkRole = ({ menu, method, attribute, yes, no, empty, userRules }) => {
  let aFlag = 0
  if (userRules.rules) {
    const rules = userRules.rules
    // check the menu itself exist or not in the rules
    // this is lvl 1 permission: can access the menu
    const permitMethod = rules[menu]
    if (!permitMethod) {
      return no(method + ' ' + menu)
    } else {
      // permit Method contains two type of the data: object and string
      // we check for the string
      // this is lvl 2 permission: can do view, edit, delete, create
      if (permitMethod.includes(method)) {
        return yes('method', method)
      } else {
        // for object we need to check one by one from all the rules
        permitMethod.forEach(action => {
          // check which one is object
          // this is for checking is there any lvl 3 permission
          if (typeof action === 'object') {
            const permitAttb = action[method]
            if (permitAttb) {
              permitAttb.forEach(attb => {
                // this is for lvl 3 permission: can do some action that more specific inside the lvl 1 and lvl 2 permission
                if (!isEmpty(attribute)) {
                  if (attb === attribute) {
                    aFlag = 2
                    // return yes('attribute')
                  } else {
                    if (Object.keys(action).includes(method)) {
                      aFlag = 1
                      // return yes('method')
                    }
                  }
                  // if there's no match we'll back to the first checking which is checking lvl 1 permission
                  // at least it pass the lvl 1 permission
                } else if (Object.keys(action).includes(method)) {
                  aFlag = 1
                  // return yes('method')
                }
              })
            }
            // just in case there isn't any lvl 3 permission which means it isn't object and just string
            // at least it pass the lvl 1 permission
          } else {
            if (action === method) {
              aFlag = 1
              // return yes('method')
            }
          }
        })
      }
      if (aFlag === 1) {
        return yes('method')
      } else if (aFlag === 2) {
        return yes('attribute')
      } else {
        return no(attribute + ' ' + menu)
      }
    }
  } else return no(method + ' ' + menu)
}
