import React, { useState, useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import CheckProductDetail from '../../Orders/CheckProductDetail'

import { ScaleContext } from '../../../Context/ScaleContext'
import { isEmpty } from 'lodash'
import { fetchUpdateInvoice, fetchUpdateInvoiceItemAvailability, fetchGetRejectReasonList } from '../../../Services/FetchingGroup'

function CheckItemsModal ({ invoice, onClickedModal, handleToogleModal, handleParamsChange }) {
  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)

  const [productID, setProductID] = useState()
  const [showConditionalBoxes, setShowConditionalBoxes] = useState(false)
  const [itemSelected, setItemSelected] = useState([])
  const [scaleContext] = useContext(ScaleContext)
  const { isMobileView } = scaleContext
  const [listReason, setListReason] = useState([])

  const handleInit = () => {
    setIsLoading(false)
    setErr({ err: false, message: '' })
    setSuccess({ success: false, data: {} })
    setProductID()
    setShowConditionalBoxes(false)
    setItemSelected([])
  }

  function handleConditionalBoxes (invoice, states, choose, reason, note) {
    setProductID(invoice.invoice_item_id)
    setShowConditionalBoxes(states)
    const items = itemSelected
    const find = itemSelected.findIndex(e => e.invoice_item_id === invoice.invoice_item_id)
    if (find !== -1) {
      items[find] = {
        invoice_item_id: invoice.invoice_item_id,
        status_fulfillment: choose,
        reason: reason,
        note: note
      }
    } else {
      items.push({
        invoice_item_id: invoice.invoice_item_id,
        status_fulfillment: choose,
        reason: reason,
        note: note
      })
    }
    setItemSelected(items)
  }

  const handleProductID = (index) => {
    setProductID(index)
  }

  useEffect(() => {
    if (!onClickedModal.checkItemsModal) {
      setItemSelected([])
    }
  }, [onClickedModal.checkItemsModal])

  useEffect(() => {
    if (success.success) {
      if (invoice.shipment?.shipment_status === 'processing') {
        const bodyUpdate = {
          invoices: [invoice.invoice_id],
          status: 'stand_by'
        }
        const bodyCheck = {
          invoices: [
            {
              invoice_id: invoice.invoice_id,
              items: itemSelected
            }
          ]
        }
        // checking first if there is incomplete
        const filter = bodyCheck.invoices[0].items.filter(invoice => invoice.status_fulfillment === 'incomplete').length
        if (filter !== bodyCheck.invoices[0].items.length) {
          // we only need minimal 1 status fullfillment complete to update the invoice
          fetchUpdateInvoice(bodyUpdate, setIsLoading, setErr, setSuccess)
        } else {
        // if all the items is incomplete, their status will never be updated
          window.location.reload()
        }
      } else {
        // this for cancel, that only showed when you were in standby
        handleParamsChange({ status: invoice.shipment.shipment_status, page: 1 })
        handleToogleModal('checkItemsModal', 'close')
      }
      setSuccess({ success: false, data: {} })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  useEffect(() => {
    if (invoice.shipment.shipment_status !== 'processing') {
      // this for the recheck items fullfilment in stand by
      if (!isEmpty(invoice.items)) {
        const items = invoice.items
        items.forEach(element => {
          const body = {
            invoice_item_id: element.invoice_item_id,
            status_fulfillment: element.status_fulfillment,
            reason: element.reason,
            note: element.note
          }
          itemSelected.push(body)
        })
      }
    }
    fetchGetRejectReasonList({}, setListReason, setIsLoading, setErr)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e) => {
    setErr({ err: false, message: '' })
    const body = {
      invoices: [{
        invoice_id: invoice.invoice_id,
        items: itemSelected
      }]
    }
    if (invoice.items?.length === itemSelected?.length) {
      fetchUpdateInvoiceItemAvailability(body, setIsLoading, setErr, setSuccess)
    } else setErr({ err: true, message: 'Semua produk harus di cek kelengkapannya' })
  }

  function renderBodyModal () {
    if (!isMobileView) {
      return (
        <div className='container pt-3 pb-3'>
          <table className='table'>
            <thead>
              <tr className='fs--m my-auto'>
                <th className='pt-5 pb-5 pl-5' width='15%'>SKU</th>
                <th className='pt-5 pb-5'>Nama Barang</th>
                <th className='pt-5 pb-5' width='10%'>Qty</th>
                <th className='pt-5 pb-5'>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                invoice.items?.map((item, idx) => {
                  let show = true
                  if (invoice.shipment.shipment_status === 'stand_by') {
                    if (item.status_fulfillment === 'incomplete') show = false
                  }
                  if (show) {
                    return (
                      <CheckProductDetail
                        key={idx}
                        item={item}
                        invoice={invoice}
                        productID={productID}
                        handleProductID={handleProductID}
                        reasons={listReason}
                        handleConditionalBoxes={handleConditionalBoxes}
                        showConditionalBoxes={showConditionalBoxes}
                      />
                    )
                  } else return null
                })
              }
            </tbody>
          </table>
          {(err.err) &&
            (
              <div className='row fs--m'>
                <div className='col-12 pl-5 py-4 error'>
                  {err.message}
                </div>
              </div>
            )}
        </div>
      )
    } else {
      return (
        <div className='container pt-3 pb-3'>
          {invoice.items?.map((item, idx) => {
            return (
              <CheckProductDetail
                key={idx}
                item={item}
                productID={productID}
                handleProductID={handleProductID}
                reasons={listReason}
                handleConditionalBoxes={handleConditionalBoxes}
                showConditionalBoxes={showConditionalBoxes}
              />
            )
          })}
        </div>
      )
    }
  }

  return (
    <Modal
      show={onClickedModal.checkItemsModal}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => { handleToogleModal('checkItemsModal', 'close'); handleInit() }}
      centered
    >
      <Modal.Header className='text-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h1 className='pt-4 px-5'><b>Cek Kelengkapan {invoice.invoice_no}</b></h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {renderBodyModal()}
      </Modal.Body>
      <Modal.Footer className=' justify-content-center text-center m-2'>
        <div className='col-12'>
          <button type='button' disabled={isLoading || isEmpty(itemSelected)} onClick={(e) => handleSubmit(e)} className='btn-primary btn-m btn-full'>
            {isLoading ? 'Mohon Menunggu ...' : 'Lanjutkan'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CheckItemsModal
