import React from 'react'
import DefaultLayout from '../Containers/DefaultLayout'
import CommentSellers from '../Containers/Comments/CommentSellers'

export default function CommentSeller () {
  return (
    <DefaultLayout pages='comment-seller'>
      <CommentSellers />
    </DefaultLayout>
  )
}
