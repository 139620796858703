import React, { useState, useEffect } from 'react'

import dayjs from 'dayjs'
import { fetchGetProductCommission, fetchUpdateCommission } from '../../../Services/FetchingGroup'
import UpdateCommission from '../../../Components/Products/Modals/UpdateCommission'
import { isEmpty } from 'lodash'

export default function SetCommissionModal ({
  params,
  product,
  onClickedSetCommissionModal,
  handleToggleSetCommissionModal,
  handleChangeParams
}) {
  const [commission, setCommission] = useState({})
  const [commissionModified, setCommissionModified] = useState({})
  const [paramsGetCommission, setParamsGetCommission] = useState({ sku: product.sku, store_code: params.store_code, limit: 3, page: 1 })

  const [err, setErr] = useState({ err: false, message: '' })
  const [success, setSuccess] = useState({ success: false, data: {} })
  const [isLoading, setIsLoading] = useState(false)

  function handleInsertSpecialCommission () {
    const arr = commissionModified?.special_commissions
    const collection = {
      sku: paramsGetCommission?.sku, // sku
      store_code: paramsGetCommission?.store_code, // storeCode
      special_commission: commission?.commission,
      special_from_date: dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
      special_to_date: dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59'
    }
    arr.push(collection)
    setCommissionModified({ ...commission, special_commissions: arr })
  }

  function handleDelete (index) {
    const arr = commissionModified?.special_commissions
    arr.splice(index, 1)
    setCommissionModified({ ...commission, special_commissions: arr })
  }

  useEffect(() => {
    if (!isEmpty(commission)) {
      const arr = commission?.special_commissions
      const collection = []
      arr.forEach(element => {
        const body = {
          sku: commission.sku,
          store_code: commission.store_code,
          special_commission: element.special_commission,
          special_from_date: dayjs(new Date(element.special_from_date)).format('YYYY-MM-DD HH:mm:ss'),
          special_to_date: dayjs(new Date(element.special_to_date)).format('YYYY-MM-DD HH:mm:ss')
        }
        collection.push(body)
      })
      setCommissionModified({ ...commission, special_commissions: collection })
    }
  }, [commission])

  const handleOnChange = (name, index, value) => {
    const collection = commissionModified?.special_commissions
    collection[index].[name] = paramsGetCommission.store_code
    if (name === 'special_commission') {
      collection[index].[name] = +value
    } else {
      // for the start date and end date
      if (name === 'special_from_date') collection[index].[name] = dayjs(new Date(value)).format('YYYY-MM-DD 00:00:00')
      else if (name === 'special_to_date') collection[index].[name] = dayjs(new Date(value)).format('YYYY-MM-DD 23:59:59')
    }
    setCommissionModified({ ...commission, special_commissions: collection })
  }

  useEffect(() => {
    if (success.success) {
      setSuccess({ success: false, data: {} })
      handleToggleSetCommissionModal('close')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const handleSetCommission = () => {
    const body = {
      commission: commission.commission,
      sku: commission.sku,
      special_commissions: commissionModified.special_commissions,
      old_special_commissions: commission.special_commissions,
      store_code: commission.store_code
    }
    // if you change in the one page, the changes will not carried if you change the page and make some update in that page
    // example: you delete 1 schedule in page 1, and you didn't submit it but you change to the next page. The deleted schedule will be ignored
    fetchUpdateCommission(body, setIsLoading, setErr, setSuccess)
  }

  useEffect(() => {
    if (product.sku) {
      // const params = { sku: product.sku }
      handleParamsChange(paramsGetCommission)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageClick = (data) => {
    const selected = data.selected
    if (selected + 1 !== paramsGetCommission.page) {
      handleParamsChange({ ...paramsGetCommission, page: selected + 1 })
    }
  }

  const handleParamsChange = (paramsModified) => {
    const paramsChange = {
      ...params, ...paramsModified
    }
    setParamsGetCommission(paramsChange)
    setCommissionModified({})
    fetchGetProductCommission(paramsChange, setCommission, setIsLoading, setErr)
  }

  //! MOVE TO COMPONENT

  if (onClickedSetCommissionModal) {
    return (
      <UpdateCommission
        product={product}
        commissionModified={commissionModified}
        commission={commission}
        fetching={isLoading}
        error={err}
        handleParamsChange={handleParamsChange}
        handlePageClick={handlePageClick}
        handleInsertSpecialCommission={handleInsertSpecialCommission}
        handleToggleSetCommissionModal={handleToggleSetCommissionModal}
        handleSetCommission={handleSetCommission}
        handleDelete={handleDelete}
        handleOnChange={handleOnChange}
      />
    )
  } else return null
}
