import React, { useContext, useState } from 'react'
import { ScaleContext } from '../Context/ScaleContext'

export default function SearchPendingProduct ({ onKeyDown, handleParamsChange }) {
  const [stateContext] = useContext(ScaleContext)
  const { isMobileView } = stateContext

  const [keyword, setKeyword] = useState('')

  return (
    <div className='input-bar input-bar-product' style={isMobileView ? { width: '100%' } : { width: '75%%' }}>
      {/* <form onSubmit={(e) => handleFilter(e)} method='GET'> */}
      <input className='fs--s' name='keyword' value={keyword} onChange={(e) => setKeyword(e.target.value)} onKeyDown={(e) => onKeyDown(e)} placeholder='Order ID, Invoice ID (minimal 3 kata)' type='text' />
      <button className='search-btn-product' type='button' onClick={() => handleParamsChange({ keyword: keyword })}><i className='anticon icon-search1' /></button>
      {/* </form> */}
    </div>
  )
}
